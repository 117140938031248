import React, { useState, useEffect,useRef,useMemo,useCallback  } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Loader from '../../../Loader/Loader';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../../sass/pages/date-picker.css';
import { ToastContainer, toast } from 'react-toastify';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/he';
import SellerDropdown from '../../Custom/SellerDropdown/SellerDropdown';
import api from '../../../../apis/api';
import { fakeValues } from './Report_Type_1_static-data';
import { storage } from '../../../firebase/index';
import FileUpload from '../../../FileUpload/FileUpload';
import { readAndCompressImage } from 'browser-image-resizer';



import pic1 from '../../../../images/pic1.png';
import pic2 from '../../../../images/pic2.png';
import pic3 from '../../../../images/pic3.png';
import pic4 from '../../../../images/pic4.png';
import logo3 from '../../../../images/logo_new.png';
import pic5 from  '../../../../images/pic5.png';
import pic6 from  '../../../../images/pic6.png';
import pic7 from  '../../../../images/pic7.png';
import pic8 from  '../../../../images/pic8.png';
import pic9 from  '../../../../images/pic9.png';
import pic10 from  '../../../../images/pic10.png';
import pic11 from  '../../../../images/pic11.png';
import pic22 from  '../../../../images/pic22.png';
import pic23 from  '../../../../images/pic23.png';

import sig_asaf from  '../../../../images/sig_asaf.png';

import {add , multiply , divide , subtract, floor } from 'mathjs'
import CanvasDraw  from 'react-canvas-draw' ;
import {dataURIToBlob} from "browser-image-resizer/src/data_operations";
import ImageCapture from 'react-image-data-capture';



registerLocale('il', es);
const setWidth = (width) => ({ width: `${width}%` });



const Report_Type_1 = (props) => {
  const [currentReport, setCurrentReport] = useState(fakeValues);
  console.log(currentReport)
  const [oldReport, setoldReport] = useState(fakeValues);
  const { reportId } = props;

  const [isLoading, setIsLoading] = useState(false);

  const [imgSrc, setImgSrc] = useState(null);
  const [imgFile, setImgFile] = useState(null);

  const onCapture = (imageData) => {
    setImgSrc(imageData.webP);
    setImgFile(imageData.file);
    console.log(imageData)
    handleUpload_test(imageData)
  };

  const onError = useCallback((error) => { console.log(error) }, []);
  const config = useMemo(() => (
      { video: true,
        height: 300,
        weight: 300
      }), []);






  localStorage.removeItem("Sig_Drawing" +"_" + currentReport._id)

  const canvasRef = useRef(0);


  const CanvasSave_init = (data) => {
    localStorage.setItem("Sig_Drawing" + "_" + data, canvasRef.current.getSaveData());
    const sig_name = "Sig_Drawing" + "_" + data
    const image_canvas = dataURIToBlob(canvasRef.current.getDataURL())
    handleUpload_sig(image_canvas, sig_name)
  }


  const CanvasSave = () => {
    localStorage.setItem("Sig_Drawing" + "_" + currentReport._id, canvasRef.current.getSaveData());
    const sig_name = "Sig_Drawing" + "_" + currentReport._id
    const image_canvas = dataURIToBlob(canvasRef.current.getDataURL())
    handleUpload_sig(image_canvas, sig_name)

  }

  const CanvasClear = () => {
    canvasRef.current.clear();
  }


  // images state
  const [Image1, setImage1] = useState(null);
  const [Image2, setImage2] = useState(null);
  const [Image3, setImage3] = useState(null);
  const [Image4, setImage4] = useState(null);
  const [Image5, setImage5] = useState(null);
  const [Image6, setImage6] = useState(null);
  const [Image7, setImage7] = useState(null);
  const [Image8, setImage8] = useState(null);
  const [Image9, setImage9] = useState(null);
  const [Image10, setImage10] = useState(null);
  const [Image11, setImage11] = useState(null);
  const [Image12, setImage12] = useState(null);





  const [pImage1, setPImage1] = useState(null);
  const [pImage2, setPImage2] = useState(null);
  const [pImage3, setPImage3] = useState(null);
  const [pImage4, setPImage4] = useState(null);
  const [pImage5, setPImage5] = useState(null);
  const [pImage6, setPImage6] = useState(null);
  const [pImage7, setPImage7] = useState(null);
  const [pImage8, setPImage8] = useState(null);
  const [pImage9, setPImage9] = useState(null);
  const [pImage10, setPImage10] = useState(null);
  const [pImage11, setPImage11] = useState(null);
  const [pImage12, setPImage12] = useState(null);
  const [pSigImage, setSigImage] = useState(null);
  const [pImage_Test, setImage_Test] = useState(null);


  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCurrentReport({ ...currentReport, [name]: value });
  };

  const image_config = {
    quality: 0.5,
    width : 100,
    height : 100,
    mimeType: 'image/jpeg',
    resize: true
  };


  const handleUpload_test = async (image, id) => {

    let resizedImage = await readAndCompressImage(image, image_config);

    const uploadTask = storage.ref(`test_images/${id}`).put(resizedImage);
    uploadTask.on(
        'state_changed',
        (snapshot) => {
        },
        (error) => {
          console.log('Error_Image_Upload');
        }

        /*
        () => {
          storage
              .ref('test_images')
              .child(image.name)
              .getDownloadURL()
              .then((url) => {
              });
        }
        */

    );
  };






  const handleUpload = async (image, id, name) => {

    let resizedImage = await readAndCompressImage(image, image_config);

    const uploadTask = storage.ref(`images/${id}-${name}`).put(resizedImage);
    // const uploadTask = storage.ref(`images/${id}-${name}`)
    uploadTask.on(
        'state_changed',
        (snapshot) => {
          // const p = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
          // setProgress(p);
        },
        (error) => {
          console.log('Error_Image_Upload');
        },
        () => {

          storage
              .ref('images')
              .child(image.name)
              .getDownloadURL()
              .then((url) => {

              });
        }
    );
  };


  const handleUpload_sig = async (image,name) => {

    // let resizedImage = await readAndCompressImage(image, image_config);

    const uploadTask = storage.ref(`signatures/${name}`).put(image);
    // const uploadTask = storage.ref(`images/${id}-${name}`)
    uploadTask.on(
        'state_changed',
        (snapshot) => {
        },
        (error) => {
          console.log('Error_Upload_Signature');
        },
        () => {


        }
    );
  };


  const handleUpload_document = async (name) => {
    const  { data } = await api.get(`reports/${reportId}`);
    setoldReport(data)
    const blob = new Blob([JSON.stringify(data)],{type: "application/json"})
    const MyDocRef = storage.ref(`Documents/report_old_${name}.json`).put(blob)

    MyDocRef.on(
        'state_changed',
        (snapshot) => {
        },
        (error) => {
          console.log('Error_Upload_Old_Document');
        },
        () => {
        }
    );
  };



  const onGroupChangeClassification = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        classification: value,
      },
    });
  };



  const onGroupChangeComment = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        comments: value,
      },
    });
  };

  const onGroupChangeFinds = (e) => {
    const name = e.target.name;
    const value = e.target.value;


    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        finds: value,
      },
    });
  };

  const updateImages = async (id) => {
    if (Image1) {
      await handleUpload(Image1, id, 'image1');
    }
    if (Image2) {
      await handleUpload(Image2, id, 'image2');
    }
    if (Image3) {
      await handleUpload(Image3, id, 'image3');
    }
    if (Image4) {
      await handleUpload(Image4, id, 'image4');
    }
    if (Image5) {
      await handleUpload(Image5, id, 'image5');
    }
    if (Image6) {
      await handleUpload(Image6, id, 'image6');
    }
    if (Image7) {
      await handleUpload(Image7, id, 'image7');
    }
    if (Image8) {
      await handleUpload(Image8, id, 'image8');
    }
    if (Image9) {
      await handleUpload(Image9, id, 'image9');
    }
    if (Image10) {
      await handleUpload(Image10, id, 'image10');
    }
    if (Image11) {
      await handleUpload(Image11, id, 'image11');
    }
    if (Image12) {
      await handleUpload(Image12, id, 'image12');
    }
    if (imgSrc) {
      await handleUpload_test(imgSrc,id);
    }

  };

  const onGroupChangeSectionD_3VIN = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        VIN: value,
      },
    });
  };

  const onGroupChangeSectionD_3Classification = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        SectionD_3classification: value,
      },
    });
  };

  const onGroupChangeSectionD_3comments = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        SectionD_3comments: value,
      },
    });
  };

  const onGroupChangeSectionD_3value_GVW = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        value_GVW: value,
      },
    });
  };

  const onGroupChangeSectionD_3value_GCW = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        value_GCW: value,
      },
    });
  };

  const onGroupChangeSectionD_3value_Axle_1 = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        value_Axle_1: value,
      },
    });
  };

  const onGroupChangeSectionD_3value_Axle_2 = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        value_Axle_2: value,
      },
    });
  };

  const onGroupChangeSectionD_3reg_GVW = (e) => {
    const name = e.target.name;
    const value = e.target.value;


    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        reg_GVW: value,
      },
    });
  };

  const onGroupChangeSectionD_3reg_GCW = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        reg_GCW: value,
      },
    });
  };



  const onGroupChangeSectionD_3reg_Axle_1 = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        reg_Axle_1: value,
      },
    });
  };

  const onGroupChangeSectionD_3reg_Axle_2 = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        reg_Axle_2: value,
      },
    });
  };

  const onGroupChangeWeights_N1 = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        N1: value,
      },
    });
  };

  const onGroupChangeWeights_N2 = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        N2: value,
      },
    });
  };

  const onGroupChangeWeights_N3 = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        N3: value,
      },
    });
  };

  const onGroupChangeWeights_N4 = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        N4: value,
      },
    });
  };

  const onGroupChangeWeights_KWr = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        KWr: value,
      },
    });
  };

  const onGroupChangeWeights_KWf = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        KWf: value,
      },
    });
  };

  const onGroupChangeWeights_total_allow = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        weight_total_allow: value,
      },
    });
  };

  const onGroupChangeWeights_KWtot = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        KWtot: value,
      },
    });
  };

  const onGroupChangeWeights_tank_x = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        tank_x: value,
      },
    });
  };

  const onGroupChangeWeights_tank_y = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        tank_y: value,
      },
    });
  };

  const onGroupChangeWeights_tank_z = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        tank_z: value,
      },
    });
  };



  const onGroupChangeWeights_FWr = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        FWr: value,
      },
    });
  };


  const onGroupChangeWeights_FWf = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        FWf: value,
      },
    });
  };

  const onGroupChangeWeights_tank_total= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        tank_total: value,
      },
    });
  };


  const onGroupChangeWeights_FV= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        FV: value,
      },
    });
  };

  const onGroupChangeWeights_FP= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        FP: value,
      },
    });
  };

  const chk= true

  const onGroupChangeWeights_FWtot= (e) => {
    const name = e.target.name;
    const value = e.target.value;


    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        FWtot: value,
      },
    });
  };

  const onGroupChangeWeights_WB= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        WB: value,
      },
    });
  };

  const onGroupChangeWeights_Lf= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Lf: value,
      },
    });
  };

  const onGroupChangeWeights_VIN_front= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        VIN_front: value,
      },
    });
  };


  const onGroupChangeWeights_VIN_Back= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        VIN_Back: value,
      },
    });
  };

  const onGroupChangeWeights_PM= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        PM: value,
      },
    });
  };


  const onGroupChangeWeights_KW= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        KW: value,
      },
    });
  };


  const onGroupChangeWeights_GVWf= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        GVWf: value,
      },
    });
  };


  const onGroupChangeWeights_GVWr= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        GVWr: value,
      },
    });
  };


  const onGroupChangeWeights_GVWl_tot= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        GVWl_tot: value,
      },
    });
  };


  const onGroupChangeWeights_GVWr_tot= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        GVWr_tot: value,
      },
    });
  };


  const onGroupChangeWeights_GVW_accept= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        GVW_accept: value,
      },
    });
  };



  const onGroupChangeWeights_Seren_self_front= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Seren_self_front: value,
      },
    });
  };



  const onGroupChangeWeights_Seren_self_back= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Seren_self_back: value,
      },
    });
  };


  const onGroupChangeWeights_Seren_self_total= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Seren_self_total: value,
      },
    });
  };


  const onGroupChangeWeights_laugage_allow= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        laugage_allow: value,
      },
    });
  };



  const onGroupChangeWeights_laugage_a= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        laugage_a: value,
      },
    });
  };


  const onGroupChangeWeights_laugage_b= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        laugage_b: value,
      },
    });
  };


  const onGroupChangeWeights_laugage_c= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        laugage_b: value,
      },
    });
  };


  const onGroupChangeWeights_Seren_driver_front= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Seren_driver_front: value,
      },
    });
  };


  const onGroupChangeWeights_Seren_driver_back= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Seren_driver_back: value,
      },
    });
  };

  const onGroupChangeWeights_overload_WB= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        overload_WB: value,
      },
    });
  };



  const onGroupChangeWeights_Seren_driver_total= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Seren_driver_total: value,
      },
    });
  };

  const onGroupChangeWeights_Seren_total_front= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Seren_total_front: value,
      },
    });
  };

  const onGroupChangeWeights_Seren_total_back= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Seren_total_back: value,
      },
    });
  };

  const onGroupChangeWeights_Seren_total_all= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Seren_total_all: value,
      },
    });
  };

  const onGroupChangeWeights_Seren_total_front_accept= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Seren_total_front_accept: value,
      },
    });
  };

  const onGroupChangeWeights_Seren_total_back_accept= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Seren_total_back_accept: value,
      },
    });
  };

  const onGroupChangeWeights_Seren_total_all_accept= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Seren_total_all_accept: value,
      },
    });
  };

  const onGroupChangeWeights_DPW= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        DPW: value,
      },
    });
  };

  const onGroupChangeWeights_X1= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        X1: value,
      },
    });
  };

  const onGroupChangeWeights_X2= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        X2: value,
      },
    });
  };

  const onGroupChangeWeights_GVW= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        GVW: value,
      },
    });
  };

  const onGroupChangeDevices_Device= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Device: value,
      },
    });
  };

  const onGroupChangeDevices_Name= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Name: value,
      },
    });
  };

  const onGroupChangeDevices_Manufactor= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Manufactor: value,
      },
    });
  };

  const onGroupChangeDevices_masad= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        masad: value,
      },
    });
  };

  const onGroupChangeDevices_safety= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        safety: value,
      },
    });
  };

  const onGroupChangeDevices_date= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        date: value,
      },
    });
  };

  const onGroupChangeDevices_identity= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        identity: value,
      },
    });
  };


  const onGroupChangeDevices_report_lab= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        report_lab: value,
      },
    });
  };

  const onGroupChangeDevices_identity_lab= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        identity_lab: value,
      },
    });
  };

  const onGroupChangeDevices_model= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        model: value,
      },
    });
  };


  const [users, setUsers] = useState([]);

  const onSubmit = async (e) => {
    e.preventDefault();
    // const formData = new FormData();

    if (
        currentReport.testerName.toString() === '0' ||
        currentReport.testerName.toString().trim().length === 0
    ) {
      toast.error(' יש לבחור בודק טופס ');
      return;
    }
    try {
      setIsLoading(true);


      if (!currentReport._id) {

        const { data } = await api.post('reports/save', currentReport);
        //   await api.post('reports/save_v1', currentReport);


        toast.success('👍  הדוח נוצר בהצלחה');
        updateImages(data.id);

        setCurrentReport(data);
        setoldReport(data);
        CanvasSave_init(data._id)
        setIsLoading(false);

      } else {

        const { data_updated } = await api.post('reports/update', {
          _id: currentReport._id,
          payload: currentReport,
        });

        updateImages(currentReport._id);
        setIsLoading(false);
        CanvasSave()
        toast.success('👍  הדוח עודכן בהצלחה');
      }
    } catch (err) {
      setIsLoading(false);
    }
  };


  const setReportDetails = async () => {
    const { data } = await api.get(`reports/${reportId}`);
    setCurrentReport(data)
    load_document(`Documents/report_old_${reportId}.json`)
  }


  const getImageDetails = async () => {

    const Image1Name =    `${reportId}-image1`;
    const Image2Name =    `${reportId}-image2`;
    const Image3Name =    `${reportId}-image3`;
    const Image4Name =    `${reportId}-image4`;
    const Image5Name =    `${reportId}-image5`;
    const Image6Name =    `${reportId}-image6`;
    const Image7Name =    `${reportId}-image7`;
    const Image8Name =    `${reportId}-image8`;
    const Image9Name =    `${reportId}-image9`;
    const Image10Name =    `${reportId}-image10`;
    const Image11Name =    `${reportId}-image11`;
    const Image12Name =    `${reportId}-image12`;
    const Sig =  `Sig_Drawing_${reportId}`;
    const image_testName = `${reportId}`;


    loadImages(Image1Name, 1);
    loadImages(Image2Name, 2);
    loadImages(Image3Name, 3);
    loadImages(Image4Name, 4);
    loadImages(Image5Name, 5);
    loadImages(Image6Name, 6);
    loadImages(Image7Name, 7);
    loadImages(Image8Name, 8);
    loadImages(Image9Name, 9);
    loadImages(Image10Name, 10);
    loadImages(Image11Name, 11);
    loadImages(Image12Name, 12);
    load_sig_Image(Sig);
    loadImages(Image12Name, 12);


  };

  useEffect(() => {
    const getUsers = async () => {
      try {
        const { data } = await api.get('users/');
        setUsers(data);
      } catch (err) {
        console.log(err);
      }
    };

    setReportDetails();
    getUsers();
    getImageDetails()

  }, []);





  useEffect(() => {

  }, [currentReport]);


  const load_document = (name) => {

    storage
        .ref(`${name}`)
        .getDownloadURL()
        .then(onResolve, onReject);
    function onResolve(foundURL) {
      axios.get(foundURL).then(resp => {
        setoldReport(resp.data)
      })
    }
    function onReject(error) {

      handleUpload_document(reportId)

      storage
          .ref(`${name}`)
          .getDownloadURL()
          .then((url) => {
            axios.get(url).then(resp => {
              setoldReport(resp.data)
            })
          })
    }
  };


  const delete_old_report = () => {
    storage
        .ref(`Documents/report_old_${reportId}.json`)
        .delete()
        .then(() => {
          setoldReport(currentReport);
        });
  };


  const load_sig_Image = (name) => {
    storage
        .ref(`signatures/${name}`)
        .getDownloadURL()
        .then((url) => {
          setSigImage(url);
        });
  };

  const loadImages = (name, type) => {

    storage
        .ref(`images/${name}`)
        .getDownloadURL()
        .then((url) => {

          switch (type) {
            case 1: {

              setPImage1(url);
              break;
            }
            case 2: {

              setPImage2(url);
              break;
            }
            case 3: {

              setPImage3(url);
              break;
            }
            case 4: {

              setPImage4(url);
              break;
            }
            case 5: {

              setPImage5(url);
              break;
            }
            case 6: {

              setPImage6(url);
              break;
            }
            case 7: {

              setPImage7(url);
              break;
            }
            case 8: {

              setPImage8(url);
              break;
            }
            case 9: {

              setPImage9(url);
              break;
            }

            case 10: {

              setPImage10(url);
              break;
            }
            case 11: {

              setPImage11(url);
              break;
            }
            case 12: {

              setPImage12(url);
              break;
            }


            default: {
              break;
            }
          }
        });
  };

  const loadImage_Test = (name) => {

    storage
        .ref(`test_images/${name}`)
        .getDownloadURL()
        .then((url) => {

          setImage_Test(url)

        });
  };


  return (
      <React.Fragment>

        <form  style={{width: '100%'}}
               onSubmit={onSubmit} >
          <div className='reports__container' style={{width: '100%'}}>
            <div>
              <button
                  onClick={delete_old_report}
              >
              </button>
            </div>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 1 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>



              <tr className='reports__table-header'>
                <td style={setWidth(25)}>
                  <table border='0' className='table-no-border width-100'>
                    <tr>
                      <td className='text-align-center'>טופס בדיקה מס: f-02</td>
                    </tr>
                    <tr>
                      <td className='text-align-center font-0-8'>
                        עורך מסמך:
                        {` ${props.user.firstName} ${props.user.lastName}`}
                      </td>
                    </tr>
                  </table>
                </td>
                <td className='text-align-center' style={setWidth(50)} colSpan='2'>
                  בדיקה פרטנית לרכב משא הנבנה בשלבים בהתאמה להוראת משרד התחבורה
                  H-02-2018
                </td>
              </tr>
            </table>

            <h2 className='mt-3 text-align-center'>

            </h2>

            {currentReport.isApproved > 0 &&
            <div className='reports__container' style={{maxwidth: '40em'}}>
              <table className='reports__table'>
                <tr className='reports__table-header'>
                  <td style={setWidth(20)}>&nbsp;
                    <h2 className='text-align-center font-weight-700'>
                      <div className='table-no-border'>
                        <td >  תאריך :{moment().format('DD-MM-YY')}</td>
                      </div>
                    </h2>
                  </td>
                  <td
                      style={setWidth(20)}
                      className=' text-align-center font-weight-700'
                  >
                    שם מאשר הטופס:  {` ${props.user.firstName} ${props.user.lastName}`}
                  </td>
                  <td className='text-align-center font-weight-700'>
                    חתימה :
                    <img src={sig_asaf} alt={''} style={{width: '10%'}}/>
                  </td>
                </tr>
              </table>
            </div>
            }






            <h1 className='mt-5 flex justify-content-center gap-1'>
              <p>בדיקה מספר</p>

              <input
                  type='text'
                  name='testId'
                  className='w-200'
                  value={currentReport.testId}
                  onChange={onChange}
              />
            </h1>

            <h1 className='mt-5 text-align-center'>
              <u>  בהתאם להוראת נוהל משרד התחבורה H-02-2018.</u>
            </h1>

            <h2 className='text-underline mt-5'><u>א.כללי</u></h2>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{ maxWidth: '80rem' }}
              >
                <tr>
                  <td>דוח הבדיקה מס'</td>
                  <td>

                    <input className='reports_text'
                           type='text'
                           name='testReportNumber'
                           value={currentReport.testReportNumber=currentReport.testId}
                           onChange={onChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>שם מזמין הבדיקה</td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='ordereeName'
                           value={currentReport.ordereeName}
                           onChange={onChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>מענו</td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='ordereeAddress'
                           value={currentReport.ordereeAddress}
                           onChange={onChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>הזמנה מס'</td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='orderId'
                           value={currentReport.orderId}
                           onChange={onChange}
                    />
                  </td>
                </tr>

                <tr>
                  <td>רכב מקטגוריות  - </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           list='Category_Vehicle_list'
                           name='Category_Vehicle'
                           value={currentReport.Category_Vehicle}
                           onChange={onChange}
                    />
                    <datalist id="Category_Vehicle_list">
                      <option value="N2"> N2</option>
                      <option value="N3"> N3</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>רישום ראשוני/שינוי מבנה</td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           list='header_option_list'
                           name='header_option'
                           value={currentReport.header_option}
                           onChange={onChange}
                    />
                    <datalist id="header_option_list">
                      <option value="רישום ראשוני"> רישום ראשוני</option>
                      <option value="שינוי מבנה"> שינוי מבנה</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>תאריה ההזמנה</td>
                  <td >

                    <DatePicker
                        className='reports_text'
                        dateFormat="dd/MM/yyyy"
                        locale='he'
                        name='orderDate'
                        selected={new Date(currentReport.orderDate)}
                        onChange={(date) => {
                          setCurrentReport({
                            ...currentReport,
                            orderDate: date,
                          });
                        }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>תאריך ביצוע בדיקת מסמכים מקדימה</td>
                  <td>

                    <DatePicker className='reports_text'
                                dateFormat="dd/MM/yyyy"
                                locale='he'
                                name='frontDocumentTest'
                                selected={new Date(currentReport.frontDocumentTest)}
                                onChange={(date) => {
                                  setCurrentReport({
                                    ...currentReport,
                                    frontDocumentTest: date,
                                  });
                                }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>תאריך ביצוע הבדיקה בפועל</td>
                  <td>

                    <DatePicker className='reports_text'
                                name='realOrderDate'
                                dateFormat="dd/MM/yyyy"
                                locale='he'
                                selected={new Date(currentReport.realOrderDate)}
                                onChange={(date) => {
                                  setCurrentReport({
                                    ...currentReport,
                                    realOrderDate: date,
                                  });
                                }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>שם הבודק</td>
                  <td>
                    <SellerDropdown className='reports_text'
                                    value={currentReport.testerName}
                                    onChange={onChange}
                                    users={users.filter((item) => item.role.type === 'בודק')}
                                    name='testerName'
                                    defaultText='-- בחר בודק --'
                    />
                  </td>
                </tr>
              </table>
            </div>

            <div className='wrapper mt-6'>
              <h2><h2 className='text-underline mt-5'><u>ב . ביצוע הבדיקה בשטח - הצהרת מבצע הבדיקה:
              </u></h2></h2>
            </div>
            <div className='flex justify-content-center align-items-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{ maxWidth: '120rem' }}
              >
                <tbody>
                <tr>
                  <td colSpan='2'>
                    <input className='reports_text'
                           type='checkbox'
                           style={{ marginLeft: '1rem' }}
                           checked={currentReport.approveInterst}
                           onChange={(e) => {
                             setCurrentReport({
                               ...currentReport,
                               approveInterst: e.target.checked,
                             });
                           }}
                    />
                    <label>
                      אני מצהיר כי אין לי כל מצב של<b> ניגוד עניינים </b> ומשוא פנים מול
                      מזמין הבדיקה ו/או הלקוח.
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    שם מלא:{' '}
                    <input className='reports_text'
                           type='text'
                           style={{ margin: '0 2rem' }}
                           name='fullName'
                           list ='fullname_list'
                           onChange={onChange}
                           value={currentReport.fullName }
                    />
                    <datalist id="fullname_list">
                      <option value="ליעד ברודו">ליעד ברודו</option>
                      <option value="איציק הילריו">איציק הילריו</option>
                      <option value="פיראס חלבי">פיראס חלבי</option>
                      <option value="שריף שגזי">שריף שגזי</option>
                    </datalist>

                    חתימת הבודק:
                    <div>
                      {pSigImage ? (
                          <img
                              src={pSigImage}
                              alt='אין חתימה'
                              style={{width: '100px', height: '100px'}}
                          />
                      ) : (
                          'אין חתימה'
                      )}
                    </div>

                  </td>
                </tr>
                </tbody>
              </table>
            </div>


            <div className='flex justify-content-center align-items-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{ maxWidth: '120rem' }}
              >
                <tbody>
                <tr>
                  <td colSpan='2'>
                    <input className='reports_text'
                           type='checkbox'
                           style={{ marginLeft: '1rem' }}
                           checked={currentReport.approveInterst}
                           onChange={(e) => {
                             setCurrentReport({
                               ...currentReport,
                               approveInterst: e.target.checked,
                             });
                           }}
                    />
                    <label>
                      אני מצהיר כי בוצעה<b> ביקורת קבלה</b> ו הרכב תועד חיצונית/פנימית טרם ביצוע הבדיקה .
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    שם מלא:{' '}
                    <input className='reports_text'
                           type='text'
                           list ='fullname_list'
                           style={{ margin: '0 2rem' }}
                           name='fullName'
                           onChange={onChange}
                           value={currentReport.fullName}

                    />
                    <datalist id="fullname_list">
                      <option value="ליעד ברודו">ליעד ברודו</option>
                      <option value="איציק הילריו">איציק הילריו</option>
                      <option value="פיראס חלבי">פיראס חלבי</option>
                      <option value="שריף שגזי">שריף שגזי</option>
                    </datalist>
                    חתימת הבודק:

                    <div>
                      {pSigImage ? (
                          <img
                              src={pSigImage}
                              alt='אין חתימה'
                              style={{width: '100px', height: '100px'}}
                          />
                      ) : (
                          'אין חתימה'
                      )}
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>



            <div style={{padding: 30}}></div>
            <h2 className='text-underline mt-5'><u>משטח חתימה
            </u></h2>
            <div className='flex justify-content-right align-items-right'>
              <table
                  className='reports__table reports__table--dark'
                  style={{ maxWidth: '40rem' }}
              >
                <tr>
                  <td>
                    <CanvasDraw
                        ref={canvasRef}
                        brushRadius={1}
                        brushColor={"#0720bb"}
                        canvasWidth={430}  // ---- fix this ----
                        canvasHeight={300}
                        lazyRadius={3}
                        hideGrid ={false}
                        saveData={localStorage.getItem("Sig_Drawing" +"_" + currentReport._id)}
                    />

                  </td>
                </tr>
              </table>
            </div>

            <button
                className='button bg-secondary'
                onClick={CanvasClear}

            >
              נקה
            </button>





          </div>

          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 2 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>



              <tr className='reports__table-header'>
                <td style={setWidth(25)}>
                  <table border='0' className='table-no-border width-100'>
                    <tr>
                      <td className='text-align-center'>טופס בדיקה מס: f-02</td>
                    </tr>
                    <tr>
                      <td className='text-align-center font-0-8'>
                        עורך מסמך:
                        {` ${props.user.firstName} ${props.user.lastName}`}
                      </td>
                    </tr>
                  </table>
                </td>
                <td className='text-align-center' style={setWidth(50)} colSpan='2'>
                  בדיקה פרטנית לרכב משא הנבנה בשלבים בהתאמה להוראת משרד התחבורה
                  H-02-2018
                </td>
              </tr>
            </table>




            <h2 className='mt-3 text-align-right'>
              <u>  ג . ביצוע בדיקה בשטח - בדיקת התאמה בין המסמכים שהוצגו לבין כלי הרכב הנבדק :</u>

            </h2>


            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <th>מס"ד</th>
                  <th style={{width: '35%'}}>דרישה</th>
                  <th style={{width: '10%'}}>סיווג: מתאים/לא מתאים</th>
                  <th>הערות</th>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    1
                  </td>
                  <td>
                    בדוק האם מס' השלדה של הרכב הנושא זהה למס' השלדה המפורטת ברישיון הרכב הקבוע או הזמני שהתקבל
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group21'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group21.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group21'
                               value='0'

                               onChange={onGroupChangeClassification}
                               checked={currentReport.group21.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group21'
                           onChange={onGroupChangeComment}
                           value={currentReport.group21.comments}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    2
                  </td>
                  <td> בדוק האם הוראת הרישום שהתקבלה במעבדה תואמת ל-VIN של הרכב הנבדק לרבות שנת ייצור של הרכב. ראו ביאור
                    VIN בהוראת הרישום

                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group22'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group22.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group22'
                               value='0'

                               onChange={onGroupChangeClassification}
                               checked={currentReport.group22.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group22'
                           onChange={onGroupChangeComment}
                           value={currentReport.group22.comments}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    3
                  </td>
                  <td className='mt-3 text-align-right'>
                    <div>
                      מס' VIN:
                      <div className='reports_text2'>
                        {oldReport.SectionD_3.VIN}
                      </div>
                      <input className='reports_text' type='text'
                             name='SectionD_3'
                             onChange={onGroupChangeSectionD_3VIN}
                             value={currentReport.SectionD_3.VIN}
                      />

                    </div>

                    <table
                        className='reports__table reports__table--dark'
                        style={{maxWidth: '40rem'}}
                    >
                      <tr>
                        <td className='mt-3 text-align-center'>
                          בהתאם לתקנות התעבורה בק"ג -IL
                        </td>
                        <td className='mt-3 text-align-center'>
                          משמעות
                        </td>
                        <td className='mt-3 text-align-center'>
                          ערך ב-ק"ג
                        </td>
                        <td className='mt-3 text-align-center'>
                          בהתאם ל-VIN
                        </td>
                      </tr>
                      <tr>
                        <td className='mt-3 text-align-center'>
                          <div className='reports_text2'>
                            {oldReport.SectionD_3.reg_GVW}
                          </div>
                          <input className='reports_text' type='text'
                                 name='SectionD_3'
                                 onChange={onGroupChangeSectionD_3reg_GVW}
                                 value={currentReport.SectionD_3.reg_GVW}
                          />
                        </td>
                        <td className='mt-3 text-align-center'>
                          משקל כולל מותר
                        </td>
                        <td className='mt-3 text-align-center'>
                          <div className='reports_text2'>
                            {oldReport.SectionD_3.value_GCW}
                          </div>
                          <input className='reports_text' type='text'
                                 name='SectionD_3'
                                 onChange={onGroupChangeSectionD_3value_GVW}
                                 value={currentReport.SectionD_3.value_GVW}

                          />
                        </td>
                        <td className='mt-3 text-align-center'>
                          GVW
                        </td>
                      </tr>
                      <tr>
                        <td className='mt-3 text-align-center'>
                          <div className='reports_text2'>
                            {oldReport.SectionD_3.reg_GCW}
                          </div>
                          <input className='reports_text' type='text'
                                 name='SectionD_3'
                                 onChange={onGroupChangeSectionD_3reg_GCW}
                                 value={currentReport.SectionD_3.reg_GCW}
                          />
                        </td>
                        <td className='mt-3 text-align-center'>
                          משקל כולל מחובר
                        </td>
                        <td className='mt-3 text-align-center'>
                          <div className='reports_text2'>
                            {oldReport.SectionD_3.value_GCW}
                          </div>
                          <input className='reports_text' type='text'
                                 name='SectionD_3'
                                 onChange={onGroupChangeSectionD_3value_GCW}
                                 value={currentReport.SectionD_3.value_GCW}
                          />
                        </td>
                        <td className='mt-3 text-align-center'>
                          GCW
                        </td>
                      </tr>
                      <tr>
                        <td className='mt-3 text-align-center'>
                          <div className='reports_text2'>
                            {oldReport.SectionD_3.reg_Axle_1}
                          </div>
                          <input className='reports_text' type='text'
                                 name='SectionD_3'
                                 onChange={onGroupChangeSectionD_3reg_Axle_1}
                                 value={currentReport.SectionD_3.reg_Axle_1}
                          />
                        </td>
                        <td className='mt-3 text-align-center'>
                          עומס טכני סרן 1/2 / עומס מורשה- ת"ת
                        </td>
                        <td className='mt-3 text-align-center'>
                          <div className='reports_text2'>
                            {oldReport.SectionD_3.value_Axle_1}
                          </div>
                          <input className='reports_text' type='text'
                                 name='SectionD_3'
                                 onChange={onGroupChangeSectionD_3value_Axle_1}
                                 value={currentReport.SectionD_3.value_Axle_1}

                          />
                        </td>
                        <td className='mt-3 text-align-center'>
                          Axle 1
                        </td>
                      </tr>
                      <tr>
                        <td className='mt-3 text-align-center'>
                          <div className='reports_text2'>
                            {oldReport.SectionD_3.reg_Axle_2}
                          </div>
                          <input className='reports_text' type='text'
                                 name='SectionD_3'
                                 onChange={onGroupChangeSectionD_3reg_Axle_2}
                                 value={currentReport.SectionD_3.reg_Axle_2}
                          />
                        </td>
                        <td className='mt-3 text-align-center'>
                          עומס טכני סרן 1/2 / עומס מורשה- ת"ת
                        </td>
                        <td className='mt-3 text-align-center'>
                          <div className='reports_text2'>
                            {oldReport.SectionD_3.value_Axle_2}
                          </div>
                          <input className='reports_text' type='text'
                                 name='SectionD_3'
                                 onChange={onGroupChangeSectionD_3value_Axle_2}
                                 value={currentReport.SectionD_3.value_Axle_2}

                          />
                        </td>
                        <td className='mt-3 text-align-center'>
                          Axle 2
                        </td>
                      </tr>
                    </table>


                  </td>

                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='SectionD_3'
                               value='1'
                               onChange={onGroupChangeSectionD_3Classification}
                               checked={currentReport.SectionD_3.SectionD_3classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='SectionD_3'
                               value='0'

                               onChange={onGroupChangeSectionD_3Classification}
                               checked={currentReport.SectionD_3.SectionD_3classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='SectionD_3'
                           onChange={onGroupChangeSectionD_3comments}
                           value={currentReport.SectionD_3.SectionD_3comments}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    4
                  </td>
                  <td className='text-underline mt-5'>
                    בדוק האם תעודת האבטיפוס של המרכב תואמת למרכב שהותקן ע"ג הרכב הנושא כלהלן:
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>

                  </td>
                  <td>בדוק האם דגם המרכב המפורט בתעודת האבטיפוס של המרכב זהה לשם דגם המרכב המוטבע ע"ג תווית יצרן המרכב ?
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group23'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group23.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group23'
                               value='0'

                               onChange={onGroupChangeClassification}
                               checked={currentReport.group23.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group23'
                           list='group23_list'
                           onChange={onGroupChangeComment}
                           value={currentReport.group23.comments}
                    />
                    <datalist id="group23_list">
                      <option value="N/A"> N/A</option>
                    </datalist>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>

                  </td>
                  <td>בדוק האם גודל המרכב המפורט בתעודת האבטיפוס של המרכב זהה לדגם המרכב המורכב ברכב ?</td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group24'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group24.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group24'
                               value='0'

                               onChange={onGroupChangeClassification}
                               checked={currentReport.group24.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group24'
                           list='group24_list'
                           onChange={onGroupChangeComment}
                           value={currentReport.group24.comments}
                    />
                    <datalist id="group24_list">
                      <option value="N/A"> N/A</option>
                    </datalist>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>

                  </td>
                  <td>בדוק האם גודל המרכב המפורט בתעודת האבטיפוס של המרכב גדול מדגם המרכב המורכב ברכב ?
                    במידה ונמצא שהותקן ברכב מרכב זהה למרכב שאושר בתעודת האבטיפוס, אך גדול מגודל המרכב שאושר, יש לסווג סעיף
                    זה כ-"לא מתאים" לדרישות, האבטיפוס שהוצג אינו חל על המרכב שהורכב ברכב.
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group25'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group25.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group25'
                               value='0'

                               onChange={onGroupChangeClassification}
                               checked={currentReport.group25.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group25'
                           list='group25_list'
                           onChange={onGroupChangeComment}
                           value={currentReport.group25.comments}
                    />
                    <datalist id="group25_list">
                      <option value="N/A"> N/A</option>
                    </datalist>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>

                  </td>
                  <td>
                    בדוק האם גודל המרכב המפורט בתעודת האבטיפוס של המרכב קטן מדגם המרכב המורכב ברכב ?
                    במידה ונמצא שהותקן ברכב מרכב זהה למרכב שאושר בתעודת האבטיפוס, אך קטן מגודל המרכב שאושר, יש לסווג סעיף
                    זה
                    כ-"מתאים" לדרישות, האבטיפוס שהוצג חל על המרכב
                    שהורכב ברכב.
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group26'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group26.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group26'
                               value='0'

                               onChange={onGroupChangeClassification}
                               checked={currentReport.group26.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group26'
                           list='group26_list'
                           onChange={onGroupChangeComment}
                           value={currentReport.group26.comments}
                    />

                    <datalist id="group26_list">
                      <option value="N/A"> N/A</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    5
                  </td>
                  <td>
                    בדוק האם מס' דופן ההרמה ההידראולית המפורטת בתסקיר בודק המוסמך זהה למספר הדופן המורכבת ברכב
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group27'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group27.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group27'
                               value='0'

                               onChange={onGroupChangeClassification}
                               checked={currentReport.group27.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group27'
                           list='group27_list'
                           onChange={onGroupChangeComment}
                           value={currentReport.group27.comments}
                    />
                    <datalist id="group27_list">
                      <option value="N/A"> N/A</option>
                    </datalist>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    6
                  </td>
                  <td>
                    בדוק האם מס' המנוף המפורט בתסקיר בודק המוסמך זהה למספר המנוף שהותקן ברכב
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group28'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group28.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group28'
                               value='0'

                               onChange={onGroupChangeClassification}
                               checked={currentReport.group28.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group28'
                           list='group28_list'
                           onChange={onGroupChangeComment}
                           value={currentReport.group28.comments}
                    />
                    <datalist id="group28_list">
                      <option value="N/A"> N/A</option>
                    </datalist>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>

                  </td>

                  <b>
                    סיכום ממצאים לבדיקת התאמה בין המסמכים שהוצגו לבין כלי הרכב הנבדק:
                  </b>

                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>

                  </td>
                  <td>
                    רק במידה ונמצא כי קיימת זהות בין המסמכים שהוצגו ע"י מזמין הבדיקה למכלולים שנמצאו מורכבים ברכב ניתן
                    לבצע המשך בדיקה כמפורט בהמשך טופס זה.
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group29'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group29.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group29'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group29.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group29'
                           onChange={onGroupChangeComment}
                           value={currentReport.group29.comments}
                    />
                  </td>
                </tr>

              </table>
            </div>
          </div>

          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>

            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 3 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>



            <h2 className='mt-3 text-align-right'>
              1. פרטי כלי הרכב
            </h2>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <th>מס"ד</th>
                  <th style={{width: '15%'}}>פרמטר</th>
                  <th style={{width: '25%'}}>ממצאים</th>
                  <th style={{width: '10%'}}>סיווג: מתאים/לא מתאים</th>
                  <th>הערות</th>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    1.1
                  </td>
                  <td>
                    מספר רישוי
                  </td>

                  <td>

                    <div class='reports_text2' >
                      {oldReport.group30.finds}
                    </div>

                    <input className='reports_text'
                           type='text'
                           name='group30'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group30.finds}
                    />
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group30'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group30.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group30'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group30.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>

                  <td>

                    <input className='reports_text' type='text'
                           name='group30'
                           onChange={onGroupChangeComment}
                           value={currentReport.group30.comments}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    1.2
                  </td>
                  <td>
                    מספר שלדה
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group31.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group31'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group31.finds=currentReport.SectionD_3.VIN}
                    />
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group31'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group31.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group31'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group31.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group31'
                           onChange={onGroupChangeComment}
                           value={currentReport.group31.comments}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    1.3
                  </td>
                  <td>
                    תוצר
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group32.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group32'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group32.finds}
                    />
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group32'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group32.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group32'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group32.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>

                    <input className='reports_text' type='text'
                           name='group32'
                           onChange={onGroupChangeComment}
                           value={currentReport.group32.comments}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    1.4
                  </td>
                  <td>
                    קוד דגם
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group33.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group33'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group33.finds}
                    />
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group33'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group33.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group33'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group33.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group33'
                           onChange={onGroupChangeComment}
                           value={currentReport.group33.comments}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    1.5
                  </td>
                  <td>
                    הוראת רישום
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group34.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group34'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group34.finds}
                    />
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group34'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group34.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group34'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group34.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group34'
                           onChange={onGroupChangeComment}
                           value={currentReport.group34.comments}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    1.6
                  </td>
                  <td>
                    קטגוריה
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group35.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           list='category_types'
                           name='group35'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group35.finds}
                    />
                    <datalist id="category_types">
                      <option value="N1">N1</option>
                      <option value="N2">N2</option>
                      <option value="N3">N3</option>
                    </datalist>


                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group35'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group35.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group35'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group35.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group35'
                           onChange={onGroupChangeComment}
                           value={currentReport.group35.comments}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    1.7
                  </td>
                  <td>
                    סוג רכב
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group48.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           list='group48_list'
                           name='group48'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group48.finds}
                    />

                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group48'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group48.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group48'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group48.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group48'
                           onChange={onGroupChangeComment}
                           value={currentReport.group48.comments}
                    />
                  </td>
                </tr>





                <tr>
                  <td className='mt-3 text-align-center'>
                    1.8
                  </td>
                  <td>
                    צבע
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group36.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           list='color_types'
                           name='group36'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group36.finds}
                    />
                    <datalist id="color_types">
                      <option value="לבן">לבן</option>
                      <option value="שחור">שחור</option>
                      <option value="אדום">אדום</option>
                      <option value="אחר:">אחר:</option>
                    </datalist>
                  </td>

                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group36'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group36.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group36'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group36.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group36'
                           onChange={onGroupChangeComment}
                           value={currentReport.group36.comments}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    1.9
                  </td>
                  <td>
                    מס' תיעוד WVTA
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group37.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group37'
                           list='group37_list'
                           dir='LTR'
                           lang='EN'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group37.finds}/>

                    <datalist id="group37_list">
                      <option value="e*2007/46*">e*2007/46*</option>
                      <option value="e*2018/858*">e*2018/858*</option>

                    </datalist>

                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group37'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group37.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group37'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group37.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group37'
                           onChange={onGroupChangeComment}
                           value={currentReport.group37.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    1.10
                  </td>
                  <td>
                    מס' נוסעים מאחור
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group38.finds}
                    </div>
                    <input className='reports_text'

                           type='number'
                           name='group38'
                           list='group38_list'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group38.finds}/>

                    <datalist id="group38_list">
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </datalist>

                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group38'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group38.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group38'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group38.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group38'
                           onChange={onGroupChangeComment}
                           value={currentReport.group38.comments}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    1.10
                  </td>
                  <td>
                    מס' נוסעים ליד הנהג
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group39.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group39'
                           list='group39_list'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group39.finds}/>

                    <datalist id="group39_list">
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </datalist>

                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group39'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group39.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group39'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group39.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group39'
                           onChange={onGroupChangeComment}
                           value={currentReport.group39.comments}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    1.10
                  </td>
                  <td>
                    תא שינה
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group245.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group245'
                           list='group245_list'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group245.finds}/>

                    <datalist id="group245_list">
                      <option value="קיים">קיים</option>
                      <option value="לא קיים">לא קיים</option>
                    </datalist>

                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group245'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group245.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group245'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group245.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group245'
                           onChange={onGroupChangeComment}
                           value={currentReport.group245.comments}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    1.11
                  </td>
                  <td>
                    גלגלים/סרנים
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group40.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           list='seren_types'
                           name='group40'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group40.finds}
                    />
                    <datalist id="seren_types">
                      <option value="2/4">2/4</option>
                      <option value="2/6">2/6</option>
                      <option value="3/8">3/8</option>
                      <option value="3/10">3/10</option>
                      <option value="4/12">4/12</option>
                      <option value="4/14">4/14</option>
                    </datalist>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group40'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group40.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group40'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group40.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group40'
                           onChange={onGroupChangeComment}
                           value={currentReport.group40.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    1.12
                  </td>
                  <td>
                    דגם מנוע
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group41.finds}
                    </div>

                    <input className='reports_text'
                           type='text'
                           name='group41'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group41.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group41'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group41.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group41'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group41.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group41'
                           onChange={onGroupChangeComment}
                           value={currentReport.group41.comments}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    1.13
                  </td>
                  <td>
                    מספר מנוע
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group42.finds}
                    </div>
                    <input className='reports_text'

                           type='text'
                           name='group42'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group42.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group42'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group42.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group42'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group42.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group42'
                           onChange={onGroupChangeComment}
                           value={currentReport.group42.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    1.14
                  </td>
                  <td>
                    צמיג קד'
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group43.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group43'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group43.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group43'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group43.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group43'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group43.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group43'
                           onChange={onGroupChangeComment}
                           value={currentReport.group43.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    1.15
                  </td>
                  <td>
                    צמיג אח'
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group44.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group44'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group44.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group44'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group44.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group44'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group44.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group44'
                           onChange={onGroupChangeComment}
                           value={currentReport.group44.comments}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    1.16
                  </td>
                  <td>
                    -
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group272.finds}
                    </div>
                    <input className='reports_text'

                           type='text'
                           name='group272'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group272.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group272'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group272.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group272'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group272.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group272'
                           onChange={onGroupChangeComment}
                           value={currentReport.group272.comments}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    1.17
                  </td>
                  <td>
                    -
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group273.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group273'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group273.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group273'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group273.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group273'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group273.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group273'
                           onChange={onGroupChangeComment}
                           value={currentReport.group273.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    1.18
                  </td>
                  <td>
                    הנעה
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group45.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           list='hanaa_types'
                           name='group45'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group45.finds}
                    />
                    <datalist id="hanaa_types">
                      <option value="2x4">2x4</option>
                      <option value="2x6">2x6</option>
                      <option value="4x6">4x6</option>
                      <option value="4x8">4x8</option>
                      <option value="אחר:">אחר:</option>
                    </datalist>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group45'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group45.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group45'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group45.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group45'
                           onChange={onGroupChangeComment}
                           value={currentReport.group45.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    1.19
                  </td>
                  <td>
                    רוחק סרנים(מ"מ)
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group46.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group46'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group46.finds}/>
                  </td>

                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group46'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group46.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group46'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group46.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group46'
                           onChange={onGroupChangeComment}
                           value={currentReport.group46.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    1.20
                  </td>
                  <td>
                    משקל כולל(ק"ג))
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group47.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group47'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group47.finds=currentReport.SectionD_3.reg_GVW}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group47'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group47.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group47'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group47.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group47'
                           onChange={onGroupChangeComment}
                           value={currentReport.group47.comments}
                    />
                  </td>
                </tr>

              </table>

            </div>
            <h2 className='flex justify-content-right'>
              <div className='table-no-border'>
                <img src={pic23}  style={{ width: '100%' }} />
              </div>
            </h2>
          </div>

          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>

            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 4 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <h2 className='mt-3 text-align-right'>
              2. פרטי מרכב הרכב
            </h2>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <th>מס"ד</th>
                  <th style={{width: '15%'}}>פרמטר</th>
                  <th style={{width: '25%'}}>ממצאים</th>
                  <th style={{width: '10%'}}>סיווג: מתאים/לא מתאים</th>
                  <th>הערות</th>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    2.1
                  </td>
                  <td>
                    יצרן
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group50.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group50'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group50.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group50'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group50.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group50'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group50.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group50'
                           onChange={onGroupChangeComment}
                           value={currentReport.group50.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    2.2
                  </td>
                  <td>
                    סוג מרכב
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group51.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group51'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group51.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group51'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group51.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group51'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group51.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group51'
                           onChange={onGroupChangeComment}
                           value={currentReport.group51.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    2.3
                  </td>
                  <td>
                    דגם מרכב
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group52.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group52'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group52.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group52'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group52.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group52'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group52.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group52'
                           onChange={onGroupChangeComment}
                           value={currentReport.group52.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    2.4
                  </td>
                  <td>
                    מס"ד מרכב
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group53.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group53'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group53.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group53'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group53.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group53'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group53.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group53'
                           onChange={onGroupChangeComment}
                           value={currentReport.group53.comments}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    2.5
                  </td>
                  <td>
                    מס' תעודה אב טיפוס
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group54.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group54'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group54.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group54'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group54.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group54'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group54.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group54'
                           onChange={onGroupChangeComment}
                           value={currentReport.group54.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    2.6
                  </td>
                  <td>
                    תוקף תעודת אב טיפוס
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group55.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group55'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group55.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group55'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group55.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group55'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group55.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group55'
                           onChange={onGroupChangeComment}
                           value={currentReport.group55.comments}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    2.7
                  </td>
                  <td>
                    זהות מעבדה
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group56.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group56'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group56.finds}/>

                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group56'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group56.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group56'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group56.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group56'
                           onChange={onGroupChangeComment}
                           value={currentReport.group56.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    2.8
                  </td>
                  <td>
                    הוראת רישום למרכב
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group57.finds}
                    </div>
                    <input className='reports_text'
                           list='section_2_8'
                           type='text'
                           name='group57'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group57.finds}/>
                    <datalist id="section_2_8">
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>

                    </datalist>

                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group57'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group57.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group57'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group57.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group57'
                           onChange={onGroupChangeComment}
                           value={currentReport.group57.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    2.9
                  </td>
                  <td>
                    תוקף ומס' יצרן
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group58.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group58'
                           list='group58_list'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group58.finds}/>

                    <datalist id="group58_list">
                      <option value="31/12/21"> 31/12/21</option>
                      <option value="31/12/22"> 31/12/22</option>
                      <option value="31/12/23"> 31/12/23</option>
                      <option value="31/12/24"> 31/12/24</option>
                      <option value="31/12/25"> 31/12/25</option>
                    </datalist>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group58'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group58.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group58'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group58.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group58'
                           onChange={onGroupChangeComment}
                           value={currentReport.group58.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    2.10
                  </td>
                  <td>
                    מס' אישור התקנה
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group59.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group59'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group59.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group59'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group59.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group59'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group59.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group59'
                           onChange={onGroupChangeComment}
                           value={currentReport.group59.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    2.11
                  </td>
                  <td>
                    תאריך ייצור
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group60.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group60'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group60.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group60'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group60.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group60'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group60.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group60'
                           onChange={onGroupChangeComment}
                           value={currentReport.group60.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    2.12
                  </td>
                  <td>
                    הטבעה על המרכב
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group61.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group61'
                           list='group61_list'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group61.finds}/>

                    <datalist id="group61_list">
                      <option value="לא מתאים">לא מתאים</option>
                      <option value="מתאים">מתאים</option>
                    </datalist>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group61'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group61.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group61'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group61.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group61'
                           onChange={onGroupChangeComment}
                           value={currentReport.group61.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    2.13
                  </td>
                  <td>
                    (*) כמות דלק בבדיקה [%]
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group62.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group62'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group62.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group62'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group62.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group62'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group62.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group62'
                           onChange={onGroupChangeComment}
                           value={currentReport.group62.comments}
                    />
                  </td>
                </tr>
              </table>
            </div>
            <h2 className='mt-3 text-align-center'>
              (*) יש לבצע חישוב נפח בפועל כמפורט בסעיף (3) (ג') (1) למטה או בהתאם לנפח מיכל נקוב(יש לתעד). </h2>
          </div>

          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>

            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 5 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>





            <h2 className='text-underline mt-5'>
              3. משקלים(ק"ג)
            </h2>
            <h2 className='mt-3 mb-3 text-align-right'>
              3.1 יש למדוד בעזרת פלס דיגיטלי את השיפוע של מישור עליו מתבצעת הבדיקה בשני כיוונים אורכי ורוחבי.
            </h2>
            <h2 className='mt-3 mb-3 text-align-right'>
              3.2 יש למקם את מדי משקל ואת משטחי הדמה כך, שיפצו על השיפוע של הכביש.
            </h2>
            <h2 className='mt-3 mb-3 text-align-right'>
              3.3 יש לעלות את הרכב עם הסרן הקדמי על גבי המשקלים והסרן האחורי יעמוד על משטחי הדמי.
            </h2>
            <h2 className='mt-3 text-align-right'>

              3.3.1 הבודק יכוון את הנהג , שמירת קשר עין רצופה הינה חובה.
            </h2>
            <h2 className='mt-3 text-align-right'>
              3.3.2 טרם קריאת המשקל יש לוודא שהנהג ירד מהרכב והוא ריק ממטען ואנשים.
            </h2>

            <h2 className='mt-3 text-align-right'>
              3.3.3 יש לוודא, בעזרת פלס דיגיטלי , שהרכב מפולס במישור אופקי והרוחבי, סטייה מקס' -°5.
            </h2>
            <h2 className='mt-3 text-align-right'>
              3.3.4 את הקריאות הנמדדות יש לתעד בסעיף המתאים בטבלה 3.
            </h2>
            <h2 className='mt-3 text-align-right'>
              3.4 יש לרדת מהמשקלים וממשטחי הדמה.
            </h2>
            <h2 className='mt-3 text-align-right'>
              3.5 יש לעלות את הרכב עם הסרן הקדמי על גבי משטחי הדמי והסרן האחורי יעמוד על המשקלים.
            </h2>
            <h2 className='mt-3 text-align-right'>
              3.6 יש לפעול ע"פ המפורט בסעף 3.3. לעיל.
            </h2>
            <h2 className='mt-3 text-align-right'>
              3.7 יש לרדת מהמשקלים וממשטחי הדמה.
            </h2>
            <h2 className='mt-3 text-align-right'>
              3.8 יש לחשב את הערכים הבאים:
            </h2>
            <h2 className='mt-3 text-align-right'>
              א. משקל עצמי: KWtot[kg]= N1 + N2+ N3+ N4
            </h2>
            <h2 className='mt-3 text-align-right'>
              איפה ש:
            </h2>
            <h2 className='mt-3 text-align-right'>
              KW = משקל עצמי מחושב (kerb weight)
            </h2>
            <h2 className='mt-3 text-align-right'>
              N_n = כוח נורמלי הנמדד בגלל מסוים.
            </h2>
            <h2 className='mt-3 text-align-right'>
              ב. התפלגות המשקל העצמי של הרכב בין סרן קדמי/אחורי ובין צד ימין/שמאל כלהלן:
            </h2>
            <h2 className='mt-3 text-align-center'>
              KWf=N1+N2 KWr=N3+N4
            </h2>
            <h2 className='mt-3 text-align-center'>
              איפה ש: KWf = משקל עצ' סרן קדמי; KWr = משקל עצ' סרן אח'; KWtot = משקל עצמי; WB = ר' סרנים.
            </h2>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '30rem'}}
              >
                <tr>
                  <th>KWf[kg]</th>
                  <th>N2[kg]</th>
                  <th>N1[kg]</th>
                </tr>
                <tr>

                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.KWf}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_KWf}
                           value={currentReport.weights.KWf = add(currentReport.weights.N1,currentReport.weights.N2)}
                    />
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.N2}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_N2}
                           value={currentReport.weights.N2}
                    />
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.N1}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_N1}
                           value={currentReport.weights.N1}
                    />
                  </td>
                </tr>
              </table>

            </div>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '30rem'}}
              >
                <tr>
                  <th>KWr[kg]</th>
                  <th>N3[kg]</th>
                  <th>N4[kg]</th>
                </tr>
                <tr>

                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.KWr}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_KWr}
                           value={currentReport.weights.KWr = add(currentReport.weights.N3,currentReport.weights.N4)}
                    />

                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.N3}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_N3}
                           value={currentReport.weights.N3}
                    />
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.N4}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_N4}
                           value={currentReport.weights.N4}
                    />
                  </td>
                </tr>

                <tr>
                  <td> KWtot[kg]</td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.KWtot}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_KWtot}
                           value={currentReport.weights.KWtot = add(currentReport.weights.KWr,currentReport.weights.KWf)}
                    />
                  </td>

                </tr>
              </table>

            </div>
          </div>

          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>

            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 6 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>





            <h2 className='mt-3 text-align-right'>
              ג. במידה וכמות הדלק שנמדדה במיכל, קטנה מ-90% - יש לחשב:
            </h2>


            <td>
              <div className='flex font-bold'>
                <h2 className='mt-3 text-align-right'>
                  <label for=''>1. את נפח המיכל כלהלן:  </label>
                </h2>

                <h2 className='flex align-items-center gap-04'>
                  <label className='reports_text2'>
                    {oldReport.weights.tank_x}
                  </label>
                  <input className='reports_text'
                         style={{width: '45%'}}
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_tank_x}
                         value={currentReport.weights.tank_x}
                  />

                  <label for=''>X אורך (ס"מ)</label>
                </h2>
                <h2 className='flex align-items-center gap-04'>
                  <label className='reports_text2'>
                    {oldReport.weights.tank_y}
                  </label>
                  <input className='reports_text'
                         style={{width: '65%'}}
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_tank_y}
                         value={currentReport.weights.tank_y}
                  />
                  <label for=''>X רוחב (ס"מ)</label>
                </h2>
                <h2 className='flex align-items-center gap-04'>
                  <label className='reports_text2'>
                    {oldReport.weights.tank_z}
                  </label>
                  <input className='reports_text'
                         style={{width: '65%'}}
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_tank_z}
                         value={currentReport.weights.tank_z}
                  />
                  <label for=''>X גובה (ס"מ)</label>
                </h2>
                <h2 className='flex align-items-center gap-04'>
                  <label className='reports_text2'>
                    {oldReport.weights.tank_total}
                  </label>
                  <input className='reports_text'
                         style={{width: '65%'}}
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_tank_total}
                         value={currentReport.weights.tank_total=floor(((currentReport.weights.tank_x * currentReport.weights.tank_y * currentReport.weights.tank_z) / 1000))}
                  />
                  <label for=''> =נפח [ליטר]</label>
                </h2>
              </div>
            </td>


            <h2 mt-3 text-align-right>

              2. את תוספת למשקל העצמי של הרכב, כתוצאה מחוסר בדלק כלהלן:
            </h2>
            <h2 className='mt-3 text-align-center'>

              = FWtot=ρf×FV×(0.9-FP⁄100)

            </h2>
            <td>
              <div className='flex font-bold'>
                <h2 className='flex align-items-center gap-04'>
                  <label for=''> kg</label>

                </h2>

                <h2 className='flex align-items-center gap-04'>

                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_FWtot}
                         value={currentReport.weights.FWtot=floor(multiply(currentReport.weights.ρf,currentReport.weights.FV,(subtract(0.9,(divide(currentReport.group62.finds,100))))))}

                  />

                  <label for=''></label>
                </h2>
                <h2 className='flex align-items-center gap-04'>

                  <label for=''>=(100/ </label>
                </h2>

                <h2 className='flex align-items-center gap-04'>
                  <label className='reports_text2'>
                    {oldReport.group62.finds}
                  </label>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_FP}
                         value={currentReport.group62.finds}
                  />
                  <label for=''>-0.9</label>
                </h2>

                <h2 className='flex align-items-center gap-04'>
                  <label for=''>)x </label>
                </h2>

                <h2 className='flex align-items-center gap-04'>
                  <label className='reports_text2'>
                    {oldReport.weights.FV}
                  </label>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_FV}
                         value={currentReport.weights.FV}
                  />
                  <label for=''> 0.8x </label>
                </h2>
                <h2 className='flex align-items-center gap-04'>
                  <label for=''> = </label>
                </h2>

              </div>
            </td>


            <h2 className='mt-3 text-align-right'>

              איפה ש:
            </h2>
            <h2 className='mt-3 text-align-right'>

              FWtot = משקל הדלק החסר בק"ג.
            </h2>
            <h2 className='text-align-right'>

              ρf = צפיפות נפחית של הדלק 0.8[kg⁄l]
            </h2>
            <h2 className='text-align-right'>

              FV = נפח מיכל הדלק בליטרים.
            </h2>
            <h2 className='text-align-right'>
              FP = אחוז הדלק במיכל.
            </h2>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '30rem'}}
              >
                <tr>
                  <th>FV [l]</th>
                  <th>FP [%]</th>
                </tr>
                <tr>

                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.FV}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'

                           onChange={onGroupChangeWeights_FV}
                           value={currentReport.weights.FV}
                    />
                  </td>
                  <td>
                    <label className='reports_text2'>
                      {oldReport.group62.finds}
                    </label>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_FP}
                           value={currentReport.group62.finds}
                    />
                  </td>

                </tr>

              </table>

            </div>
            <h2 className='mt-3 text-align-right'>
              ולכן תוספת משקל הדלק החסר על סרני הרכב, קדמי ואחורי יחושב כלהלן:

            </h2>


            <td>
              <div className='flex font-bold'>
                <h2 className='flex align-items-right gap-04'>
                  <label for=''> kg</label>
                </h2>

                <h2 className='flex align-items-right gap-04'>

                  <input className='reports_text'
                         type='text'
                         name='weights'
                         onChange={onGroupChangeWeights_FWr}
                         value={currentReport.weights.FWr=floor(multiply(currentReport.weights.FWtot,divide(currentReport.weights.Lf,currentReport.group46.finds)))}

                  />
                  <label for=''>=</label>
                </h2>

                <h2 className='flex align-items-right gap-04'>
                  <label className='reports_text2'>
                    {oldReport.group46.finds}
                  </label>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_WB}
                         value={currentReport.group46.finds}
                  />
                  <label for=''>/</label>
                </h2>
                <h2 className='flex align-items-right gap-04'>
                  <div className='reports_text2'>
                    {oldReport.weights.Lf}
                  </div>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_Lf}
                         value={currentReport.weights.Lf}
                  />
                  <label for=''>X</label>
                </h2>

                <h2 className='flex align-items-right gap-04'>
                  <div className='reports_text2'>
                    {oldReport.weights.FWtot}
                  </div>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_FWtot}
                         value={currentReport.weights.FWtot}
                  />
                  <label for=''>=</label>
                </h2>
                <h2 className='flex align-items-right gap-04'>
                  <label for=''>FWr = FWtot × Lf⁄WB </label>
                </h2>

              </div>
            </td>
            <h2 className='text-align-center'>

            </h2>

            <td>
              <div className='flex font-bold'>
                <h2 className='flex align-items-right gap-04'>
                  <label for=''> kg</label>
                </h2>

                <h2 className='flex align-items-right gap-04'>

                  <input className='reports_text'
                         type='number'
                         name='weights'
                         maxlength="3"
                         onChange={onGroupChangeWeights_FWf}
                         value={currentReport.weights.FWf=floor(subtract(currentReport.weights.FWtot,currentReport.weights.FWr))}
                  />
                  <label for=''>=</label>
                </h2>

                <h2 className='flex align-items-right gap-04'>
                  <div className='reports_text2'>
                    {oldReport.weights.FWr}
                  </div>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_FWr}
                         value={currentReport.weights.FWr}
                  />
                  <label for=''>-</label>
                </h2>


                <h2 className='flex align-items-right gap-04'>
                  <div className='reports_text2'>
                    {oldReport.weights.FWtot}
                  </div>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_FWtot}
                         value={currentReport.weights.FWtot}
                  />
                  <label for=''>=</label>
                </h2>
                <h2 className='flex align-items-center gap-04'>
                  <label for=''>FWf = FWtot - FWr </label>
                </h2>

              </div>
            </td>
            <h2 className='mt-3 text-align-right'>
              איפה ש:
            </h2>
            <h2 className='mt-3 text-align-right'>
              FWr = תוספת משקל הדלק החסר על סרן אחורי
            </h2>
            <h2 className='text-align-right'>
              FWf = תוספת משקל הדלק החסר על סרן קדמי
            </h2>
            <td>
              <div className='flex font-bold'>
                <h2 className='flex align-items-right gap-04'>
                  <label for=''>Lf = </label>
                </h2>

                <h2 className='flex align-items-right gap-04'>
                  <div className='reports_text2'>
                    {oldReport.weights.Lf}
                  </div>
                  <input className='reports_text'
                         style={{width: '25%'}}
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_Lf}
                         value={currentReport.weights.Lf}
                  />

                  <label for=''>מ"מ - מרחק בין מרכז תא דלק לציר הקדמי.</label>
                </h2>
              </div>
            </td>
            <h2 className='text-align-right'>
              WB = מרחק בין הסרנים
            </h2>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '30rem'}}
              >
                <tr>
                  <th>FWf[kg]</th>
                </tr>
                <tr>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.FWf}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_FWf}
                           value={currentReport.weights.FWf}
                    />
                  </td>
                </tr>
              </table>
            </div>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '30rem'}}
              >
                <tr>
                  <th>FWr[kg]</th>
                </tr>
                <tr>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.FWr}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_FWr}
                           value={currentReport.weights.FWr}
                    />
                  </td>
                </tr>
              </table>
            </div>


            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '30rem'}}
              >
                <tr>
                  <th>FWtot[kg]</th>
                </tr>
                <tr>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.FWtot}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_FWtot}
                           value={currentReport.weights.FWtot}
                    />
                  </td>
                </tr>
              </table>
            </div>


            <h2 className='mt-3 text-align-right'>
              עדכן את טבלת חלוקת העומסים בתוספת משקל הדלק החסר כלהלן:

            </h2>
            <h2 className='mt-3 text-align-right'>

            </h2>

            <div className='flex justify-content-right'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '80rem'}}
              >
                <tr>
                  <th style={{Width: '15%'}}> משקל מותר סרן קדמי – בהתאם ל-VIN או בהתאם לתקנות התע'</th>
                  <th style={{Width: '25%'}}> =KWf[kg]+FWf[kg]</th>
                  <th style={{Width: '25%'}}> N2[kg]</th>
                  <th style={{Width: '25%'}}> N1[kg]</th>
                </tr>
                <td>
                  <div className='reports_text2'>
                    {oldReport.weights.VIN_front}
                  </div>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_VIN_front}
                         value={currentReport.weights.VIN_front=currentReport.SectionD_3.reg_Axle_1}
                  />
                </td>
                <td>
                  <div className='reports_text2'>
                    {oldReport.weights.GVWf}
                  </div>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_GVWf}
                         value={currentReport.weights.GVWf=add(currentReport.weights.KWf,currentReport.weights.FWf)}
                  />
                </td>
                <td>
                  <div className='reports_text2'>
                    {oldReport.weights.N2}
                  </div>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_N2}
                         value={currentReport.weights.N2}
                  />
                </td>
                <td>
                  <div className='reports_text2'>
                    {oldReport.weights.N1}
                  </div>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_N1}
                         value={currentReport.weights.N1}
                  />
                </td>
              </table>
            </div>

            <div className='flex justify-content-right'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '80rem'}}
              >
                <tr>
                  <th style={{Width: '15%'}}> משקל מותר סרן אחורי – בהתאם ל-VIN או בהתאם לתקנות התע'</th>
                  <th style={{Width: '25%'}}> =KWr[kg]+FWr[kg]</th>
                  <th style={{Width: '25%'}}> N3[kg]</th>
                  <th style={{Width: '25%'}}> N4[kg]</th>
                </tr>
                <td>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_VIN_Back}
                         value={currentReport.weights.VIN_Back=currentReport.SectionD_3.reg_Axle_2}
                  />
                </td>
                <td>
                  <div className='reports_text2'>
                    {oldReport.weights.GVWr}
                  </div>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_GVWr}
                         value={currentReport.weights.GVWr=add(currentReport.weights.KWr,currentReport.weights.FWr)}
                  />
                </td>
                <td>
                  <div className='reports_text2'>
                    {oldReport.weights.N3}
                  </div>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_N3}
                         value={currentReport.weights.N3}
                  />
                </td>
                <td>
                  <div className='reports_text2'>
                    {oldReport.weights.N4}
                  </div>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_N4}
                         value={currentReport.weights.N4}
                  />
                </td>
              </table>
            </div>

            <div className='flex justify-content-right'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '80rem'}}
              >
                <tr>
                  <th style={{Width: '15%'}}> KWtot[kg]+FWtot[kg]='</th>
                  <th style={{Width: '25%'}}>
                    <td>
                      <input className='reports_text'
                             type='number'
                             name='weights'
                             onChange={onGroupChangeWeights_overload_WB}
                             value={currentReport.overload_WB=add(currentReport.weights.KWtot,currentReport.weights.FWtot)}
                      />
                    </td>
                  </th>
                  <th
                      style={{Width: '15%'}}>
                    <div className='flex justify-content-right'>
                      <label for=''>ימין</label>
                    </div>
                    <div className='flex justify-content-right'>



                      {currentReport.weight_right == 1 &&
                      <input className='reports_text'

                             type='number'
                             name='weights'
                             onChange={onGroupChangeWeights_GVWr_tot}
                             value={currentReport.weights.GVWr_tot = add(currentReport.weights.N2, currentReport.weights.N3, currentReport.weights.FWtot)}

                      />
                      }
                      {currentReport.weight_right == 0 &&
                      <input className='reports_text'
                             type='number'
                             name='weights'
                             onChange={onGroupChangeWeights_GVWr_tot}
                             value={currentReport.weights.GVWr_tot = add(currentReport.weights.N2, currentReport.weights.N3)}
                      />
                      }
                      <label for=''>[ק"ג]</label>
                    </div>
                  </th>
                  <th style={{Width: '15%'}}>
                    <div className='flex justify-content-right'>
                      <label for=''>שמאל</label>
                    </div>
                    <div className='flex justify-content-right'>
                      {currentReport.weight_left == 1 &&
                      <input className='reports_text'
                             type='number'
                             name='weights'
                             onChange={onGroupChangeWeights_GVWl_tot}
                             value={currentReport.weights.GVWl_tot = add(currentReport.weights.N1, currentReport.weights.N4, currentReport.weights.FWtot)}
                      />
                      }
                      {currentReport.weight_left == 0 &&
                      <input className='reports_text'
                             type='number'
                             name='weights'
                             onChange={onGroupChangeWeights_GVWl_tot}
                             value={currentReport.weights.GVWl_tot = add(currentReport.weights.N1, currentReport.weights.N4)}
                      />
                      }
                      <label for=''>[ק"ג]</label>
                    </div>
                  </th>
                </tr>

              </table>
            </div>






            <div className='flex justify-content-right'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '80rem'}}
              >
                <tr>
                  <th style={{Width: '15%'}}>האם המיכל בצד ימין או שמאל</th>
                  <th style={{Width: '25%'}}>
                    <td>
                      <input className='reports_text'
                             type='checkbox'
                             style={{ marginLeft: '1rem' }}
                             checked={currentReport.weight_right}
                             onChange={(e) => {
                               setCurrentReport({
                                 ...currentReport,
                                 weight_right: e.target.checked,
                               });
                             }}
                      />
                      <label>
                        ימין
                      </label>
                    </td>

                    <td>
                      <input className='reports_text'
                             type='checkbox'
                             style={{ marginLeft: '1rem' }}
                             checked={currentReport.weight_left}
                             onChange={(e) => {
                               setCurrentReport({
                                 ...currentReport,
                                 weight_left: e.target.checked,
                               });
                             }}
                      />
                      <label>
                        שמאל
                      </label>
                    </td>
                  </th>
                </tr>
              </table>
            </div>
            <div className='flex justify-content-right'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '80rem'}}
              >
                <tr>
                  <th style={{Width: '15%'}}> משקל כולל מותר</th>
                  <th style={{Width: '25%'}}>
                    <td>

                      <div className='reports_text2'>
                        {oldReport.group47.finds}
                      </div>

                      <input className='reports_text'
                             type='number'
                             name='weights'
                             onChange={onGroupChangeWeights_total_allow}
                             value={currentReport.weight_total_allow = currentReport.group47.finds  }
                      />
                    </td>
                  </th>
                  <th
                      style={{Width: '45%'}}>
                    <div className='flex justify-content-right'>
                      <label for=''>הצב ערכים שהתקבלו בסעיף 3.10 במשך טופס זה</label>
                    </div>
                  </th>
                </tr>
              </table>
            </div>
          </div>

          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>


            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 7 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>










            <h2 className='mt-3 text-align-right'>
              3.9 חישוב משקל מורשה : PM = GVW - KW - DPW
            </h2>
            <h2 className='mt-3 text-align-right'>
              איפה ש:
            </h2>
            <h2 className='text-align-right'>
              PM = משקל מורשה
            </h2>
            <h2 className='text-align-right'>
              GVW = משקל כולל ע"פ טבלת ה-VIN של הרכב.
            </h2>
            <h2 className='text-align-right'>
              KW = משקל עצמי מחושב כולל 90% מהנוזלים
            </h2>


            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '15rem'}}
              >
                <tr>
                  <th>KW[kg]</th>
                </tr>
                <tr>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.KW}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_KW}
                           value={currentReport.weights.KW =add(currentReport.weights.KWtot,currentReport.weights.FWtot)}
                    />
                  </td>
                </tr>
              </table>
            </div>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '15rem'}}
              >
                <tr>
                  <th>PM[kg]</th>
                </tr>
                <tr>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.PM}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_PM}
                           value={currentReport.weights.PM = floor((currentReport.weights.GVW - currentReport.weights.KW))}
                    />
                  </td>
                </tr>
              </table>
            </div>
            <h2 className='mt-3 text-align-right'>
              3.10 חישוב חלוקת עומסים:
            </h2>
            <h2 className='mt-3 text-align-right'>
              א. יש לוודא שלא קיימת חריגה בסימטריה המשקל העצמי, בין צד ימין לצד שמאל של הרכב כמפורט להלן:
            </h2>
            <h2 className='flex justify-content-center'>
              <div className='table-no-border'>
                <img src={pic2} alt={''} style={{width: '100%'}}/>
              </div>
            </h2>

            <h2 className='mt-3 text-align-right'>
              במידה וקיימת חריגה בחלוקת עומסי המשקל העצמי של הרכב בין צד ימין לצד שמאל העולה על 5% יש לסווג סעיף זה כ-"לא
              מתאים".
            </h2>

            <h2 className='mt-3 text-align-right'>
              <th style={{Width: '15%'}}>
                <td>
                  משקל ימין :
                  <div className='reports_text2'>
                    {oldReport.weights.GVWr_tot}
                  </div>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_GVWr_tot}
                         value={currentReport.weights.GVWr_tot}
                  />
                </td>
              </th>
            </h2>

            <h2 className='mt-3 text-align-right'>
              <th style={{Width: '15%'}}>
                <td>
                  משקל שמאל :
                  <div className='reports_text2'>
                    {oldReport.weights.GVWl_tot}
                  </div>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_GVWl_tot}
                         value={currentReport.weights.GVWl_tot}
                  />
                </td>
              </th>
            </h2>

            <h2 className='mt-3 text-align-right'>
              <th style={{Width: '15%'}}>
                <td>
                  תוצאה חלוקת עומסים באחוזים :

                  {currentReport.weights.GVWr_tot < currentReport.weights.GVWl_tot &&
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_GVW_accept}
                         value={currentReport.weights.GVW_accept= floor(multiply(subtract(1,(divide(currentReport.weights.GVWr_tot,currentReport.weights.GVWl_tot))),100))}
                  />

                  }
                  {currentReport.weights.GVWr_tot > currentReport.weights.GVWl_tot &&
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_GVW_accept}
                         value={currentReport.weights.GVW_accept= floor(multiply(subtract(1,(divide(currentReport.weights.GVWl_tot,currentReport.weights.GVWr_tot))),100))}
                  />
                  }
                </td>
              </th>
            </h2>
            <h2 className='mt-3 text-align-right'>
              ב. יש לבצע חישוב חלוקת עומסים למיקום מרכז הארגז/מטען מורשה בתצורה הבאה:
            </h2>
            <h2 className='mt-3 text-align-right'>

            </h2>
            <h2 className='mt-3 text-align-right'>

            </h2>
            <div className='flex justify-content-right'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '80rem'}}
              >
                <tr>
                  <th style={{Width: '15%'}}> מס"ד</th>
                  <th style={{Width: '25%'}}> העומס</th>
                  <th style={{Width: '15%'}}>סרן קדמי</th>
                  <th style={{Width: '15%'}}> סרן אחורי</th>
                  <th style={{Width: '15%'}}>סה"כ</th>
                </tr>
                <tr>
                  <td>
                    1
                  </td>
                  <td>
                    משקל עצמי
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.Seren_self_front}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_Seren_self_front}
                           value={currentReport.weights.Seren_self_front}
                    />
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.Seren_self_back}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_Seren_self_back}
                           value={currentReport.weights.Seren_self_back}
                    />
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.Seren_self_total}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_Seren_self_total}
                        // value={currentReport.weights.Seren_self_total=add(currentReport.weights.Seren_self_front,currentReport.weights.Seren_self_back)}
                           value={currentReport.weights.Seren_self_total}
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    2
                  </td>
                  <td className='text-align-center'>
                    *מטען מורשה ל-מ.כ.
                    <div className='reports_text2'>
                      {oldReport.weights.laugage_allow}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_laugage_allow}
                           value={currentReport.weights.laugage_allow}
                    />
                    (ראה הנחיות למטה)
                  </td>
                  <td className='text-align-center'>
                    (ב)
                    <div className='reports_text2'>
                      {oldReport.weights.laugage_b}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_laugage_b}
                           value={currentReport.weights.laugage_b}
                    />
                  </td>
                  <td className='text-align-center'>
                    (ג)
                    <div className='reports_text2'>
                      {oldReport.weights.laugage_c}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_laugage_c}
                           value={currentReport.weights.laugage_c}
                    />
                  </td>
                  <td className='text-align-center'>
                    (א)
                    <div className='reports_text2'>
                      {oldReport.weights.laugage_a}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_laugage_a}
                           value={currentReport.weights.laugage_a}
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    3
                  </td>
                  <td className='text-align-center'>
                    **משקל נהג + נוסעים
                  </td>
                  <td className='text-align-center'>
                    <div className='reports_text2'>
                      {oldReport.weights.Seren_driver_front}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_Seren_driver_front}
                           value={currentReport.weights.Seren_driver_front}
                    />
                  </td>
                  <td className='text-align-center'>
                    <div className='reports_text2'>
                      {oldReport.weights.Seren_driver_back}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_Seren_driver_back}
                           value={currentReport.weights.Seren_driver_back}
                    />
                  </td>
                  <td className='text-align-center'>
                    <div className='reports_text2'>
                      {oldReport.weights.Seren_driver_total}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_Seren_driver_total}
                           value={currentReport.weights.Seren_driver_total}
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    4
                  </td>
                  <td className='text-align-center'>
                    סה"כ
                  </td>
                  <td className='text-align-center'>
                    <div className='reports_text2'>
                      {oldReport.weights.Seren_total_front}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_Seren_total_front}
                           value={currentReport.weights.Seren_total_front}
                    />
                  </td>
                  <td className='text-align-center'>
                    <div className='reports_text2'>
                      {oldReport.weights.Seren_total_back}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_Seren_total_back}
                           value={currentReport.weights.Seren_total_back}
                    />
                  </td>
                  <td className='text-align-center'>
                    <div className='reports_text2'>
                      {oldReport.weights.Seren_total_all}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_Seren_total_all}
                           value={currentReport.weights.Seren_total_all}
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    5
                  </td>
                  <td className='text-align-center'>
                    משקל כולל מותר
                  </td>
                  <td className='text-align-center'>
                    <div className='reports_text2'>
                      {oldReport.weights.Seren_total_front_accept}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_Seren_total_front_accept}
                           value={currentReport.weights.Seren_total_front_accept}
                    />
                  </td>
                  <td className='text-align-center'>
                    <div className='reports_text2'>
                      {oldReport.weights.Seren_total_back_accept}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_Seren_total_back_accept}
                           value={currentReport.weights.Seren_total_back_accept}
                    />
                  </td>
                  <td className='text-align-center'>
                    <div className='reports_text2'>
                      {oldReport.weights.Seren_total_all_accept}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_Seren_total_all_accept}
                           value={currentReport.weights.Seren_total_all_accept}
                    />
                  </td>
                </tr>


              </table>
            </div>
            <h2 className='mt-3 text-align-right'>

            </h2>


            <td>
              <div className='flex font-bold'>
                <h2 className='flex align-items-right gap-04'>
                  <label for=''>חישוב חלוקת עומסים לפי רוחק סרנים של </label>
                </h2>

                <h2 className='flex align-items-right gap-04'>
                  <div className='reports_text2'>
                    {oldReport.group46.finds}
                  </div>
                  <input className='reports_text'
                         style={{width: '45%'}}

                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_WB}
                         value={currentReport.group46.finds}
                  />

                  <label for=''>מ"מ. </label>
                </h2>
              </div>
            </td>

            <h2 className='mt-3 text-align-right'>
              1. (*) שלב ראשון - חישוב תחום מרכז כובד מטען - אילוץ קדמי ביצוע החישוב באמצעות מאזן מומנטים על הסרן קדמי,
              כלהלן:
            </h2>

            <h2 className='flex justify-content-center'>
              <div className='table-no-border'>
                <img src={pic3} style={{width: '100%'}}/>
              </div>
            </h2>

            <td>
              <div className='flex font-bold'>
                <h2 className='flex align-items-right gap-04'>
                  <label for=''>GVWf =</label>
                </h2>
                <h2 className='flex align-items-right gap-04'>
                  <label className='reports_text2'>
                    {oldReport.weights.GVWf}
                  </label>
                  <input className='reports_text'
                         style={{width: '70%'}}
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_GVWf}
                         value={currentReport.weights.GVWf}
                  />
                </h2>
              </div>
            </td>


            <td>
              <div className='flex font-bold'>
                <h2 className='flex align-items-right gap-04'>
                  <label for=''>WB =</label>
                </h2>
                <h2 className='flex align-items-right gap-04'>
                  <label className='reports_text2'>
                    {oldReport.group46.finds}
                  </label>
                  <input className='reports_text'
                         style={{width: '70%'}}
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_WB}
                         value={currentReport.group46.finds}
                  />
                </h2>
              </div>
            </td>
            <td>
              <div className='flex font-bold'>
                <h2 className='flex align-items-right gap-04'>
                  <label for=''>GVW =</label>
                </h2>
                <h2 className='flex align-items-right gap-04'>
                  <label className='reports_text2'>
                    {oldReport.group47.finds}
                  </label>
                  <input className='reports_text'
                         style={{width: '70%'}}
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_GVW}
                         value={currentReport.weights.GVW = currentReport.group47.finds}
                  />

                </h2>
              </div>
            </td>
            <td>
              <div className='flex font-bold'>
                <h2 className='flex align-items-right gap-04'>
                  <label for=''> X2(מ"מ) =</label>
                </h2>
                <h2 className='flex align-items-right gap-04'>
                  <label className='reports_text2'>
                    {oldReport.weights.X2}
                  </label>
                  <input className='reports_text'
                         style={{width: '45%'}}
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_X2}
                         value={currentReport.weights.X2=floor(divide((subtract((subtract(currentReport.SectionD_3.reg_Axle_1 ,currentReport.weights.KWf) ),currentReport.weights.FWf) * currentReport.group46.finds ),(subtract((subtract(currentReport.weights.GVW ,currentReport.weights.KWtot)) ,currentReport.weights.FWtot))))}
                      // value={currentReport.weights.X2}
                  />

                </h2>
              </div>
            </td>

            <h2 className='mt-3 text-align-right'>
              הצבת הערכים שנמדדו, משקל העצמי+ תוספת משקל הדלק, משקל הנהג והנוסעים, בנוסף לאילוץ העומס טכני של הסרן הקדמי,
              יתקבל הגבול הקדמי בתחום מרכז הכובד, ממרכז הסרן האחורי.
              הערה: החישוב לעיל הינו למקרה של כלי רכב חד קבינה.
            </h2>

          </div>

          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 8 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>







            <h2 className='mt-3 text-align-right'>
              2. (*) שלב שני - חישוב תחום מרכז כובד מטען - אילוץ אחורי
            </h2>
            <h2 className='mt-3 text-align-right'>
              ביצוע חישוב באמצעות מאזן מומנטים על סרנים אחוריים, כלהלן:
            </h2>

            <h2 className='flex justify-content-center'>
              <div className='table-no-border'>
                <img src={pic4} style={{width: '100%'}}/>
              </div>
            </h2>
            <td>
              <div className='flex font-bold'>
                <h2 className='flex align-items-right gap-04'>
                  <label for=''>GVWr =</label>
                </h2>
                <h2 className='flex align-items-right gap-04'>

                  <input className='reports_text'
                         style={{width: '70%'}}

                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_GVWr}
                         value={currentReport.weights.GVWr}
                  />

                </h2>
              </div>
            </td>


            <td>
              <div className='flex font-bold'>
                <h2 className='flex align-items-right gap-04'>
                  <label for=''>WB =</label>
                </h2>
                <h2 className='flex align-items-right gap-04'>

                  <input className='reports_text'
                         style={{width: '70%'}}

                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_WB}
                         value={currentReport.group46.finds}
                  />
                </h2>
              </div>
            </td>
            <td>
              <div className='flex font-bold'>
                <h2 className='flex align-items-right gap-04'>
                  <label for=''>GVW =</label>
                </h2>
                <h2 className='flex align-items-right gap-04'>

                  <input className='reports_text'
                         style={{width: '70%'}}
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_GVW}
                         value={currentReport.weights.GVW}
                  />
                </h2>
              </div>
            </td>
            <td>
              <div className='flex font-bold'>
                <h2 className='flex align-items-right gap-04'>
                  <label for=''> X1(מ"מ) =</label>
                </h2>
                <h2 className='flex align-items-right gap-04'>

                  <input className='reports_text'
                         style={{width: '45%'}}
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_X1}
                         value={currentReport.weights.X1=floor(divide((subtract((subtract((subtract(currentReport.weights.GVW ,currentReport.weights.KWtot) ),currentReport.weights.FWtot)),(subtract((subtract(currentReport.SectionD_3.reg_Axle_2 ,currentReport.weights.KWr) ),currentReport.weights.FWr))) * currentReport.group46.finds ),(subtract((subtract(currentReport.weights.GVW ,currentReport.weights.KWtot)) ,currentReport.weights.FWtot))))}
                  />
                </h2>
              </div>
            </td>
            <h2 className='mt-3 text-align-right'>
              הצבת הערכים שנמדדו , משקל העצמי+ תוספת משקל הדלק, משקל הנהג והנוסעים, בנוסף לאילוץ עומס טכני על הסרן האחורי
              מתקבל הגבול האחורי בתחום מרכז הכובד, ממרכז הסרן האחורי.
            </h2>

            <h2 className='mt-3 text-align-right'>
              איפה ש:
            </h2>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >
                <tr>
                  <th style={{Width: '15%'}}> DPW</th>
                  <th style={{Width: '15%'}}> GVWr</th>
                  <th style={{Width: '15%'}}> WB</th>
                  <th style={{Width: '15%'}}> GVW</th>
                  <th style={{Width: '15%'}}> GVWf</th>
                  <th style={{Width: '15%'}}> KWr</th>
                  <th style={{Width: '15%'}}> KWtot</th>
                  <th style={{Width: '15%'}}> KWf</th>
                  <th style={{Width: '15%'}}> X2</th>
                  <th style={{Width: '15%'}}> X1</th>
                </tr>
                <tr>
                  <td>
                    משקל נהג+ נוסעים
                  </td>
                  <td>
                    משקל טכני סרן אחורי.
                  </td>
                  <td>
                    רוחק הסרנים.
                  </td>
                  <td>
                    משקל כולל מותר.
                  </td>
                  <td>
                    משקל טכני סרן קדמי.
                  </td>
                  <td>
                    משקל עצמי אחורי.
                  </td>
                  <td>
                    משקל עצמי.
                  </td>
                  <td>
                    משקל עצמי קדמי.
                  </td>
                  <td>
                    גבול קדמי לתחום מ.כ.
                  </td>
                  <td>
                    גבול אחורי לתחום מ.כ.
                  </td>
                </tr>
              </table>
            </div>

            <h2 className='mt-3 text-align-right'>
              יתקבל תחום מרכז הכובד המותר של הארגז והמטען המורשה כלהלן:
            </h2>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '15rem'}}
              >
                <tr>
                  <th>X1[מ"מ]</th>
                </tr>
                <tr>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group247.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group247'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group247.finds=currentReport.weights.X1}
                    />
                  </td>
                </tr>
              </table>
            </div>


            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '15rem'}}
              >
                <tr>
                  <th>X2[מ"מ]</th>
                </tr>
                <tr>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group248.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group248'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group248.finds=currentReport.weights.X2}
                    />
                  </td>
                </tr>
              </table>
            </div>

            <h2 className='mt-3 text-align-right'>
              3. (*) שלב שלישי –
            </h2>
            <h2 className='mt-3 text-align-right'>
              נדרש לוודא כי המרכז הגיאומטרי של הארגז והמטען המורשה נמצא בתחום מרכז הכובד המותר.
            </h2>
            <h2 className='mt-3 text-align-right'>
              4. (*) שלב רביעי - מילוי ערכים בטבלה לעיל:
            </h2>
            <h2 className='flex align-items-center gap-04'>
              1. ביצוע חישוב למטען מורשה, מכ"מ פחות משקל עצמי פחות משקל נהג ונוסעים - רישום בעמודת סה"כ (א).
            </h2>
            <h2 className='flex align-items-center gap-04'>
              2. בחירת אילוץ, קדמי או אחורי וביצוע חישוב חלוקת עומסים בהתאם - קבלת עומס בפועל על הציר - לדוגמא בחירת אילוץ
              קדמי ורישום ב-(ב).
            </h2>
            <h2 className='flex align-items-center gap-04'>
              3. ביצוע חישוב: מטען מורשה פחות עומס בפועל על הציר המחושב בסעיף 2 לעיל - יתקבל העומס בפועל על הציר השני,
              בהתאמה, קרי (א) פחות (ב) = (ג).
            </h2>
          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 9 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>






            <h2 className='mt-3 text-align-right'>
              <u> 4. סיכום שקילות ומשקלים - חלוקת עומסים</u>
            </h2>
            <h2 className='mt-3 text-align-center'>
              <u>  טבלה מס' 3.</u>
            </h2>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <th>מס"ד</th>
                  <th style={{width: '15%'}}>פרמטר</th>
                  <th style={{width: '25%'}}>ממצאים</th>
                  <th style={{width: '10%'}}>סיווג: מתאים/לא מתאים</th>
                  <th>הערות</th>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    3.1
                  </td>
                  <td>
                    עומס מרבי - סרן 1
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group63.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group63'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group63.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group63'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group63.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group63'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group63.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group63'
                           onChange={onGroupChangeComment}
                           value={currentReport.group63.comments}/>
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    3.2
                  </td>
                  <td>
                    עומס מרבי - סרן 2
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group64.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group64'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group64.finds }/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group64'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group64.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group64'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group64.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group64'
                           onChange={onGroupChangeComment}
                           value={currentReport.group64.comments}/>
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    3.3
                  </td>
                  <td>
                    עומס מרבי - סרן 3
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group65.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group65'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group65.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group65'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group65.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group65'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group65.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group65'
                           onChange={onGroupChangeComment}
                           value={currentReport.group65.comments}/>
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    3.4
                  </td>
                  <td>
                    עומס מרבי - סרן 4
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group66.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group66'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group66.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group66'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group66.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group66'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group66.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group66'
                           onChange={onGroupChangeComment}
                           value={currentReport.group66.comments}/>
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    3.5
                  </td>
                  <td>
                    עומס מרבי - סרן 5
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group67.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group67'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group67.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group67'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group67.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group67'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group67.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group67'
                           onChange={onGroupChangeComment}
                           value={currentReport.group67.comments}/>
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    3.6
                  </td>
                  <td>
                    משקל עצמי קדמי
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group68.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group68'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group68.finds = currentReport.weights.GVWf }/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group68'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group68.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group68'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group68.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group68'
                           onChange={onGroupChangeComment}
                           value={currentReport.group68.comments}/>
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    3.7
                  </td>
                  <td>
                    משקל עצמי אחורי
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group69.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group69'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group69.finds = currentReport.weights.GVWr}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group69'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group69.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group69'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group69.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group69'
                           onChange={onGroupChangeComment}
                           value={currentReport.group69.comments}/>
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    3.8
                  </td>
                  <td>
                    (*)משקל עצמי
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group70.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group70'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group70.finds = currentReport.weights.KW}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group70'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group70.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group70'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group70.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group70'
                           onChange={onGroupChangeComment}
                           value={currentReport.group70.comments}/>
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    3.9
                  </td>
                  <td>
                    (**) משקל מורשה
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group71.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group71'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group71.finds = currentReport.weights.PM}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group71'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group71.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group71'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group71.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group71'
                           onChange={onGroupChangeComment}
                           value={currentReport.group71.comments}/>
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    3.10
                  </td>
                  <td>
                    חלוקת העומסים
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group72.comments}
                    </div>
                    <input
                        className='reports_text'
                        type='text'
                        name='group72'
                        list = 'group72_list'
                        onChange={onGroupChangeComment}
                        value={currentReport.group72.comments}/>

                    <datalist id="group72_list">
                      <option value="לא מתאים">לא מתאים</option>
                      <option value="מתאים">מתאים</option>
                    </datalist>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group72'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group72.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group72'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group72.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.GVW_accept}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeFinds}
                           value={currentReport.weights.GVW_accept}
                    />
                  </td>

                </tr>
              </table>
            </div>


            <h2 className='mt-3 text-align-right'>
              (*) המשקל העצמי לא כולל את משקל הנוסעים ברכב - - אלא אם מדובר במרכב קירור מבוקר (ת"י 1291 ) במקרה זו יש להוסיף
              את משקל הנהג והנוסעים ברכב למשקל העצמי של הרכב .
            </h2>
            <h2 className='flex align-items-center gap-04'>
              (**) המשקל המורשה שווה למשקל המטען המורשה + משקל הנוסעים ברכב למעט מקרה של רכב להובלת מזון בטמפרטורה
              מבוקרת (ת"י 1291).
            </h2>

            <h2 className='mt-3 text-align-right'>
              <u> 4. מידות(מ"מ)</u>
            </h2>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <th>מס"ד</th>
                  <th style={{width: '15%'}}>פרמטר</th>
                  <th style={{width: '25%'}}>ממצאים</th>
                  <th style={{width: '10%'}}>סיווג: מתאים/לא מתאים</th>
                  <th>הערות</th>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    4.1
                  </td>
                  <td>
                    אורך כללי
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group73.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group73'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group73.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group73'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group73.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group73'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group73.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group73.comments}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group73'
                           onChange={onGroupChangeComment}
                           value={currentReport.group73.comments}/>
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    4.2
                  </td>
                  <td>
                    רוחב כללי
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group74.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group74'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group74.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group74'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group29.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group74'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group74.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group74'
                           onChange={onGroupChangeComment}
                           value={currentReport.group74.comments}/>
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    4.3
                  </td>
                  <td>
                    גובה כללי
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group75.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group75'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group75.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group75'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group75.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group75'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group75.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group75'
                           onChange={onGroupChangeComment}
                           value={currentReport.group75.comments}/>
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    4.4
                  </td>
                  <td>
                    שלוחה אחורית
                  </td>
                  <td>

                    <input className='reports_text'
                           type='number'
                           name='group76'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group76.finds}/>
                    הכנס שלוחה אחורית

                    <input className='reports_text'
                           type='number'
                           name='group246'
                           onChange={onGroupChangeFinds}
                        // value={currentReport.group246.finds}/>
                           value={currentReport.group246.finds=floor(multiply(divide(currentReport.group76.finds,currentReport.group46.finds),100),1)}/>
                    %
                  </td>

                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group76'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group76.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group76'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group76.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group76'
                           onChange={onGroupChangeComment}
                           value={currentReport.group76.comments}/>
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    4.5
                  </td>
                  <td>
                    מרכז כובד מטען
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group77.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group77'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group77.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group77'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group77.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group77'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group77.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group77'
                           onChange={onGroupChangeComment}
                           value={currentReport.group77.comments}/>
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    4.6
                  </td>
                  <td>
                    אורך מרכב
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group78.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group78'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group78.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group78'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group78.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group78'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group78.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group78.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group78'
                           onChange={onGroupChangeComment}
                           value={currentReport.group78.comments= (currentReport.group78.finds/2)}/>
                  </td>
                </tr>




              </table>

            </div>

            <h2 className='mt-3 text-align-right'>
              <u>   5	התקנים נוספים   </u>
            </h2>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '160rem'}}
              >

                <tr>
                  <td style={{width: '5%'}}></td>
                  <td style={{width: '10%'}}>התקן</td>
                  <td style={{width: '10%'}}>שם המתקין</td>
                  <td style={{width: '10%'}}>שם יצרן</td>
                  <td style={{width: '10%'}}>מס"ד</td>
                  <td style={{width: '10%'}}>תסקיר בטיחות</td>
                  <td style={{width: '10%'}}>תאריך תסקיר בטיחות</td>
                  <td style={{width: '10%'}}>סוקר זהות</td>
                  <td style={{width: '10%'}}>דו"ח מעבדה</td>
                  <td style={{width: '10%'}}>זהות מעבדה</td>
                  <td style={{width: '10%'}}>דגם</td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    5.1
                  </td>
                  <td>
                <textarea className='reports_text'
                          type='text'
                          name='Device1'
                          onChange={onGroupChangeDevices_Device}
                          value={currentReport.Device1.Device}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device1'
                            onChange={onGroupChangeDevices_Name}
                            value={currentReport.Device1.Name}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device1'
                            onChange={onGroupChangeDevices_Manufactor}
                            value={currentReport.Device1.Manufactor}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='Device1'
                           dir='LTR'
                           lang='EN'
                           onChange={onGroupChangeDevices_masad}
                           value={currentReport.Device1.masad}/>
                  </td>

                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device1'
                            dir='LTR'
                            lang='EN'
                            onChange={onGroupChangeDevices_safety}
                            value={currentReport.Device1.safety}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device1'
                            onChange={onGroupChangeDevices_date}
                            value={currentReport.Device1.date}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device1'
                            onChange={onGroupChangeDevices_identity}
                            value={currentReport.Device1.identity}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device1'
                            dir="ltr"
                            onChange={onGroupChangeDevices_report_lab}
                            value={currentReport.Device1.report_lab}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device1'
                            onChange={onGroupChangeDevices_identity_lab}
                            value={currentReport.Device1.identity_lab}/>
                  </td>
                  <td>
                   <textarea className='reports_text'
                             type='text'
                             name='Device1'
                             onChange={onGroupChangeDevices_model}
                             value={currentReport.Device1.model}/>
                  </td>


                </tr>



                <tr>
                  <td className='mt-3 text-align-center'>
                    5.2
                  </td>
                  <td>
                <textarea className='reports_text'
                          type='text'
                          name='Device2'
                          onChange={onGroupChangeDevices_Device}
                          value={currentReport.Device2.Device}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device2'
                            onChange={onGroupChangeDevices_Name}
                            value={currentReport.Device2.Name}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device2'
                            onChange={onGroupChangeDevices_Manufactor}
                            value={currentReport.Device2.Manufactor}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='Device2'
                           dir='LTR'
                           lang='EN'
                           onChange={onGroupChangeDevices_masad}
                           value={currentReport.Device2.masad}/>
                  </td>

                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device2'
                            dir='LTR'
                            lang='EN'
                            onChange={onGroupChangeDevices_safety}
                            value={currentReport.Device2.safety}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device2'
                            onChange={onGroupChangeDevices_date}
                            value={currentReport.Device2.date}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device2'
                            onChange={onGroupChangeDevices_identity}
                            value={currentReport.Device2.identity}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device2'
                            dir="ltr"
                            onChange={onGroupChangeDevices_report_lab}
                            value={currentReport.Device2.report_lab}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device2'
                            onChange={onGroupChangeDevices_identity_lab}
                            value={currentReport.Device2.identity_lab}/>
                  </td>
                  <td>
                   <textarea className='reports_text'
                             type='text'
                             name='Device2'
                             onChange={onGroupChangeDevices_model}
                             value={currentReport.Device2.model}/>
                  </td>

                </tr>



                <tr>
                  <td className='mt-3 text-align-center'>
                    5.3
                  </td>
                  <td>
                <textarea className='reports_text'
                          type='text'
                          name='Device3'
                          onChange={onGroupChangeDevices_Device}
                          value={currentReport.Device3.Device}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device3'
                            onChange={onGroupChangeDevices_Name}
                            value={currentReport.Device3.Name}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device3'
                            onChange={onGroupChangeDevices_Manufactor}
                            value={currentReport.Device3.Manufactor}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='Device3'
                           dir='LTR'
                           lang='EN'
                           onChange={onGroupChangeDevices_masad}
                           value={currentReport.Device3.masad}/>
                  </td>

                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device3'
                            dir='LTR'
                            lang='EN'
                            onChange={onGroupChangeDevices_safety}
                            value={currentReport.Device3.safety}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device3'
                            onChange={onGroupChangeDevices_date}
                            value={currentReport.Device3.date}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device3'
                            onChange={onGroupChangeDevices_identity}
                            value={currentReport.Device3.identity}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device3'
                            dir="ltr"
                            onChange={onGroupChangeDevices_report_lab}
                            value={currentReport.Device3.report_lab}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device3'
                            onChange={onGroupChangeDevices_identity_lab}
                            value={currentReport.Device3.identity_lab}/>
                  </td>
                  <td>
                   <textarea className='reports_text'
                             type='text'
                             name='Device3'
                             onChange={onGroupChangeDevices_model}
                             value={currentReport.Device3.model}/>
                  </td>


                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    5.4
                  </td>
                  <td>
                <textarea className='reports_text'
                          type='text'
                          name='Device4'
                          onChange={onGroupChangeDevices_Device}
                          value={currentReport.Device4.Device}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device4'
                            onChange={onGroupChangeDevices_Name}
                            value={currentReport.Device4.Name}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device4'
                            onChange={onGroupChangeDevices_Manufactor}
                            value={currentReport.Device4.Manufactor}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='Device4'
                           dir='LTR'
                           lang='EN'
                           onChange={onGroupChangeDevices_masad}
                           value={currentReport.Device4.masad}/>
                  </td>

                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device4'
                            dir='LTR'
                            lang='EN'
                            onChange={onGroupChangeDevices_safety}
                            value={currentReport.Device4.safety}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device4'
                            onChange={onGroupChangeDevices_date}
                            value={currentReport.Device4.date}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device4'
                            onChange={onGroupChangeDevices_identity}
                            value={currentReport.Device4.identity}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device4'
                            dir="ltr"
                            onChange={onGroupChangeDevices_report_lab}
                            value={currentReport.Device4.report_lab}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device4'
                            onChange={onGroupChangeDevices_identity_lab}
                            value={currentReport.Device4.identity_lab}/>
                  </td>
                  <td>
                   <textarea className='reports_text'
                             type='text'
                             name='Device4'
                             onChange={onGroupChangeDevices_model}
                             value={currentReport.Device4.model}/>
                  </td>

                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    5.5
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device5'
                            onChange={onGroupChangeDevices_Device}
                            value={currentReport.Device5.Device}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device5'
                            onChange={onGroupChangeDevices_Name}
                            value={currentReport.Device5.Name}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device5'
                            onChange={onGroupChangeDevices_Manufactor}
                            value={currentReport.Device5.Manufactor}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='Device5'
                           dir="LTR"
                           lang="EN"
                           onChange={onGroupChangeDevices_masad}
                           value={currentReport.Device5.masad}/>
                  </td>

                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device5'
                            dir="LTR"
                            onChange={onGroupChangeDevices_safety}
                            value={currentReport.Device5.safety}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device5'
                            onChange={onGroupChangeDevices_date}
                            value={currentReport.Device5.date}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device5'
                            onChange={onGroupChangeDevices_identity}
                            value={currentReport.Device5.identity}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device5'
                            dir="ltr"
                            onChange={onGroupChangeDevices_report_lab}
                            value={currentReport.Device5.report_lab}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device5'
                            onChange={onGroupChangeDevices_identity_lab}
                            value={currentReport.Device5.identity_lab}/>
                  </td>
                  <td>
                      <textarea className='reports_text'
                                type='text'
                                name='Device5'
                                onChange={onGroupChangeDevices_model}
                                value={currentReport.Device5.model}/>
                  </td>

                </tr>

              </table>
            </div>

          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 10 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>







            <h2 className='mt-3 text-align-right'>
              <u>   6	תנאים ומגבלות להוספה  </u>
            </h2>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >



                <tr>

                  <th style={{width: '5%'}}>מס"ד</th>
                  <th style={{width: '90%'}}>תיאור</th>

                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    6.1
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group79'
                           onChange={onGroupChangeComment}
                           value={currentReport.group79.comments}/>
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    6.2
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group80'
                           onChange={onGroupChangeComment}
                           value={currentReport.group80.comments}/>
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    6.3
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group81'
                           onChange={onGroupChangeComment}
                           value={currentReport.group81.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    6.4
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group82'
                           onChange={onGroupChangeComment}
                           value={currentReport.group82.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    6.5
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group249'
                           onChange={onGroupChangeComment}
                           value={currentReport.group249.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    6.6
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group250'
                           onChange={onGroupChangeComment}
                           value={currentReport.group250.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    6.7
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group251'
                           onChange={onGroupChangeComment}
                           value={currentReport.group251.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    6.8
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group252'
                           onChange={onGroupChangeComment}
                           value={currentReport.group252.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    6.9
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group253'
                           onChange={onGroupChangeComment}
                           value={currentReport.group253.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    6.10
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group254'
                           onChange={onGroupChangeComment}
                           value={currentReport.group254.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    6.11
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group1'
                           onChange={onGroupChangeComment}
                           value={currentReport.group1.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    6.12
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group2'
                           onChange={onGroupChangeComment}
                           value={currentReport.group2.comments}/>
                  </td>
                </tr>
              </table>
            </div>

            <h2 className='mt-3 text-align-right'>
              <u>  7	תנאים והגבלות להסרה (במקרה של שינוי מבנה)</u>
            </h2>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <th style={{width: '5%'}}>מס"ד</th>
                  <th style={{width: '90%'}}>תיאור</th>

                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    7.1
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group83'
                           onChange={onGroupChangeComment}
                           value={currentReport.group83.comments}/>

                    <datalist id="group83_list">

                    </datalist>
                  </td>

                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    7.2
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group84'
                           onChange={onGroupChangeComment}
                           value={currentReport.group84.comments}/>
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    7.3
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group85'
                           onChange={onGroupChangeComment}
                           value={currentReport.group85.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    7.4
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group86'
                           onChange={onGroupChangeComment}
                           value={currentReport.group86.comments}/>
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    7.5
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group3'
                           onChange={onGroupChangeComment}
                           value={currentReport.group3.comments}/>
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    7.6
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group4'
                           onChange={onGroupChangeComment}
                           value={currentReport.group4.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    7.7
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group5'
                           onChange={onGroupChangeComment}
                           value={currentReport.group5.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    7.8
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group6'
                           onChange={onGroupChangeComment}
                           value={currentReport.group6.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    7.9
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group7'
                           onChange={onGroupChangeComment}
                           value={currentReport.group7.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    7.10
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group8'
                           onChange={onGroupChangeComment}
                           value={currentReport.group8.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    7.11
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group9'
                           onChange={onGroupChangeComment}
                           value={currentReport.group9.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    7.12
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group10'
                           onChange={onGroupChangeComment}
                           value={currentReport.group10.comments}/>
                  </td>
                </tr>

              </table>
            </div>

            <h2 className='mt-3 text-align-right'>
              8	שינויים שבוצעו ברכב
            </h2>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <th style={{width: '5%'}}>מס"ד</th>
                  <th style={{width: '90%'}}>תיאור</th>

                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    8.1
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group264'
                           onChange={onGroupChangeComment}
                           value={currentReport.group264.comments}/>

                  </td>

                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    8.2
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group265'
                           onChange={onGroupChangeComment}
                           value={currentReport.group265.comments}/>
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    8.3
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group266'
                           onChange={onGroupChangeComment}
                           value={currentReport.group266.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    8.4
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group267'
                           onChange={onGroupChangeComment}
                           value={currentReport.group267.comments}/>
                  </td>
                </tr>
              </table>
            </div>

            <h2 className='mt-3 text-align-right'>
              <u>     9	הערות</u>
            </h2>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <th style={{width: '5%'}}>מס"ד</th>
                  <th style={{width: '90%'}}>תיאור</th>

                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    9.1
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group268'
                           list='group268_list'
                           onChange={onGroupChangeComment}
                           value={currentReport.group268.comments}/>

                    <datalist id="group268_list">
                      <option value='בדיקה בהסמכה, שקילות באמצעות ציוד בדיקה המעבדה'>בדיקה בהסכמה, שקילות באמצעות ציוד בדיקה המעבדה</option>
                      <option value='השקילה בוצעה בנוכחות הבוחן'>השקילה בוצעה בנוכחות הבוחן</option>
                    </datalist>

                  </td>

                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    9.2
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group269'

                           onChange={onGroupChangeComment}
                           value={currentReport.group269.comments}/>


                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    9.3
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group270'
                           onChange={onGroupChangeComment}
                           value={currentReport.group270.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    9.4
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group271'
                           onChange={onGroupChangeComment}
                           value={currentReport.group271.comments}/>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>

            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 11 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>




            <h2 className='mt-3 text-align-right'>
              <u>   10	התאמה לדרישות הרגולציה בהתאם לנספח IV של דירקטיבה 2007/46/EC</u>
            </h2>
            <h2 className='mt-3 text-align-right'>

            </h2>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <th style={{width: '5%'}}>מס"ד</th>
                  <th style={{width: '10%'}}>נושא</th>
                  <th style={{width: '10%'}}> רגולציה</th>
                  <th style={{width: '10%'}}>ממצאים</th>
                  <th style={{width: '10%'}}>התאמה</th>
                  <th style={{width: '10%'}}>הערות</th>
                </tr>


                <tr>
                  <td>
                    1
                  </td>
                  <td>
                    רמת רעש
                  </td>
                  <td>
                    R51
                    (EU)
                    No.540/2014
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group87'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group87.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group87'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group87.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group87'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group87.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group87'
                           list='group87_list'
                           onChange={onGroupChangeComment}
                           value={currentReport.group87.comments}

                    />

                    <datalist id="group87_list">
                      <option value="A"> A</option>
                      <option value="V"> V</option>
                      <option value="N/A"> N/A</option>
                      <option value="-"> -</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    2a
                  </td>
                  <td>
                    זיהום אוויר  (יורו 5, יורו 6) וגישה למערכות מידע
                  </td>
                  <td>
                    (EC) No
                    715/2007
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group88'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group88.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group88'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group88.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group88'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group88.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group88'
                           onChange={onGroupChangeComment}
                           value={currentReport.group88.comments}
                           list='group88_list'
                    />

                    <datalist id="group88_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>

                    </datalist>
                  </td>
                </tr>





                <tr>
                  <td>
                    3a
                  </td>
                  <td>
                    מניעת סיכוני התלקחות (מכלי דלק נוזלי) - R34
                  </td>
                </tr>

                <tr>
                  <td>
                    3a
                  </td>

                  <td>
                    נדרש לבדוק האם בוצע הוספה או הוזזה של המיכל (אב טיפוס).
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group90'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group90.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group90'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group90.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group90'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group90.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group90'
                           onChange={onGroupChangeComment}
                           value={currentReport.group90.comments}
                           list='group90_list'
                    />

                    <datalist id="group90_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A </option>
                      <option value="-"> - </option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    3a
                  </td>

                  <td>
                    יש לוודא קיום הגנה מתאימה.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group91'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group91.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group91'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group91.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group91'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group91.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group91'
                           onChange={onGroupChangeComment}
                           value={currentReport.group91.comments}

                    />


                  </td>
                </tr>

                <tr>
                  <td>
                    3b
                  </td>
                  <td>
                    התקן הגנה תת רכבית (RUPD) כולל התקנה - R58
                  </td>
                </tr>

                <tr>
                  <td>

                  </td>

                  <td>
                    גובה חתך פרופיל הפגוש האחורי: עבור רכב מקטגוריה N2- 100 מ"מ מינימלי, קטגוריה -N3 120מ"מ.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group92'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group92.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group92'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group92.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group92'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group92.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group92'
                           onChange={onGroupChangeComment}
                           value={currentReport.group92.comments}

                           list='group92_list'
                    />

                    <datalist id="group92_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>


                  </td>
                </tr>

                <tr>
                  <td>

                  </td>

                  <td>
                    המרחק בציר הרוחק בין קצה ההתקן למישור החיצוני של הגלגל- 100 מ"מ פנימה מקסימום מכל צד, למעט דופן הרמה.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group93'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group93.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group93'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group93.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group93'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group93.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group93'
                           onChange={onGroupChangeComment}
                           value={currentReport.group93.comments}

                    />


                  </td>
                </tr>

                <tr>
                  <td>

                  </td>

                  <td>
                    גובה מקסימלי מהקרקע לחלק התחתון של ההתקן 550 מ"מ.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group94'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group94.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group94'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group94.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group94'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group94.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group94'
                           onChange={onGroupChangeComment}
                           value={currentReport.group94.comments}

                    />

                  </td>
                </tr>

                <tr>
                  <td>

                  </td>

                  <td>
                    המרחק בציר האורכי בין התקן אחורי לדופן אחורי של המרכב: עבור רכב מקטגוריה N2- 400 מ"מ מקסימום לכיוון פנים הרכב, קטגוריה N3 – 300ממ. למעט מרכב עם דופן הרמה והייבר.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group95'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group95.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group95'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group95.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group95'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group95.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group95'
                           onChange={onGroupChangeComment}
                           value={currentReport.group95.comments}

                    />


                  </td>
                </tr>

                <tr>
                  <td>

                  </td>

                  <td>
                    להתקן לא יהיו פינות חדות: rmin>2.5mm
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group96'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group96.finds}/>
                  </td>

                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group96'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group96.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group96'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group96.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group96'
                           onChange={onGroupChangeComment}
                           value={currentReport.group96.comments}

                    />


                  </td>
                </tr>
              </table>
            </div>

          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>

            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 12 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <div style={{padding: 20}}></div>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <th style={{width: '5%'}}>מס"ד</th>
                  <th style={{width: '10%'}}>נושא</th>
                  <th style={{width: '10%'}}> רגולציה</th>
                  <th style={{width: '10%'}}>ממצאים</th>
                  <th style={{width: '10%'}}>התאמה</th>
                  <th style={{width: '10%'}}>הערות</th>
                </tr>
                <tr>
                  <td>

                  </td>

                  <td>
                    במידה ומותקנת דופן הרמה כל חלקי ההתקן יהיו באותו מישור ובמרחק מינימלי של 25 מ"מ מבוכנות הדופן.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group97'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group97.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group97'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group97.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group97'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group97.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group97'
                           onChange={onGroupChangeComment}
                           value={currentReport.group97.comments}

                    />


                  </td>
                </tr>

                <tr>
                  <td>

                  </td>

                  <td>
                    במידה ומותקן התקן עגלות משטחים אין לעבור את קו ההתקן האחורי (אב טיפוס).
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group98'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group98.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group98'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group98.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group98'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group98.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group98'
                           onChange={onGroupChangeComment}
                           value={currentReport.group98.comments}

                    />


                  </td>
                </tr>

                <tr>
                  <td>

                  </td>

                  <td>
                    אין לרתך בהתקן אחורי מקורי.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group99'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group99.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group99'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group99.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group99'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group99.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group99'
                           onChange={onGroupChangeComment}
                           value={currentReport.group99.comments}

                    />


                  </td>
                </tr>

                <tr>
                  <td>

                  </td>

                  <td>
                    להתקן לא יהיה שום קעורות לכיוון פנים הרכב.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group100'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group100.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group100'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group100.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group100'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group100.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group100'
                           onChange={onGroupChangeComment}
                           value={currentReport.group100.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>

                  </td>

                  <td>
                    הפגוש ומחברו לגוף הרכב יהיה בעל חוזק מספיק.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group101'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group101.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group101'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group101.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group101'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group101.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group101'
                           onChange={onGroupChangeComment}
                           value={currentReport.group101.comments}/>
                  </td>
                </tr>


                <tr>
                  <td>

                  </td>

                  <td>
                    שטח פני פגוש האחורי או של כל מקטעו הנפרדים יהיה מעל 350 סמ"ר ומישורו יהיה אחיד.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group102'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group102.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group102'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group102.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group102'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group102.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group102'
                           onChange={onGroupChangeComment}
                           value={currentReport.group102.comments}/>
                  </td>
                </tr>


              </table>
            </div>


            <h2 className='flex justify-content-center'>
              <div className='table-no-border'>
                <img src={pic1}  style={{ width: '100%' }} />
              </div>
            </h2>




            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <th style={{width: '5%'}}></th>
                  <th style={{width: '10%'}}></th>
                  <th style={{width: '10%'}}></th>
                  <th style={{width: '10%'}}></th>
                  <th style={{width: '10%'}}></th>
                  <th style={{width: '10%'}}></th>
                </tr>

                <tr>
                  <td>
                    4a
                  </td>
                  <td>
                    (EC) No.661/2009 EU) No.1003/2010 - מיקום לוחית רישוי אחורי
                  </td>
                </tr>

                <tr>
                  <td>
                    4a
                  </td>
                  <td>
                    הלוחית תותקן ע"ג התקן ייעודי ולא תבלוט מהמרכב.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group103'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group103.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group103'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group103.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group103'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group103.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group103'
                           onChange={onGroupChangeComment}
                           value={currentReport.group103.comments}
                           list='group103_list'
                    />

                    <datalist id="group103_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>

                  </td>
                  <td>
                    הלוחית תותקן באופן שלא תחצה את קו ‏אמצע הארגז.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group104'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group104.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group104'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group104.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group104'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group104.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group104'
                           onChange={onGroupChangeComment}
                           value={currentReport.group104.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>

                  </td>
                  <td>
                    הלוחית לא תותקן בצד ימין.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group105'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group105.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group105'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group105.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group105'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group105.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group105'
                           onChange={onGroupChangeComment}
                           value={currentReport.group105.comments}/>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>


            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 13 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <div style={{padding: 20}}></div>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >
                <tr>
                  <th style={{width: '5%'}}>מס"ד</th>
                  <th style={{width: '10%'}}>נושא</th>
                  <th style={{width: '10%'}}> רגולציה</th>
                  <th style={{width: '10%'}}>ממצאים</th>
                  <th style={{width: '10%'}}>התאמה</th>
                  <th style={{width: '10%'}}>הערות</th>
                </tr>
                <tr>
                  <td>

                  </td>
                  <td>
                    גובה מינימלי מהקרקע 300 מ"מ מחלק ‏תחתון של הלוחית.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group106'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group106.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group106'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group106.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group106'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group106.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group106'
                           onChange={onGroupChangeComment}
                           value={currentReport.group106.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>

                  </td>
                  <td>
                    גובה מקסימלי מהקרקע 1200 מ"מ מחלק עליון ‏של הלוחית.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group107'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group107.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group107'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group107.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group107'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group107.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group107'
                           onChange={onGroupChangeComment}
                           value={currentReport.group107.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>

                  </td>
                  <td>
                    אין לכופף את הלוחית ולהסתיר חלק ‏ממנה.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group108'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group108.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group108'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group108.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group108'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group108.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group108'
                           onChange={onGroupChangeComment}
                           value={currentReport.group108.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>

                  </td>
                  <td>
                    הלוחית לא תבלוט מקצה ההתקן‏.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group109'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group109.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group109'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group109.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group109'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group109.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group109'
                           onChange={onGroupChangeComment}
                           value={currentReport.group109.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>

                  </td>
                  <td>
                    ההתקן ללוחית יהיה מהסוגים הבאים:
                    •	התקן לוחית "אירופאית" 520x120 מינימום.
                    •	התקן לוחית "אמריקאית"
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group110'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group110.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group110'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group110.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group110'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group110.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group110'
                           onChange={onGroupChangeComment}
                           value={currentReport.group110.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>

                  </td>
                  <td>
                    הלוחית תהיה מפולסת ביחס לרכב.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group111'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group111.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group111'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group111.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group111'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group111.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group111'
                           onChange={onGroupChangeComment}
                           value={currentReport.group111.comments}/>
                  </td>
                </tr>
                <tr>
                  <td>
                    5a
                  </td>
                  <td>
                    מערכת היגוי
                  </td>
                  <td>
                    R79
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group112'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group112.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group112'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group112.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group112'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group112.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group112'
                           onChange={onGroupChangeComment}
                           value={currentReport.group112.comments}
                           list='group112_list'
                    />

                    <datalist id="group112_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    6a
                  </td>
                  <td>
                    נגישות לרכב, תמרון
                  </td>
                  <td>
                    (EU)
                    No.130/2012
                    (EC)
                    No.631/2009
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group113'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group113.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group113'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group113.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group113'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group113.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group113'
                           onChange={onGroupChangeComment}
                           value={currentReport.group113.comments}
                           list='group113_list'
                    />

                    <datalist id="group113_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    6b
                  </td>
                  <td>
                    נגישות לרכב, תמרון
                  </td>
                  <td>
                    (EU)
                    No.130/2012
                    (EC)
                    No.631/2009
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group89'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group89.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group89'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group89.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group89'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group89.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group89'
                           onChange={onGroupChangeComment}
                           value={currentReport.group113.comments}
                           list='group89_list'
                    />

                    <datalist id="group89_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>




                <tr>
                  <td>
                    7a
                  </td>
                  <td>
                    התקני אזהרה ואותות קוליים
                  </td>
                  <td>
                    R28
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group114'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group114.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group114'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group114.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group114'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group114.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group114'
                           onChange={onGroupChangeComment}
                           value={currentReport.group114.comments}
                           list='group114_list'
                    />

                    <datalist id="group114_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    8a
                  </td>
                  <td>
                    התקנים לראיה בלתי ישירה והתקנתם
                  </td>
                  <td>
                    R46
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group115'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group115.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group115'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group115.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group115'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group115.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group115'
                           onChange={onGroupChangeComment}
                           value={currentReport.group115.comments}
                           list='group115_list'
                    />

                    <datalist id="group115_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    9a
                  </td>
                  <td>
                    מערכת בלימה
                  </td>
                  <td>
                    R13
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group116'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group116.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group116'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group116.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group116'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group116.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group116'
                           onChange={onGroupChangeComment}
                           value={currentReport.group116.comments}
                           list='group116_list'
                    />

                    <datalist id="group116_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    9b
                  </td>
                  <td>
                    בלימה של מכוניות נוסעים
                  </td>
                  <td>
                    R13H
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group11'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group11.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group11'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group11.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group11'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group11.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group11'
                           onChange={onGroupChangeComment}
                           value={currentReport.group11.comments}
                           list='group11_list'
                    />

                    <datalist id="group11_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>
                <tr>
                  <td>
                    10a
                  </td>
                  <td>
                    אלקטרומגנטית EMC - R10
                  </td>

                </tr>



              </table>
            </div>





          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>

            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 14 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <div style={{padding: 20}}></div>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <th style={{width: '5%'}}>מס"ד</th>
                  <th style={{width: '10%'}}>נושא</th>
                  <th style={{width: '10%'}}>דרישות רגולציה</th>
                  <th style={{width: '10%'}}>ממצאים</th>
                  <th style={{width: '10%'}}>התאמה</th>
                  <th style={{width: '10%'}}>הערות</th>
                </tr>



                <tr>
                  <td>
                    10a
                  </td>
                  <td>
                    יש לנטר סימון רגולציה או הומולוגציה על גבי ההתקן, לתעדו ולציין מס' האישור בדו"ח הבדיקה.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group117'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group117.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group117'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group117.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group117'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group117.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group117'
                           onChange={onGroupChangeComment}
                           value={currentReport.group117.comments}
                           list='group117_list'
                    />

                    <datalist id="group117_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>


                <tr>
                  <td>
                    10a
                  </td>
                  <td>
                    או
                    לנטר הרכבה של כל מכלול חשמלי חדש ברכב, ע"י בונה מרכב. ובהתאמה לכך לבקש תעודת בדיקה או הצהרת יצרן
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group118'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group118.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group118'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group118.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group118'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group118.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group118'
                           onChange={onGroupChangeComment}
                           value={currentReport.group118.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    13a
                  </td>
                  <td>
                    הגנה על כלי רכב מפני שימוש בלתי מורשה
                  </td>
                  <td>
                    R18
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group119'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group119.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group119'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group119.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group119'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group119.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group119'
                           onChange={onGroupChangeComment}
                           value={currentReport.group119.comments}
                           list='group119_list'
                    />

                    <datalist id="group119_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    13b
                  </td>
                  <td>
                    הגנה על כלי
                    רכב מפני
                    שימוש בלתי
                    מורשה
                  </td>
                  <td>
                    R116
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group12'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group12.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group12'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group12.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group12'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group12.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group12'
                           onChange={onGroupChangeComment}
                           value={currentReport.group12.comments}
                           list='group12_list'
                    />

                    <datalist id="group12_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>


                <tr>
                  <td>
                    14a
                  </td>
                  <td>
                    הגנה על הנהג
                    מפני מנגנון
                    ההיגוי במקרה
                    של פגיעה
                  </td>
                  <td>
                    R12
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group13'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group13.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group13'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group13.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group13'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group13.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group13'
                           onChange={onGroupChangeComment}
                           value={currentReport.group13.comments}
                           list='group13_list'
                    />

                    <datalist id="group13_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>


                <tr>
                  <td>
                    15a
                  </td>
                  <td>
                    חוזק מושבים, עיגונם ומשענות ראש
                  </td>
                  <td>
                    R17
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group120'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group120.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group120'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group120.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group120'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group120.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group120'
                           onChange={onGroupChangeComment}
                           value={currentReport.group120.comments}
                           list='group120_list'
                    />

                    <datalist id="group120_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    17a
                  </td>
                  <td>
                    נגישות לרכב, תמרון  (הילוך אחורי)
                  </td>
                  <td>
                    (EU)
                    No.130/2012
                    (EC)
                    No.661/2009
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group121'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group121.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group121'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group121.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group121'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group121.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group121'
                           onChange={onGroupChangeComment}
                           value={currentReport.group121.comments}
                           list='group121_list'
                    />

                    <datalist id="group121_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    17b
                  </td>
                  <td>
                    ציוד מד מהירות כולל ההתקנה
                  </td>
                  <td>
                    R39
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group122'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group122.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group122'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group122.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group122'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group122.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group122'
                           onChange={onGroupChangeComment}
                           value={currentReport.group122.comments}
                           list='group122_list'
                    />

                    <datalist id="group122_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    18a
                  </td>
                  <td>
                    תוויות סטטוטוריות של היצרן ומספר זיהוי רכב
                  </td>
                  <td>
                    (EC)
                    No.661/2009
                    (EU) No.
                    19/2011
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group123'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group123.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group123'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group123.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group123'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group123.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group123'
                           onChange={onGroupChangeComment}
                           value={currentReport.group123.comments}
                           list='group123_list'
                    />

                    <datalist id="group123_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>


              </table>
            </div>





          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>

            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 15 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <div style={{padding: 20}}></div>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <th style={{width: '5%'}}>מס"ד</th>
                  <th style={{width: '10%'}}>נושא</th>
                  <th style={{width: '10%'}}>דרישות רגולציה</th>
                  <th style={{width: '10%'}}>ממצאים</th>
                  <th style={{width: '10%'}}>התאמה</th>
                  <th style={{width: '10%'}}>הערות</th>
                </tr>






                <tr>
                  <td>
                    19a
                  </td>
                  <td>
                    עיגון חגורות בטיחות
                  </td>
                  <td>
                    R14
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group124'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group124.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group124'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group124.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group124'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group124.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group124'
                           onChange={onGroupChangeComment}
                           value={currentReport.group124.comments}
                           list='group124_list'
                    />

                    <datalist id="group124_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>


                <tr>
                  <td>
                    20a
                  </td>
                  <td>
                    חוזק מושבים, עיגונם ומשענות ראש
                  </td>

                </tr>


                <tr>
                  <td>
                    20a
                  </td>
                  <td>
                    תאורת אזהרה :צבע פנסים כתום, לרכב חירום –כחול.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group125'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group125.finds}
                           list='color_20a'
                    />

                    <datalist id="color_20a">
                      <option value="כתום">כתום</option>
                      <option value="כחול">כחול</option>
                    </datalist>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group125'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group125.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group125'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group125.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group125'
                           onChange={onGroupChangeComment}
                           value={currentReport.group125.comments}
                           list='group125_list'
                    />

                    <datalist id="group125_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>


                <tr>
                  <td>
                    20a
                  </td>
                  <td>
                    תאורת עבודה פנסים בצבע לבן.
                  </td>
                  <td>
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group126'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group126.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group126'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group126.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group126'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group126.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group126'
                           onChange={onGroupChangeComment}
                           value={currentReport.group126.comments}/>
                  </td>
                </tr>


                <tr>
                  <td>
                    20a
                  </td>
                  <td>
                    <b>

                      איתות:
                    </b>
                  </td>
                </tr>


                <tr>
                  <td>
                    20a
                  </td>
                  <td>
                    נורת איתות צד המותקנת ע"ג הקבינה או המרכב ‏תראה מזווית 5-60 מעלות
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group127'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group127.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group127'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group127.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group127'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group127.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group127'
                           onChange={onGroupChangeComment}
                           value={currentReport.group127.comments}/>
                  </td>
                </tr>


                <tr>
                  <td>
                    20a
                  </td>
                  <td>
                    יש לוודא ‏שהמרכב אינו מסתיר את אלומת האור.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group128'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group128.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group128'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group128.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group128'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group128.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group128'
                           onChange={onGroupChangeComment}
                           value={currentReport.group128.comments}/>
                  </td>
                </tr>


                <tr>
                  <td>
                    20a
                  </td>
                  <td>
                    הנורה תותקן בגובה 1500-500 מ"מ מהקרקע.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group129'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group129.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group129'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group129.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group129'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group129.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group129'
                           onChange={onGroupChangeComment}
                           value={currentReport.group129.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    20a
                  </td>
                  <td>
                    פנסי איתות קדמים לא יהיו רחוקים מ-1800 מ"מ מהחזית של הקבינה.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group130'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group130.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group130'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group130.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group130'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group130.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group130'
                           onChange={onGroupChangeComment}
                           value={currentReport.group130.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    20a
                  </td>
                  <td>
                    הפנסים אור כתום.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group131'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group131.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group131'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group131.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group131'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group131.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group131'
                           onChange={onGroupChangeComment}
                           value={currentReport.group131.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    20a
                  </td>
                  <td>
                    תאורה היקפית, נדרשת ברכבים: כל הרכבים שאורכם מעל 6 מטר, כל הרכבים בקטגוריהN1:
                  </td>

                </tr>

                <tr>
                  <td>
                    20a
                  </td>
                  <td>
                    מרחק מהחזית של הקבינה לפנס הקדמי ביותר בדופן 3 מטר.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group132'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group132.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group132'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group132.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group132'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group132.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group132'
                           onChange={onGroupChangeComment}
                           value={currentReport.group132.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    20a
                  </td>
                  <td>
                    מרחקים בין הפנסים 3 מטר לאורך המרכב.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group133'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group133.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group133'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group133.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group133'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group133.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group133'
                           onChange={onGroupChangeComment}
                           value={currentReport.group133.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    20a
                  </td>
                  <td>
                    מרחק של פנס האחורי מקצה האחורי של המרכב עד 1 מ'
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group134'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group134.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group134'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group134.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group134'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group134.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group134'
                           onChange={onGroupChangeComment}
                           value={currentReport.group134.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    20a
                  </td>
                  <td>
                    הפנסים בצבע אדום.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           list= 'color_20a_red'
                           name='group135'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group135.finds}/>
                    <datalist id="color_20a_red">
                      <option value="אדום">אדום</option>
                      <option value="כתום">כתום</option>
                    </datalist>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group135'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group135.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group135'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group135.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group135'
                           onChange={onGroupChangeComment}
                           value={currentReport.group135.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    20a
                  </td>
                  <td>
                    זווית תאורה אנכית ±10 מעלות.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group136'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group136.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group136'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group136.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group136'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group136.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group136'
                           onChange={onGroupChangeComment}
                           value={currentReport.group136.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    20a
                  </td>
                  <td>
                    זווית תאורה אופקית‎‏ ±45
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group137'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group137.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group137'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group137.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group137'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group137.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group137'
                           onChange={onGroupChangeComment}
                           value={currentReport.group137.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    20a
                  </td>
                  <td>
                    כל התאורה ההיקפית תופעל דרך מתג אחד.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group138'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group138.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group138'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group138.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group138'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group138.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group138'
                           onChange={onGroupChangeComment}
                           value={currentReport.group138.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    20a
                  </td>
                  <td>
                    תאורת ארגז:
                  </td>

                </tr>

                <tr>
                  <td>
                    20a
                  </td>
                  <td>
                    קדמי: צבע הפנס לבן/ענבר.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group139'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group139.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group139'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group139.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group139'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group139.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group139'
                           onChange={onGroupChangeComment}
                           value={currentReport.group139.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    20a
                  </td>
                  <td>
                    אחורי: צבע הפנס אדום/כתום.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group140'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group140.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group140'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group140.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group140'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group140.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group140'
                           onChange={onGroupChangeComment}
                           value={currentReport.group140.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    20a
                  </td>
                  <td>
                    צדדי: צבע הפנס כתום.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group141'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group141.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group141'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group141.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group141'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group141.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group141'
                           onChange={onGroupChangeComment}
                           value={currentReport.group141.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    20a
                  </td>
                  <td>
                    תותקן תאורת ארגז אחורי כאשר רוחב הארגז עולה על 2.1 מטר.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group142'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group142.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group142'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group142.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group142'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group142.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group142'
                           onChange={onGroupChangeComment}
                           value={currentReport.group142.comments}/>
                  </td>
                </tr>

              </table>
            </div>





          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>

            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 16 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <div style={{padding: 20}}></div>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <th style={{width: '5%'}}>מס"ד</th>
                  <th style={{width: '10%'}}>נושא</th>
                  <th style={{width: '10%'}}> רגולציה</th>
                  <th style={{width: '10%'}}>ממצאים</th>
                  <th style={{width: '10%'}}>התאמה</th>
                  <th style={{width: '10%'}}>הערות</th>
                </tr>



                <tr>
                  <td>
                    20a
                  </td>
                  <td>
                    הפנסים של תארה אחורית בארגז יותקן בגובה מקסימלי ככל האפשר.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group143'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group143.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group143'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group143.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group143'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group143.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group143'
                           onChange={onGroupChangeComment}
                           value={currentReport.group143.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    20a
                  </td>
                  <td>
                    הפנסים יותקן במרווח מקסימלי של 400 מ"מ מדופנות קדמיים ואחוריים של המרכב.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group144'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group144.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group144'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group144.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group144'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group144.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group144'
                           onChange={onGroupChangeComment}
                           value={currentReport.group144.comments}/>
                  </td>
                </tr>
              </table>
            </div>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <th style={{width: '5%'}}></th>
                  <th style={{width: '95%'}}></th>
                </tr>

                <tr>
                  <td>

                  </td>
                  <td>
                    מחזירי אור - R3/R104. חובה לכל רכב אשר: משקלו מעל 7.5 טון או מעל 6 מטר אורך.
                  </td>

                </tr>
              </table>
            </div>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <th style={{width: '5%'}}></th>
                  <th style={{width: '10%'}}></th>
                  <th style={{width: '10%'}}> </th>
                  <th style={{width: '10%'}}></th>
                  <th style={{width: '10%'}}></th>
                  <th style={{width: '10%'}}></th>
                </tr>


                <tr>
                  <td>
                    21a
                  </td>
                  <td>
                    אורכם המינימלי של הפסים הזוהרים לאורך המרכב 70% מאורך הרכב.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group145'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group145.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group145'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group145.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group145'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group145.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group145'
                           onChange={onGroupChangeComment}
                           value={currentReport.group145.comments}
                           list='group145_list'
                    />

                    <datalist id="group145_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    21a
                  </td>
                  <td>
                    מיקום הפסים בציר הגובה 250-1500 מ"מ מהקרקע.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group146'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group146.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group146'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group146.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group146'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group146.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group146'
                           onChange={onGroupChangeComment}
                           value={currentReport.group146.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    21a
                  </td>
                  <td>
                    קצוות הפסים לא יהיו רחוקים מ-600 מ"מ מהקצוות המרכב.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group147'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group147.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group147'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group147.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group147'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group147.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group147'
                           onChange={onGroupChangeComment}
                           value={currentReport.group147.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    21a
                  </td>
                  <td>
                    צבע הפסים לבן/צהוב.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group148'
                           list='color_21a'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group148.finds}/>
                    <datalist id="color_21a">
                      <option value="לבן">לבן</option>
                      <option value="צהוב">צהוב</option>
                    </datalist>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group148'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group148.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group148'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group148.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group148'
                           onChange={onGroupChangeComment}
                           value={currentReport.group148.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    21a
                  </td>
                  <td>
                    יש למקם פסים בצורת "ר" בפינות הקיצוניות העליונות של המרכב, גודלם 250‏X‏250 מ"מ.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group149'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group149.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group149'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group149.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group149'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group149.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group149'
                           onChange={onGroupChangeComment}
                           value={currentReport.group149.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    21a
                  </td>
                  <td>
                    מרחק מקצה עליון של המרכב עד ל"ר" לא יהיה יותר מ-400 מ"מ.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group150'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group150.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group150'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group150.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group150'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group150.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group150'
                           onChange={onGroupChangeComment}
                           value={currentReport.group150.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    21a
                  </td>
                  <td>
                    חובה לוחות מחזירי אור בדופן האחורי ‏לרוחב בצורת פסים זוהרים אדום וצהוב.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group151'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group151.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group151'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group151.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group151'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group151.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group151'
                           onChange={onGroupChangeComment}
                           value={currentReport.group151.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    21a
                  </td>
                  <td>
                    פס זוהר היקפי בדופן אחורית הרחבה מ-2.1 ‏מטר בצבע אדום/ענבר/צהוב בעובי פס 50-60 מ "מ.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group152'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group152.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group152'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group152.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group152'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group152.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group152'
                           onChange={onGroupChangeComment}
                           value={currentReport.group152.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    21a
                  </td>
                  <td>
                    קצה כל מחזיר אור אחורי יהיה במרחק מעל 200 מ"מ פנסי
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group153'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group153.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group153'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group153.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group153'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group153.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group153'
                           onChange={onGroupChangeComment}
                           value={currentReport.group153.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    21a
                  </td>
                  <td>
                    אורכם של מחזירי האור האחורי יהיו לכל הפחות 80% מרוחב הכולל של המרכב.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group154'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group154.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group154'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group154.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group154'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group154.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group154'
                           onChange={onGroupChangeComment}
                           value={currentReport.group154.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    21a
                  </td>
                  <td>
                    פס תקני עם סימון ת"י או ‏E.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group155'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group155.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group155'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group155.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group155'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group155.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group155'
                           onChange={onGroupChangeComment}
                           value={currentReport.group155.comments}/>
                  </td>
                </tr>

              </table>
            </div>

          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 17 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>


            <div style={{padding: 20}}></div>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >
                <tr>
                  <th style={{width: '5%'}}>מס"ד</th>
                  <th style={{width: '10%'}}>נושא</th>
                  <th style={{width: '10%'}}>רגולציה</th>
                  <th style={{width: '10%'}}>ממצאים </th>
                  <th style={{width: '10%'}}>התאמה</th>
                  <th style={{width: '10%'}}>הערות</th>

                </tr>

              </table>
            </div>



            <h2 className='flex justify-content-center'>
              <div className='table-no-border'>
                <img src={pic5}  style={{ width: '100%' }} />
              </div>
            </h2>

            <h2 className='flex justify-content-center'>
              <div className='table-no-border'>
                <img src={pic6}  style={{ width: '100%' }} />
              </div>
            </h2>

          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 18 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <h2 className='flex justify-content-center'>
              <div className='table-no-border'>
                <img src={pic7}  style={{ width: '100%' }} />
              </div>
            </h2>

            <h2 className='flex justify-content-center'>
              <div className='table-no-border'>
                <img src={pic8}  style={{ width: '100%' }} />
              </div>
            </h2>

            <h2 className='flex justify-content-center'>
              <div className='table-no-border'>
                <img src={pic22}  style={{ width: '100%' }} />
              </div>
            </h2>


            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <th style={{width: '5%'}}></th>
                  <th style={{width: '100%'}}></th>
                </tr>
                <tr>
                  <td>
                    22a
                  </td>
                  <td>
                    תאורת קדמיות ואחוריות, תאורת בילום ותאורת קצה - R7
                  </td>
                </tr>
              </table>
            </div>

          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 19 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <div style={{padding: 20}}></div>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <th style={{width: '5%'}}>מס"ד</th>
                  <th style={{width: '10%'}}>נושא</th>
                  <th style={{width: '10%'}}>רגולציה</th>
                  <th style={{width: '10%'}}>ממצאים</th>
                  <th style={{width: '10%'}}>התאמה</th>
                  <th style={{width: '10%'}}>הערות</th>
                </tr>



                <tr>
                  <td>
                    22a
                  </td>
                  <td>
                    תאורת בילום תאיר מגזר של 90 מעלות.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group156'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group156.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group156'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group156.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group156'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group156.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group156'
                           onChange={onGroupChangeComment}
                           value={currentReport.group156.comments}
                           list='group156_list'
                    />

                    <datalist id="group156_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    22a
                  </td>
                  <td>
                    לוודא סימון ‏E
                  </td>
                  <td>

                  </td>


                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group157'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group157.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group157'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group157.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group157'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group157.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group157'
                           onChange={onGroupChangeComment}
                           value={currentReport.group157.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    22a
                  </td>
                  <td>
                    תאורה אחורית וקדמית:
                  </td>
                </tr>

                <tr>
                  <td>
                    22a
                  </td>
                  <td>
                    תראה מזוויות אופקית בין 45 מעלות בין הפנסים ל80 ‏מעלות החוצה.
                  </td>
                  <td>

                  </td>


                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group158'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group158.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group158'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group158.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group158'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group158.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group158'
                           onChange={onGroupChangeComment}
                           value={currentReport.group158.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    22a
                  </td>
                  <td>
                    תראה מזוויות אנכית ±15.
                  </td>
                  <td>

                  </td>


                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group159'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group159.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group159'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group159.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group159'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group159.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group159'
                           onChange={onGroupChangeComment}
                           value={currentReport.group159.comments}/>
                  </td>
                </tr>


                <tr>
                  <td>
                    22a
                  </td>
                  <td>
                    אין להתקין רשת על פנסים אחוריים ‏שמסתירה מעל 10% מהפנס.
                  </td>
                  <td>

                  </td>


                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group160'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group160.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group160'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group160.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group160'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group160.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group160'
                           onChange={onGroupChangeComment}
                           value={currentReport.group160.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    22a
                  </td>
                  <td>
                    גובה של הפנס מהקרקע בין 350 ל-1500 מ"מ.
                  </td>
                  <td>

                  </td>


                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group161'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group161.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group161'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group161.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group161'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group161.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group161'
                           onChange={onGroupChangeComment}
                           value={currentReport.group161.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    22a
                  </td>
                  <td>
                    מרחק מקסימלי של הפנס מקצה הצדדי של המרכב 400 מ"מ.
                  </td>
                  <td>

                  </td>


                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group162'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group162.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group162'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group162.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group162'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group162.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group162'
                           onChange={onGroupChangeComment}
                           value={currentReport.group162.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    22a
                  </td>
                  <td>
                    מרחק מינימלי בין הפנסים יהיה 600 מ"מ.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group163'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group163.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group163'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group163.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group163'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group163.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group163'
                           onChange={onGroupChangeComment}
                           value={currentReport.group163.comments}/>
                  </td>
                </tr>
                <tr>
                  <td>
                    22b
                  </td>
                  <td>
                    תאורת אור יום DRL
                  </td>
                  <td>
                    R87
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group164'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group164.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group164'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group164.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group164'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group164.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group164'
                           onChange={onGroupChangeComment}
                           value={currentReport.group164.comments}
                           list='group164_list'
                    />

                    <datalist id="group164_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>


                <tr>
                  <td>
                    22c
                  </td>
                  <td>
                    תאורה היקפית
                  </td>
                  <td>
                    R91
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group165'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group165.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group165'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group165.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group165'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group165.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group165'
                           onChange={onGroupChangeComment}
                           value={currentReport.group165.comments}
                           list='group165_list'
                    />

                    <datalist id="group165_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>


                <tr>
                  <td>
                    23a
                  </td>
                  <td>
                    תאורת איתות כיוון
                  </td>
                  <td>
                    R6
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group166'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group166.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group166'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group166.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group166'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group166.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group166'
                           onChange={onGroupChangeComment}
                           value={currentReport.group166.comments}
                           list='group166_list'
                    />

                    <datalist id="group166_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    24a
                  </td>
                  <td>
                    תאורת לוחית רישוי
                  </td>
                  <td>
                    R4
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group167'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group167.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group167'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group167.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group167'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group167.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group167'
                           onChange={onGroupChangeComment}
                           value={currentReport.group167.comments}
                           list='group167_list'
                    />

                    <datalist id="group167_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    25a
                  </td>
                  <td>
                    תאורה ראשית SB, פולטת קרן אור אסימטרית
                  </td>
                  <td>
                    R31
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group168'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group168.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group168'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group168.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group168'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group168.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group168'
                           onChange={onGroupChangeComment}
                           value={currentReport.group168.comments}
                           list='group168_list'
                    />

                    <datalist id="group168_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    25b
                  </td>
                  <td>
                    נורות להט לשימוש בפנסים ראשיים
                  </td>
                  <td>
                    R37
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group169'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group169.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group169'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group169.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group169'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group169.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group169'
                           onChange={onGroupChangeComment}
                           value={currentReport.group169.comments}
                           list='group169_list'
                    />

                    <datalist id="group169_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>


                <tr>
                  <td>
                    25c
                  </td>
                  <td>
                    פנסים ראשים מצוידים בנורות HID
                  </td>
                  <td>
                    R98
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group170'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group170.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group170'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group170.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group170'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group170.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group170'
                           onChange={onGroupChangeComment}
                           value={currentReport.group170.comments}
                           list='group168_list'
                    />

                    <datalist id="group168_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

              </table>
            </div>

          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 20 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <div style={{padding: 20}}></div>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <th style={{width: '5%'}}>מס"ד</th>
                  <th style={{width: '10%'}}>נושא</th>
                  <th style={{width: '10%'}}>רגולציה</th>
                  <th style={{width: '10%'}}>ממצאים</th>
                  <th style={{width: '10%'}}>התאמה</th>
                  <th style={{width: '10%'}}>הערות</th>
                </tr>

                <tr>
                  <td>
                    25d
                  </td>
                  <td>
                    נורות HID לשימוש בפנסים ראשיים HID מאושרים
                  </td>
                  <td>
                    R99
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group171'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group171.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group171'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group171.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group171'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group171.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group171'
                           onChange={onGroupChangeComment}
                           value={currentReport.group171.comments}
                           list='group171_list'
                    />

                    <datalist id="group171_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>


                <tr>
                  <td>
                    25e
                  </td>
                  <td>
                    תאורה ראשית פולטת קרן אור אסימטרית  מצוידת עם נורות להט ו/או יחידות LED
                  </td>
                  <td>
                    R112
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group172'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group172.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group172'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group172.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group172'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group172.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group172'
                           onChange={onGroupChangeComment}
                           value={currentReport.group172.comments}
                           list='group172_list'
                    />

                    <datalist id="group172_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    25f
                  </td>
                  <td>
                    מערכות תאורה ראשיות  אדפטיביות
                  </td>
                  <td>
                    R123
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group173'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group173.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group173'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group173.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group173'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group173.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group173'
                           onChange={onGroupChangeComment}
                           value={currentReport.group173.comments}
                           list='group173_list'
                    />

                    <datalist id="group173_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    26a
                  </td>
                  <td>
                    תאורת ערפל קדמית
                  </td>
                  <td>
                    R19
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group174'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group174.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group174'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group174.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group174'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group174.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group174'
                           onChange={onGroupChangeComment}
                           value={currentReport.group174.comments}
                           list='group174_list'
                    />

                    <datalist id="group174_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    27a
                  </td>
                  <td>
                    אמצעי גרירה
                  </td>
                  <td>
                    (EC)
                    No.661/2009
                    (EU)
                    No.1005/2010
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group175'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group175.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group175'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group175.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group175'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group175.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group175'
                           onChange={onGroupChangeComment}
                           value={currentReport.group175.comments}
                           list='group175_list'
                    />

                    <datalist id="group175_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>
                <tr>
                  <td>
                    28a
                  </td>

                  <td>
                    תאורת ערפל אחורית -R38
                  </td>

                </tr>

                <tr>
                  <td>
                    28a
                  </td>

                  <td>
                    תופעל דרך מתג ייעודי.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group176'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group176.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group176'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group176.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group176'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group176.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group176'
                           onChange={onGroupChangeComment}
                           value={currentReport.group176.comments}
                           list='group176_list'
                    />

                    <datalist id="group176_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    28a
                  </td>


                  <td>
                    חובה אינדיקציה בלוח מחוונים.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group177'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group177.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group177'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group177.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group177'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group177.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group177'
                           onChange={onGroupChangeComment}
                           value={currentReport.group177.comments}/>
                  </td>
                </tr>



                <tr>
                  <td>
                    28a
                  </td>

                  <td>
                    אור הפנסים בצבע צהוב.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group178'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group178.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group178'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group178.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group178'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group178.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group178'
                           onChange={onGroupChangeComment}
                           value={currentReport.group178.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    28a
                  </td>

                  <td>
                    זווית תאורה אנכית ±50 מעלות.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group179'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group179.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group179'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group179.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group179'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group179.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group179'
                           onChange={onGroupChangeComment}
                           value={currentReport.group179.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    28a
                  </td>

                  <td>
                    יש לוודא סימון ‏E.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group180'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group180.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group180'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group180.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group180'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group180.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group180'
                           onChange={onGroupChangeComment}
                           value={currentReport.group180.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    29a
                  </td>

                  <td>
                    תאורת הילוך אחורי - R23
                  </td>
                </tr>

                <tr>
                  <td>
                    29a
                  </td>

                  <td>
                    אור הפנסים בצבע לבן.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group181'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group181.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group181'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group181.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group181'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group181.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group181'
                           list='group181_list'
                           onChange={onGroupChangeComment}
                           value={currentReport.group181.comments}/>

                    <datalist id="group181_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    29a
                  </td>

                  <td>
                    התאורה תופעל ע"י הכנסת הילוך אחורי.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group182'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group182.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group182'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group182.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group182'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group182.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group182'
                           onChange={onGroupChangeComment}
                           value={currentReport.group182.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    29a
                  </td>

                  <td>
                    חובה אינדיקציה בלוח מחוונים.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group183'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group183.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group183'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group183.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group183'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group183.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group183'
                           onChange={onGroupChangeComment}
                           value={currentReport.group183.comments}/>
                  </td>
                </tr>


              </table>
            </div>

          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 21 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <div style={{padding: 20}}></div>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <th style={{width: '5%'}}>מס"ד</th>
                  <th style={{width: '10%'}}>נושא</th>
                  <th style={{width: '10%'}}>רגולציה</th>
                  <th style={{width: '10%'}}>ממצאים</th>
                  <th style={{width: '10%'}}>התאמה</th>
                  <th style={{width: '10%'}}>הערות</th>
                </tr>


                <tr>
                  <td>
                    29a
                  </td>

                  <td>
                    לרכב הקצר מ-6 מ' מס' מנורות בין 1 ל-2, לרכב הארוך מ-6 מ' מס' מנורות יהיה בין 2 ל-4.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group184'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group184.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group184'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group184.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group184'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group184.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group184'
                           onChange={onGroupChangeComment}
                           value={currentReport.group184.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    29a
                  </td>

                  <td>
                    גבוה הפנסים מפני הקרקע בין 250 ל-1200 מ"מ.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group185'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group185.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group185'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group185.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group185'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group185.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group185'
                           onChange={onGroupChangeComment}
                           value={currentReport.group185.comments}

                    />


                  </td>
                </tr>

                <tr>
                  <td>
                    29a
                  </td>

                  <td>
                    זווית תאורה ±15 מעלות.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group186'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group186.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group186'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group186.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group186'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group186.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group186'
                           onChange={onGroupChangeComment}
                           value={currentReport.group186.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    29a
                  </td>

                  <td>
                    יש לוודא סימון ‏E
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group187'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group187.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group187'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group187.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group187'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group187.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group187'
                           onChange={onGroupChangeComment}
                           value={currentReport.group187.comments}/>
                  </td>
                </tr>
                <tr>
                  <td>
                    30a
                  </td>
                  <td>
                    תאורת חנייה
                  </td>
                  <td>
                    R77
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group188'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group188.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group188'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group188.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group188'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group188.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group188'
                           onChange={onGroupChangeComment}
                           value={currentReport.group188.comments}
                           list='group188_list'
                    />

                    <datalist id="group188_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    31a
                  </td>
                  <td>
                    חגורות בטיחות ומערכות ריסון
                  </td>
                  <td>
                    R16
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group189'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group189.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group189'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group189.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group189'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group189.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group189'
                           onChange={onGroupChangeComment}
                           value={currentReport.group189.comments}
                           list='group189_list'
                    />

                    <datalist id="group189_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>


                <tr>
                  <td>
                    33a
                  </td>
                  <td>
                    מיקום וזיהוי של אמצעי בקרה וחיווי
                  </td>
                  <td>
                    R121
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group190'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group190.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group190'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group190.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group190'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group190.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group190'
                           onChange={onGroupChangeComment}
                           value={currentReport.group190.comments}
                           list='group190_list'
                    />

                    <datalist id="group190_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>


                <tr>
                  <td>
                    34a
                  </td>
                  <td>
                    מערכות הפשרה וסליקת אדים לשמשה קדמית
                  </td>
                  <td>
                    (EU) No.672/2010
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group191'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group191.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group191'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group191.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group191'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group191.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group191'
                           onChange={onGroupChangeComment}
                           value={currentReport.group191.comments}
                           list='group191_list'
                    />

                    <datalist id="group191_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>


                <tr>
                  <td>
                    35a
                  </td>
                  <td>
                    מערכת ניקוי וניגוב לשמשה קדמית
                  </td>
                  <td>
                    (EU) No.1008/2010
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group192'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group192.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group192'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group192.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group192'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group192.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group192'
                           onChange={onGroupChangeComment}
                           value={currentReport.group192.comments}
                           list='group192_list'
                    />

                    <datalist id="group192_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>



                <tr>
                  <td>
                    36a
                  </td>
                  <td>
                    מערכת חימום
                  </td>
                  <td>
                    R122
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group193'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group193.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group193'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group193.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group193'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group193.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group193'
                           onChange={onGroupChangeComment}
                           value={currentReport.group193.comments}
                           list='group193_list'
                    />

                    <datalist id="group193_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    41a
                  </td>
                  <td>
                    פליטת מזהמים לרכב כבד
                  </td>
                  <td>
                    (EC) No.595/2009
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group194'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group194.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group194'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group194.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group194'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group194.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group194'
                           onChange={onGroupChangeComment}
                           value={currentReport.group194.comments}
                           list='group194_list'
                    />

                    <datalist id="group194_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>
                <tr>
                  <td>
                    42a
                  </td>
                  <td>
                    הגנת צד - R73, המגן הוא חובה לכל רכב שרוחק הסרנים שלו גדול מ-3 מ'.
                  </td>
                </tr>

                <tr>
                  <td>
                    42a
                  </td>
                  <td>
                    מרחק מקצה גלגל קדמי\אחורי עד קצה ההתקן 300 מ"מ
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group195'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group195.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group195'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group195.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group195'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group195.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group195'
                           onChange={onGroupChangeComment}
                           value={currentReport.group195.comments}
                           list='group195_list'
                    />

                    <datalist id="group195_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    42a
                  </td>
                  <td>
                    מרחק מקסימלי של קצה קדמי של ההתקן מהקבינה 100 מ"מ.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group196'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group196.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group196'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group196.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group196'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group196.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group196'
                           onChange={onGroupChangeComment}
                           value={currentReport.group196.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    42a
                  </td>
                  <td>
                    תותקן סופית בקצה הקדמי של ההתקן ברוחב של 100 מ"מ עד 45 מעלות.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group197'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group197.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group197'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group197.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group197'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group197.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group197'
                           onChange={onGroupChangeComment}
                           value={currentReport.group197.comments}/>
                  </td>
                </tr>



              </table>
            </div>

          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 22 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <div style={{padding: 20}}></div>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <th style={{width: '5%'}}>מס"ד</th>
                  <th style={{width: '10%'}}>נושא</th>
                  <th style={{width: '10%'}}>רגולציה</th>
                  <th style={{width: '10%'}}>ממצאים</th>
                  <th style={{width: '10%'}}>התאמה</th>
                  <th style={{width: '10%'}}>הערות</th>
                </tr>


                <tr>
                  <td>
                    42a
                  </td>
                  <td>
                    גובה מקסימלי מרצפה לקצה תחתון 550 מ"מ.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group198'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group198.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group198'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group198.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group198'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group198.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group198'
                           onChange={onGroupChangeComment}
                           value={currentReport.group198.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    42a
                  </td>
                  <td>
                    גובה מקסימלי מקצה עליון לארגז 350 מ"מ.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group199'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group199.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group199'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group199.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group199'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group199.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group199'
                           onChange={onGroupChangeComment}
                           value={currentReport.group199.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    42a
                  </td>
                  <td>
                    במידה ויש 2 התקנים, אחד מעל השני, מרווח האנכי ביניהם 300 מ"מ ויש לסגור את קדמת הפרופיל החיצוני בעובי המינימלי של הפרופיל.
                  </td>
                  <td>

                  </td>

                  <td>

                    <input className='reports_text'
                           type='text'
                           name='group200'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group200.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group200'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group200.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group200'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group200.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group200'
                           onChange={onGroupChangeComment}
                           value={currentReport.group200.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    42a
                  </td>
                  <td>
                    ההתקן יהיה ללא פינות חדות: ‏rmin>2.5mm
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group201'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group201.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group201'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group201.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group201'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group201.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group201'
                           onChange={onGroupChangeComment}
                           value={currentReport.group201.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    42a
                  </td>
                  <td>
                    ברכבים מהקטגוריה N2  גובה פורפיל מינימלי 50 מ"מ, ברכבים מהקטגוריה N3 ‎גובה פורפיל מינימלי 100 מ"מ.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group202'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group202.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group202'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group202.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group202'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group202.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group202'
                           onChange={onGroupChangeComment}
                           value={currentReport.group202.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    42a
                  </td>
                  <td>
                    מרווח בין התקן לאביזרים בולטים 25 מ"מ.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group203'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group203.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group203'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group203.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group203'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group203.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group203'
                           onChange={onGroupChangeComment}
                           value={currentReport.group203.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    42a
                  </td>
                  <td>
                    ההתקן לא ימוקם מעבר ל-150 מ"מ פנימה מדופן הארגז.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group204'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group204.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group204'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group204.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group204'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group204.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group204'
                           onChange={onGroupChangeComment}
                           value={currentReport.group204.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    42a
                  </td>
                  <td>
                    וגם ההתקן לא ימוקם מעבר ל-30 מ"מ פנימה מקצה הסרן האחורי.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group205'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group205.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group205'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group205.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group205'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group205.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group205'
                           onChange={onGroupChangeComment}
                           value={currentReport.group205.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    42a
                  </td>
                  <td>
                    המגן ומחברו לגוף הרכב יהיה בעל חוזק מספיק.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group206'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group206.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group206'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group206.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group206'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group206.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group206'
                           onChange={onGroupChangeComment}
                           value={currentReport.group206.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    42a
                  </td>
                  <td>
                    פני המגן או של כל מקטעו הנפרדים יהיו מישור אחיד.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group207'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group207.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group207'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group207.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group207'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group207.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group207'
                           onChange={onGroupChangeComment}
                           value={currentReport.group207.comments}/>
                  </td>
                </tr>

              </table>
            </div>

          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 23 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <div style={{padding: 20}}></div>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <th style={{width: '5%'}}>מס"ד</th>
                  <th style={{width: '10%'}}>נושא</th>
                  <th style={{width: '10%'}}>רגולציה</th>
                  <th style={{width: '10%'}}>ממצאים</th>
                  <th style={{width: '10%'}}>התאמה</th>
                  <th style={{width: '10%'}}>הערות</th>
                </tr>
              </table>
            </div>


            <h2 className='flex justify-content-center'>
              <div className='table-no-border'>
                <img src={pic9}  style={{ width: '100%' }} />
              </div>
            </h2>

            <h2 className='flex justify-content-center'>
              <div className='table-no-border'>
                <img src={pic10}  style={{ width: '100%' }} />
              </div>
            </h2>





            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >
                <tr>
                  <th style={{width: '5%'}}></th>
                  <th style={{width: '100%'}}></th>
                </tr>
                <tr>
                  <td>
                    43a
                  </td>
                  <td>
                    מערכת למניעת התזת מים ובוץ - 91/226/EEC (EU) No.109/2011, נדרש ברכבים שמשקלם מעל 7.5 טון.
                  </td>
                </tr>

              </table>
            </div>

          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 24 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <div style={{padding: 20}}></div>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <th style={{width: '5%'}}>מס"ד</th>
                  <th style={{width: '10%'}}>נושא</th>
                  <th style={{width: '10%'}}>רגולציה</th>
                  <th style={{width: '10%'}}>ממצאים</th>
                  <th style={{width: '10%'}}>התאמה</th>
                  <th style={{width: '10%'}}>הערות</th>
                </tr>
                <tr>
                  <td>
                    43a
                  </td>
                  <td>
                    מגן הבוץ יכסה את כל הרוחב של הצמיג.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group209'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group209.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group209'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group209.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group209'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group209.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group209'
                           onChange={onGroupChangeComment}
                           value={currentReport.group209.comments}
                           list='group209_list'
                    />

                    <datalist id="group209_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>


                <tr>
                  <td>
                    43a
                  </td>
                  <td>
                    בליטה חיצונית מקסימלית - 100 מ"מ עבור סרן היגוי.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group210'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group210.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group210'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group210.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group210'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group210.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group210'
                           onChange={onGroupChangeComment}
                           value={currentReport.group210.comments}
                           list='group210_list'
                    />

                    <datalist id="group210_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>



                <tr>
                  <td>
                    43a
                  </td>
                  <td>
                    בליטה חיצונית מקסימלית - 75 מ"מ עבור סרן אחר.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group211'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group211.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group211'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group211.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group211'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group211.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group211'
                           onChange={onGroupChangeComment}
                           value={currentReport.group211.comments}/>
                  </td>
                </tr>



                <tr>
                  <td>
                    43a
                  </td>
                  <td>
                    גובה מעל ציר סיבוב הגלגל - 1.5 רדיוס הגלגל עבור סרן היגוי.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group212'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group212.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group212'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group212.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group212'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group212.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group212'
                           onChange={onGroupChangeComment}
                           value={currentReport.group212.comments}/>
                  </td>
                </tr>



                <tr>
                  <td>
                    43a
                  </td>
                  <td>
                    גובה מעל ציר סיבוב הגלגל - 1.25 רדיוס הגלגל עבור סרן אחר.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group213'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group213.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group213'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group213.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group213'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group213.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group213'
                           onChange={onGroupChangeComment}
                           value={currentReport.group213.comments}/>
                  </td>
                </tr>



                <tr>
                  <td>
                    43a
                  </td>
                  <td>
                    על כל חלקי המגן להיות צמודים ומחוברים ללא ‏רווחים המאפשרים התזה.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group214'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group214.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group214'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group214.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group214'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group214.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group214'
                           onChange={onGroupChangeComment}
                           value={currentReport.group214.comments}/>
                  </td>
                </tr>



                <tr>
                  <td>
                    43a
                  </td>
                  <td>
                    גובה המגן מקרקע בין 200-300 מ"מ.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group215'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group215.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group215'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group215.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group215'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group215.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group215'
                           onChange={onGroupChangeComment}
                           value={currentReport.group215.comments}/>
                  </td>
                </tr>



                <tr>
                  <td>
                    43a
                  </td>
                  <td>
                    מגן הבוץ יכסה את כל הרוחב של הצמיג.מרווח‎ ‎מקצה צמיג מקס' 300
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group216'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group216.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group216'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group216.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group216'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group216.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group216'
                           onChange={onGroupChangeComment}
                           value={currentReport.group216.comments}/>
                  </td>
                </tr>



                <tr>
                  <td>
                    43a
                  </td>
                  <td>
                    המגן לא יזוז מעל 100 מ"מ לכיוון החלק האחורי של הרכב.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group217'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group217.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group217'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group217.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group217'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group217.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group217'
                           onChange={onGroupChangeComment}
                           value={currentReport.group217.comments}/>
                  </td>
                </tr>



                <tr>
                  <td>
                    43a
                  </td>
                  <td>
                    גובה חתך מינימלי של מגן התזה מעל הצמיג 100 מ"מ.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group218'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group218.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group218'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group218.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group218'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group218.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group218'
                           onChange={onGroupChangeComment}
                           value={currentReport.group218.comments}/>
                  </td>
                </tr>



                <tr>
                  <td>
                    43a
                  </td>
                  <td>
                    קצה הקדמי של המגן לא יחצה את הקו הדמיוני העובר דרך מרכז הגלגל ב-45 מעלות מעל האופק.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group219'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group219.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group219'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group219.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group219'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group219.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group219'
                           onChange={onGroupChangeComment}
                           value={currentReport.group219.comments}/>
                  </td>
                </tr>


              </table>
            </div>
            <h2 className='flex justify-content-center'>
              <div className='table-no-border'>
                <img src={pic11}  style={{ width: '100%' }} />
              </div>
            </h2>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >
                <tr>
                  <th style={{width: '5%'}}></th>
                  <th style={{width: '10%'}}></th>
                  <th style={{width: '10%'}}></th>
                  <th style={{width: '10%'}}></th>
                  <th style={{width: '10%'}}></th>
                  <th style={{width: '10%'}}></th>

                </tr>


                <tr>
                  <td>
                    45a
                  </td>
                  <td>
                    בטיחות חלונות (זכוכית)
                  </td>
                  <td>
                    R43
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group220'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group220.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group220'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group220.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group220'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group220.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group220'
                           onChange={onGroupChangeComment}
                           value={currentReport.group220.comments}
                           list='group220_list'
                    />

                    <datalist id="group220_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>
              </table>
            </div>


          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 25 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <div style={{padding: 20}}></div>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <th style={{width: '5%'}}>מס"ד</th>
                  <th style={{width: '10%'}}>נושא</th>
                  <th style={{width: '10%'}}>רגולציה</th>
                  <th style={{width: '10%'}}>ממצאים</th>
                  <th style={{width: '10%'}}>התאמה</th>
                  <th style={{width: '10%'}}>הערות</th>
                </tr>
                <tr>
                  <td>
                    46a
                  </td>
                  <td>
                    התקנת צמיגים ברכב
                  </td>
                  <td>
                    92/23/EEC
                    (EU) No.458/2011
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group221'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group221.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group221'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group221.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group221'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group221.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group221'
                           onChange={onGroupChangeComment}
                           value={currentReport.group221.comments}
                           list='group221_list'
                    />

                    <datalist id="group221_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    46b
                  </td>
                  <td>
                    צמיגים
                    פנאומטיים
                    לכלי רכב
                    ולנגררים שלהם
                  </td>
                  <td>
                    R30
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group14'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group14.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group14'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group14.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group14'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group14.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group14'
                           onChange={onGroupChangeComment}
                           value={currentReport.group14.comments}
                           list='group14_list'
                    />

                    <datalist id="group14_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>



                <tr>
                  <td>
                    46c
                  </td>
                  <td>
                    צמיגי אוויר לרכב מסחרי
                  </td>
                  <td>
                    R54
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group222'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group222.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group222'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group222.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group222'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group222.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group222'
                           onChange={onGroupChangeComment}
                           value={currentReport.group222.comments}
                           list='group222_list'
                    />

                    <datalist id="group222_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    46d
                  </td>
                  <td>
                    פליטת רעש גלגול צמיגים, אחיזה על משטחים רטובים והתנגדות לגלילה
                  </td>
                  <td>
                    R117
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group223'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group223.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group223'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group223.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group223'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group223.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group223'
                           onChange={onGroupChangeComment}
                           value={currentReport.group223.comments}
                           list='group223_list'
                    />

                    <datalist id="group223_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    46e
                  </td>
                  <td>
                    יחידת חילוף
                    זמנית,
                    צמיגים/מערכת
                    ריצה ומערכת
                    ניטור לחץ
                    אוויר בצמיגים
                  </td>
                  <td>
                    R64
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group15'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group15.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group15'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group15.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group15'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group15.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group15'
                           onChange={onGroupChangeComment}
                           value={currentReport.group15.comments}
                           list='group15_list'
                    />

                    <datalist id="group15_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>


                <tr>
                  <td>
                    47a
                  </td>
                  <td>
                    מגביל מהירות
                  </td>
                  <td>
                    R89
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group224'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group224.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group224'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group224.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group224'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group224.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group224'
                           onChange={onGroupChangeComment}
                           value={currentReport.group224.comments}
                           list='group224_list'
                    />

                    <datalist id="group224_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>
                <tr>
                  <th style={{width: '25%'}}>בכלי רכב מעל 8 טון יש לוודא שקיים טכוגרף.</th>
                </tr>

                <tr>
                  <td>
                    48a
                  </td>
                  <td>
                    משקלים ומידות
                  </td>
                  <td>
                    97/27/EC
                    (EU) No.1230/2012
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group225'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group225.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group225'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group225.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group225'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group225.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group225'
                           onChange={onGroupChangeComment}
                           value={currentReport.group225.comments}
                           list='group225_list'
                    />

                    <datalist id="group225_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>


                <tr>
                  <td>
                    49a
                  </td>
                  <td>
                    בליטות חיצוניות מעבר לתא נהג
                  </td>
                  <td>
                    R61
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group226'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group226.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group226'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group226.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group226'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group226.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group226'
                           onChange={onGroupChangeComment}
                           value={currentReport.group226.comments}
                           list='group226_list'
                    />

                    <datalist id="group226_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>
                <tr>
                  <td>
                    50a
                  </td>
                  <td>
                    התקני צימוד מכניים - R55
                  </td>
                </tr>
              </table>
            </div>


          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 26 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>



            <div style={{padding: 20}}></div>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >
                <tr>
                  <th style={{width: '5%'}}>מס"ד</th>
                  <th style={{width: '10%'}}>נושא</th>
                  <th style={{width: '10%'}}>דרישות רגולציה</th>
                  <th style={{width: '10%'}}>ממצאים </th>
                  <th style={{width: '10%'}}>התאמה</th>
                  <th style={{width: '10%'}}>הערות</th>
                </tr>




                <tr>
                  <td>
                    50a
                  </td>
                  <td>
                    תפוח גרירה / אונקל / נשלף / מקורי ומאושר
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group227'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group227.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group227'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group227.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group227'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group227.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group227'
                           onChange={onGroupChangeComment}
                           value={currentReport.group227.comments}
                           list='group227_list'
                    />

                    <datalist id="group227_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>
                <tr>
                  <td>
                    50a
                  </td>
                  <td>
                    גובה ציר האונקל מהקרקע 600-400 מ"מ.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group228'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group228.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group228'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group228.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group228'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group228.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group228'
                           onChange={onGroupChangeComment}
                           value={currentReport.group228.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    50a
                  </td>
                  <td>
                    גובה ציר אונקל משולב כדור מהקרקע (לציר הכדור) 47-400 מ"מ.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group229'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group229.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group229'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group229.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group229'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group229.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group229'
                           onChange={onGroupChangeComment}
                           value={currentReport.group229.comments}/>
                  </td>
                </tr>


                <tr>
                  <td>
                    50a
                  </td>
                  <td>
                    מזמין בדיקה ימסור את המשקל המרבי המותר לגרירה.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group230'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group230.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group230'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group230.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group230'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group230.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group230'
                           onChange={onGroupChangeComment}
                           value={currentReport.group230.comments}/>
                  </td>
                </tr>

                <tr>
                  <td>
                    50a
                  </td>
                  <td>
                    נדרשת הוראת התקנה והפעלה של ייצרן הנגרר.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group231'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group231.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group231'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group231.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group231'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group231.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group231'
                           onChange={onGroupChangeComment}
                           value={currentReport.group231.comments}/>
                  </td>
                </tr>


                <tr>
                  <td>
                    50a
                  </td>
                  <td>
                    גיאומטריה של התקן צימוד יאפשר לגורר תמרון חופשי ובטוח ביחס לרכב, וגם יאפשר אזור בטוח שבו המפעל יכול לעבוד ולברוח ממנו בעת הצורך.
                  </td>
                  <td>

                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group232'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group232.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group232'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group232.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group232'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group232.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group232'
                           onChange={onGroupChangeComment}
                           value={currentReport.group232.comments}/>
                  </td>
                </tr>
                <tr>
                  <td>
                    50b
                  </td>
                  <td>
                    התקני צימוד CCD
                  </td>
                  <td>
                    R102
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group233'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group233.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group233'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group233.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group233'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group233.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group233'
                           onChange={onGroupChangeComment}
                           value={currentReport.group233.comments}
                           list='group233_list'
                    />

                    <datalist id="group233_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    56a
                  </td>
                  <td>
                    רכב להובלת חומרים מסוכנים
                  </td>
                  <td>
                    R105
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group234'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group234.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group234'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group234.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group234'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group234.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group234'
                           onChange={onGroupChangeComment}
                           value={currentReport.group234.comments}
                           list='group234_list'
                    />

                    <datalist id="group234_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    57a
                  </td>
                  <td>
                    הגנה תת רכבית קדמית
                  </td>
                  <td>
                    R93
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group235'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group235.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group235'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group235.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group235'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group235.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group235'
                           onChange={onGroupChangeComment}
                           value={currentReport.group235.comments}
                           list='group235_list'
                    />

                    <datalist id="group235_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    58
                  </td>
                  <td>
                    הגנה על הולכי
                    רגל
                  </td>
                  <td>
                    (EC) No.
                    78/2009
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group16'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group16.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group16'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group16.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group16'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group16.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group16'
                           onChange={onGroupChangeComment}
                           value={currentReport.group16.comments}
                           list='group16_list'
                    />

                    <datalist id="group16_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    59
                  </td>
                  <td>
                    מיחזור יכולת
                  </td>
                  <td>
                    EC /2005/64
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group17'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group17.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group17'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group17.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group17'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group17.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group17'
                           onChange={onGroupChangeComment}
                           value={currentReport.group17.comments}
                           list='group17_list'
                    />

                    <datalist id="group17_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    61
                  </td>
                  <td>
                    מערכות מיזוג
                    או
                  </td>
                  <td>
                    2006/40/EC
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group18'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group18.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group18'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group18.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group18'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group18.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group18'
                           onChange={onGroupChangeComment}
                           value={currentReport.group18.comments}
                           list='group18_list'
                    />

                    <datalist id="group18_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    62
                  </td>
                  <td>
                    מערכות מימן
                  </td>
                  <td>
                    (EC) No.79/2009
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group236'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group236.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group236'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group236.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group236'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group236.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group236'
                           onChange={onGroupChangeComment}
                           value={currentReport.group236.comments}
                           list='group236_list'
                    />

                    <datalist id="group236_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

              </table>
            </div>


          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>06</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 27 מתוך 27</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>



            <div style={{padding: 20}}></div>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >
                <tr>
                  <th style={{width: '5%'}}>מס"ד</th>
                  <th style={{width: '10%'}}>נושא</th>
                  <th style={{width: '10%'}}> רגולציה</th>
                  <th style={{width: '10%'}}>ממצאים </th>
                  <th style={{width: '10%'}}>התאמה</th>
                  <th style={{width: '10%'}}>הערות</th>
                </tr>





                <tr>
                  <td>
                    63
                  </td>
                  <td>
                    בטיחות כללית
                  </td>
                  <td>
                    (EC) No.661/2009
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group237'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group237.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group237'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group237.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group237'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group237.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group237'
                           onChange={onGroupChangeComment}
                           value={currentReport.group237.comments}
                           list='group237_list'
                    />

                    <datalist id="group237_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    65
                  </td>
                  <td>
                    מערכות בלימת חירום מתקדמות
                    AEBS

                  </td>
                  <td>
                    R131
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           list='AEBS_types'
                           name='group238'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group238.finds}
                    />
                    <datalist id="AEBS_types">
                      <option value="מקורית">מקורית</option>
                      <option value="התקנה מקומית">התקנה מקומית</option>
                    </datalist>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group238'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group238.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group238'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group238.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group238'
                           onChange={onGroupChangeComment}
                           value={currentReport.group238.comments}
                           list='group238_list'
                    />

                    <datalist id="group238_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    66
                  </td>
                  <td>
                    מערכת התרעה לסטייה מנתיב  LDWS
                  </td>
                  <td>
                    R130
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group239'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group239.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group239'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group239.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group239'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group239.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group239'
                           onChange={onGroupChangeComment}
                           value={currentReport.group239.comments}
                           list='group239_list'
                    />

                    <datalist id="group239_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    67
                  </td>
                  <td>
                    מערכות מנוע המונע בגז (LPG)  והתקנתו
                  </td>
                  <td>
                    R67
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group240'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group240.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group240'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group240.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group240'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group240.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group240'
                           onChange={onGroupChangeComment}
                           value={currentReport.group240.comments}
                           list='group240_list'
                    />

                    <datalist id="group240_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    69
                  </td>
                  <td>
                    בטיחות מערכת חשמל
                  </td>
                  <td>
                    R100
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group241'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group241.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group241'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group241.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group241'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group241.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group241'
                           onChange={onGroupChangeComment}
                           value={currentReport.group241.comments}
                           list='group241_list'
                    />

                    <datalist id="group241_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    70
                  </td>
                  <td>
                    רכיבים למערכות CNG והתקנתם
                  </td>
                  <td>
                    R110
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group242'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group242.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group242'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group242.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group242'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group242.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group242'
                           onChange={onGroupChangeComment}
                           value={currentReport.group242.comments}
                           list='group242_list'
                    />

                    <datalist id="group242_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    71
                  </td>
                  <td>
                    חוזק תא נהג
                  </td>
                  <td>
                    R29
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group243'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group243.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group243'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group243.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group243'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group243.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group243'
                           onChange={onGroupChangeComment}
                           value={currentReport.group243.comments}
                           list='group243_list'
                    />

                    <datalist id="group243_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>
                    72
                  </td>
                  <td>
                    eCall מערכת
                  </td>
                  <td>
                    (EU)2015/758
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group19'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group19.finds}/>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group19'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group19.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group19'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group19.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group19'
                           onChange={onGroupChangeComment}
                           value={currentReport.group19.comments}
                           list='group19_list'
                    />

                    <datalist id="group19_list">
                      <option value="A">A</option>
                      <option value="V">V</option>
                      <option value="N/A">N/A</option>
                      <option value="-">-</option>
                    </datalist>
                  </td>
                </tr>

              </table>
            </div>

            <h2 className='mt-3 text-align-right'>
              הערות כלליות ו/או פרטים רלוונטיים אחרים:
            </h2>

            <textarea className='reports_text' rows="5" cols="33"

                      type='text'
                      name='group244'
                      onChange={onGroupChangeComment}
                      value={currentReport.group244.comments}/>

          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>

            <div className='mt-3 text-underline'>
              <h1 className='mt-3 text-align-right'>תמונות</h1>
              <div className='flex justify-content-center'>
                <table
                    className='reports__table reports__table--dark'
                    style={{maxWidth: '120rem'}}
                >
                  <tr>
                    <td style={{width: '5%'}}>מס"ד</td>
                    <td style={{width: '10%'}}>שם התמונה</td>
                    <td style={{width: '10%'}}>העלת תמונה</td>
                    <td style={{width: '10%'}}>תצוגת תמונה</td>
                    <td style={{width: '10%'}}>סטטוס </td>
                    <td style={{width: '10%'}}>האם לשים בתעודה?</td>
                  </tr>


                  <tr>
                    <td>
                      1
                    </td>
                    <td>
                      חזית
                    </td>
                    <td>
                      <FileUpload
                          setFile={setImage1}
                          name='image1'
                          text='image1'
                      />
                    </td>
                    <td>
                      <div>
                        {pImage1 ? (
                            <img
                                src={pImage1}
                                alt='לא הועלה תמונה'
                                style={{width: '100px', height: '100px',transform: `rotate(${-90}deg)`}}
                            />
                        ) : (
                            'אין תמונה'
                        )}
                      </div>

                    </td>
                    <td>
                      <div

                          className={`${
                              !Image1 ? '' : 'bg-success'
                          } color-white p-1`}
                      >
                        {!Image1 ? '' : 'נבחר'}
                      </div>

                    </td>

                    <td>

                    </td>

                  </tr>

                  <tr>
                    <td>
                      2
                    </td>
                    <td>
                      אחורי
                    </td>
                    <td>

                      <FileUpload
                          setFile={setImage2}
                          name='image2'
                          text='image2'
                      />
                    </td>
                    <td>
                      <div>
                        {pImage2 ? (
                            <img
                                src={pImage2}
                                alt='לא הועלה תמונה'
                                style={{width: '100px', height: '100px',transform: `rotate(${-90}deg)`}}
                            />
                        ) : (
                            'אין תמונה'
                        )}
                      </div>

                    </td>
                    <td>
                      <div
                          className={`${
                              !Image2 ? '' : 'bg-success'
                          } color-white p-1`}
                      >
                        {!Image2 ? '' : 'נבחר'}
                      </div>

                    </td>

                    <td>

                    </td>
                  </tr>

                  <tr>
                    <td>
                      3
                    </td>
                    <td>
                      תווית Vin
                    </td>
                    <td>

                      <FileUpload
                          setFile={setImage3}
                          name='image3'
                          text='image3'
                      />
                    </td>
                    <td>
                      <div>
                        {pImage3 ? (
                            <img
                                src={pImage3}
                                alt='לא הועלה תמונה'
                                style={{width: '100px', height: '100px',transform: `rotate(${-90}deg)`}}
                            />
                        ) : (
                            'אין תמונה'
                        )}
                      </div>

                    </td>
                    <td>
                      <div
                          className={`${
                              !Image3 ? '' : 'bg-success'
                          } color-white p-1`}
                      >
                        {!Image3 ? '' : 'נבחר'}
                      </div>

                    </td>

                    <td>

                    </td>
                  </tr>

                  <tr>
                    <td>
                      4
                    </td>
                    <td>
                      <input
                          type='text'
                          name='image4'
                          onChange={onGroupChangeComment}
                          value={currentReport.image4.comments}
                          list='image4_list'
                      />
                      <datalist id='image4_list'>
                        <option value='תיעוד הטבעה על המרכב'> תיעוד הטבעה על המרכב</option>
                        <option value='תיעוד תווית המרכב'> תיעוד תווית המרכב</option>
                        <option value='משקל קדמי ימין'>משקל קדמי ימין </option>
                        <option value='משקל קדמי שמאל'> משקל קדמי שמאל </option>
                        <option value='משקל אחורי ימין'>משקל אחורי ימין</option>
                        <option value='משקל אחורי שמאל'> משקל אחורי שמאל </option>
                        <option value='משקל כללי'> משקל כללי </option>
                        <option value='משקל אחורי'> משקל אחורי </option>
                        <option value='משקל קדמי'> משקל קדמי </option>
                        <option value='תיעוד תווית דופן הידראולית'> תיעוד תווית דופן הידראולית </option>
                        <option value='תיעוד תווית מנוף'> תיעוד תווית מנוף</option>
                        <option value='תיעוד הטבעת על המנוף'> תיעוד הטבעת על המנוף</option>
                        <option value='תיעוד תוויות התקו צימוד'> תיעוד תוויות התקו צימוד</option>
                        <option value='תיעוד התקן צימוד'> תיעוד התקן צימוד</option>
                      </datalist>
                    </td>
                    <td>

                      <FileUpload
                          setFile={setImage4}
                          name='image4'
                          text='image4'
                      />
                    </td>
                    <td>
                      <div>
                        {pImage4 ? (
                            <img
                                src={pImage4}
                                alt='לא הועלה תמונה'
                                style={{width: '100px', height: '100px',transform: `rotate(${-90}deg)`}}
                            />
                        ) : (
                            'אין תמונה'
                        )}
                      </div>

                    </td>
                    <td>
                      <div
                          className={`${
                              !Image4 ? '' : 'bg-success'
                          } color-white p-1`}
                      >
                        {!Image4 ? '' : 'נבחר'}
                      </div>

                    </td>

                    <td>

                    </td>
                  </tr>

                  <tr>
                    <td>
                      5
                    </td>
                    <td>
                      <input
                          type='text'
                          name='image5'
                          onChange={onGroupChangeComment}
                          value={currentReport.image5.comments}
                          list='image5_list'
                      />
                      <datalist id='image5_list'>
                        <option value='תיעוד הטבעה על המרכב'> תיעוד הטבעה על המרכב</option>
                        <option value='תיעוד תווית המרכב'> תיעוד תווית המרכב</option>
                        <option value='משקל קדמי ימין'>משקל קדמי ימין </option>
                        <option value='משקל קדמי שמאל'> משקל קדמי שמאל </option>
                        <option value='משקל אחורי ימין'>משקל אחורי ימין</option>
                        <option value='משקל אחורי שמאל'> משקל אחורי שמאל </option>
                        <option value='משקל כללי'> משקל כללי </option>
                        <option value='משקל אחורי'> משקל אחורי </option>
                        <option value='משקל קדמי'> משקל קדמי </option>
                        <option value='תיעוד תווית דופן הידראולית'> תיעוד תווית דופן הידראולית </option>
                        <option value='תיעוד תווית מנוף'> תיעוד תווית מנוף</option>
                        <option value='תיעוד הטבעת על המנוף'> תיעוד הטבעת על המנוף</option>
                        <option value='תיעוד תוויות התקו צימוד'> תיעוד תוויות התקו צימוד</option>
                        <option value='תיעוד התקן צימוד'> תיעוד התקן צימוד</option>
                      </datalist>
                    </td>
                    <td>

                      <FileUpload
                          setFile={setImage5}
                          name='image5'
                          text='image5'
                      />
                    </td>
                    <td>
                      <div>
                        {pImage5 ? (
                            <img
                                src={pImage5}
                                alt='לא הועלה תמונה'
                                style={{width: '100px', height: '100px',transform: `rotate(${-90}deg)`}}
                            />
                        ) : (
                            'אין תמונה'
                        )}
                      </div>

                    </td>
                    <td>
                      <div
                          className={`${
                              !Image5 ? '' : 'bg-success'
                          } color-white p-1`}
                      >
                        {!Image5 ? '' : 'נבחר'}
                      </div>

                    </td>

                    <td>

                    </td>
                  </tr>

                  <tr>
                    <td>
                      6
                    </td>
                    <td>
                      <input
                          type='text'
                          name='image6'
                          onChange={onGroupChangeComment}
                          value={currentReport.image6.comments}
                          list='image6_list'
                      />
                      <datalist id='image6_list'>
                        <option value='תיעוד הטבעה על המרכב'> תיעוד הטבעה על המרכב</option>
                        <option value='תיעוד תווית המרכב'> תיעוד תווית המרכב</option>
                        <option value='משקל קדמי ימין'>משקל קדמי ימין </option>
                        <option value='משקל קדמי שמאל'> משקל קדמי שמאל </option>
                        <option value='משקל אחורי ימין'>משקל אחורי ימין</option>
                        <option value='משקל אחורי שמאל'> משקל אחורי שמאל </option>
                        <option value='משקל כללי'> משקל כללי </option>
                        <option value='משקל אחורי'> משקל אחורי </option>
                        <option value='משקל קדמי'> משקל קדמי </option>
                        <option value='תיעוד תווית דופן הידראולית'> תיעוד תווית דופן הידראולית </option>
                        <option value='תיעוד תווית מנוף'> תיעוד תווית מנוף</option>
                        <option value='תיעוד הטבעת על המנוף'> תיעוד הטבעת על המנוף</option>
                        <option value='תיעוד תוויות התקו צימוד'> תיעוד תוויות התקו צימוד</option>
                        <option value='תיעוד התקן צימוד'> תיעוד התקן צימוד</option>
                      </datalist>
                    </td>
                    <td>

                      <FileUpload
                          setFile={setImage6}
                          name='image6'
                          text='image6'
                      />
                    </td>
                    <td>
                      <div>
                        {pImage6 ? (
                            <img
                                src={pImage6}
                                alt='לא הועלה תמונה'
                                style={{width: '100px', height: '100px',transform: `rotate(${-90}deg)`}}
                            />
                        ) : (
                            'אין תמונה'
                        )}
                      </div>

                    </td>
                    <td>
                      <div
                          className={`${
                              !Image6 ? '' : 'bg-success'
                          } color-white p-1`}
                      >
                        {!Image6 ? '' : 'נבחר'}
                      </div>

                    </td>

                    <td>

                    </td>
                  </tr>

                  <tr>
                    <td>
                      7
                    </td>
                    <td>
                      <input
                          type='text'
                          name='image7'
                          onChange={onGroupChangeComment}
                          value={currentReport.image7.comments}
                          list='image7_list'
                      />
                      <datalist id='image7_list'>
                        <option value='תיעוד הטבעה על המרכב'> תיעוד הטבעה על המרכב</option>
                        <option value='תיעוד תווית המרכב'> תיעוד תווית המרכב</option>
                        <option value='משקל קדמי ימין'>משקל קדמי ימין </option>
                        <option value='משקל קדמי שמאל'> משקל קדמי שמאל </option>
                        <option value='משקל אחורי ימין'>משקל אחורי ימין</option>
                        <option value='משקל אחורי שמאל'> משקל אחורי שמאל </option>
                        <option value='משקל כללי'> משקל כללי </option>
                        <option value='משקל אחורי'> משקל אחורי </option>
                        <option value='משקל קדמי'> משקל קדמי </option>
                        <option value='תיעוד תווית דופן הידראולית'> תיעוד תווית דופן הידראולית </option>
                        <option value='תיעוד תווית מנוף'> תיעוד תווית מנוף</option>
                        <option value='תיעוד הטבעת על המנוף'> תיעוד הטבעת על המנוף</option>
                        <option value='תיעוד תוויות התקו צימוד'> תיעוד תוויות התקו צימוד</option>
                        <option value='תיעוד התקן צימוד'> תיעוד התקן צימוד</option>
                      </datalist>
                    </td>
                    <td>

                      <FileUpload
                          setFile={setImage7}
                          name='image7'
                          text='image7'
                      />
                    </td>
                    <td>
                      <div>
                        {pImage7 ? (
                            <img
                                src={pImage7}
                                alt='לא הועלה תמונה'
                                style={{width: '100px', height: '100px',transform: `rotate(${-90}deg)`}}
                            />
                        ) : (
                            'אין תמונה'
                        )}
                      </div>

                    </td>
                    <td>
                      <div
                          className={`${
                              !Image7 ? '' : 'bg-success'
                          } color-white p-1`}
                      >
                        {!Image7 ? '' : 'נבחר'}
                      </div>

                    </td>

                    <td>
                      <input className='reports_text'
                             type='checkbox'
                             style={{ marginLeft: '1rem' }}
                             checked={currentReport.image7_set}
                             onChange={(e) => {
                               setCurrentReport({
                                 ...currentReport,
                                 image7_set: e.target.checked,
                               });
                             }}
                      />
                      <label>
                        סמן לאישור
                      </label>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      8
                    </td>
                    <td>
                      <input
                          type='text'
                          name='image8'
                          onChange={onGroupChangeComment}
                          value={currentReport.image8.comments}
                          list='image8_list'
                      />
                      <datalist id='image8_list'>
                        <option value='תיעוד הטבעה על המרכב'> תיעוד הטבעה על המרכב</option>
                        <option value='תיעוד תווית המרכב'> תיעוד תווית המרכב</option>
                        <option value='משקל קדמי ימין'>משקל קדמי ימין </option>
                        <option value='משקל קדמי שמאל'> משקל קדמי שמאל </option>
                        <option value='משקל אחורי ימין'>משקל אחורי ימין</option>
                        <option value='משקל אחורי שמאל'> משקל אחורי שמאל </option>
                        <option value='משקל כללי'> משקל כללי </option>
                        <option value='משקל אחורי'> משקל אחורי </option>
                        <option value='משקל קדמי'> משקל קדמי </option>
                        <option value='תיעוד תווית דופן הידראולית'> תיעוד תווית דופן הידראולית </option>
                        <option value='תיעוד תווית מנוף'> תיעוד תווית מנוף</option>
                        <option value='תיעוד הטבעת על המנוף'> תיעוד הטבעת על המנוף</option>
                        <option value='תיעוד תוויות התקו צימוד'> תיעוד תוויות התקו צימוד</option>
                        <option value='תיעוד התקן צימוד'> תיעוד התקן צימוד</option>
                      </datalist>
                    </td>
                    <td>

                      <FileUpload
                          setFile={setImage8}
                          name='image8'
                          text='image8'
                      />
                    </td>
                    <td>
                      <div>
                        {pImage8 ? (
                            <img
                                src={pImage8}
                                alt='לא הועלה תמונה'
                                style={{width: '100px', height: '100px',transform: `rotate(${-90}deg)`}}
                            />
                        ) : (
                            'אין תמונה'
                        )}
                      </div>

                    </td>
                    <td>
                      <div
                          className={`${
                              !Image8 ? '' : 'bg-success'
                          } color-white p-1`}
                      >
                        {!Image8 ? '' : 'נבחר'}
                      </div>

                    </td>

                    <td>
                      <input className='reports_text'
                             type='checkbox'
                             style={{ marginLeft: '1rem' }}
                             checked={currentReport.image8_set}
                             onChange={(e) => {
                               setCurrentReport({
                                 ...currentReport,
                                 image8_set: e.target.checked,
                               });
                             }}
                      />
                      <label>
                        סמן לאישור
                      </label>
                    </td>
                  </tr>


                  <tr>
                    <td>
                      9
                    </td>
                    <td>
                      <input
                          type='text'
                          name='image9'
                          onChange={onGroupChangeComment}
                          value={currentReport.image9.comments}
                          list='image9_list'
                      />
                      <datalist id='image9_list'>
                        <option value='תיעוד הטבעה על המרכב'> תיעוד הטבעה על המרכב</option>
                        <option value='תיעוד תווית המרכב'> תיעוד תווית המרכב</option>
                        <option value='משקל קדמי ימין'>משקל קדמי ימין </option>
                        <option value='משקל קדמי שמאל'> משקל קדמי שמאל </option>
                        <option value='משקל אחורי ימין'>משקל אחורי ימין</option>
                        <option value='משקל אחורי שמאל'> משקל אחורי שמאל </option>
                        <option value='משקל כללי'> משקל כללי </option>
                        <option value='משקל אחורי'> משקל אחורי </option>
                        <option value='משקל קדמי'> משקל קדמי </option>
                        <option value='תיעוד תווית דופן הידראולית'> תיעוד תווית דופן הידראולית </option>
                        <option value='תיעוד תווית מנוף'> תיעוד תווית מנוף</option>
                        <option value='תיעוד הטבעת על המנוף'> תיעוד הטבעת על המנוף</option>
                        <option value='תיעוד תוויות התקו צימוד'> תיעוד תוויות התקו צימוד</option>
                        <option value='תיעוד התקן צימוד'> תיעוד התקן צימוד</option>
                      </datalist>
                    </td>
                    <td>

                      <FileUpload
                          setFile={setImage9}
                          name='image9'
                          text='image9'
                      />
                    </td>
                    <td>
                      <div>
                        {pImage9 ? (
                            <img
                                src={pImage9}
                                alt='לא הועלה תמונה'
                                style={{width: '100px', height: '100px',transform: `rotate(${-90}deg)`}}
                            />
                        ) : (
                            'אין תמונה'
                        )}
                      </div>

                    </td>
                    <td>
                      <div
                          className={`${
                              !Image9 ? '' : 'bg-success'
                          } color-white p-1`}
                      >
                        {!Image9 ? '' : 'נבחר'}
                      </div>

                    </td>

                    <td>
                      <input className='reports_text'
                             type='checkbox'
                             style={{ marginLeft: '1rem' }}
                             checked={currentReport.image9_set}
                             onChange={(e) => {
                               setCurrentReport({
                                 ...currentReport,
                                 image9_set: e.target.checked,
                               });
                             }}
                      />
                      <label>
                        סמן לאישור
                      </label>

                    </td>
                  </tr>


                  <tr>
                    <td>
                      10
                    </td>
                    <td>
                      <input
                          type='text'
                          name='image10'
                          onChange={onGroupChangeComment}
                          value={currentReport.image10.comments}
                          list='image10_list'
                      />
                      <datalist id='image10_list'>
                        <option value='תיעוד הטבעה על המרכב'> תיעוד הטבעה על המרכב</option>
                        <option value='תיעוד תווית המרכב'> תיעוד תווית המרכב</option>
                        <option value='משקל קדמי ימין'>משקל קדמי ימין </option>
                        <option value='משקל קדמי שמאל'> משקל קדמי שמאל </option>
                        <option value='משקל אחורי ימין'>משקל אחורי ימין</option>
                        <option value='משקל אחורי שמאל'> משקל אחורי שמאל </option>
                        <option value='משקל כללי'> משקל כללי </option>
                        <option value='משקל אחורי'> משקל אחורי </option>
                        <option value='משקל קדמי'> משקל קדמי </option>
                        <option value='תיעוד תווית דופן הידראולית'> תיעוד תווית דופן הידראולית </option>
                        <option value='תיעוד תווית מנוף'> תיעוד תווית מנוף</option>
                        <option value='תיעוד הטבעת על המנוף'> תיעוד הטבעת על המנוף</option>
                        <option value='תיעוד תוויות התקו צימוד'> תיעוד תוויות התקו צימוד</option>
                        <option value='תיעוד התקן צימוד'> תיעוד התקן צימוד</option>
                      </datalist>
                    </td>
                    <td>

                      <FileUpload
                          setFile={setImage10}
                          name='image10'
                          text='image10'
                      />
                    </td>
                    <td>
                      <div>
                        {pImage10 ? (
                            <img
                                src={pImage10}
                                alt='לא הועלה תמונה'
                                style={{width: '100px', height: '100px',transform: `rotate(${-90}deg)`}}
                            />
                        ) : (
                            'אין תמונה'
                        )}
                      </div>

                    </td>
                    <td>
                      <div
                          className={`${
                              !Image10 ? '' : 'bg-success'
                          } color-white p-1`}
                      >
                        {!Image10 ? '' : 'נבחר'}
                      </div>

                    </td>

                    <td>
                      <input className='reports_text'
                             type='checkbox'
                             style={{ marginLeft: '1rem' }}
                             checked={currentReport.image10_set}
                             onChange={(e) => {
                               setCurrentReport({
                                 ...currentReport,
                                 image10_set: e.target.checked,
                               });
                             }}
                      />
                      <label>
                        סמן לאישור
                      </label>

                    </td>
                  </tr>


                  <tr>
                    <td>
                      11
                    </td>
                    <td>
                      <input
                          type='text'
                          name='image11'
                          onChange={onGroupChangeComment}
                          value={currentReport.image11.comments}
                          list='image11_list'
                      />
                      <datalist id='image11_list'>
                        <option value='תיעוד הטבעה על המרכב'> תיעוד הטבעה על המרכב</option>
                        <option value='תיעוד תווית המרכב'> תיעוד תווית המרכב</option>
                        <option value='משקל קדמי ימין'>משקל קדמי ימין </option>
                        <option value='משקל קדמי שמאל'> משקל קדמי שמאל </option>
                        <option value='משקל אחורי ימין'>משקל אחורי ימין</option>
                        <option value='משקל אחורי שמאל'> משקל אחורי שמאל </option>
                        <option value='משקל כללי'> משקל כללי </option>
                        <option value='משקל אחורי'> משקל אחורי </option>
                        <option value='משקל קדמי'> משקל קדמי </option>
                        <option value='תיעוד תווית דופן הידראולית'> תיעוד תווית דופן הידראולית </option>
                        <option value='תיעוד תווית מנוף'> תיעוד תווית מנוף</option>
                        <option value='תיעוד הטבעת על המנוף'> תיעוד הטבעת על המנוף</option>
                        <option value='תיעוד תוויות התקו צימוד'> תיעוד תוויות התקו צימוד</option>
                        <option value='תיעוד התקן צימוד'> תיעוד התקן צימוד</option>
                      </datalist>
                    </td>
                    <td>

                      <FileUpload
                          setFile={setImage11}
                          name='image11'
                          text='image11'
                      />
                    </td>
                    <td>
                      <div>
                        {pImage11 ? (
                            <img
                                src={pImage11}
                                alt='לא הועלה תמונה'
                                style={{width: '100px', height: '100px',transform: `rotate(${-90}deg)`}}
                            />
                        ) : (
                            'אין תמונה'
                        )}
                      </div>

                    </td>
                    <td>
                      <div
                          className={`${
                              !Image11 ? '' : 'bg-success'
                          } color-white p-1`}
                      >
                        {!Image11 ? '' : 'נבחר'}
                      </div>

                    </td>

                    <td>
                      <input className='reports_text'
                             type='checkbox'
                             style={{ marginLeft: '1rem' }}
                             checked={currentReport.image11_set}
                             onChange={(e) => {
                               setCurrentReport({
                                 ...currentReport,
                                 image11_set: e.target.checked,
                               });
                             }}
                      />
                      <label>
                        סמן לאישור
                      </label>
                    </td>
                  </tr>



                  <tr>
                    <td>
                      12
                    </td>
                    <td>
                      <input
                          type='text'
                          name='image12'
                          onChange={onGroupChangeComment}
                          value={currentReport.image12.comments}
                          list='image12_list'
                      />
                      <datalist id='image12_list'>
                        <option value='תיעוד הטבעה על המרכב'> תיעוד הטבעה על המרכב</option>
                        <option value='תיעוד תווית המרכב'> תיעוד תווית המרכב</option>
                        <option value='משקל קדמי ימין'>משקל קדמי ימין </option>
                        <option value='משקל קדמי שמאל'> משקל קדמי שמאל </option>
                        <option value='משקל אחורי ימין'>משקל אחורי ימין</option>
                        <option value='משקל אחורי שמאל'> משקל אחורי שמאל </option>
                        <option value='משקל כללי'> משקל כללי </option>
                        <option value='משקל אחורי'> משקל אחורי </option>
                        <option value='משקל קדמי'> משקל קדמי </option>
                        <option value='תיעוד תווית דופן הידראולית'> תיעוד תווית דופן הידראולית </option>
                        <option value='תיעוד תווית מנוף'> תיעוד תווית מנוף</option>
                        <option value='תיעוד הטבעת על המנוף'> תיעוד הטבעת על המנוף</option>
                        <option value='תיעוד תוויות התקו צימוד'> תיעוד תוויות התקו צימוד</option>
                        <option value='תיעוד התקן צימוד'> תיעוד התקן צימוד</option>
                      </datalist>
                    </td>
                    <td>

                      <FileUpload
                          setFile={setImage12}
                          name='image12'
                          text='image12'
                      />
                    </td>
                    <td>
                      <div>
                        {pImage12 ? (
                            <img
                                src={pImage12}
                                alt='לא הועלה תמונה'
                                style={{width: '100px', height: '100px',transform: `rotate(${-90}deg)`}}
                            />
                        ) : (
                            'אין תמונה'
                        )}
                      </div>

                    </td>
                    <td>
                      <div
                          className={`${
                              !Image12 ? '' : 'bg-success'
                          } color-white p-1`}
                      >
                        {!Image12 ? '' : 'נבחר'}
                      </div>

                    </td>

                    <td>
                      <input className='reports_text'
                             type='checkbox'
                             style={{ marginLeft: '1rem' }}
                             checked={currentReport.image12_set}
                             onChange={(e) => {
                               setCurrentReport({
                                 ...currentReport,
                                 image12_set: e.target.checked,
                               });
                             }}
                      />
                      <label>
                        סמן לאישור
                      </label>
                    </td>
                  </tr>


                </table>
              </div>

              <div className='flex justify-content-center mt-4 mb-3'>
                <button
                    type='submit'
                    className='button bg-secondary w-400 reports__submit'
                    style={{ padding: '2rem', fontSize: '1.4em' }}
                >
                  {currentReport.id ? 'צור טופס' : 'עדכן טופס'}
                </button>

              </div>


            </div>
          </div>
          <ToastContainer position='bottom-left' autoClose={2500} />

        </form>
        <Loader
            isVisible={isLoading}
            text='טוען נתונים...'
        />

      </React.Fragment>
  );
};

const stateToProps = (state) => {
  return {
    user: state.userReducer,
  };
};

export default connect(stateToProps)(Report_Type_1);
