import React from 'react';
import ReactDOM from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion';

const Loader = ({

                    text = 'Loading data...',
                    isVisible,
                    spinnerClass = 'loader-spinner',
                    backgroundMode = 'dark',
                }) => {
    const renderLoader = () => {
        return ReactDOM.createPortal(
            <AnimatePresence>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className={`loader loader--${backgroundMode}`}
                >
                    <div className='loader__content'>
                        <div className={spinnerClass}></div>
                        <div></div>
                        {text}
                    </div>
                </motion.div>
            </AnimatePresence>,
            document.getElementById('loader')
        );
    };
    return isVisible ? renderLoader() : null;
};

export default Loader;
