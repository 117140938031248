import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import history from '../../../history';
import { setMenuItem } from '../../../actions/menuActions';
import Loader from "../../Loader/Loader";
import ProgressBar from "../../Secured/Custom/Progressbar/Progressbar"
import {isVisible} from "bootstrap/js/src/util";

const OptionsMenu = (props) => {
  const menu = props.menu;
  console.log(menu);
  //const menuItem = 1;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {}, []);
  const loadPage = (menuItem) => {
    props.setMenuItem(menuItem);
    switch (menuItem) {
      case 0: {
        history.push('/backoffice/dashboard');
        break;
      }
      case 1: {
        history.push('/backoffice/reports');
        break;
      }
      case 2: {
          history.push('/backoffice/reports-list');
        break;
      }
      case 3: {
        history.push('/backoffice/manage/');
        break;
      }
      case 4: {
        history.push('/backoffice/planning');
        break;
      }
      default: {
        history.push('/backoffice/reports');
      }
    }
  };

  return (
    <div
      className={`secured-container__menu ${
        isMenuOpen ? '' : 'secured-container--close'
      }`}
    >
      <div
        className={`secured-container__close`}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <label
          labelfor='chbMenu'
          className={`secured-container__humburger ${
            !isMenuOpen ? '' : 'secured-container__menu--closed'
          }`}
        ></label>
      </div>

      <div
        className={`secured-container__menu-item ${
          menu.menuItem === '0' ? 'secured-container__selected' : ''
        }`}
        onClick={() => loadPage(0)}
      >
        <div className='secured-container__menu-icon-container'>
          <i className='fas fa-tachometer-alt'></i>
        </div>
        <span>כללי</span>
      </div>
      <div
        className={`secured-container__menu-item ${
          menu.menuItem === '1' ? 'secured-container__selected' : ''
        }`}
        onClick={() => loadPage(1)}
      >
        <div className='secured-container__menu-icon-container'>
          <i className='fas fa-file-signature'></i>
        </div>
        <span>דוח חדש</span>
      </div>

      <div
        className={`secured-container__menu-item ${
          menu.menuItem === '2' ? 'secured-container__selected' : ''
        }`}
        onClick={() => loadPage(2)}
      >
        <div className='secured-container__menu-icon-container'>
          <i class='fas fa-list-alt'></i>
        </div>
        <span>רשימת דוחות</span>
      </div>

      {props.user.role.type.toLocaleLowerCase() === 'מנהל' && (
        <div
          className={`secured-container__menu-item ${
            menu.menuItem === '3' ? 'secured-container__selected' : ''
          }`}
          onClick={() => loadPage(3)}
        >
          <div className='secured-container__menu-icon-container'>
            <i className='fas fa-user-shield header-container__icon-1'></i>
          </div>
          <span>ניהול</span>
        </div>
      )}
    </div>
  );
};

const propToState = (state) => {
  return {
    user: state.userReducer,
    menu: state.menuReducer,
  };
};
export default connect(propToState, { setMenuItem })(OptionsMenu);
