/* eslint-disable */
import React, { useRef } from 'react';
import api from '../../../../apis/api';

const SellerDropdown = ({
                          users,
                          onChange,
                          currentUser,
                          defaultText = '-- צור משתמש חדש --',
                          name = 'owner',
                          withSellerTitle = true,
                          withDefaultOption = true,
                          field,
                          value,
                        }) => {
  const selectRef = useRef();

  return users ? (
      <select
          {...field}
          onChange={onChange}
          className={`seller-dropdown w-300`}
          ref={selectRef}
          name={name}
          value={value}
      >
        {withDefaultOption && <option value='0'>{defaultText}</option>}
        {users.map((user) => {
          const title = withSellerTitle ? `- (${user.role.type})` : '';
          return (
              <option
                  key={user._id}
                  value={user._id}
                  selected={user._id === currentUser?._id}
              >{`${user.firstName} ${user.lastName} ${title}`}</option>
          );
        })}
      </select>
  ) : (
      <select className='w-300'>
        <option value='0'>טוען נתונים...</option>
      </select>
  );
};

export default SellerDropdown;
