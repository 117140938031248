export const setMenuItem = (menuItem) => async (dispatch) => {
  try {
    dispatch({
      type: 'SET_MENU_ITEM',
      payload: menuItem,
    });
  } catch (error) {
    console.log(error);
  }
};
