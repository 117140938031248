import React, { useEffect } from 'react';

import { motion } from 'framer-motion';
import { connect } from 'react-redux';

import { containerVariants, itemVariants } from './variants';

const Dashboard = ({ customer }) => {
  useEffect(() => {
    document.title = 'HIT Lab - Dashboard';
  }, []);

  return (
    <div className='dashboard'>
      <motion.div
        variants={containerVariants}
        initial='hidden'
        animate='visible'
        div
        className='dashboard__cards'
      >

      </motion.div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    customer: state.customerReducer,
    user: state.userReducer,
  };
};
export default connect(mapStateToProps)(Dashboard);
