import moment from 'moment';
var longToDate = function (millisec) {
  console.log('moment', moment().format('DD-MM-YY'));

  return moment().format('DD-MM-YY');
};

const groupFields = {
  finds : { type: String },
  classification: {default : ''},
  comments: { type: String },
};

const SectionD_3 = {
  VIN :  { type: String,  default: '' },
  SectionD_3classification: { type: String },
  SectionD_3comments: { type: String },
  value_GVW : { type: String },
  value_GCW : { type: String },
  value_Axle_1 : { type: String },
  value_Axle_2 : { type: String },
  reg_GVW :{ type: String },
  reg_GCW : { type: String },
  reg_Axle_1 : { type: String },
  reg_Axle_2 : { type: String},

};

const DevicesFields = {
  Device : { type: String },
  Name :  { type: String },
  Manufactor: { type: String },
  masad: { type: String },
  safety : { type: String },
  identity : { type: String },
  report_lab : { type: String },
  identity_lab : { type: String },
  model :{ type: String },
  date :{ type: String },
};



const weights_fields =   {
  N1 :  { type: String },
  N2:   { type: String },
  N3:   { type: String },
  N4:   { type: String },
  KWr:  { type: String },
  KWf:   { type: String },
  ρf :  { type: String , default: 0.8},
  KWtot :   { type: String },
  tank_x:   { type: String },
  tank_y:   { type: String },
  tank_z:   { type: String },
  tank_total :  { type: String },
  FWr:   { type: String },
  FWf:   { type: String },
  FV:   { type: String },
  FP:   { type: String },
  FWtot:   { type: String },
  WB :  { type: String },
  Lf:   { type: String },
  VIN_front:   { type: String },
  VIN_Back:   { type: String },
  PM :  { type: String },
  GVW:   { type: String },
  KW:   { type: String },
  weight_left:   { type: String },
  weight_right:   { type: String },
  weight_total_allow:   { type: String },
  overload_WB :  { type: String },
  GVWf:   { type: String },
  GVWr:   { type: String },
  GVWl_tot :  { type: String },
  GVWr_tot:   { type: String },
  GVW_accept:   { type: String },
  DPW:   { type: String },
  X1 :  { type: String },
  X2:   { type: String },
  laugage_allow: { type: String },
  laugage_a: { type: String },
  laugage_b: { type: String },
  laugage_c: { type: String },
  Seren_self_front:  { type: String },
  Seren_self_back: { type: String },
  Seren_self_total: { type: String },
  Seren_driver_front:  { type: String },
  Seren_driver_back: { type: String },
  Seren_driver_total: { type: String },
  Seren_total_front:  { type: String },
  Seren_total_back:  { type: String },
  Seren_total_all: { type: String },
  Seren_total_front_accept:  { type: String },
  Seren_total_back_accept: { type: String },
  Seren_total_all_accept: { type: String },

};


export const initialValues = {
  testId: { type: String },
  reportType: '',
  isApproved: false,
  testReportNumber: { type: String },
  ordereeName: '',
  ordereeAddress: '',
  orderId: { type: String  } ,
  Category_Vehicle: '',
  header_option: '',
  orderDate: '',
  frontDocumentTest: '',
  realOrderDate: '',
  testerName: '',
  approveInterst: false,
  fullName: '',
  signiture: '',



  group1:  groupFields,
  group2:  groupFields,
  group3:  groupFields,
  group4:  groupFields,
  group5:  groupFields,
  group6:  groupFields,
  group7:  groupFields,
  group8:  groupFields,
  group9:  groupFields,
  group10: groupFields,
  group11: groupFields,
  group12: groupFields,
  group13: groupFields,
  group14: groupFields,
  group15: groupFields,
  group16: groupFields,
  group17: groupFields,
  group18: groupFields,
  group19   :groupFields,
  group20	:groupFields,
  group21	:groupFields,
  group22	:groupFields,
  SectionD_3 : SectionD_3,
  group23	:groupFields,
  group24	:groupFields,
  group25	:groupFields,
  group26	:groupFields,
  group27	:groupFields,
  group28	:groupFields,
  group29	:groupFields,
  group30	:groupFields,
  group31	:groupFields,
  group32	:groupFields,
  group33	:groupFields,
  group34	:groupFields,
  group35	:groupFields,
  group36	:groupFields,
  group37	:groupFields,
  group38	:groupFields,
  group39	:groupFields,
  group40	:groupFields,
  group41	:groupFields,
  group42	:groupFields,
  group43	:groupFields,
  group44	:groupFields,
  group45	:groupFields,
  group46	:groupFields,
  group47	:groupFields,
  group48	:groupFields,
  group49	:groupFields,
  group50	:groupFields,
  group51	:groupFields,
  group52	:groupFields,
  group53	:groupFields,
  group54	:groupFields,
  group55	:groupFields,
  group56	:groupFields,
  group57	:groupFields,
  group58	:groupFields,
  group59	:groupFields,
  group60	:groupFields,
  group61	:groupFields,
  group62	:groupFields,
  weights : weights_fields,
  group63	:groupFields,
  group64	:groupFields,
  group65	:groupFields,
  group66	:groupFields,
  group67	:groupFields,
  group68	:groupFields,
  group69	:groupFields,
  group70	:groupFields,
  group71	:groupFields,
  group72	:groupFields,
  group73	:groupFields,
  group74	:groupFields,
  group75	:groupFields,
  group76	:groupFields,
  group77	:groupFields,
  group78	:groupFields,
  Device1  : DevicesFields,
  Device2  : DevicesFields,
  Device3  : DevicesFields,
  Device4  : DevicesFields,
  Device5  : DevicesFields,
  group79	:groupFields,
  group80	:groupFields,
  group81	:groupFields,
  group82	:groupFields,
  group83	:groupFields,
  group84	:groupFields,
  group85	:groupFields,
  group86	:groupFields,
  group87	:groupFields,
  group88	:groupFields,
  group89	:groupFields,
  group90	:groupFields,
  group91	:groupFields,
  group92	:groupFields,
  group93	:groupFields,
  group94	:groupFields,
  group95	:groupFields,
  group96	:groupFields,
  group97	:groupFields,
  group98	:groupFields,
  group99	:groupFields,
  group100	:groupFields,
  group101	:groupFields,
  group102	:groupFields,
  group103	:groupFields,
  group104	:groupFields,
  group105	:groupFields,
  group106	:groupFields,
  group107	:groupFields,
  group108	:groupFields,
  group109	:groupFields,
  group110	:groupFields,
  group111	:groupFields,
  group112	:groupFields,
  group113	:groupFields,
  group114	:groupFields,
  group115	:groupFields,
  group116	:groupFields,
  group117	:groupFields,
  group118	:groupFields,
  group119	:groupFields,
  group120	:groupFields,
  group121	:groupFields,
  group122	:groupFields,
  group123	:groupFields,
  group124	:groupFields,
  group125	:groupFields,
  group126	:groupFields,
  group127	:groupFields,
  group128	:groupFields,
  group129	:groupFields,
  group130	:groupFields,
  group131	:groupFields,
  group132	:groupFields,
  group133	:groupFields,
  group134	:groupFields,
  group135	:groupFields,
  group136	:groupFields,
  group137	:groupFields,
  group138	:groupFields,
  group139	:groupFields,
  group140	:groupFields,
  group141	:groupFields,
  group142	:groupFields,
  group143	:groupFields,
  group144	:groupFields,
  group145	:groupFields,
  group146	:groupFields,
  group147	:groupFields,
  group148	:groupFields,
  group149	:groupFields,
  group150	:groupFields,
  group151	:groupFields,
  group152	:groupFields,
  group153	:groupFields,
  group154	:groupFields,
  group155	:groupFields,
  group156	:groupFields,
  group157	:groupFields,
  group158	:groupFields,
  group159	:groupFields,
  group160	:groupFields,
  group161	:groupFields,
  group162	:groupFields,
  group163	:groupFields,
  group164	:groupFields,
  group165	:groupFields,
  group166	:groupFields,
  group167	:groupFields,
  group168	:groupFields,
  group169	:groupFields,
  group170	:groupFields,
  group171	:groupFields,
  group172	:groupFields,
  group173	:groupFields,
  group174	:groupFields,
  group175	:groupFields,
  group176	:groupFields,
  group177	:groupFields,
  group178	:groupFields,
  group179	:groupFields,
  group180	:groupFields,
  group181	:groupFields,
  group182	:groupFields,
  group183	:groupFields,
  group184	:groupFields,
  group185	:groupFields,
  group186	:groupFields,
  group187	:groupFields,
  group188	:groupFields,
  group189	:groupFields,
  group190	:groupFields,
  group191	:groupFields,
  group192	:groupFields,
  group193	:groupFields,
  group194	:groupFields,
  group195	:groupFields,
  group196	:groupFields,
  group197	:groupFields,
  group198	:groupFields,
  group199	:groupFields,
  group200	:groupFields,
  group201	:groupFields,
  group202	:groupFields,
  group203	:groupFields,
  group204	:groupFields,
  group205	:groupFields,
  group206	:groupFields,
  group207	:groupFields,
  group208	:groupFields,
  group209	:groupFields,
  group210	:groupFields,
  group211	:groupFields,
  group212	:groupFields,
  group213	:groupFields,
  group214	:groupFields,
  group215	:groupFields,
  group216	:groupFields,
  group217	:groupFields,
  group218	:groupFields,
  group219	:groupFields,
  group220	:groupFields,
  group221	:groupFields,
  group222	:groupFields,
  group223	:groupFields,
  group224	:groupFields,
  group225	:groupFields,
  group226	:groupFields,
  group227	:groupFields,
  group228	:groupFields,
  group229	:groupFields,
  group230	:groupFields,
  group231	:groupFields,
  group232	:groupFields,
  group233	:groupFields,
  group234	:groupFields,
  group235	:groupFields,
  group236	:groupFields,
  group237	:groupFields,
  group238	:groupFields,
  group239	:groupFields,
  group240	:groupFields,
  group241	:groupFields,
  group242	:groupFields,
  group243	:groupFields,
  group244	:groupFields,
  group245	:groupFields,
  group246	:groupFields,
  group247	:groupFields,
  group248	:groupFields,
  group249	:groupFields,
  group250	:groupFields,
  group251	:groupFields,
  group252	:groupFields,
  group253	:groupFields,
  group254	:groupFields,
  group255	:groupFields,
  group256	:groupFields,
  group257	:groupFields,
  group258	:groupFields,
  group259	:groupFields,
  group260	:groupFields,
  group261	:groupFields,
  group262	:groupFields,
  group263	:groupFields,
  group264	:groupFields,
  group265	:groupFields,
  group266	:groupFields,
  group267	:groupFields,
  group268	:groupFields,
  group269	:groupFields,
  group270	:groupFields,
  group271	:groupFields,
  group272	:groupFields,
  group273	:groupFields,
  group274	:groupFields,
  image1    :groupFields,
  image2    :groupFields,
  image3    :groupFields,
  image4    :groupFields,
  image5    :groupFields,
  image6    :groupFields,
  image7    :groupFields,
  image8    :groupFields,
  image9    :groupFields,
  image10   :groupFields,
  image11   :groupFields,
  image12   :groupFields,




};

export const fakeValues = {
  testId: '',
  reportType: 1,
  isApproved: false,
  testReportNumber: '',
  ordereeName: '',
  ordereeAddress: '',
  orderId: '',
  Category_Vehicle: '',
  orderDate: new Date(),
  frontDocumentTest: new Date(),
  realOrderDate: new Date(),
  testerName: '',
  approveInterst: false,
  fullName: '',
  weight_right: '',
  weight_left: '',
  image7_set: false,
  image8_set: false,
  image9_set: false,
  image10_set: false,
  image11_set: false,
  image12_set: false,

  SectionD_3 : {
    VIN: '',
    SectionD_3classification: '1',
    SectionD_3comments: '',
    value_GVW: '',
    value_GCW: '',
    value_Axle_1: '',
    value_Axle_2: '',
    reg_GVW: '',
    reg_GCW: '',
    reg_Axle_1: '',
    reg_Axle_2: '',
  },





Device1 : {
  Device :  '',
  Name :  '',
  Manufactor:'',
  masad: '',
  safety :'',
  identity : '',
  report_lab : '',
  identity_lab : '',
  model :'',
  date :'',
  },


  Device2 : {
    Device :  '',
    Name :  '',
    Manufactor:'',
    masad: '',
    safety :'',
    identity : '',
    report_lab : '',
    identity_lab : '',
    model :'',
    date :'',
  },


  Device3 : {
    Device :  '',
    Name :  '',
    Manufactor:'',
    masad: '',
    safety :'',
    identity : '',
    report_lab : '',
    identity_lab : '',
    model :'',
    date :'',
  },


  Device4 : {
    Device :  '',
    Name :  '',
    Manufactor:'',
    masad: '',
    safety :'',
    identity : '',
    report_lab : '',
    identity_lab : '',
    model :'',
    date :'',
  },


  Device5 : {
    Device :  '',
    Name :  '',
    Manufactor:'',
    masad: '',
    safety :'',
    identity : '',
    report_lab : '',
    identity_lab : '',
    model :'',
    date :'',
  },

  group1: {
    finds: '',
    classification: '',
    comments: '',
  },
  group2:{
    finds: '',
    classification: '',
    comments: '',
  },
  group3: {
    classification: '1',
    comments: '',
  },
  group4: {
    classification: '1',
    comments: '',
  },
  group5: {
    classification: '1',
    comments: '',
  },
  group6: {
    classification: '1',
    comments: '',
  },
  group7: {
    classification: '1',
    comments: '',
  },
  group8: {
    classification: '1',
    comments: '',
  },
  group9: {
    classification: '1',
    comments: '',
  },
  group10: {
    classification: '1',
    comments: '',
  },
  group11: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group12: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group13: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group14: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group15: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group16: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group17: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group18: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group19: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group20: {
    classification: '1',
    comments: '',
  },
  group21: {
    classification: '1',
    comments: '',
  },
  group22: {
    classification: '1',
    comments: '',
  },
  group23: {
    classification: '1',
    comments: '',
  },
  group24: {
    classification: '1',
    comments: '',
  },
  group25: {
    classification: '1',
    comments: '',
  },
  group26: {
    classification: '1',
    comments: '',
  },
  group27: {
    classification: '1',
    comments: '',
  },
  group28: {
    classification: '1',
    comments: '',
  },
  group29: {
    classification: '1',
    comments: '',
  },
  group30: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group31: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group32: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group33: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group33_v1: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group34: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group35: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group36: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group37: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group38: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group39: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group40: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group41: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group42: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group43: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group44: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group45: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group46: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group47: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group48: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group49: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group50: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group51: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group52: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group53: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group54: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group55: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group56: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group57: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group58: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group59: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group60: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group61: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group62: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group63: {
    finds: '0',
    classification: '1',
    comments: '',
  },
  group64: {
    finds: '0',
    classification: '1',
    comments: '',
  },
  group65: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group66: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group67: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group68: {
    finds: '0',
    classification: '1',
    comments: '',
  },
  group69: {
    finds: '0',
    classification: '1',
    comments: '',
  },
  group70: {
    finds: '0',
    classification: '1',
    comments: '',
  },
  group71: {
    finds: '0',
    classification: '1',
    comments: '',
  },
  group72: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group73: {
    finds: '0',
    classification: '1',
    comments: '',
  },
  group74: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group75: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group76: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group77: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group78: {
    finds: '',
    classification: '1',
    comments: '',
  },



  weights :  {
    N1 :  '',
    N2:  '',
    N3:   '',
    N4:   '',
    KWr: '',
    KWf:   '',
    ρf :  '0.8',
    KWtot :  '',
    tank_x:  '',
    tank_y:   '',
    tank_z:   '',
    FWr:     {type : String , Default: ''},
    FWf:     {type : String , Default: ''},
    FWtot:   {type : String , Default: ''},
    tank_total :  '',
    FV:  '',
    FP:  '',
    WB : '',
    Lf:   '',
    VIN_front:   '',
    VIN_Back: '',
    PM : '',
    GVW:  '',
    KW:  '',
    weight_left: '',
    weight_right: '',
    overload_WB : '',
    GVWf: '',
    GVWr:  '',
    GVWl_tot : '',
    GVWr_tot:  '',
    GVW_accept:  '',
    DPW: '',
    X1 : '',
    X2: '',
    laugage_allow: '',
    laugage_a: '',
    laugage_b: '',
    laugage_c: '',
    Seren_self_front:  '',
    Seren_self_back:  '',
    Seren_self_total:  '',
    Seren_driver_front: '',
    Seren_driver_back: '',
    Seren_driver_total:'',
    Seren_total_front:  '',
    Seren_total_back:  '',
    Seren_total_all:  '',
    Seren_total_front_accept:  '',
    Seren_total_back_accept:  '',
    Seren_total_all_accept:  '',

  },


  group79: {
    finds: '',
    classification: '',
    comments: '',
  },
  group80: {
    finds: '',
    classification: '',
    comments: '',
  },
  group81: {
    finds: '',
    classification: '',
    comments: '',
  },
  group82: {
    finds: '',
    classification: '',
    comments: '',
  },
  group83: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group84: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group85: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group86: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group87: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group88: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group89: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group90: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group91: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group92: {
    finds: '',
    classification: '1',
    comments: 'V',
  },
  group93: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group94: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group95: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group96: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group97: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group98: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group99: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group100: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group101: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group102: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group103: {
    finds: '',
    classification: '1',
    comments: 'V',
  },
  group104: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group105: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group106: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group107: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group108: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group109: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group110: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group111: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group112: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group113: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group114: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group115: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group116: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group117: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group118: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group119: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group120: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group121: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group122: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group123: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group124: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group125: {
    finds: '',
    classification: '1',
    comments: 'V',
  },
  group126: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group127: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group128: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group129: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group130: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group131: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group132: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group133: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group134: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group135: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group136: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group137: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group138: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group139: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group140: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group141: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group142: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group143: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group144: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group145: {
    finds: '',
    classification: '1',
    comments: 'V',
  },
  group146: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group147: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group148: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group149: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group150: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group151: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group152: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group153: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group154: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group155: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group156: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group157: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group158: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group159: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group160: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group161: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group162: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group163: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group164: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group165: {
    finds: '',
    classification: '1',
    comments: 'V',
  },
  group166: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group167: {
    finds: '',
    classification: '1',
    comments: 'V',
  },
  group168: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group169: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group170: {
    finds: '',
    classification: '0',
    comments: 'N/A',
  },
  group171: {
    finds: '',
    classification: '0',
    comments: 'N/A',
  },
  group172: {
    finds: '',
    classification: '0',
    comments: 'N/A',
  },
  group173: {
    finds: '',
    classification: '0',
    comments: 'N/A',
  },
  group174: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group175: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group176: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group177: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group178: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group179: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group180: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group181: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group182: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group183: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group184: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group185: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group186: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group187: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group188: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group189: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group190: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group191: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group192: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group193: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group194: {
    finds: '',
    classification: '1',
    comments: 'A',
  },
  group195: {
    finds: '',
    classification: '1',
    comments: 'V',
  },
  group196: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group197: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group198: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group199: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group200: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group201: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group202: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group203: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group204: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group205: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group206: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group207: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group208: {
    finds: '',
    classification: '1',
    comments: '',
  },

  group209: {
    finds: '',
    classification: '1',
    comments: 'V',
  },

  group210: {
    finds: '',
    classification: '1',
    comments: '',
  },

  group211: {
    finds: '',
    classification: '1',
    comments: '',
  },

  group212: {
    finds: '',
    classification: '1',
    comments: '',
  },

  group213: {
    finds: '',
    classification: '1',
    comments: '',
  },

  group214: {
    finds: '',
    classification: '1',
    comments: '',
  },

  group215: {
    finds: '',
    classification: '1',
    comments: '',
  },

  group216: {
    finds: '',
    classification: '1',
    comments: '',
  },

  group217: {
    finds: '',
    classification: '1',
    comments: '',
  },

  group218: {
    finds: '',
    classification: '1',
    comments: '',
  },

  group219: {
    finds: '',
    classification: '1',
    comments: '',
  },

  group220: {
    finds: '',
    classification: '1',
    comments: 'A',
  },

  group221: {
    finds: '',
    classification: '1',
    comments: 'A',
  },

  group222: {
    finds: '',
    classification: '1',
    comments: 'A',
  },

  group223: {
    finds: '',
    classification: '1',
    comments: 'A',
  },

  group224: {
    finds: '',
    classification: '1',
    comments: 'A',
  },

  group225: {
    finds: '',
    classification: '1',
    comments: 'V',
  },

  group226: {
    finds: '',
    classification: '1',
    comments: 'A',
  },

  group227: {
    finds: '',
    classification: '0',
    comments: 'N/A',
  },

  group228: {
    finds: '',
    classification: '1',
    comments: '',
  },

  group229: {
    finds: '',
    classification: '1',
    comments: '',
  },

  group230: {
    finds: '',
    classification: '1',
    comments: '',
  },

  group231: {
    finds: '',
    classification: '1',
    comments: '',
  },

  group232: {
    finds: '',
    classification: '1',
    comments: '',
  },

  group233: {
    finds: '',
    classification: '0',
    comments: 'N/A',
  },

  group234: {
    finds: '',
    classification: '0',
    comments: 'N/A',
  },

  group235: {
    finds: '',
    classification: '1',
    comments: 'A',
  },

  group236: {
    finds: '',
    classification: '0',
    comments: 'N/A',
  },

  group237: {
    finds: '',
    classification: '1',
    comments: 'A',
  },

  group238: {
    finds: '',
    classification: '1',
    comments: 'A',
  },

  group239: {
    finds: '',
    classification: '1',
    comments: 'A',
  },

  group240: {
    finds: '',
    classification: '0',
    comments: 'N/A',
  },

  group241: {
    finds: '',
    classification: '0',
    comments: 'N/A',
  },

  group242: {
    finds: '',
    classification: '0',
    comments: 'N/A',
  },

  group243: {
    finds: '',
    classification: '1',
    comments: 'A',
  },

  group244: {
    finds: '',
    classification: '1',
    comments: '',
  },

  group245: {
    finds: '',
    classification: '1',
    comments: '',
  },

  group246: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group247: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group248: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group249: {
    finds: '',
    classification: '',
    comments: '',
  },
  group250: {
    finds: '',
    classification: '',
    comments: '',
  },
  group251: {
    finds: '',
    classification: '',
    comments: '',
  },
  group252: {
    finds: '',
    classification: '',
    comments: '',
  },
  group253: {
    finds: '',
    classification: '',
    comments: '',
  },
  group254: {
    finds: '',
    classification: '',
    comments: '',
  },
  group255: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group256: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group257: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group258: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group259: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group260: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group261: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group262: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group263: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group264: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group265: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group266: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group267: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group268: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group269: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group270: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group271: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group272: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group273: {
    finds: '',
    classification: '1',
    comments: '',
  },
  group274: {
    finds: '',
    classification: '1',
    comments: '',
  },
  image1: {
    finds: '',
    classification: '',
    comments: '',
  },
  image2: {
    finds: '',
    classification: '',
    comments: '',
  },
  image3: {
    finds: '',
    classification: '',
    comments: '',
  },
  image4: {
    finds: '',
    classification: '',
    comments: '',
  },
  image5: {
    finds: '',
    classification: '',
    comments: '',
  },
  image6: {
    finds: '',
    classification: '',
    comments: '',
  },
  image7: {
    finds: '',
    classification: '',
    comments: '',
  },
  image8: {
    finds: '',
    classification: '',
    comments: '',
  },
  image9: {
    finds: '',
    classification: '',
    comments: '',
  },
  image10: {
    finds: '',
    classification: '',
    comments: '',
  },
  image11: {
    finds: '',
    classification: '',
    comments: '',
  },
  image12: {
    finds: '',
    classification: '',
    comments: '',
  },


};
