import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';


import { repTypes } from './reportTypes';
import Report_Type_1 from './Report_Type_1/Report_Type_1';
import Report_Type_2 from './Report_Type_2/Report_Type_2';
import Report_Type_3 from './Report_Type_3/Report_Type_3';

const Reports = () => {

  const { reportType, id } = useParams();

  const [currentReport, setCurrentReport] = useState(reportType);

  const renderReport = () => {
    if (currentReport) {
      switch (currentReport) {
        case 0: {
          return <div>אנא בחר דוח</div>;
        }
        case '1': {

          return <Report_Type_1 reportId={id} />;
        }
        case '2': {

          return <Report_Type_2 reportId={id} />;
        }
        case '3': {

          return <Report_Type_3 reportId={id} />;
        }

        default: {
          return <div>אנא בחר דוח</div>;
        }
      }
    } else {
      return <div>אנא בחר דוח</div>;
    }
  };

  useEffect(() => {}, []);

  const reportChanged = (e) => {
    // console.log(e.target.value);
    setCurrentReport(e.target.value);
  };
  return (
      <div className='reports'>
        <div className='reports__toolbar flex align-items-center gap-1'>
          בחר סוג דוח:
          <div>
            <select onChange={reportChanged}>
              <option value='0'>-- בחר דוח --</option>
              {repTypes &&
              repTypes.map((item, index) => {
                return (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className='reports-content flex justify-content-center mt-3'>
          {renderReport()}
        </div>
      </div>
  );
};

export default Reports;
