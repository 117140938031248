import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { repTypes } from '../reportTypes';
import { connect } from 'react-redux';
import api from '../../../../apis/api';
import { ToastContainer, toast } from 'react-toastify';
import { setMenuItem } from '../../../../actions/menuActions';
import { Pagination } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'


const ReportsList = (props) => {
  const { user } = props;
  const [list, setList] = useState([]);
  const [pageNumber,setPageNumber] = useState(0);
  const [pagen,setpagen] = useState(1);


  var activePage = 1;

  const handlePaginationChange = (e, pageInfo) => {
   activePage = pageInfo.activePage;
   console.log(pageInfo);
   console.log(activePage);
   setpagen(activePage);
   getAllReports(activePage - 1);
   return activePage
  }


  useEffect(() => {
     getAllReports(0);
  }, []);


const deleteReport = async (_id) => {
const { data } = await api.delete('/reports/delete', {
  data: { _id },
});
};


const getAllReports = async (page) => {
    var skip = page;
    const { data } = await api.get(`/reports/ReportP/${skip}`);
    console.log('all_reports', data);
    setPageNumber(data.page_num);
    setList(data.report);

}

    const reports_count = async () => {
        await api.get('/reports/reports_count' );
        // count(count_documents);
        //  console.log('count_reports', count_documents);
    };



const createPDF = async (item) => {


//if (item.reportType == 1) {
    const text1 = item.testId ;
    const toast_msg =   toast.info(text1+'  : מייצא תעודה לדוח מס ', {
        toastId: {text1},
        position: "top-center",
        autoClose: 23000,
        closeOnClick: true,
    });
   const { data } = await api.post('/pdf/save', item, {
    responseType: 'blob',
   });
  const file = new Blob([data], {
    type: 'application/pdf',
  });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
  toast.dismiss(toast_msg.current);
//};

/*
if (item.reportType == 2) {
        toast.error("סוג תעודה לא קיים",{
            position: "top-right",
            autoClose: 2000,
            closeOnClick: true,
            }
            )
};
*/

}
  const setIsApproved = async (id, isApproved) => {
    const { data } = await api.post('/reports/update', {
      _id: id,
      payload: { isApproved },
    });

    const newList = list.map((item) => {
      if (item._id === id) {
        const newItem = { ...item, isApproved: isApproved };
        isApproved
            ? toast.success('הטופס אושר בהצלחה 👍')
            : toast.error('הטופס לא אושר 👎');
        return newItem;
      }

      return item;
    });

    setList(newList);

  };



  return (

      <div className='reports-list'>

        <div className='wrapper'>
          <div>

              <div style={{
                  display: 'flex',
                  marginLeft : '3in',
                  alignItems: 'center',
                  justifyContent: 'center',
                  }}>

                  <Pagination style={{fontSize : 16,fontWeight: 'bold'}}
                              onPageChange={handlePaginationChange}
                              totalPages={pageNumber}
                  />


              </div>

            <table className='reports_list__table p-2 child-color-white'>

              <tr className='bg-secondary  child-color-white child-padding-1'>
                <th>מס"ד</th>
                <th>סוג דוח</th>
                <th>מספר בדיקה</th>
                <th>תאריך יצירה</th>
                <th>עדכון אחרון</th>
                <th>עורך מסמך</th>
                <th>סטטוס</th>
                {user.role.type === 'מנהל' && <th>אישור מנהל</th>}
                <th colspan='3'>כלים</th>
              </tr>

              {list.map((item,index) => {
                const {
                  _id,
                  reportType,
                  testId,
                  createdAt,
                  updatedAt,
                  testerName,
                  isApproved,
                } = item;

                const isapprovedText = isApproved ? (
                    <div className='bg-success  p-1 color-white rounded-sm '>
                      מאושר
                    </div>
                ) : (
                    <div className='bg-error p-1 color-white rounded-sm '>
                      לא מאושר
                    </div>
                );

                return (

                    <tr>

                        {/* <td>{index + 1}</td>*/}
                      <td>{((pagen -1) * 10) + index + 1}</td>
                      <td>{repTypes[parseInt(reportType - 1)].name}</td>
                      <td>{testId}</td>
                      <td>{moment(createdAt).format('HH:ss DD-MM-YY')}</td>
                      <td>{moment(updatedAt).format('HH:ss DD-MM-YY')}</td>
                      <td>{`${testerName.firstName} ${testerName.lastName}`}</td>
                      <td>{isapprovedText}</td>
                      {user.role.type === 'מנהל' && (
                          <td>
                            <input
                                type='checkbox'
                                checked={isApproved}
                                onChange={() =>
                                    setIsApproved(item._id, !item.isApproved)
                                }
                            />
                          </td>
                      )}

                      <td>

                        <Link to={`/backoffice/reports/${reportType}/${_id}`}>
                          <button
                              className='button bg-secondary'
                              onClick={() => {
                                props.setMenuItem('1');
                              }}
                          >
                            ערוך
                          </button>
                        </Link>
                      </td>
                      {user.role.type === 'מנהל' &&  (
                      <td>
                        <button
                            className='button bg-secondary'
                            onClick={() => createPDF(item) }

                        >
                          ייצא תעודה ל-PDF.
                        </button>
                      </td>
                      )}


                    </tr>
                );
              })}
            </table>
          </div>

        </div>
        <ToastContainer position='bottom-left' autoClose={2500} />


      </div>
  );
};

const propToState = (state) => {
  return {
    user: state.userReducer,
    menu: state.menuReducer,
  };
};
export default connect(propToState, { setMenuItem })(ReportsList);
