const menUReducer = (state = { menuItem: '0' }, action) => {
  switch (action.type) {
    case 'SET_MENU_ITEM': {
      return { ...action.payload, menuItem: action.payload.toString() };
    }
    default:
      return state;
  }
};
export default menUReducer;
