import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { login as adminLogin } from '../../actions/userActions';
const IsLogged = (props) => {
  const history = useHistory();
  const [user, setUser] = useState(null);
  const path = props.location.pathname;
  const isAdmin = path.indexOf('backoffice') > -1;
  const mainPath = isAdmin ? '/backoffice' : '/';


  const setData = async (data) => {
    setUser(data);
    const customerDetails = {
      email: data.email,
      password: data.userPassword,
    };


  // if (!isAdmin) {
  //   const user = await props.login(customerDetails, false);
  // } else {
  //    const user = await props.adminLogin(customerDetails, false);
  // }
  };

  useEffect(() => {
    let user = null;
    const getUser = async () => {
   //   try {
   //     const { data } = await adminApi.post('/refresh/admin', {
   //       isAdmin: true,
   //     });
   //   setData(data);
   //    user = data;
   //   } catch (error) {
   //   history.push(mainPath);
   //   }
      if (user) {
       setTimeout(() => {
         history.push(path);
        }, 0);
      } else {
       history.push(mainPath);
      }
     // eslint-disable-next-line
    };

    getUser();
  }, []);

  return user && <div className='is-logged'> </div>;
};

const stateToProps = (state) => {
  return {
    customer: state.customerReducer,
  };
};
export default connect(stateToProps, { adminLogin })(IsLogged);
