export const repTypes = [
  {
    _id: 1,
    name: 'טופס בחינה פרטנית למשא',
  },

 {
  _id: 2,
 name: 'טופס בחינה פרטנית להסעים',
  },


  {
    _id: 3,
    name: 'טופס בחינה פרטנית לגרורים',
  },


];
