import moment from 'moment';
var longToDate = function (millisec) {
    console.log('moment', moment().format('DD-MM-YY'));

    return moment().format('DD-MM-YY');
};

const groupFields = {
    finds : { type: String },
    classification: {type: String},
    comments: { type: String },
};

const ResultsTableRow =   {
    distance :  { type: String },
    front_weight: { type: String },
    rear_weight: { type: String },
    total_row_weight: { type: String },
    total_front_weight: { type: String},
    total_rear_weight: { type: String },
};

const SectionD_3 = {
    VIN :  { type: String,  default: '' },
    SectionD_3classification: { type: String },
    SectionD_3comments: { type: String },
    value_GVW : { type: String },
    value_Axle_1 : { type: String },
    value_Axle_2 : { type: String },
    value_Axle_3 : { type: String },
    value_Axle_4 : { type: String },
    reg_GVW :{ type: String },
    reg_Axle_1 : { type: String },
    reg_Axle_2 : { type: String },
    reg_Axle_3 : { type: String },
    reg_Axle_4 : { type: String },

};

const DevicesFields = {
    Device : { type: String },
    Name :  { type: String },
    Manufactor: { type: String },
    masad: { type: String },
    safety : { type: String },
    identity : { type: String },
    report_lab : { type: String },
    identity_lab : { type: String },
    model :{ type: String },
    date :{ type: String },
};



const weights_fields =   {
    N1 :  { type: String },
    N2:   { type: String },
    N3:   { type: String },
    N4:   { type: String },
    KWr:  { type: String },
    KWf:   { type: String },
    ρf :  { type: String , default: 0.8},
    KWtot :   { type: String },
    tank_x:   { type: String },
    tank_y:   { type: String },
    tank_z:   { type: String },
    tank_total :  { type: String },
    FWr:   { type: String },
    FWf:   { type: String },
    FV:   { type: String },
    FP:   { type: String },
    FWtot:   { type: String },
    WB :  { type: String },
    Lf:   { type: String },
    VIN_front:   { type: String },
    VIN_Back:   { type: String },
    PM :  { type: String },
    GVW:   { type: String },
    KW:   { type: String },
    weight_left:   { type: String },
    weight_right:   { type: String },
    weight_total_allow:   { type: String },
    overload_WB :  { type: String },
    GVWf:   { type: String },
    GVWr:   { type: String },
    GVWl_tot :  { type: String },
    GVWr_tot:   { type: String },
    GVW_accept:   { type: String },
    DPW:   { type: String },
    X1 :  { type: String },
    X2:   { type: String },
    laugage_allow: { type: String },
    laugage_a: { type: String },
    laugage_b: { type: String },
    laugage_c: { type: String },
    Seren_self_front:  { type: String },
    Seren_self_back: { type: String },
    Seren_self_total: { type: String },
    Seren_driver_front:  { type: String },
    Seren_driver_back: { type: String },
    Seren_driver_total: { type: String },
    Seren_total_front:  { type: String },
    Seren_total_back:  { type: String },
    Seren_total_all: { type: String },
    Seren_total_front_accept:  { type: String },
    Seren_total_back_accept: { type: String },
    Seren_total_all_accept: { type: String },

};


export const initialValues = {
    testId: { type: String },
    reportType: 2,
    isApproved: false,
    testReportNumber: { type: String },
    ordereeName: '',
    ordereeAddress: '',
    orderId: { type: String , Default : '' } ,
    Category_Vehicle: '',
    header_option: '',
    orderDate: '',
    frontDocumentTest: '',
    realOrderDate: '',
    testerName: '',
    approveInterst: false,
    fullName: '',
    signiture: '',



    group1:  groupFields,
    group2:  groupFields,
    group3:  groupFields,
    group4:  groupFields,
    group5:  groupFields,
    group6:  groupFields,
    group7:  groupFields,
    group8:  groupFields,
    group9:  groupFields,
    group10: groupFields,
    group11: groupFields,
    group12: groupFields,
    group13: groupFields,
    group14: groupFields,
    group15: groupFields,
    group16: groupFields,
    group17: groupFields,
    group18: groupFields,
    group19   :groupFields,
    group20	:groupFields,
    group21	:groupFields,
    group22	:groupFields,
    SectionD_3 : SectionD_3,
    group23	:groupFields,
    group24	:groupFields,
    group25	:groupFields,
    group26	:groupFields,
    group27	:groupFields,
    group28	:groupFields,
    group29	:groupFields,
    group30	:groupFields,
    group31	:groupFields,
    group32	:groupFields,
    group33	:groupFields,
    group34	:groupFields,
    group35	:groupFields,
    group36	:groupFields,
    group37	:groupFields,
    group38	:groupFields,
    group39	:groupFields,
    group40	:groupFields,
    group41	:groupFields,
    group42	:groupFields,
    group43	:groupFields,
    group44	:groupFields,
    group45	:groupFields,
    group46	:groupFields,
    group47	:groupFields,
    group48	:groupFields,
    group49	:groupFields,
    group50	:groupFields,
    group51	:groupFields,
    group52	:groupFields,
    group53	:groupFields,
    group54	:groupFields,
    group55	:groupFields,
    group56	:groupFields,
    group57	:groupFields,
    group58	:groupFields,
    group59	:groupFields,
    group60	:groupFields,
    group61	:groupFields,
    group62	:groupFields,
    weights : weights_fields,
    group63	:groupFields,
    group64	:groupFields,
    group65	:groupFields,
    group66	:groupFields,
    group67	:groupFields,
    group68	:groupFields,
    group69	:groupFields,
    group70	:groupFields,
    group71	:groupFields,
    group72	:groupFields,
    group73	:groupFields,
    group74	:groupFields,
    group75	:groupFields,
    group76	:groupFields,
    group77	:groupFields,
    group78	:groupFields,
    Device1  : DevicesFields,
    Device2  : DevicesFields,
    Device3  : DevicesFields,
    Device4  : DevicesFields,
    Device5  : DevicesFields,
    group79	:groupFields,
    group80	:groupFields,
    group81	:groupFields,
    group82	:groupFields,
    group83	:groupFields,
    group84	:groupFields,
    group85	:groupFields,
    group86	:groupFields,
    group87	:groupFields,
    group88	:groupFields,
    group89	:groupFields,
    group90	:groupFields,
    group91	:groupFields,
    group92	:groupFields,
    group93	:groupFields,
    group94	:groupFields,
    group95	:groupFields,
    group96	:groupFields,
    group97	:groupFields,
    group98	:groupFields,
    group99	:groupFields,
    group100	:groupFields,
    group101	:groupFields,
    group102	:groupFields,
    group103	:groupFields,
    group104	:groupFields,
    group105	:groupFields,
    group106	:groupFields,
    group107	:groupFields,
    group108	:groupFields,
    group109	:groupFields,
    group110	:groupFields,
    group111	:groupFields,
    group112	:groupFields,
    group113	:groupFields,
    group114	:groupFields,
    group115	:groupFields,
    group116	:groupFields,
    group117	:groupFields,
    group118	:groupFields,
    group119	:groupFields,
    group120	:groupFields,
    group121	:groupFields,
    group122	:groupFields,
    group123	:groupFields,
    group124	:groupFields,
    group125	:groupFields,
    group126	:groupFields,
    group127	:groupFields,
    group128	:groupFields,
    group129	:groupFields,
    group130	:groupFields,
    group131	:groupFields,
    group132	:groupFields,
    group133	:groupFields,
    group134	:groupFields,
    group135	:groupFields,
    group136	:groupFields,
    group137	:groupFields,
    group138	:groupFields,
    group139	:groupFields,
    group140	:groupFields,
    group141	:groupFields,
    group142	:groupFields,
    group143	:groupFields,
    group144	:groupFields,
    group145	:groupFields,
    group146	:groupFields,
    group147	:groupFields,
    group148	:groupFields,
    group149	:groupFields,
    group150	:groupFields,
    group151	:groupFields,
    group152	:groupFields,
    group153	:groupFields,
    group154	:groupFields,
    group155	:groupFields,
    group156	:groupFields,
    group157	:groupFields,
    group158	:groupFields,
    group159	:groupFields,
    group160	:groupFields,
    group161	:groupFields,
    group162	:groupFields,
    group163	:groupFields,
    group164	:groupFields,
    group165	:groupFields,
    group166	:groupFields,
    group167	:groupFields,
    group168	:groupFields,
    group169	:groupFields,
    group170	:groupFields,
    group171	:groupFields,
    group172	:groupFields,
    group173	:groupFields,
    group174	:groupFields,
    group175	:groupFields,
    group176	:groupFields,
    group177	:groupFields,
    group178	:groupFields,
    group179	:groupFields,
    group180	:groupFields,
    group181	:groupFields,
    group182	:groupFields,
    group183	:groupFields,
    group184	:groupFields,
    group185	:groupFields,
    group186	:groupFields,
    group187	:groupFields,
    group188	:groupFields,
    group189	:groupFields,
    group190	:groupFields,
    group191	:groupFields,
    group192	:groupFields,
    group193	:groupFields,
    group194	:groupFields,
    group195	:groupFields,
    group196	:groupFields,
    group197	:groupFields,
    group198	:groupFields,
    group199	:groupFields,
    group200	:groupFields,
    group201	:groupFields,
    group202	:groupFields,
    group203	:groupFields,
    group204	:groupFields,
    group205	:groupFields,
    group206	:groupFields,
    group207	:groupFields,
    group208	:groupFields,
    group209	:groupFields,
    group210	:groupFields,
    group211	:groupFields,
    group212	:groupFields,
    group213	:groupFields,
    group214	:groupFields,
    group215	:groupFields,
    group216	:groupFields,
    group217	:groupFields,
    group218	:groupFields,
    group219	:groupFields,
    group220	:groupFields,
    group221	:groupFields,
    group222	:groupFields,
    group223	:groupFields,
    group224	:groupFields,
    group225	:groupFields,
    group226	:groupFields,
    group227	:groupFields,
    group228	:groupFields,
    group229	:groupFields,
    group230	:groupFields,
    group231	:groupFields,
    group232	:groupFields,
    group233	:groupFields,
    group234	:groupFields,
    group235	:groupFields,
    group236	:groupFields,
    group237	:groupFields,
    group238	:groupFields,
    group239	:groupFields,
    group240	:groupFields,
    group241	:groupFields,
    group242	:groupFields,

    group244	:groupFields,
    group245	:groupFields,
    group246	:groupFields,
    group247	:groupFields,
    group248	:groupFields,
    group249	:groupFields,
    group250	:groupFields,
    group251	:groupFields,
    group252	:groupFields,
    group253	:groupFields,
    group254	:groupFields,
    group255	:groupFields,
    group256	:groupFields,
    group257	:groupFields,
    group258	:groupFields,
    group259	:groupFields,
    group260	:groupFields,
    group261	:groupFields,
    group262	:groupFields,
    group263	:groupFields,
    group264	:groupFields,
    group265	:groupFields,
    group266	:groupFields,
    group267	:groupFields,
    group268	:groupFields,
    group269	:groupFields,
    group270	:groupFields,
    group271	:groupFields,
    group272	:groupFields,
    group273	:groupFields,
    group274   	 :groupFields,
    group275	 :groupFields,
    group276	 :groupFields,
    group277	 :groupFields,
    group278	 :groupFields,
    group279	 :groupFields,
    group280	 :groupFields,
    group281	 :groupFields,
    group282	 :groupFields,
    group283	 :groupFields,
    group284	 :groupFields,
    group285	 :groupFields,

    image1    :groupFields,
    image2    :groupFields,
    image3    :groupFields,
    image4    :groupFields,
    image5    :groupFields,
    image6    :groupFields,
    image7    :groupFields,
    image8    :groupFields,
    image9    :groupFields,
    image10   :groupFields,
    image11   :groupFields,
    image12   :groupFields,
    table_row1   :   ResultsTableRow,
    table_row2	:  ResultsTableRow ,
    table_row3	:  ResultsTableRow ,
    table_row4	:  ResultsTableRow ,
    table_row5	:  ResultsTableRow ,
    table_row6	:  ResultsTableRow ,
    table_row7	:  ResultsTableRow ,
    table_row8	:  ResultsTableRow ,
    table_row9	:  ResultsTableRow ,
    table_row10	:  ResultsTableRow ,
    table_row11	:  ResultsTableRow ,
    table_row12	:  ResultsTableRow ,
    table_row13	:  ResultsTableRow ,
    table_row14	:  ResultsTableRow ,
    table_row15	:  ResultsTableRow ,
    table_row16	:  ResultsTableRow ,
    table_row17	:  ResultsTableRow ,
    table_row18	:  ResultsTableRow ,
    table_row19	:  ResultsTableRow ,
    table_row20	:  ResultsTableRow ,
    table_row21	:  ResultsTableRow ,
    table_row22	:  ResultsTableRow ,
    table_row23	:  ResultsTableRow ,
    table_row24	:  ResultsTableRow ,
    table_row25	:  ResultsTableRow ,
    table_row26	:  ResultsTableRow ,
    table_row27	:  ResultsTableRow ,
    table_row28	:  ResultsTableRow ,
    table_row29	:  ResultsTableRow ,
    table_row30	:  ResultsTableRow ,
    table_row31	:  ResultsTableRow ,
    table_row32	:  ResultsTableRow ,
    table_row33	:  ResultsTableRow ,
    table_row34	:  ResultsTableRow ,
    table_row35	:  ResultsTableRow ,
    table_row36	:  ResultsTableRow ,
    table_row37	:  ResultsTableRow ,
    table_row38	:  ResultsTableRow ,
    table_row39	:  ResultsTableRow ,
    table_row40	:  ResultsTableRow ,
    table_row41	:  ResultsTableRow ,
    table_row42	:  ResultsTableRow ,
    table_row43	:  ResultsTableRow ,
    table_row44	:  ResultsTableRow ,
    table_row45	:  ResultsTableRow ,
    table_row46	:  ResultsTableRow ,
    table_row47	:  ResultsTableRow ,
    table_row48	:  ResultsTableRow ,
    table_row49	:  ResultsTableRow ,
    table_row50	:  ResultsTableRow ,
    table_row51	:  ResultsTableRow ,




};

export const fakeValues = {
    testId: '',
    reportType: 2,
    isApproved: false,
    testReportNumber: '',
    ordereeName: '',
    ordereeAddress: '',
    orderId: '',
    Category_Vehicle: '',
    orderDate: new Date(),
    frontDocumentTest: new Date(),
    realOrderDate: new Date(),
    testerName: '',
    approveInterst: false,
    fullName: '',
    weight_right: '',
    weight_left: '',
    image7_set: false,
    image8_set: false,
    image9_set: false,
    image10_set: false,
    image11_set: false,
    image12_set: false,

    SectionD_3 : {
        VIN: '',
        SectionD_3classification: '1',
        SectionD_3comments: '',
        value_GVW: '',
        value_GCW: '',
        value_Axle_1: '',
        value_Axle_2: '',
        reg_GVW: '',
        reg_GCW: '',
        reg_Axle_1: '',
        reg_Axle_2: '',
    },





    Device1 : {
        Device :  '',
        Name :  '',
        Manufactor:'',
        masad: '',
        safety :'',
        identity : '',
        report_lab : '',
        identity_lab : '',
        model :'',
        date :'',
    },


    Device2 : {
        Device :  '',
        Name :  '',
        Manufactor:'',
        masad: '',
        safety :'',
        identity : '',
        report_lab : '',
        identity_lab : '',
        model :'',
        date :'',
    },


    Device3 : {
        Device :  '',
        Name :  '',
        Manufactor:'',
        masad: '',
        safety :'',
        identity : '',
        report_lab : '',
        identity_lab : '',
        model :'',
        date :'',
    },


    Device4 : {
        Device :  '',
        Name :  '',
        Manufactor:'',
        masad: '',
        safety :'',
        identity : '',
        report_lab : '',
        identity_lab : '',
        model :'',
        date :'',
    },


    Device5 : {
        Device :  '',
        Name :  '',
        Manufactor:'',
        masad: '',
        safety :'',
        identity : '',
        report_lab : '',
        identity_lab : '',
        model :'',
        date :'',
    },

    group1: {

        classification: '1',
        comments: '',
    },
    group2:{
        classification: '1',
        comments: '',
    },
    group3: {
        classification: '1',
        comments: '',
    },
    group4: {
        classification: '1',
        comments: '',
    },
    group5: {
        classification: '1',
        comments: '',
    },
    group6: {
        classification: '',
        comments: '',
    },
    group7: {
        classification: '',
        comments: '',
    },
    group8: {
        classification: '',
        comments: '',
    },
    group9: {
        classification: '',
        comments: '',
    },
    group10: {
        classification: '',
        comments: '',
    },
    group11: {
        finds: '',
        classification: '',
        comments: '',
    },
    group12: {
        finds: '',
        classification: '',
        comments: '',
    },
    group13: {
        finds: '',
        classification: '',
        comments: '',
    },
    group14: {
        finds: '',
        classification: '',
        comments: '',
    },
    group15: {
        finds: '',
        classification: '',
        comments: '',
    },
    group16: {
        finds: '',
        classification: '',
        comments: '',
    },
    group17: {
        finds: '',
        classification: '',
        comments: '',
    },
    group18: {
        finds: '',
        classification: '',
        comments: '',
    },
    group19: {
        finds: '',
        classification: '',
        comments: '',
    },
    group20: {
        finds: '',
        classification: '',
        comments: '',
    },
    group21: {
        finds: '',
        classification: '',
        comments: '',
    },
    group22: {
        finds: '',
        classification: '',
        comments: '',
    },
    group23: {
        finds: '',
        classification: '',
        comments: '',
    },
    group24: {
        finds: '',
        classification: '',
        comments: '',
    },
    group25: {
        finds: '',
        classification: '',
        comments: '',
    },
    group26: {
        finds: '',
        classification: '',
        comments: '',
    },
    group27: {
        finds: '',
        classification: '',
        comments: '',
    },
    group28: {
        finds: '',
        classification: '',
        comments: '',
    },
    group29: {
        finds: '',
        classification: '',
        comments: '',
    },
    group30: {
        finds: '',
        classification: '',
        comments: '',
    },
    group31: {
        finds: '',
        classification: '',
        comments: '',
    },
    group32: {
        finds: '',
        classification: '',
        comments: '',
    },
    group33: {
        finds: '',
        classification: '',
        comments: '',
    },
    group33_v1: {
        finds: '',
        classification: '',
        comments: '',
    },
    group34: {
        finds: '',
        classification: '',
        comments: '',
    },
    group35: {
        finds: '',
        classification: '',
        comments: '',
    },
    group36: {
        finds: '',
        classification: '',
        comments: '',
    },
    group37: {
        finds: '',
        classification: '',
        comments: '',
    },
    group38: {
        finds: '',
        classification: '',
        comments: '',
    },
    group39: {
        finds: '',
        classification: '',
        comments: '',
    },
    group40: {
        finds: '',
        classification: '',
        comments: '',
    },
    group41: {
        finds: '',
        classification: '',
        comments: '',
    },
    group42: {
        finds: '',
        classification: '',
        comments: '',
    },
    group43: {
        finds: '',
        classification: '',
        comments: '',
    },
    group44: {
        finds: '',
        classification: '',
        comments: '',
    },
    group45: {
        finds: '',
        classification: '',
        comments: '',
    },
    group46: {
        finds: '',
        classification: '',
        comments: '',
    },
    group47: {
        finds: '',
        classification: '',
        comments: '',
    },
    group48: {
        finds: '',
        classification: '',
        comments: '',
    },
    group49: {
        finds: '',
        classification: '',
        comments: '',
    },
    group50: {
        finds: '',
        classification: '',
        comments: '',
    },
    group51: {
        finds: '',
        classification: '',
        comments: '',
    },
    group52: {
        finds: '',
        classification: '',
        comments: '',
    },
    group53: {
        finds: '',
        classification: '',
        comments: '',
    },
    group54: {
        finds: '',
        classification: '',
        comments: '',
    },
    group55: {
        finds: '',
        classification: '',
        comments: '',
    },
    group56: {
        finds: '',
        classification: '',
        comments: '',
    },
    group57: {
        finds: '',
        classification: '',
        comments: '',
    },
    group58: {
        finds: '',
        classification: '',
        comments: '',
    },
    group59: {
        finds: '',
        classification: '',
        comments: '',
    },
    group60: {
        finds: '',
        classification: '',
        comments: '',
    },
    group61: {
        finds: '',
        classification: '',
        comments: '',
    },
    group62: {
        finds: '',
        classification: '',
        comments: '',
    },
    group63: {
        finds: '',
        classification: '',
        comments: '',
    },
    group64: {
        finds: '',
        classification: '',
        comments: '',
    },
    group65: {
        finds: '',
        classification: '',
        comments: '',
    },
    group66: {
        finds: '',
        classification: '',
        comments: '',
    },
    group67: {
        finds: '',
        classification: '',
        comments: '',
    },
    group68: {
        finds: '',
        classification: '',
        comments: '',
    },
    group69: {
        finds: '',
        classification: '',
        comments: '',
    },
    group70: {
        finds: '',
        classification: '',
        comments: '',
    },
    group71: {
        finds: '',
        classification: '',
        comments: '',
    },
    group72: {
        finds: '',
        classification: '',
        comments: '',
    },
    group73: {
        finds: '',
        classification: '',
        comments: '',
    },
    group74: {
        finds: '',
        classification: '',
        comments: '',
    },
    group75: {
        finds: '',
        classification: '',
        comments: '',
    },
    group76: {
        finds: '',
        classification: '',
        comments: '',
    },
    group77: {
        finds: '',
        classification: '',
        comments: '',
    },
    group78: {
        finds: '',
        classification: '',
        comments: '',
    },



    weights :  {
        N1 :  '',
        N2:  '',
        N3:   '',
        N4:   '',
        KWr: '',
        KWf:   '',
        ρf :  '0.8',
        KWtot :  '',
        tank_x:  '',
        tank_y:   '',
        tank_z:   '',
        FWr:     {type : String , Default: ''},
        FWf:     {type : String , Default: ''},
        FWtot:   {type : String , Default: ''},
        tank_total :  '',
        FV:  '',
        FP:  '',
        WB : '',
        Lf:   '',
        VIN_front:   '',
        VIN_Back: '',
        PM : '',
        GVW:  '',
        KW:  '',
        weight_left: '',
        weight_right: '',
        overload_WB : '',
        GVWf: '',
        GVWr:  '',
        GVWl_tot : '',
        GVWr_tot:  '',
        GVW_accept:  '',
        DPW: '',
        X1 : '',
        X2: '',
        laugage_allow: '',
        laugage_a: '',
        laugage_b: '',
        laugage_c: '',
        Seren_self_front:  '',
        Seren_self_back:  '',
        Seren_self_total:  '',
        Seren_driver_front: '',
        Seren_driver_back: '',
        Seren_driver_total:'',
        Seren_total_front:  '',
        Seren_total_back:  '',
        Seren_total_all:  '',
        Seren_total_front_accept:  '',
        Seren_total_back_accept:  '',
        Seren_total_all_accept:  '',

    },


    group79: {
        finds: '',
        classification: '',
        comments: '',
    },
    group80: {
        finds: '',
        classification: '',
        comments: '',
    },
    group81: {
        finds: '',
        classification: '',
        comments: '',
    },
    group82: {
        finds: '',
        classification: '',
        comments: '',
    },
    group83: {
        finds: '',
        classification: '',
        comments: '',
    },
    group84: {
        finds: '',
        classification: '',
        comments: '',
    },
    group85: {
        finds: '',
        classification: '',
        comments: '',
    },
    group86: {
        finds: '',
        classification: '',
        comments: '',
    },
    group87: {
        finds: '',
        classification: '',
        comments: '',
    },
    group88: {
        finds: '',
        classification: '',
        comments: '',
    },
    group89: {
        finds: '',
        classification: '',
        comments: '',
    },
    group90: {
        finds: '',
        classification: '',
        comments: '',
    },
    group91: {
        finds: '',
        classification: '',
        comments: '',
    },
    group92: {
        finds: '',
        classification: '',
        comments: '',
    },
    group93: {
        finds: '',
        classification: '',
        comments: '',
    },
    group94: {
        finds: '',
        classification: '',
        comments: '',
    },
    group95: {
        finds: '',
        classification: '',
        comments: '',
    },
    group96: {
        finds: '',
        classification: '',
        comments: '',
    },
    group97: {
        finds: '',
        classification: '',
        comments: '',
    },
    group98: {
        finds: '',
        classification: '',
        comments: '',
    },
    group99: {
        finds: '',
        classification: '',
        comments: '',
    },
    group100: {
        finds: '',
        classification: '',
        comments: '',
    },
    group101: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group102: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group103: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group104: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group105: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group106: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group107: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group108: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group109: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group110: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group111: {
        finds: '',
        classification: 'V',
        comments: '',
    },
    group112: {
        finds: '',
        classification: 'V',
        comments: '',
    },
    group113: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group114: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group115: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group116: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group117: {
        finds: '',
        classification: 'V',
        comments: '',
    },
    group118: {
        finds: '',
        classification: 'V',
        comments: '',
    },
    group119: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group120: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group121: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group122: {
        finds: '',
        classification: '',
        comments: '',
    },
    group123: {
        finds: '',
        classification: 'V',
        comments: '',
    },
    group124: {
        finds: '',
        classification: 'V',
        comments: '',
    },
    group125: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group126: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group127: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group128: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group129: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group130: {
        finds: '',
        classification: '',
        comments: '',
    },
    group131: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group132: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group133: {
        finds: '',
        classification: 'V',
        comments: '',
    },
    group134: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group135: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group136: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group137: {
        finds: '',
        classification: 'V',
        comments: '',
    },
    group138: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group139: {
        finds: '',
        classification: '',
        comments: '',
    },
    group140: {
        finds: '',
        classification: '',
        comments: '',
    },
    group141: {
        finds: '',
        classification: '',
        comments: '',
    },
    group142: {
        finds: '',
        classification: '',
        comments: '',
    },
    group143: {
        finds: '',
        classification: '',
        comments: '',
    },
    group144: {
        finds: '',
        classification: '',
        comments: '',
    },
    group145: {
        finds: '',
        classification: '',
        comments: '',
    },
    group146: {
        finds: '',
        classification: '',
        comments: '',
    },
    group147: {
        finds: '',
        classification: '',
        comments: '',
    },
    group148: {
        finds: '',
        classification: '',
        comments: '',
    },
    group149: {
        finds: '',
        classification: '',
        comments: '',
    },
    group150: {
        finds: '',
        classification: '',
        comments: '',
    },
    group151: {
        finds: '',
        classification: '',
        comments: '',
    },
    group152: {
        finds: '',
        classification: '',
        comments: '',
    },
    group153: {
        finds: '',
        classification: '',
        comments: '',
    },
    group154: {
        finds: '',
        classification: '',
        comments: '',
    },
    group155: {
        finds: '',
        classification: '',
        comments: '',
    },
    group156: {
        finds: '',
        classification: '',
        comments: '',
    },
    group157: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group158: {
        finds: '',
        classification: '',
        comments: '',
    },
    group159: {
        finds: '',
        classification: '',
        comments: '',
    },
    group160: {
        finds: '',
        classification: '',
        comments: '',
    },
    group161: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group162: {
        finds: '',
        classification: '',
        comments: '',
    },
    group163: {
        finds: '',
        classification: '',
        comments: '',
    },
    group164: {
        finds: '',
        classification: '',
        comments: '',
    },
    group165: {
        finds: '',
        classification: '',
        comments: '',
    },
    group166: {
        finds: '',
        classification: '',
        comments: '',
    },
    group167: {
        finds: '',
        classification: '',
        comments: '',
    },
    group168: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group169: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group170: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group171: {
        finds: '',
        classification: 'V',
        comments: '',
    },
    group172: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group173: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group174: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group175: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group176: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group177: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group178: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group179: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group180: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group181: {
        finds: '',
        classification: '',
        comments: '',
    },
    group182: {
        finds: '',
        classification: '',
        comments: '',
    },
    group183: {
        finds: '',
        classification: '',
        comments: '',
    },
    group184: {
        finds: '',
        classification: '',
        comments: '',
    },
    group185: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group186: {
        finds: '',
        classification: '',
        comments: '',
    },
    group187: {
        finds: '',
        classification: '',
        comments: '',
    },
    group188: {
        finds: '',
        classification: '',
        comments: '',
    },
    group189: {
        finds: '',
        classification: '',
        comments: '',
    },
    group190: {
        finds: '',
        classification: '',
        comments: '',
    },
    group191: {
        finds: '',
        classification: '',
        comments: '',
    },
    group192: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group193: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group194: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group195: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group196: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group197: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group198: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group199: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group200: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group201: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group202: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group203: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group204: {
        finds: '',
        classification: 'V',
        comments: '',
    },
    group205: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group206: {
        finds: '',
        classification: '',
        comments: '',
    },
    group207: {
        finds: '',
        classification: '',
        comments: '',
    },
    group208: {
        finds: '',
        classification: '',
        comments: '',
    },

    group209: {
        finds: '',
        classification: '',
        comments: '',
    },

    group210: {
        finds: '',
        classification: '',
        comments: '',
    },

    group211: {
        finds: '',
        classification: '',
        comments: '',
    },

    group212: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },

    group213: {
        finds: '',
        classification: 'A',
        comments: '',
    },

    group214: {
        finds: '',
        classification: 'A',
        comments: '',
    },

    group215: {
        finds: '',
        classification: 'V',
        comments: '',
    },

    group216: {
        finds: '',
        classification: 'V',
        comments: '',
    },

    group217: {
        finds: '',
        classification: '',
        comments: '',
    },

    group218: {
        finds: '',
        classification: '',
        comments: '',
    },

    group219: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },

    group220: {
        finds: '',
        classification: 'V',
        comments: '',
    },

    group221: {
        finds: '',
        classification: 'V',
        comments: '',
    },

    group222: {
        finds: '',
        classification: 'V',
        comments: '',
    },

    group223: {
        finds: '',
        classification: 'V',
        comments: '',
    },

    group224: {
        finds: '',
        classification: 'V',
        comments: '',
    },

    group225: {
        finds: '',
        classification: 'V',
        comments: '',
    },

    group226: {
        finds: '',
        classification: 'V',
        comments: '',
    },

    group227: {
        finds: '',
        classification: 'V',
        comments: '',
    },

    group228: {
        finds: '',
        classification: '',
        comments: '',
    },

    group229: {
        finds: '',
        classification: '',
        comments: '',
    },

    group230: {
        finds: '',
        classification: 'A',
        comments: '',
    },

    group231: {
        finds: '',
        classification: '',
        comments: '',
    },

    group232: {
        finds: '',
        classification: '',
        comments: '',
    },

    group233: {
        finds: '',
        classification: '',
        comments: '',
    },

    group234: {
        finds: '',
        classification: '',
        comments: '',
    },

    group235: {
        finds: '',
        classification: 'V',
        comments: '',
    },

    group236: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },

    group237: {
        finds: '',
        classification: '',
        comments: '',
    },

    group238: {
        finds: '',
        classification: '',
        comments: '',
    },

    group239: {
        finds: '',
        classification: '',
        comments: '',
    },

    group240: {
        finds: '',
        classification: '',
        comments: '',
    },

    group241: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },

    group242: {
        finds: '',
        classification: '',
        comments: '',
    },

    group243: {
        finds: '',
        classification: 'V',
        comments: '',
    },

    group244: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },

    group245: {
        finds: '',
        classification: 'V',
        comments: '',
    },

    group246: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group247: {
        finds: '',
        classification: '',
        comments: '',
    },
    group248: {
        finds: '',
        classification: 'V',
        comments: '',
    },
    group249: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group250: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group251: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group252: {
        finds: '',
        classification: '',
        comments: '',
    },
    group253: {
        finds: '',
        classification: '',
        comments: '',
    },
    group254: {
        finds: '',
        classification: '',
        comments: '',
    },
    group255: {
        finds: '',
        classification: '',
        comments: '',
    },
    group256: {
        finds: '',
        classification: '',
        comments: '',
    },
    group257: {
        finds: '',
        classification: '',
        comments: '',
    },
    group258: {
        finds: '',
        classification: '',
        comments: '',
    },
    group259: {
        finds: '',
        classification: '',
        comments: '',
    },
    group260: {
        finds: '',
        classification: '',
        comments: '',
    },
    group261: {
        finds: '',
        classification: '',
        comments: '',
    },
    group262: {
        finds: '',
        classification: '',
        comments: '',
    },
    group263: {
        finds: '',
        classification: '',
        comments: '',
    },
    group264: {
        finds: '',
        classification: '',
        comments: '',
    },
    group265: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group266: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group267: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group268: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group269: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group270: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group271: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group272: {
        finds: '',
        classification: '',
        comments: '',
    },
    group273: {
        finds: '',
        classification: '',
        comments: '',
    },
    group274: {
        finds: '',
        classification: '',
        comments: '',
    },

    group275: {
        finds: '',
        classification: '',
        comments: '',
    },
    group276: {
        finds: '',
        classification: '',
        comments: '',
    },
    group277: {
        finds: '',
        classification: '',
        comments: '',
    },
    group278: {
        finds: '',
        classification: '',
        comments: '',
    },
    group279: {
        finds: '',
        classification: '',
        comments: '',
    },
    group280: {
        finds: '',
        classification: '',
        comments: '',
    },
    group281: {
        finds: '',
        classification: '',
        comments: '',
    },
    group282: {
        finds: '',
        classification: '',
        comments: '',
    },
    group283: {
        finds: '',
        classification: '',
        comments: '',
    },
    group284: {
        finds: '',
        classification: '',
        comments: '',
    },
    group285: {
        finds: '',
        classification: '',
        comments: '',
    },







    image1: {
        finds: '',
        classification: '',
        comments: '',
    },
    image2: {
        finds: '',
        classification: '',
        comments: '',
    },
    image3: {
        finds: '',
        classification: '',
        comments: '',
    },
    image4: {
        finds: '',
        classification: '',
        comments: '',
    },
    image5: {
        finds: '',
        classification: '',
        comments: '',
    },
    image6: {
        finds: '',
        classification: '',
        comments: '',
    },
    image7: {
        finds: '',
        classification: '',
        comments: '',
    },
    image8: {
        finds: '',
        classification: '',
        comments: '',
    },
    image9: {
        finds: '',
        classification: '',
        comments: '',
    },
    image10: {
        finds: '',
        classification: '',
        comments: '',
    },
    image11: {
        finds: '',
        classification: '',
        comments: '',
    },
    image12: {
        finds: '',
        classification: '',
        comments: '',
    },


     table_row1 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },

    table_row2 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },

    table_row3 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },

    table_row4 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },

    table_row5 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },

    table_row6 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },

    table_row7 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row8 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },

    table_row9 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row10 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row11 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row12 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row13 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row14 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row15 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row16 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row17 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row18 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row19 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row20 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row21 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row22 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row23 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row24 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row25 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row26 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row27 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row28 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row29 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row30 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row31 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row32 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row33 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row34 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row35 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row36 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row37 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row38 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row39 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row40 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row41 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row42 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row43 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row44 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row45 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row46 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row47 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row48 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row49 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row50 :  {
        distance :  '',
        front_weight: '',
        rear_weight: '',
        total_row_weight: '',
    },
    table_row51 :  {
        total_front_weight: '',
        total_rear_weight: '',
        total_row_weight: '',
    },






};
