import moment from 'moment';
var longToDate = function (millisec) {
    console.log('moment', moment().format('DD-MM-YY'));

    return moment().format('DD-MM-YY');
};

const groupFields = {
    finds : { type: String },
    classification: {type: String},
    comments: { type: String },
};

const ResultsTableRow =   {
    distance :  { type: String },
    front_weight: { type: String },
    rear_weight: { type: String },
    total_row_weight: { type: String },
    total_front_weight: { type: String},
    total_rear_weight: { type: String },
};

const SectionD_3 = {
    VIN :  { type: String, },
    SectionD_3classification: { type: String },
    SectionD_3comments: { type: String },
    value_GVW : { type: String },
    value_Axle_1 : { type: String },
    value_Axle_2 : { type: String },
    value_Axle_3 : { type: String },
    value_Axle_4 : { type: String },
    reg_GVW :{ type: String },
    reg_Axle_1 : { type: String },
    reg_Axle_2 : { type: String },
    reg_Axle_3 : { type: String },
    reg_Axle_4 : { type: String },

};

const DevicesFields = {
    Device : { type: String },
    Name :  { type: String },
    Manufactor: { type: String },
    masad: { type: String },
    safety : { type: String },
    identity : { type: String },
    report_lab : { type: String },
    identity_lab : { type: String },
    model :{ type: String },
    date :{ type: String },
};



const weights_fields =   {
    N1 :  { type: String },
    N2:   { type: String },
    N3:   { type: String },
    N4:   { type: String },
    N5:   { type: String },
    N6:   { type: String },
    KWr:  { type: String },
    KWf:   { type: String },
    KWtot :   { type: String },


};


export const initialValues = {
    testId: { type: String },
    reportType: 3,
    isApproved: false,
    testReportNumber: { type: String },
    ordereeName: '',
    ordereeAddress: '',
    orderId: { type: String } ,
    Category_Vehicle: '',
    header_option: '',
    orderDate: '',
    frontDocumentTest: '',
    realOrderDate: '',
    testerName: '',
    approveInterst: false,
    fullName: '',
    signiture: '',



    group1:  groupFields,
    group2:  groupFields,
    group3:  groupFields,
    group4:  groupFields,
    group5:  groupFields,
    group6:  groupFields,
    group7:  groupFields,
    group8:  groupFields,
    group9:  groupFields,
    group10: groupFields,
    group11: groupFields,
    group12: groupFields,
    group13: groupFields,
    group14: groupFields,
    group15: groupFields,
    group16: groupFields,
    group17: groupFields,
    group18: groupFields,
    group19   :groupFields,
    group20	:groupFields,
    group21	:groupFields,
    group22	:groupFields,
    SectionD_3 : SectionD_3,
    group23	:groupFields,
    group24	:groupFields,
    group25	:groupFields,
    group26	:groupFields,
    group27	:groupFields,
    group28	:groupFields,
    group29	:groupFields,
    group30	:groupFields,
    group31	:groupFields,
    group32	:groupFields,
    group33	:groupFields,
    group34	:groupFields,
    group35	:groupFields,
    group36	:groupFields,
    group37	:groupFields,
    group38	:groupFields,
    group39	:groupFields,
    group40	:groupFields,
    group41	:groupFields,
    group42	:groupFields,
    group43	:groupFields,
    group44	:groupFields,
    group45	:groupFields,
    group46	:groupFields,
    group47	:groupFields,
    group48	:groupFields,
    group49	:groupFields,
    group50	:groupFields,
    group51	:groupFields,
    group52	:groupFields,
    group53	:groupFields,
    group54	:groupFields,
    group55	:groupFields,
    group56	:groupFields,
    group57	:groupFields,
    group58	:groupFields,
    group59	:groupFields,
    group60	:groupFields,
    group61	:groupFields,
    group62	:groupFields,
    weights : weights_fields,
    group63	:groupFields,
    group64	:groupFields,
    group65	:groupFields,
    group66	:groupFields,
    group67	:groupFields,
    group68	:groupFields,
    group69	:groupFields,
    group70	:groupFields,
    group71	:groupFields,
    group72	:groupFields,
    group73	:groupFields,
    group74	:groupFields,
    group75	:groupFields,
    group76	:groupFields,
    group77	:groupFields,
    group78	:groupFields,
    Device1  : DevicesFields,
    Device2  : DevicesFields,
    Device3  : DevicesFields,
    Device4  : DevicesFields,
    Device5  : DevicesFields,
    group79	:groupFields,
    group80	:groupFields,
    group81	:groupFields,
    group82	:groupFields,
    group83	:groupFields,
    group84	:groupFields,
    group85	:groupFields,
    group86	:groupFields,
    group87	:groupFields,
    group88	:groupFields,
    group89	:groupFields,
    group90	:groupFields,
    group91	:groupFields,
    group92	:groupFields,
    group93	:groupFields,
    group94	:groupFields,
    group95	:groupFields,
    group96	:groupFields,
    group97	:groupFields,
    group98	:groupFields,
    group99	:groupFields,
    group100	:groupFields,
    group101	:groupFields,
    group102	:groupFields,
    group103	:groupFields,
    group104	:groupFields,
    group105	:groupFields,
    group106	:groupFields,
    group107	:groupFields,
    group108	:groupFields,
    group109	:groupFields,
    group110	:groupFields,
    group111	:groupFields,
    group112	:groupFields,
    group113	:groupFields,
    group114	:groupFields,
    group115	:groupFields,
    group116	:groupFields,
    group117	:groupFields,
    group118	:groupFields,
    group119	:groupFields,
    group120	:groupFields,
    group121	:groupFields,
    group122	:groupFields,
    group123	:groupFields,
    group124	:groupFields,
    group125	:groupFields,
    group126	:groupFields,
    group127	:groupFields,
    group128	:groupFields,
    group129	:groupFields,
    group130	:groupFields,
    group131	:groupFields,
    group132	:groupFields,
    group133	:groupFields,
    group134	:groupFields,
    group135	:groupFields,
    group136	:groupFields,
    group137	:groupFields,
    group138	:groupFields,
    group139	:groupFields,
    group140	:groupFields,
    group141	:groupFields,
    group142	:groupFields,
    group143	:groupFields,
    group144	:groupFields,
    group145	:groupFields,
    group146	:groupFields,
    group147	:groupFields,
    group148	:groupFields,
    group149	:groupFields,
    group150	:groupFields,
    group151	:groupFields,
    group152	:groupFields,
    group153	:groupFields,
    group154	:groupFields,
    group155	:groupFields,
    group156	:groupFields,
    group157	:groupFields,
    group158	:groupFields,
    group159	:groupFields,
    group160	:groupFields,
    group161	:groupFields,
    group162	:groupFields,
    group163	:groupFields,
    group164	:groupFields,
    group165	:groupFields,
    group166	:groupFields,
    group167	:groupFields,
    group168	:groupFields,
    group169	:groupFields,
    group170	:groupFields,
    group171	:groupFields,
    group172	:groupFields,
    group173	:groupFields,
    group174	:groupFields,
    group175	:groupFields,
    group176	:groupFields,
    group177	:groupFields,
    group178	:groupFields,
    group179	:groupFields,
    group180	:groupFields,
    group181	:groupFields,
    group182	:groupFields,
    group183	:groupFields,
    group184	:groupFields,
    group185	:groupFields,
    group186	:groupFields,
    group187	:groupFields,
    group188	:groupFields,
    group189	:groupFields,
    group190	:groupFields,
    group191	:groupFields,
    group192	:groupFields,
    group193	:groupFields,
    group194	:groupFields,
    group195	:groupFields,
    group196	:groupFields,
    group197	:groupFields,
    group198	:groupFields,
    group199	:groupFields,
    group200	:groupFields,
    group201	:groupFields,
    group202	:groupFields,
    group203	:groupFields,
    group204	:groupFields,
    group205	:groupFields,
    group206	:groupFields,
    group207	:groupFields,
    group208	:groupFields,
    group209	:groupFields,
    group210	:groupFields,
    group211	:groupFields,
    group212	:groupFields,
    group213	:groupFields,
    group214	:groupFields,
    group215	:groupFields,
    group216	:groupFields,
    group217	:groupFields,
    group218	:groupFields,
    group219	:groupFields,
    group220	:groupFields,
    group221	:groupFields,
    group222	:groupFields,
    group223	:groupFields,
    group224	:groupFields,
    group225	:groupFields,
    group226	:groupFields,
    group227	:groupFields,
    group228	:groupFields,
    group229	:groupFields,
    group230	:groupFields,
    group231	:groupFields,
    group232	:groupFields,
    group233	:groupFields,
    group234	:groupFields,
    group235	:groupFields,
    group236	:groupFields,
    group237	:groupFields,
    group238	:groupFields,
    group239	:groupFields,
    group240	:groupFields,
    group241	:groupFields,
    group242	:groupFields,

    group244	:groupFields,
    group245	:groupFields,
    group246	:groupFields,
    group247	:groupFields,
    group248	:groupFields,
    group249	:groupFields,
    group250	:groupFields,
    group251	:groupFields,
    group252	:groupFields,
    group253	:groupFields,
    group254	:groupFields,
    group255	:groupFields,
    group256	:groupFields,
    group257	:groupFields,
    group258	:groupFields,
    group259	:groupFields,
    group260	:groupFields,
    group261	:groupFields,
    group262	:groupFields,
    group263	:groupFields,
    group264	:groupFields,
    group265	:groupFields,
    group266	:groupFields,
    group267	:groupFields,
    group268	:groupFields,
    group269	:groupFields,
    group270	:groupFields,
    group271	:groupFields,
    group272	:groupFields,
    group273	:groupFields,
    group274   	 :groupFields,
    group275	 :groupFields,
    group276	 :groupFields,
    group277	 :groupFields,
    group278	 :groupFields,
    group279	 :groupFields,
    group280	 :groupFields,
    group281	 :groupFields,
    group282	 :groupFields,
    group283	 :groupFields,
    group284	 :groupFields,
    group285	 :groupFields,

    image1    :groupFields,
    image2    :groupFields,
    image3    :groupFields,
    image4    :groupFields,
    image5    :groupFields,
    image6    :groupFields,
    image7    :groupFields,
    image8    :groupFields,
    image9    :groupFields,
    image10   :groupFields,
    image11   :groupFields,
    image12   :groupFields,




};

export const fakeValues = {
    testId: '',
    reportType: 3,
    isApproved: false,
    testReportNumber: '',
    ordereeName: '',
    ordereeAddress: '',
    orderId: '',
    Category_Vehicle: '',
    orderDate: new Date(),
    frontDocumentTest: new Date(),
    realOrderDate: new Date(),
    testerName: '',
    approveInterst: false,
    fullName: '',
    weight_right: '',
    weight_left: '',
    image7_set: false,
    image8_set: false,
    image9_set: false,
    image10_set: false,
    image11_set: false,
    image12_set: false,

    SectionD_3 : {
        VIN: '',
        SectionD_3classification: '1',
        SectionD_3comments: '',
        value_GVW: '',
        value_GCW: '',
        value_Axle_1: '',
        value_Axle_2: '',
        reg_GVW: '',
        reg_GCW: '',
        reg_Axle_1: '',
        reg_Axle_2: '',
    },





    Device1 : {
        Device :  '',
        Name :  '',
        Manufactor:'',
        masad: '',
        safety :'',
        identity : '',
        report_lab : '',
        identity_lab : '',
        model :'',
        date :'',
    },


    Device2 : {
        Device :  '',
        Name :  '',
        Manufactor:'',
        masad: '',
        safety :'',
        identity : '',
        report_lab : '',
        identity_lab : '',
        model :'',
        date :'',
    },


    Device3 : {
        Device :  '',
        Name :  '',
        Manufactor:'',
        masad: '',
        safety :'',
        identity : '',
        report_lab : '',
        identity_lab : '',
        model :'',
        date :'',
    },


    Device4 : {
        Device :  '',
        Name :  '',
        Manufactor:'',
        masad: '',
        safety :'',
        identity : '',
        report_lab : '',
        identity_lab : '',
        model :'',
        date :'',
    },


    Device5 : {
        Device :  '',
        Name :  '',
        Manufactor:'',
        masad: '',
        safety :'',
        identity : '',
        report_lab : '',
        identity_lab : '',
        model :'',
        date :'',
    },

    group1: {

        classification: '1',
        comments: '',
    },
    group2:{
        classification: '1',
        comments: '',
    },
    group3: {
        classification: '1',
        comments: '',
    },
    group4: {
        classification: '1',
        comments: '',
    },
    group5: {
        classification: '1',
        comments: '',
    },
    group6: {
        classification: '',
        comments: '',
    },
    group7: {
        classification: '',
        comments: '',
    },
    group8: {
        classification: '',
        comments: '',
    },
    group9: {
        classification: '',
        comments: '',
    },
    group10: {
        classification: '',
        comments: '',
    },
    group11: {
        finds: '',
        classification: '',
        comments: '',
    },
    group12: {
        finds: '',
        classification: '',
        comments: '',
    },
    group13: {
        finds: '',
        classification: '',
        comments: '',
    },
    group14: {
        finds: '',
        classification: '',
        comments: '',
    },
    group15: {
        finds: '',
        classification: '',
        comments: '',
    },
    group16: {
        finds: '',
        classification: '',
        comments: '',
    },
    group17: {
        finds: '',
        classification: '',
        comments: '',
    },
    group18: {
        finds: '',
        classification: '',
        comments: '',
    },
    group19: {
        finds: '',
        classification: '',
        comments: '',
    },
    group20: {
        finds: '',
        classification: '',
        comments: '',
    },
    group21: {
        finds: '',
        classification: '',
        comments: '',
    },
    group22: {
        finds: '',
        classification: '',
        comments: '',
    },
    group23: {
        finds: '',
        classification: '',
        comments: '',
    },
    group24: {
        finds: '',
        classification: '',
        comments: '',
    },
    group25: {
        finds: '',
        classification: '',
        comments: '',
    },
    group26: {
        finds: '',
        classification: '',
        comments: '',
    },
    group27: {
        finds: '',
        classification: '',
        comments: '',
    },
    group28: {
        finds: '',
        classification: '',
        comments: '',
    },
    group29: {
        finds: '',
        classification: '',
        comments: '',
    },
    group30: {
        finds: '',
        classification: '',
        comments: '',
    },
    group31: {
        finds: '',
        classification: '',
        comments: '',
    },
    group32: {
        finds: '',
        classification: '',
        comments: '',
    },
    group33: {
        finds: '',
        classification: '',
        comments: '',
    },
    group33_v1: {
        finds: '',
        classification: '',
        comments: '',
    },
    group34: {
        finds: '',
        classification: '',
        comments: '',
    },
    group35: {
        finds: '',
        classification: '',
        comments: '',
    },
    group36: {
        finds: '',
        classification: '',
        comments: '',
    },
    group37: {
        finds: '',
        classification: '',
        comments: '',
    },
    group38: {
        finds: '',
        classification: '',
        comments: '',
    },
    group39: {
        finds: '',
        classification: '',
        comments: '',
    },
    group40: {
        finds: '',
        classification: '',
        comments: '',
    },
    group41: {
        finds: '',
        classification: '',
        comments: '',
    },
    group42: {
        finds: '',
        classification: '',
        comments: '',
    },
    group43: {
        finds: '',
        classification: '',
        comments: '',
    },
    group44: {
        finds: '',
        classification: '',
        comments: '',
    },
    group45: {
        finds: '',
        classification: '',
        comments: '',
    },
    group46: {
        finds: '',
        classification: '',
        comments: '',
    },
    group47: {
        finds: '',
        classification: '',
        comments: '',
    },
    group48: {
        finds: '',
        classification: '',
        comments: '',
    },
    group49: {
        finds: '',
        classification: '',
        comments: '',
    },
    group50: {
        finds: '',
        classification: '',
        comments: '',
    },
    group51: {
        finds: '',
        classification: '',
        comments: '',
    },
    group52: {
        finds: '',
        classification: '',
        comments: '',
    },
    group53: {
        finds: '',
        classification: '',
        comments: '',
    },
    group54: {
        finds: '',
        classification: '',
        comments: '',
    },
    group55: {
        finds: '',
        classification: '',
        comments: '',
    },
    group56: {
        finds: '',
        classification: '',
        comments: '',
    },
    group57: {
        finds: '',
        classification: '',
        comments: '',
    },
    group58: {
        finds: '',
        classification: '',
        comments: '',
    },
    group59: {
        finds: '',
        classification: '',
        comments: '',
    },
    group60: {
        finds: '',
        classification: '',
        comments: '',
    },
    group61: {
        finds: '',
        classification: '',
        comments: '',
    },
    group62: {
        finds: '',
        classification: '',
        comments: '',
    },
    group63: {
        finds: '',
        classification: '',
        comments: '',
    },
    group64: {
        finds: '',
        classification: '',
        comments: '',
    },
    group65: {
        finds: '',
        classification: '',
        comments: '',
    },
    group66: {
        finds: '',
        classification: '',
        comments: '',
    },
    group67: {
        finds: '',
        classification: '',
        comments: '',
    },
    group68: {
        finds: '',
        classification: '',
        comments: '',
    },
    group69: {
        finds: '',
        classification: '',
        comments: '',
    },
    group70: {
        finds: '',
        classification: '',
        comments: '',
    },
    group71: {
        finds: '',
        classification: '',
        comments: '=²(שלוחה קדמית)+²(0.5*רוחב)',
    },
    group72: {
        finds: '',
        classification: '',
        comments: '  =²(מרחק בין רגל חניה לפין גרירה)+²(0.5*רוחב)',
    },
    group73: {
        finds: '',
        classification: '',
        comments: '',
    },
    group74: {
        finds: '',
        classification: '',
        comments: '',
    },
    group75: {
        finds: '',
        classification: '',
        comments: '',
    },
    group76: {
        finds: '',
        classification: '',
        comments: '',
    },
    group77: {
        finds: '',
        classification: '',
        comments: '',
    },
    group78: {
        finds: '',
        classification: '',
        comments: '',
    },



    weights :  {
        N1 :  '',
        N2:  '',
        N3:   '',
        N4:   '',
        N5:   '',
        N6:   '',
        KWr: '',
        KWf:   '',
        KWtot :  '',
    },


    group79: {
        finds: '',
        classification: '',
        comments: '',
    },
    group80: {
        finds: '',
        classification: '',
        comments: '',
    },
    group81: {
        finds: '',
        classification: '',
        comments: '',
    },
    group82: {
        finds: '',
        classification: '',
        comments: '',
    },
    group83: {
        finds: '',
        classification: '',
        comments: '',
    },
    group84: {
        finds: '',
        classification: '',
        comments: '',
    },
    group85: {
        finds: '',
        classification: '',
        comments: '',
    },
    group86: {
        finds: '',
        classification: '',
        comments: '',
    },
    group87: {
        finds: '',
        classification: '',
        comments: '',
    },
    group88: {
        finds: '',
        classification: '',
        comments: '',
    },
    group89: {
        finds: '',
        classification: '',
        comments: '',
    },
    group90: {
        finds: '',
        classification: '',
        comments: '',
    },
    group91: {
        finds: '',
        classification: '',
        comments: '',
    },
    group92: {
        finds: '',
        classification: '',
        comments: '',
    },
    group93: {
        finds: '',
        classification: '',
        comments: '',
    },
    group94: {
        finds: '',
        classification: '',
        comments: '',
    },
    group95: {
        finds: '',
        classification: '',
        comments: '',
    },
    group96: {
        finds: '',
        classification: '',
        comments: '',
    },
    group97: {
        finds: '',
        classification: '',
        comments: '',
    },
    group98: {
        finds: '',
        classification: '',
        comments: '',
    },
    group99: {
        finds: '',
        classification: '',
        comments: '',
    },
    group100: {
        finds: '',
        classification: '',
        comments: '',
    },
    group101: {
        finds: '',
        classification: '',
        comments: '',
    },
    group102: {
        finds: '',
        classification: '',
        comments: '',
    },
    group103: {
        finds: '',
        classification: '',
        comments: '',
    },
    group104: {
        finds: '',
        classification: '',
        comments: '',
    },
    group105: {
        finds: '',
        classification: '',
        comments: '',
    },
    group106: {
        finds: '',
        classification: '',
        comments: '',
    },
    group107: {
        finds: '',
        classification: '',
        comments: '',
    },
    group108: {
        finds: '',
        classification: '',
        comments: '',
    },
    group109: {
        finds: '',
        classification: '',
        comments: '',
    },
    group110: {
        finds: '',
        classification: '',
        comments: '',
    },
    group111: {
        finds: '',
        classification: '',
        comments: '',
    },
    group112: {
        finds: '',
        classification: '',
        comments: '',
    },
    group113: {
        finds: '',
        classification: '',
        comments: '',
    },
    group114: {
        finds: '',
        classification: '',
        comments: '',
    },
    group115: {
        finds: '',
        classification: '',
        comments: '',
    },
    group116: {
        finds: '',
        classification: '',
        comments: '',
    },
    group117: {
        finds: '',
        classification: '',
        comments: '',
    },
    group118: {
        finds: '',
        classification: '',
        comments: '',
    },
    group119: {
        finds: '',
        classification: '',
        comments: '',
    },
    group120: {
        finds: '',
        classification: '',
        comments: '',
    },
    group121: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group122: {
        finds: '',
        classification: '',
        comments: '',
    },
    group123: {
        finds: '',
        classification: 'V',
        comments: '',
    },
    group124: {
        finds: '',
        classification: 'V',
        comments: '',
    },
    group125: {
        finds: '',
        classification: '',
        comments: '',
    },
    group126: {
        finds: '',
        classification: '',
        comments: '',
    },
    group127: {
        finds: '',
        classification: '',
        comments: '',
    },
    group128: {
        finds: '',
        classification: '',
        comments: '',
    },
    group129: {
        finds: '',
        classification: '',
        comments: '',
    },
    group130: {
        finds: '',
        classification: '',
        comments: '',
    },
    group131: {
        finds: '',
        classification: '',
        comments: '',
    },
    group132: {
        finds: '',
        classification: '',
        comments: '',
    },
    group133: {
        finds: '',
        classification: '',
        comments: '',
    },
    group134: {
        finds: '',
        classification: '',
        comments: '',
    },
    group135: {
        finds: '',
        classification: '',
        comments: '',
    },
    group136: {
        finds: '',
        classification: '',
        comments: '',
    },
    group137: {
        finds: '',
        classification: '',
        comments: '',
    },
    group138: {
        finds: '',
        classification: '',
        comments: '',
    },
    group139: {
        finds: '',
        classification: '',
        comments: '',
    },
    group140: {
        finds: '',
        classification: '',
        comments: '',
    },
    group141: {
        finds: '',
        classification: '',
        comments: '',
    },
    group142: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group143: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group144: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group145: {
        finds: '',
        classification: '',
        comments: '',
    },
    group146: {
        finds: '',
        classification: 'V',
        comments: '',
    },
    group147: {
        finds: '',
        classification: 'V',
        comments: '',
    },
    group148: {
        finds: '',
        classification: 'V',
        comments: '',
    },
    group149: {
        finds: '',
        classification: '',
        comments: '',
    },
    group150: {
        finds: '',
        classification: '',
        comments: '',
    },
    group151: {
        finds: '',
        classification: '',
        comments: '',
    },
    group152: {
        finds: '',
        classification: '',
        comments: '',
    },
    group153: {
        finds: '',
        classification: '',
        comments: '',
    },
    group154: {
        finds: '',
        classification: '',
        comments: '',
    },
    group155: {
        finds: '',
        classification: '',
        comments: '',
    },
    group156: {
        finds: '',
        classification: '',
        comments: '',
    },
    group157: {
        finds: '',
        classification: '',
        comments: '',
    },
    group158: {
        finds: '',
        classification: '',
        comments: '',
    },
    group159: {
        finds: '',
        classification: '',
        comments: '',
    },
    group160: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group161: {
        finds: '',
        classification: '',
        comments: '',
    },
    group162: {
        finds: '',
        classification: '',
        comments: '',
    },
    group163: {
        finds: '',
        classification: '',
        comments: '',
    },
    group164: {
        finds: '',
        classification: '',
        comments: '',
    },
    group165: {
        finds: '',
        classification: '',
        comments: '',
    },
    group166: {
        finds: '',
        classification: 'V',
        comments: '',
    },
    group167: {
        finds: '',
        classification: '',
        comments: '',
    },
    group168: {
        finds: '',
        classification: '',
        comments: '',
    },
    group169: {
        finds: '',
        classification: '',
        comments: '',
    },
    group170: {
        finds: '',
        classification: '',
        comments: '',
    },
    group171: {
        finds: '',
        classification: '',
        comments: '',
    },
    group172: {
        finds: '',
        classification: '',
        comments: '',
    },
    group173: {
        finds: '',
        classification: '',
        comments: '',
    },
    group174: {
        finds: '',
        classification: '',
        comments: '',
    },
    group175: {
        finds: '',
        classification: '',
        comments: '',
    },
    group176: {
        finds: '',
        classification: '',
        comments: '',
    },
    group177: {
        finds: '',
        classification: '',
        comments: '',
    },
    group178: {
        finds: '',
        classification: '',
        comments: '',
    },
    group179: {
        finds: '',
        classification: '',
        comments: '',
    },
    group180: {
        finds: '',
        classification: '',
        comments: '',
    },
    group181: {
        finds: '',
        classification: '',
        comments: '',
    },
    group182: {
        finds: '',
        classification: '',
        comments: '',
    },
    group183: {
        finds: '',
        classification: '',
        comments: '',
    },
    group184: {
        finds: '',
        classification: '',
        comments: '',
    },
    group185: {
        finds: '',
        classification: '',
        comments: '',
    },
    group186: {
        finds: '',
        classification: '',
        comments: '',
    },
    group187: {
        finds: '',
        classification: '',
        comments: '',
    },
    group188: {
        finds: '',
        classification: '',
        comments: '',
    },
    group189: {
        finds: '',
        classification: '',
        comments: '',
    },
    group190: {
        finds: '',
        classification: '',
        comments: '',
    },
    group191: {
        finds: '',
        classification: '',
        comments: '',
    },
    group192: {
        finds: '',
        classification: '',
        comments: '',
    },
    group193: {
        finds: '',
        classification: '',
        comments: '',
    },
    group194: {
        finds: '',
        classification: '',
        comments: '',
    },
    group195: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group196: {
        finds: '',
        classification: '',
        comments: '',
    },
    group197: {
        finds: '',
        classification: '',
        comments: '',
    },
    group198: {
        finds: '',
        classification: '',
        comments: '',
    },
    group199: {
        finds: '',
        classification: '',
        comments: '',
    },
    group200: {
        finds: '',
        classification: '',
        comments: '',
    },
    group201: {
        finds: '',
        classification: '',
        comments: '',
    },
    group202: {
        finds: '',
        classification: 'V',
        comments: '',
    },
    group203: {
        finds: '',
        classification: 'V',
        comments: '',
    },
    group204: {
        finds: '',
        classification: 'V',
        comments: '',
    },
    group205: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group206: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group207: {
        finds: '',
        classification: '',
        comments: '',
    },
    group208: {
        finds: '',
        classification: '',
        comments: '',
    },

    group209: {
        finds: '',
        classification: '',
        comments: '',
    },

    group210: {
        finds: '',
        classification: '',
        comments: '',
    },

    group211: {
        finds: '',
        classification: '',
        comments: '',
    },

    group212: {
        finds: '',
        classification: 'A',
        comments: '',
    },

    group213: {
        finds: '',
        classification: '',
        comments: '',
    },

    group214: {
        finds: '',
        classification: '',
        comments: '',
    },

    group215: {
        finds: '',
        classification: '',
        comments: '',
    },

    group216: {
        finds: '',
        classification: '',
        comments: '',
    },

    group217: {
        finds: '',
        classification: '',
        comments: '',
    },

    group218: {
        finds: '',
        classification: '',
        comments: '',
    },

    group219: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },

    group220: {
        finds: '',
        classification: 'V',
        comments: '',
    },

    group221: {
        finds: '',
        classification: 'V',
        comments: '',
    },

    group222: {
        finds: '',
        classification: '',
        comments: '',
    },

    group223: {
        finds: '',
        classification: '',
        comments: '',
    },

    group224: {
        finds: '',
        classification: '',
        comments: '',
    },

    group225: {
        finds: '',
        classification: '',
        comments: '',
    },

    group226: {
        finds: '',
        classification: '',
        comments: '',
    },

    group227: {
        finds: '',
        classification: '',
        comments: '',
    },

    group228: {
        finds: '',
        classification: '',
        comments: '',
    },

    group229: {
        finds: '',
        classification: '',
        comments: '',
    },

    group230: {
        finds: '',
        classification: '',
        comments: '',
    },

    group231: {
        finds: '',
        classification: '',
        comments: '',
    },

    group232: {
        finds: '',
        classification: '',
        comments: '',
    },

    group233: {
        finds: '',
        classification: '',
        comments: '',
    },

    group234: {
        finds: '',
        classification: 'V',
        comments: '',
    },

    group235: {
        finds: '',
        classification: '',
        comments: '',
    },

    group236: {
        finds: '',
        classification: '',
        comments: '',
    },

    group237: {
        finds: '',
        classification: '',
        comments: '',
    },

    group238: {
        finds: '',
        classification: '',
        comments: '',
    },

    group239: {
        finds: '',
        classification: '',
        comments: '',
    },

    group240: {
        finds: '',
        classification: '',
        comments: '',
    },

    group241: {
        finds: '',
        classification: '',
        comments: '',
    },

    group242: {
        finds: '',
        classification: '',
        comments: '',
    },

    group243: {
        finds: '',
        classification: '',
        comments: '',
    },

    group244: {
        finds: '',
        classification: '',
        comments: '',
    },

    group245: {
        finds: '',
        classification: 'A',
        comments: '',
    },

    group246: {
        finds: '',
        classification: 'V',
        comments: '',
    },
    group247: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group248: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group249: {
        finds: '',
        classification: 'V',
        comments: '',
    },
    group250: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group251: {
        finds: '',
        classification: '',
        comments: '',
    },
    group252: {
        finds: '',
        classification: '',
        comments: '',
    },
    group253: {
        finds: '',
        classification: '',
        comments: '',
    },
    group254: {
        finds: '',
        classification: '',
        comments: '',
    },
    group255: {
        finds: '',
        classification: '',
        comments: '',
    },
    group256: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group257: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group258: {
        finds: '',
        classification: '',
        comments: '',
    },
    group259: {
        finds: '',
        classification: '',
        comments: '',
    },
    group260: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group261: {
        finds: '',
        classification: 'A',
        comments: '',
    },
    group262: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group263: {
        finds: '',
        classification: 'N/A',
        comments: '',
    },
    group264: {
        finds: '',
        classification: '',
        comments: '',
    },
    group265: {
        finds: '',
        classification: '',
        comments: '',
    },
    group266: {
        finds: '',
        classification: '',
        comments: '',
    },
    group267: {
        finds: '',
        classification: '',
        comments: '',
    },
    group268: {
        finds: '',
        classification: '',
        comments: '',
    },
    group269: {
        finds: '',
        classification: '',
        comments: '',
    },
    group270: {
        finds: '',
        classification: '',
        comments: '',
    },
    group271: {
        finds: '',
        classification: '',
        comments: '',
    },
    group272: {
        finds: '',
        classification: '',
        comments: '',
    },
    group273: {
        finds: '',
        classification: '',
        comments: '',
    },
    group274: {
        finds: '',
        classification: '',
        comments: '',
    },

    group275: {
        finds: '',
        classification: '',
        comments: '',
    },



    image1: {
        finds: '',
        classification: '',
        comments: '',
    },
    image2: {
        finds: '',
        classification: '',
        comments: '',
    },
    image3: {
        finds: '',
        classification: '',
        comments: '',
    },
    image4: {
        finds: '',
        classification: '',
        comments: '',
    },
    image5: {
        finds: '',
        classification: '',
        comments: '',
    },
    image6: {
        finds: '',
        classification: '',
        comments: '',
    },
    image7: {
        finds: '',
        classification: '',
        comments: '',
    },
    image8: {
        finds: '',
        classification: '',
        comments: '',
    },
    image9: {
        finds: '',
        classification: '',
        comments: '',
    },
    image10: {
        finds: '',
        classification: '',
        comments: '',
    },
    image11: {
        finds: '',
        classification: '',
        comments: '',
    },
    image12: {
        finds: '',
        classification: '',
        comments: '',
    },






};
