import React, {  useEffect } from 'react';



const FileUpload = ({ setFile, name}) => {
    const  onChange = (e) => {
        setFile(e.target.files[0]);
    };



    useEffect(() => {});

    return (
        <div className='button'>
            <input
                type='file'
                style={{color : 'black'}}
                className='custom-file-input'
                onChange={onChange}
                name={name}
                id={name}
            />
        </div>
    );
};

export default FileUpload;
