import React, { useState, useEffect,useRef  } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Loader from '../../../Loader/Loader';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../../sass/pages/date-picker.css';
import { ToastContainer, toast } from 'react-toastify';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/he';
import SellerDropdown from '../../Custom/SellerDropdown/SellerDropdown';
import api from '../../../../apis/api';
import { fakeValues } from './Report_Type_2_static-data';
import { storage } from '../../../firebase/index';
import FileUpload from '../../../FileUpload/FileUpload';
import { readAndCompressImage } from 'browser-image-resizer';

import pic2 from '../../../../images/pic2.png';
import pic12 from '../../../../images/pic12.png';
import pic13 from '../../../../images/pic13.png';
import pic14 from '../../../../images/pic14.png';
import pic15 from '../../../../images/pic15.png';
import pic16 from '../../../../images/pic16.png';
import pic17 from '../../../../images/pic17.png';
import pic18 from '../../../../images/pic18.png';
import pic19 from '../../../../images/pic19.png';
import pic20 from '../../../../images/pic20.png';


import logo3 from '../../../../images/logo_new.png';
import sig_asaf from  '../../../../images/sig_asaf.png';

import {add , multiply , divide , subtract, floor ,sum } from 'mathjs'
import CanvasDraw  from 'react-canvas-draw' ;
import {dataURIToBlob} from "browser-image-resizer/src/data_operations";




registerLocale('il', es);
const setWidth = (width) => ({ width: `${width}%` });



const Report_Type_2 = (props) => {

  const [currentReport, setCurrentReport] = useState(fakeValues);
  console.log(currentReport)
  const [oldReport, setoldReport] = useState(fakeValues);
  const { reportId } = props;

  const [isLoading, setIsLoading] = useState(false);


  localStorage.removeItem("Sig_Drawing" +"_" + currentReport._id)

  const canvasRef = useRef(0);


  const CanvasSave_init = (data) => {
    localStorage.setItem("Sig_Drawing" + "_" + data, canvasRef.current.getSaveData());
    const sig_name = "Sig_Drawing" + "_" + data
    const image_canvas = dataURIToBlob(canvasRef.current.getDataURL())
    handleUpload_sig(image_canvas, sig_name)
  }


  const CanvasSave = () => {
    localStorage.setItem("Sig_Drawing" + "_" + currentReport._id, canvasRef.current.getSaveData());
    const sig_name = "Sig_Drawing" + "_" + currentReport._id
    const image_canvas = dataURIToBlob(canvasRef.current.getDataURL())
    handleUpload_sig(image_canvas, sig_name)

  }

  const CanvasClear = () => {
    canvasRef.current.clear();
  }


  // images state
  const [Image1, setImage1] = useState(null);
  const [Image2, setImage2] = useState(null);
  const [Image3, setImage3] = useState(null);
  const [Image4, setImage4] = useState(null);
  const [Image5, setImage5] = useState(null);
  const [Image6, setImage6] = useState(null);
  const [Image7, setImage7] = useState(null);
  const [Image8, setImage8] = useState(null);
  const [Image9, setImage9] = useState(null);
  const [Image10, setImage10] = useState(null);
  const [Image11, setImage11] = useState(null);
  const [Image12, setImage12] = useState(null);





  const [pImage1, setPImage1] = useState(null);
  const [pImage2, setPImage2] = useState(null);
  const [pImage3, setPImage3] = useState(null);
  const [pImage4, setPImage4] = useState(null);
  const [pImage5, setPImage5] = useState(null);
  const [pImage6, setPImage6] = useState(null);
  const [pImage7, setPImage7] = useState(null);
  const [pImage8, setPImage8] = useState(null);
  const [pImage9, setPImage9] = useState(null);
  const [pImage10, setPImage10] = useState(null);
  const [pImage11, setPImage11] = useState(null);
  const [pImage12, setPImage12] = useState(null);
  const [pSigImage, setSigImage] = useState(null);



  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCurrentReport({ ...currentReport, [name]: value });
  };

  const image_config = {
    quality: 0.5,
    width : 100,
    height : 100,
    mimeType: 'image/jpeg',
    resize: true
  };







  const handleUpload = async (image, id, name) => {

    let resizedImage = await readAndCompressImage(image, image_config);

    const uploadTask = storage.ref(`images/${id}-${name}`).put(resizedImage);
    // const uploadTask = storage.ref(`images/${id}-${name}`)
    uploadTask.on(
        'state_changed',
        (snapshot) => {
          // const p = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
          // setProgress(p);
        },
        (error) => {
          console.log('Error_Image_Upload');
        },
        () => {

          storage
              .ref('images')
              .child(image.name)
              .getDownloadURL()
              .then((url) => {

              });
        }
    );
  };


  const handleUpload_sig = async (image,name) => {

    // let resizedImage = await readAndCompressImage(image, image_config);

    const uploadTask = storage.ref(`signatures/${name}`).put(image);
    // const uploadTask = storage.ref(`images/${id}-${name}`)
    uploadTask.on(
        'state_changed',
        (snapshot) => {
        },
        (error) => {
          console.log('Error_Upload_Signature');
        },
        () => {


        }
    );
  };


  const handleUpload_document = async (name) => {
    const  { data } = await api.get(`reports/${reportId}`);
    setoldReport(data)
    const blob = new Blob([JSON.stringify(data)],{type: "application/json"})
    const MyDocRef = storage.ref(`Documents/report_old_${name}.json`).put(blob)

    MyDocRef.on(
        'state_changed',
        (snapshot) => {
        },
        (error) => {
          console.log('Error_Upload_Old_Document');
        },
        () => {
        }
    );
  };



  const onGroupChangeClassification = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        classification: value,
      },
    });
  };

  const SumofRearSeats = () =>{
    let sum_of_rear_rows = 0
    for (let i=1; i < 31; i++){
      sum_of_rear_rows = sum(sum_of_rear_rows , (currentReport.table_row[i].rear_weight))
    }

    return sum_of_rear_rows;

  }




  const onGroupChangeComment = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        comments: value,
      },
    });
  };

  const onGroupChangeFinds = (e) => {
    const name = e.target.name;
    const value = e.target.value;


    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        finds: value,
      },
    });
  };

  const updateImages = async (id) => {
    if (Image1) {
      await handleUpload(Image1, id, 'image1');
    }
    if (Image2) {
      await handleUpload(Image2, id, 'image2');
    }
    if (Image3) {
      await handleUpload(Image3, id, 'image3');
    }
    if (Image4) {
      await handleUpload(Image4, id, 'image4');
    }
    if (Image5) {
      await handleUpload(Image5, id, 'image5');
    }
    if (Image6) {
      await handleUpload(Image6, id, 'image6');
    }
    if (Image7) {
      await handleUpload(Image7, id, 'image7');
    }
    if (Image8) {
      await handleUpload(Image8, id, 'image8');
    }
    if (Image9) {
      await handleUpload(Image9, id, 'image9');
    }
    if (Image10) {
      await handleUpload(Image10, id, 'image10');
    }
    if (Image11) {
      await handleUpload(Image11, id, 'image11');
    }
    if (Image12) {
      await handleUpload(Image12, id, 'image12');
    }


  };

  const onGroupChangeSectionD_3VIN = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        VIN: value,
      },
    });
  };

  const onGroupChangeSectionD_3Classification = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        SectionD_3classification: value,
      },
    });
  };

  const onGroupChangeSectionD_3comments = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        SectionD_3comments: value,
      },
    });
  };

  const onGroupChangeSectionD_3value_GVW = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        value_GVW: value,
      },
    });
  };

  const onResultTableRow_distance = (e) => {
    const name = e.target.name;
    const value = e.target.value
    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        distance: value,
      },
    });
  };



  const onResultTableRow_front_weight = (e) => {
    const name = e.target.name;
    const value = e.target.value
    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        front_weight: value,
      },
    });
  };

  const onResultTableRow_rear_weight = (e) => {
    const name = e.target.name;
    const value = e.target.value
    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        rear_weight: value,
      },
    });
  };

  const onResultTableRow_total_row_weight = (e) => {
    const name = e.target.name;
    const value = e.target.value
    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        total_row_weight: value,
      },
    });
  };

  const onResultTableRow_total_front_weight = (e) => {
    const name = e.target.name;
    const value = e.target.value
    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        total_front_weight: value,
      },
    });
  };

  const onResultTableRow_total_rear_weight = (e) => {
    const name = e.target.name;
    const value = e.target.value
    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        total_rear_weight: value,
      },
    });
  };




  const onGroupChangeSectionD_3value_GCW = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        value_GCW: value,
      },
    });
  };

  const onGroupChangeSectionD_3value_Axle_1 = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        value_Axle_1: value,
      },
    });
  };

  const onGroupChangeSectionD_3value_Axle_2 = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        value_Axle_2: value,
      },
    });
  };

  const onGroupChangeSectionD_3value_Axle_3 = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        value_Axle_3: value,
      },
    });
  };


  const onGroupChangeSectionD_3value_Axle_4 = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        value_Axle_4: value,
      },
    });
  };

  const onGroupChangeSectionD_3reg_GVW = (e) => {
    const name = e.target.name;
    const value = e.target.value;


    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        reg_GVW: value,
      },
    });
  };

  const onGroupChangeSectionD_3reg_GCW = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        reg_GCW: value,
      },
    });
  };



  const onGroupChangeSectionD_3reg_Axle_1 = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        reg_Axle_1: value,
      },
    });
  };

  const onGroupChangeSectionD_3reg_Axle_2 = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        reg_Axle_2: value,
      },
    });
  };

  const onGroupChangeSectionD_3reg_Axle_3 = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        reg_Axle_3: value,
      },
    });
  };



  const onGroupChangeSectionD_3reg_Axle_4 = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        reg_Axle_4: value,
      },
    });
  };


  const onGroupChangeWeights_N1 = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        N1: value,
      },
    });
  };

  const onGroupChangeWeights_N2 = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        N2: value,
      },
    });
  };

  const onGroupChangeWeights_N3 = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        N3: value,
      },
    });
  };

  const onGroupChangeWeights_N4 = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        N4: value,
      },
    });
  };

  const onGroupChangeWeights_KWr = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        KWr: value,
      },
    });
  };

  const onGroupChangeWeights_KWf = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        KWf: value,
      },
    });
  };

  const onGroupChangeWeights_total_allow = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        weight_total_allow: value,
      },
    });
  };

  const onGroupChangeWeights_KWtot = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        KWtot: value,
      },
    });
  };

  const onGroupChangeWeights_tank_x = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        tank_x: value,
      },
    });
  };

  const onGroupChangeWeights_tank_y = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        tank_y: value,
      },
    });
  };

  const onGroupChangeWeights_tank_z = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        tank_z: value,
      },
    });
  };



  const onGroupChangeWeights_FWr = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        FWr: value,
      },
    });
  };


  const onGroupChangeWeights_FWf = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        FWf: value,
      },
    });
  };

  const onGroupChangeWeights_tank_total= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        tank_total: value,
      },
    });
  };


  const onGroupChangeWeights_FV= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        FV: value,
      },
    });
  };

  const onGroupChangeWeights_FP= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        FP: value,
      },
    });
  };



  const onGroupChangeWeights_FWtot= (e) => {
    const name = e.target.name;
    const value = e.target.value;


    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        FWtot: value,
      },
    });
  };

  const onGroupChangeWeights_WB= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        WB: value,
      },
    });
  };

  const onGroupChangeWeights_Lf= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Lf: value,
      },
    });
  };

  const onGroupChangeWeights_VIN_front= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        VIN_front: value,
      },
    });
  };


  const onGroupChangeWeights_VIN_Back= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        VIN_Back: value,
      },
    });
  };

  const onGroupChangeWeights_PM= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        PM: value,
      },
    });
  };


  const onGroupChangeWeights_KW= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        KW: value,
      },
    });
  };


  const onGroupChangeWeights_GVWf= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        GVWf: value,
      },
    });
  };


  const onGroupChangeWeights_GVWr= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        GVWr: value,
      },
    });
  };


  const onGroupChangeWeights_GVWl_tot= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        GVWl_tot: value,
      },
    });
  };


  const onGroupChangeWeights_GVWr_tot= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        GVWr_tot: value,
      },
    });
  };


  const onGroupChangeWeights_GVW_accept= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        GVW_accept: value,
      },
    });
  };



  const onGroupChangeWeights_Seren_self_front= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Seren_self_front: value,
      },
    });
  };



  const onGroupChangeWeights_Seren_self_back= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Seren_self_back: value,
      },
    });
  };


  const onGroupChangeWeights_Seren_self_total= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Seren_self_total: value,
      },
    });
  };


  const onGroupChangeWeights_laugage_allow= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        laugage_allow: value,
      },
    });
  };



  const onGroupChangeWeights_laugage_a= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        laugage_a: value,
      },
    });
  };


  const onGroupChangeWeights_laugage_b= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        laugage_b: value,
      },
    });
  };


  const onGroupChangeWeights_laugage_c= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        laugage_b: value,
      },
    });
  };


  const onGroupChangeWeights_Seren_driver_front= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Seren_driver_front: value,
      },
    });
  };


  const onGroupChangeWeights_Seren_driver_back= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Seren_driver_back: value,
      },
    });
  };

  const onGroupChangeWeights_overload_WB= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        overload_WB: value,
      },
    });
  };



  const onGroupChangeWeights_Seren_driver_total= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Seren_driver_total: value,
      },
    });
  };

  const onGroupChangeWeights_Seren_total_front= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Seren_total_front: value,
      },
    });
  };

  const onGroupChangeWeights_Seren_total_back= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Seren_total_back: value,
      },
    });
  };

  const onGroupChangeWeights_Seren_total_all= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Seren_total_all: value,
      },
    });
  };

  const onGroupChangeWeights_Seren_total_front_accept= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Seren_total_front_accept: value,
      },
    });
  };

  const onGroupChangeWeights_Seren_total_back_accept= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Seren_total_back_accept: value,
      },
    });
  };

  const onGroupChangeWeights_Seren_total_all_accept= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Seren_total_all_accept: value,
      },
    });
  };

  const onGroupChangeWeights_DPW= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        DPW: value,
      },
    });
  };

  const onGroupChangeWeights_X1= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        X1: value,
      },
    });
  };

  const onGroupChangeWeights_X2= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        X2: value,
      },
    });
  };

  const onGroupChangeWeights_GVW= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        GVW: value,
      },
    });
  };

  const onGroupChangeDevices_Device= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Device: value,
      },
    });
  };

  const onGroupChangeDevices_Name= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Name: value,
      },
    });
  };

  const onGroupChangeDevices_Manufactor= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        Manufactor: value,
      },
    });
  };

  const onGroupChangeDevices_masad= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        masad: value,
      },
    });
  };

  const onGroupChangeDevices_safety= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        safety: value,
      },
    });
  };

  const onGroupChangeDevices_date= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        date: value,
      },
    });
  };

  const onGroupChangeDevices_identity= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        identity: value,
      },
    });
  };


  const onGroupChangeDevices_report_lab= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        report_lab: value,
      },
    });
  };

  const onGroupChangeDevices_identity_lab= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        identity_lab: value,
      },
    });
  };

  const onGroupChangeDevices_model= (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentReport({
      ...currentReport,
      [name]: {
        ...currentReport[name],
        model: value,
      },
    });
  };


  const [users, setUsers] = useState([]);

  const onSubmit = async (e) => {
    e.preventDefault();
    // const formData = new FormData();

    if (
        currentReport.testerName.toString() === '0' ||
        currentReport.testerName.toString().trim().length === 0
    ) {
      toast.error(' יש לבחור בודק טופס ');
      return;
    }
    try {
      setIsLoading(true);


      if (!currentReport._id) {
        console.log(currentReport)
        const { data } = await api.post('reports/save', currentReport);

        toast.success('👍  הדוח נוצר בהצלחה');
        updateImages(data.id);

        setCurrentReport(data);
        setoldReport(data);
        CanvasSave_init(data._id)
        setIsLoading(false);

      } else {

        const { data_updated } = await api.post('reports/update', {
          _id: currentReport._id,
          payload: currentReport,
        });

        updateImages(currentReport._id);
        setIsLoading(false);
        CanvasSave()
        toast.success('👍  הדוח עודכן בהצלחה');
      }
    } catch (err) {
      setIsLoading(false);
    }
  };


  const setReportDetails = async () => {
    const { data } = await api.get(`reports/${reportId}`);
    setCurrentReport(data)
    load_document(`Documents/report_old_${reportId}.json`)
  }


  const getImageDetails = async () => {

    const Image1Name =    `${reportId}-image1`;
    const Image2Name =    `${reportId}-image2`;
    const Image3Name =    `${reportId}-image3`;
    const Image4Name =    `${reportId}-image4`;
    const Image5Name =    `${reportId}-image5`;
    const Image6Name =    `${reportId}-image6`;
    const Image7Name =    `${reportId}-image7`;
    const Image8Name =    `${reportId}-image8`;
    const Image9Name =    `${reportId}-image9`;
    const Image10Name =    `${reportId}-image10`;
    const Image11Name =    `${reportId}-image11`;
    const Image12Name =    `${reportId}-image12`;
    const Sig =  `Sig_Drawing_${reportId}`;
    const image_testName = `${reportId}`;


    loadImages(Image1Name, 1);
    loadImages(Image2Name, 2);
    loadImages(Image3Name, 3);
    loadImages(Image4Name, 4);
    loadImages(Image5Name, 5);
    loadImages(Image6Name, 6);
    loadImages(Image7Name, 7);
    loadImages(Image8Name, 8);
    loadImages(Image9Name, 9);
    loadImages(Image10Name, 10);
    loadImages(Image11Name, 11);
    loadImages(Image12Name, 12);
    load_sig_Image(Sig);
    loadImages(Image12Name, 12);


  };

  useEffect(() => {
    const getUsers = async () => {
      try {
        const { data } = await api.get('users/');
        setUsers(data);
      } catch (err) {
        console.log(err);
      }
    };

    setReportDetails();
    getUsers();
    getImageDetails()

  }, []);





  useEffect(() => {
    console.log(currentReport.reportType)
  }, [currentReport]);


  const load_document = (name) => {

    storage
        .ref(`${name}`)
        .getDownloadURL()
        .then(onResolve, onReject);
    function onResolve(foundURL) {
      axios.get(foundURL).then(resp => {
        setoldReport(resp.data)
      })
    }
    function onReject(error) {

      handleUpload_document(reportId)

      storage
          .ref(`${name}`)
          .getDownloadURL()
          .then((url) => {
            axios.get(url).then(resp => {
              setoldReport(resp.data)
            })
          })
    }
  };


  const delete_old_report = () => {
    storage
        .ref(`Documents/report_old_${reportId}.json`)
        .delete()
        .then(() => {
          setoldReport(currentReport);
        });
  };


  const load_sig_Image = (name) => {
    storage
        .ref(`signatures/${name}`)
        .getDownloadURL()
        .then((url) => {
          setSigImage(url);
        });
  };

  const loadImages = (name, type) => {

    storage
        .ref(`images/${name}`)
        .getDownloadURL()
        .then((url) => {

          switch (type) {
            case 1: {

              setPImage1(url);
              break;
            }
            case 2: {

              setPImage2(url);
              break;
            }
            case 3: {

              setPImage3(url);
              break;
            }
            case 4: {

              setPImage4(url);
              break;
            }
            case 5: {

              setPImage5(url);
              break;
            }
            case 6: {

              setPImage6(url);
              break;
            }
            case 7: {

              setPImage7(url);
              break;
            }
            case 8: {

              setPImage8(url);
              break;
            }
            case 9: {

              setPImage9(url);
              break;
            }

            case 10: {

              setPImage10(url);
              break;
            }
            case 11: {

              setPImage11(url);
              break;
            }
            case 12: {

              setPImage12(url);
              break;
            }


            default: {
              break;
            }
          }
        });
  };



  return (
      <React.Fragment>

        <form  style={{width: '100%'}}
               onSubmit={onSubmit} >
          <div className='reports__container' style={{width: '100%'}}>
            <div>
              <button
                  onClick={delete_old_report}
              >
              </button>
            </div>

            <table className='reports__table'>

              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>

                <td>

                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 1 מתוך 29</b>
                    </tr>
                  </table>

                </td>
              </tr>

              <tr className='reports__table-header'>

                <td className='text-align-center font-0-8' style={setWidth(25)}>
                  <table border='0' className='table-no-border width-100'>

                    <tr>
                      <td className='text-align-center font-0-8'>
                        טופס בדיקה מס' :
                      </td>
                    </tr>
                    <tr>
                      <td className='text-align-center font-0-8'>
                        F-23M
                      </td>
                    </tr>
                  </table>
                  <table className='reports__table'>
                    <tr>
                      <td className='text-align-center font-0-8'>
                        עורך מסמך:
                        {` ${props.user.firstName} ${props.user.lastName}`}
                      </td>
                    </tr>
                  </table>
                </td>

                <td className='text-align-center' style={setWidth(50)} colSpan='2'>
                  שם הטופס: בדיקה פרטנית לרכבי היסעים מקטגוריה - M2,M3  בהתאם ל- ‏‏EU2018/858 ANNEX II
                  או EC2007/46 ANNEX VI
                </td>
              </tr>

            </table>

            {currentReport.isApproved > 0 &&
            <div className='reports__container' style={{maxwidth: '40em'}}>
              <table className='reports__table'>
                <tr className='reports__table-header'>
                  <td style={setWidth(20)}>&nbsp;
                    <h2 className='text-align-center font-weight-700'>
                      <div className='table-no-border'>
                        <td >  תאריך :{moment().format('DD-MM-YY')}</td>
                      </div>
                    </h2>
                  </td>
                  <td
                      style={setWidth(20)}
                      className=' text-align-center font-weight-700'
                  >
                    שם מאשר הטופס:  {` ${props.user.firstName} ${props.user.lastName}`}
                  </td>
                  <td className='text-align-center font-weight-700'>
                    חתימה :
                    <img src={sig_asaf} alt={''} style={{width: '10%'}}/>
                  </td>
                </tr>
              </table>
            </div>
            }






            <h1 className='mt-5 flex justify-content-center gap-1'>
              <p>בדיקה מספר</p>

              <input
                  type='text'
                  name='testId'
                  className='w-200'
                  value={currentReport.testId}
                  onChange={onChange}
              />
            </h1>

            <h2 className='text-underline mt-5'><u>א.כללי</u></h2>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{ maxWidth: '80rem' }}
              >
                <tr>
                  <td>דוח הבדיקה מס'</td>
                  <td>

                    <input className='reports_text'
                           type='text'
                           name='testReportNumber'
                           value={currentReport.testReportNumber=currentReport.testId}
                           onChange={onChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>שם מזמין הבדיקה</td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='ordereeName'
                           value={currentReport.ordereeName}
                           onChange={onChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>מענו</td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='ordereeAddress'
                           value={currentReport.ordereeAddress}
                           onChange={onChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>הזמנה מס'</td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='orderId'
                           value={currentReport.orderId}
                           onChange={onChange}
                    />
                  </td>
                </tr>


                <tr>
                  <td>רכב מקטגוריות  - </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           list='Category_Vehicle_list'
                           name='Category_Vehicle'
                           value={currentReport.Category_Vehicle}
                           onChange={onChange}
                    />
                    <datalist id="Category_Vehicle_list">
                      <option value="M2"> M2</option>
                      <option value="M3"> M3</option>
                    </datalist>
                  </td>
                </tr>

                <tr>
                  <td>רישום ראשוני/שינוי מבנה</td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           list='header_option_list'
                           name='header_option'
                           value={currentReport.header_option}
                           onChange={onChange}
                    />
                    <datalist id="header_option_list">
                      <option value="רישום ראשוני"> רישום ראשוני</option>
                      <option value="שינוי מבנה"> שינוי מבנה</option>
                    </datalist>
                  </td>
                </tr>


                <tr>
                  <td>תאריך ההזמנה</td>
                  <td >

                    <DatePicker
                        className='reports_text'
                        dateFormat="dd/MM/yyyy"
                        locale='he'
                        name='orderDate'
                        selected={new Date(currentReport.orderDate)}
                        onChange={(date) => {
                          setCurrentReport({
                            ...currentReport,
                            orderDate: date,
                          });
                        }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>תאריך ביצוע בדיקת מסמכים מקדימה</td>
                  <td>

                    <DatePicker className='reports_text'
                                dateFormat="dd/MM/yyyy"
                                locale='he'
                                name='frontDocumentTest'
                                selected={new Date(currentReport.frontDocumentTest)}
                                onChange={(date) => {
                                  setCurrentReport({
                                    ...currentReport,
                                    frontDocumentTest: date,
                                  });
                                }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>תאריך ביצוע הבדיקה בפועל</td>
                  <td>

                    <DatePicker className='reports_text'
                                name='realOrderDate'
                                dateFormat="dd/MM/yyyy"
                                locale='he'
                                selected={new Date(currentReport.realOrderDate)}
                                onChange={(date) => {
                                  setCurrentReport({
                                    ...currentReport,
                                    realOrderDate: date,
                                  });
                                }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>שם הבודק</td>
                  <td>
                    <SellerDropdown className='reports_text'
                                    value={currentReport.testerName}
                                    onChange={onChange}
                                    users={users.filter((item) => item.role.type === 'בודק')}
                                    name='testerName'
                                    defaultText='-- בחר בודק --'
                    />
                  </td>
                </tr>
              </table>
            </div>

            <div className='wrapper mt-6'>
              <h2><h2 className='text-underline mt-5'><u>ב . ביצוע הבדיקה בשטח - הצהרת מבצע הבדיקה:
              </u></h2></h2>
            </div>
            <div className='flex justify-content-center align-items-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{ maxWidth: '120rem' }}
              >
                <tbody>
                <tr>
                  <td colSpan='2'>
                    <input className='reports_text'
                           type='checkbox'
                           style={{ marginLeft: '1rem' }}
                           checked={currentReport.approveInterst}
                           onChange={(e) => {
                             setCurrentReport({
                               ...currentReport,
                               approveInterst: e.target.checked,
                             });
                           }}
                    />
                    <label>
                      אני מצהיר כי אין לי כל מצב של<b> ניגוד עניינים </b> ומשוא פנים מול
                      מזמין הבדיקה ו/או הלקוח.
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    שם מלא:{' '}
                    <input className='reports_text'
                           type='text'
                           list ='fullname_list'
                           style={{ margin: '0 2rem' }}
                           name='fullName'
                           onChange={onChange}
                           value={currentReport.fullName }

                    />
                    <datalist id="fullname_list">
                      <option value="ליעד ברודו">ליעד ברודו</option>
                      <option value="איציק הילריו">איציק הילריו</option>
                      <option value="פיראס חלבי">פיראס חלבי</option>
                      <option value="שריף שגזי">שריף שגזי</option>
                    </datalist>

                    חתימת הבודק:
                    <div>
                      {pSigImage ? (
                          <img
                              src={pSigImage}
                              alt='אין חתימה'
                              style={{width: '100px', height: '100px'}}
                          />
                      ) : (
                          'אין חתימה'
                      )}
                    </div>

                  </td>
                </tr>
                </tbody>
              </table>
            </div>


            <div className='flex justify-content-center align-items-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{ maxWidth: '120rem' }}
              >
                <tbody>
                <tr>
                  <td colSpan='2'>
                    <input className='reports_text'
                           type='checkbox'
                           style={{ marginLeft: '1rem' }}
                           checked={currentReport.approveInterst}
                           onChange={(e) => {
                             setCurrentReport({
                               ...currentReport,
                               approveInterst: e.target.checked,
                             });
                           }}
                    />
                    <label>
                      אני מצהיר כי בוצעה<b> ביקורת קבלה</b> ו הרכב תועד חיצונית/פנימית טרם ביצוע הבדיקה .
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    שם מלא:{' '}
                    <input className='reports_text'
                           type='text'
                           list ='fullname_list'
                           style={{ margin: '0 2rem' }}
                           name='fullName'
                           onChange={onChange}
                           value={currentReport.fullName}

                    />
                    <datalist id="fullname_list">
                      <option value="ליעד ברודו">ליעד ברודו</option>
                      <option value="איציק הילריו">איציק הילריו</option>
                      <option value="פיראס חלבי">פיראס חלבי</option>
                      <option value="שריף שגזי">שריף שגזי</option>
                    </datalist>
                    חתימת הבודק:

                    <div>
                      {pSigImage ? (
                          <img
                              src={pSigImage}
                              alt='אין חתימה'
                              style={{width: '100px', height: '100px'}}
                          />
                      ) : (
                          'אין חתימה'
                      )}
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>



            <div style={{padding: 30}}></div>
            <h2 className='text-underline mt-5'><u>משטח חתימה
            </u></h2>
            <div className='flex justify-content-right align-items-right'>
              <table
                  className='reports__table reports__table--dark'
                  style={{ maxWidth: '40rem' }}
              >
                <tr>
                  <td>
                    <CanvasDraw
                        ref={canvasRef}
                        brushRadius={1}
                        brushColor={"#0720bb"}
                        canvasWidth={430}
                        canvasHeight={300}
                        lazyRadius={3}
                        hideGrid ={false}
                        saveData={localStorage.getItem("Sig_Drawing" +"_" + currentReport._id)}
                    />

                  </td>
                </tr>
              </table>
            </div>

            <button
                className='button bg-secondary'
                onClick={CanvasClear}

            >
              נקה
            </button>





          </div>

          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 2 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <h2 className='mt-3 text-align-right'>
              <u>  ג . ביצוע בדיקה בשטח - בדיקת התאמה בין המסמכים שהוצגו לבין כלי הרכב הנבדק :</u>

            </h2>


            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <td className='mt-3 text-align-center' style={{background: "lightgrey"}}>מס"ד</td>
                  <td className='mt-3 text-align-center' style={{width: '35%',background: "lightgrey"}}>דרישה</td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}></td>
                  <td className='mt-3 text-align-center' style={{background: "lightgrey"}}>הערות</td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    1
                  </td>
                  <td>
                    בדוק האם מס' השלדה של
                    <b>שלדת הרכב</b>
                    מתאים למס'
                    השלדה המפורט ב - COC וב - WVTA שהתקבלו
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group1'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group1.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group1'
                               value='0'

                               onChange={onGroupChangeClassification}
                               checked={currentReport.group1.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group1'
                           onChange={onGroupChangeComment}
                           value={currentReport.group1.comments}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    2
                  </td>
                  <td>
                    וודא מיקום תווית סטטוטורי ת והטבעת מס' שלדה
                    בהתאם למפורט ב - RR
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group2'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group2.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group2'
                               value='0'

                               onChange={onGroupChangeClassification}
                               checked={currentReport.group2.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group2'
                           onChange={onGroupChangeComment}
                           value={currentReport.group2.comments}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    3
                  </td>
                  <td>
                    בדוק האם ה VIN של הרכב הנבדק , הרכב השלם, לרבות
                    שנת ייצור ו תואם ל - WVTA שהתקב ל . ראו ביאור VIN
                    בהוראת הרישום.
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group3'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group3.classification === '1'}
                        />
                        <label htmlFor=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group3'
                               value='0'

                               onChange={onGroupChangeClassification}
                               checked={currentReport.group3.classification === '0'}
                        />
                        <label htmlFor=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group3'
                           onChange={onGroupChangeComment}
                           value={currentReport.group3.comments}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    4
                  </td>
                  <td className='mt-3 text-align-right'>
                    <div>
                      מס' VIN:
                      <div className='reports_text2'>
                        {oldReport.SectionD_3.VIN}
                      </div>
                      <input className='reports_text' type='text'
                             name='SectionD_3'
                             onChange={onGroupChangeSectionD_3VIN}
                             value={currentReport.SectionD_3.VIN}
                      />

                    </div>

                    <table
                        className='reports__table reports__table--dark'
                        style={{maxWidth: '40rem'}}
                    >
                      <tr>
                        <td className='mt-3 text-align-center'>
                          בהתאם לתקנות התעבורה בק"ג -IL
                        </td>
                        <td className='mt-3 text-align-center'>
                          משמעות
                        </td>
                        <td className='mt-3 text-align-center'>
                          ערך ב-ק"ג
                        </td>
                        <td className='mt-3 text-align-center'>
                          בהתאם ל-VIN
                        </td>
                      </tr>
                      <tr>
                        <td className='mt-3 text-align-center'>
                          <div className='reports_text2'>
                            {oldReport.SectionD_3.reg_GVW}
                          </div>
                          <input className='reports_text' type='text'
                                 name='SectionD_3'
                                 onChange={onGroupChangeSectionD_3reg_GVW}
                                 value={currentReport.SectionD_3.reg_GVW}
                          />
                        </td>
                        <td className='mt-3 text-align-center'>
                          משקל כולל מותר
                        </td>
                        <td className='mt-3 text-align-center'>
                          <div className='reports_text2'>
                            {oldReport.SectionD_3.value_GVW}
                          </div>
                          <input className='reports_text' type='text'
                                 name='SectionD_3'
                                 onChange={onGroupChangeSectionD_3value_GVW}
                                 value={currentReport.SectionD_3.value_GVW}

                          />
                        </td>
                        <td className='mt-3 text-align-center'>
                          GVW
                        </td>
                      </tr>
                      <tr>
                        <td className='mt-3 text-align-center'>
                          <div className='reports_text2'>
                            {oldReport.SectionD_3.reg_Axle_1}
                          </div>
                          <input className='reports_text' type='text'
                                 name='SectionD_3'
                                 onChange={onGroupChangeSectionD_3reg_Axle_1}
                                 value={currentReport.SectionD_3.reg_Axle_1}
                          />
                        </td>
                        <td className='mt-3 text-align-center'>
                          עומס טכני סרן 1/2/3/4 / עומס מורשה- ת"ת
                        </td>
                        <td className='mt-3 text-align-center'>
                          <div className='reports_text2'>
                            {oldReport.SectionD_3.value_Axle_1}
                          </div>
                          <input className='reports_text' type='text'
                                 name='SectionD_3'
                                 onChange={onGroupChangeSectionD_3value_Axle_1}
                                 value={currentReport.SectionD_3.value_Axle_1}
                          />
                        </td>
                        <td className='mt-3 text-align-center'>
                          Axle 1
                        </td>
                      </tr>
                      <tr>
                        <td className='mt-3 text-align-center'>
                          <div className='reports_text2'>
                            {oldReport.SectionD_3.reg_Axle_2}
                          </div>
                          <input className='reports_text' type='text'
                                 name='SectionD_3'
                                 onChange={onGroupChangeSectionD_3reg_Axle_2}
                                 value={currentReport.SectionD_3.reg_Axle_2}
                          />
                        </td>
                        <td className='mt-3 text-align-center'>
                          עומס טכני סרן 1/2/3/4 / עומס מורשה- ת"ת
                        </td>
                        <td className='mt-3 text-align-center'>
                          <div className='reports_text2'>
                            {oldReport.SectionD_3.value_Axle_2}
                          </div>
                          <input className='reports_text' type='text'
                                 name='SectionD_3'
                                 onChange={onGroupChangeSectionD_3value_Axle_2}
                                 value={currentReport.SectionD_3.value_Axle_2}

                          />
                        </td>
                        <td className='mt-3 text-align-center'>
                          Axle 2
                        </td>
                      </tr>
                      <tr>
                        <td className='mt-3 text-align-center'>
                          <div className='reports_text2'>
                            {oldReport.SectionD_3.reg_Axle_3}
                          </div>
                          <input className='reports_text' type='text'
                                 name='SectionD_3'
                                 onChange={onGroupChangeSectionD_3reg_Axle_3}
                                 value={currentReport.SectionD_3.reg_Axle_3}
                          />
                        </td>
                        <td className='mt-3 text-align-center'>
                          עומס טכני סרן 1/2/3/4 / עומס מורשה- ת"ת
                        </td>
                        <td className='mt-3 text-align-center'>
                          <div className='reports_text2'>
                            {oldReport.SectionD_3.value_Axle_3}
                          </div>
                          <input className='reports_text' type='text'
                                 name='SectionD_3'
                                 onChange={onGroupChangeSectionD_3value_Axle_3}
                                 value={currentReport.SectionD_3.value_Axle_3}

                          />
                        </td>
                        <td className='mt-3 text-align-center'>
                          Axle 3
                        </td>
                      </tr>
                      <tr>
                        <td className='mt-3 text-align-center'>
                          <div className='reports_text2'>
                            {oldReport.SectionD_3.reg_Axle_4}
                          </div>
                          <input className='reports_text' type='text'
                                 name='SectionD_3'
                                 onChange={onGroupChangeSectionD_3reg_Axle_4}
                                 value={currentReport.SectionD_3.reg_Axle_4}
                          />
                        </td>
                        <td className='mt-3 text-align-center'>
                          עומס טכני סרן 1/2/3/4 / עומס מורשה- ת"ת
                        </td>
                        <td className='mt-3 text-align-center'>
                          <div className='reports_text2'>
                            {oldReport.SectionD_3.value_Axle_4}
                          </div>
                          <input className='reports_text' type='text'
                                 name='SectionD_3'
                                 onChange={onGroupChangeSectionD_3value_Axle_4}
                                 value={currentReport.SectionD_3.value_Axle_4}

                          />
                        </td>
                        <td className='mt-3 text-align-center'>
                          Axle 4
                        </td>
                      </tr>
                    </table>


                  </td>

                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='SectionD_3'
                               value='1'
                               onChange={onGroupChangeSectionD_3Classification}
                               checked={currentReport.SectionD_3.SectionD_3classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='SectionD_3'
                               value='0'

                               onChange={onGroupChangeSectionD_3Classification}
                               checked={currentReport.SectionD_3.SectionD_3classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='SectionD_3'
                           onChange={onGroupChangeSectionD_3comments}
                           value={currentReport.SectionD_3.SectionD_3comments}
                    />
                  </td>


                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    5
                  </td>
                  <td>
                    בדוק אילו מכלולים/מערכות אושרו להתקנה ב מרכב
                    ה רכב ומפורטים ב - WVTA ,לרבות מע' מיזוג/ וידאו/
                    סטריאו/ מקרר/ תקשורת אלחוטית/מסכי טלויזיה ועוד
                    ובצע השוואה למערכות/מכלולים אשר מורכבים בפועל
                    בתא הנהג והנוסעים.
                    <b>האם קיימת זהות מוחלטת ?</b>
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group4'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group4.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group4'
                               value='0'

                               onChange={onGroupChangeClassification}
                               checked={currentReport.group4.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group4'
                           list='group4_list'
                           onChange={onGroupChangeComment}
                           value={currentReport.group4.comments}
                    />
                    <datalist id="group4_list">
                      <option value="N/A"> N/A</option>
                    </datalist>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>

                  </td>

                  <b>
                    סיכום ממצאים לבדיקת התאמה בין המסמכים שהוצגו לבין כלי הרכב הנבדק:
                  </b>

                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>

                  </td>
                  <td>
                    רק במידה ונמצא כי קיימת זהות בין המסמכים שהוצגו ע"י מזמין הבדיקה למכלולים שנמצאו מורכבים ברכב ניתן
                    לבצע המשך בדיקה כמפורט בהמשך טופס זה.
                  </td>
                  <td>
                    <div className='flex flex gap-3 checkbox-group justify-content-center'>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group5'
                               value='1'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group5.classification === '1'}
                        />
                        <label for=''>כן</label>
                      </div>
                      <div className='flex align-items-center gap-04'>
                        <input className='reports_text'
                               type='radio'
                               name='group5'
                               value='0'
                               onChange={onGroupChangeClassification}
                               checked={currentReport.group5.classification === '0'}
                        />
                        <label for=''>לא</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group5'
                           onChange={onGroupChangeComment}
                           value={currentReport.group5.comments}
                    />
                  </td>
                </tr>

              </table>
            </div>
          </div>

          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>

            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 3 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>



            <h2 className='mt-3 text-align-right'>
              ד. פרטי כלי הרכב
            </h2>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <td className='mt-3 text-align-center' style={{background: "lightgrey"}}>מס"ד</td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}>דרישה</td>
                  <td className='mt-3 text-align-center' style={{width: '40%',background: "lightgrey"}}>ממצאים</td>
                  <td className='mt-3 text-align-center' style={{background: "lightgrey"}}>הערות</td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    1.1
                  </td>
                  <td className='mt-3 text-align-center'>
                    מספר רישוי
                  </td>

                  <td>

                    <div class='reports_text2' >
                      {oldReport.group11.finds}
                    </div>

                    <input className='reports_text'
                           type='text'
                           name='group11'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group11.finds}
                    />
                  </td>

                  <td>

                    <input className='reports_text' type='text'
                           name='group11'
                           onChange={onGroupChangeComment}
                           value={currentReport.group11.comments}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    1.2
                  </td>
                  <td className='mt-3 text-align-center'>
                    מספר שלדה
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group12.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group12'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group12.finds=currentReport.SectionD_3.VIN}
                    />
                  </td>

                  <td>
                    <input className='reports_text' type='text'
                           name='group12'
                           onChange={onGroupChangeComment}
                           value={currentReport.group12.comments}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    1.3
                  </td>
                  <td className='mt-3 text-align-center'>
                    תוצר
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group13.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group13'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group13.finds}
                    />
                  </td>

                  <td>

                    <input className='reports_text' type='text'
                           name='group13'
                           onChange={onGroupChangeComment}
                           value={currentReport.group13.comments}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    1.4
                  </td>
                  <td className='mt-3 text-align-center'>
                    קוד דגם
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group14.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group14'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group14.finds}
                    />
                  </td>

                  <td>
                    <input className='reports_text' type='text'
                           name='group14'
                           onChange={onGroupChangeComment}
                           value={currentReport.group14.comments}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    1.5
                  </td>
                  <td className='mt-3 text-align-center'>
                    קטגוריה
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group15.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group15'
                           list='group15_list'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group15.finds}
                    />
                    <datalist id="group15_list">
                      <option value="M1">M1</option>
                      <option value="M2">M2</option>
                      <option value="M3">M3</option>
                    </datalist>
                  </td>

                  <td>
                    <input className='reports_text' type='text'
                           name='group15'
                           onChange={onGroupChangeComment}
                           value={currentReport.group15.comments}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    1.6
                  </td>
                  <td className='mt-3 text-align-center'>
                    הוראת רישום
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group16.finds}
                    </div>
                    <input className='reports_text'
                           type='text'

                           name='group16'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group16.finds}
                    />



                  </td>

                  <td>
                    <input className='reports_text' type='text'
                           name='group16'
                           onChange={onGroupChangeComment}
                           value={currentReport.group16.comments}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    1.7
                  </td>
                  <td className='mt-3 text-align-center'>
                    מס' תיעוד WVTA של שלדת הרכב:
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group17.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           list='group17_list'
                           name='group17'
                           dir='LTR'
                           lang='EN'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group17.finds}
                    />
                    <datalist id="group17_list">
                      <option value="e*2007/46*">e*2007/46*</option>
                      <option value="e*2018/858*">e*2018/858*</option>

                    </datalist>
                  </td>

                  <td>
                    <input className='reports_text' type='text'
                           name='group17'
                           onChange={onGroupChangeComment}
                           value={currentReport.group17.comments}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    1.8
                  </td>
                  <td className='mt-3 text-align-center'>
                    סווג האוטובוס
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group18.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group18'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group18.finds}
                    />

                  </td>

                  <td>
                    <input className='reports_text' type='text'
                           name='group18'
                           onChange={onGroupChangeComment}
                           value={currentReport.group18.comments}
                    />
                  </td>
                </tr>




                <tr>
                  <td className='mt-3 text-align-center'>
                    1.9
                  </td>
                  <td className='mt-3 text-align-center'>
                    צבע
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group19.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           list='group19_list'
                           name='group19'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group19.finds}
                    />
                    <datalist id="group19_list">
                      <option value="לבן">לבן</option>
                      <option value="שחור">שחור</option>
                      <option value="אדום">אדום</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group19'
                           onChange={onGroupChangeComment}
                           value={currentReport.group19.comments}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    1.10
                  </td>
                  <td className='mt-3 text-align-center'>
                    סוג הדלק
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group20.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group20'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group20.finds}/>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group20'
                           onChange={onGroupChangeComment}
                           value={currentReport.group20.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    1.11
                  </td>
                  <td className='mt-3 text-align-center'>
                    מס' גלגלים
                  </td>
                  <td>
                    <td>
                      <div className='reports_text2'>
                        {oldReport.group21.finds}
                      </div>
                      <input className='reports_text'
                             type='number'
                             name='group21'
                             onChange={onGroupChangeFinds}
                             value={currentReport.group21.finds}/>
                    </td>
                    <td>
                      מס' סרנים
                    </td>
                    <td>
                      <div className='reports_text2'>
                        {oldReport.group22.finds}
                      </div>
                      <input className='reports_text'
                             type='number'
                             name='group22'
                             onChange={onGroupChangeFinds}
                             value={currentReport.group22.finds}/>
                    </td>
                  </td>


                  <td>
                    <input className='reports_text' type='text'
                           name='group22'
                           onChange={onGroupChangeComment}
                           value={currentReport.group22.comments}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    1.12
                  </td>
                  <td className='mt-3 text-align-center'>
                    דגם מנוע
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group23.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group23'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group23.finds}/>
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group23'
                           onChange={onGroupChangeComment}
                           value={currentReport.group23.comments}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    1.13
                  </td>
                  <td className='mt-3 text-align-center'>
                    מס' מנוע
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group24.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group24'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group24.finds}/>
                  </td>

                  <td>
                    <input className='reports_text' type='text'
                           name='group24'
                           onChange={onGroupChangeComment}
                           value={currentReport.group24.comments}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    1.14
                  </td>
                  <td>
                    <td className='mt-3 text-align-center'>
                      מידת צמיגים כולל קוד עומס/מהירות
                    </td>
                    <td>
                      קדמיים:
                    </td>
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group25.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group25'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group25.finds}
                    />
                  </td>

                  <td>
                    <input className='reports_text' type='text'
                           name='group25'
                           onChange={onGroupChangeComment}
                           value={currentReport.group25.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    1.15
                  </td>
                  <td>
                    <td className='mt-3 text-align-center'>
                      מידת צמיגים כולל קוד עומס/מהירות
                    </td>
                    <td className='mt-3 text-align-center'>
                      אחוריים:
                    </td>
                  </td>

                  <td>
                    <div className='reports_text2'>
                      {oldReport.group26.finds}
                    </div>

                    <input className='reports_text'
                           type='text'
                           name='group26'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group26.finds}/>
                  </td>

                  <td>
                    <input className='reports_text' type='text'
                           name='group26'
                           onChange={onGroupChangeComment}
                           value={currentReport.group26.comments}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    1.16
                  </td>
                  <td className='mt-3 text-align-center'>
                    הנעה
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group27.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group27'
                           list='group27_list'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group27.finds}/>

                    <datalist id="group27_list">
                      <option value="4x2">2x4</option>
                      <option value="4x4">2x6</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group27'
                           onChange={onGroupChangeComment}
                           value={currentReport.group27.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    1.17
                  </td>
                  <td className='mt-3 text-align-center'>
                    רוחק סרנים (מ"מ)
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group28.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group28'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group28.finds}/>
                  </td>
                  <td>
                    <input className='reports_text' type='text'
                           name='group28'
                           onChange={onGroupChangeComment}
                           value={currentReport.group28.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    1.18
                  </td>
                  <td className='mt-3 text-align-center'>
                    משקל כולל מותר (ק"ג)
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group29.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group29'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group29.finds=currentReport.SectionD_3.reg_GVW}/>
                  </td>

                  <td>
                    <input className='reports_text' type='text'
                           name='group29'
                           onChange={onGroupChangeComment}
                           value={currentReport.group29.comments}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    1.19
                  </td>
                  <td className='mt-3 text-align-center'>
                    מועד עליה לכביש
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group30.finds}
                    </div>
                    <input className='reports_text'

                           type='text'
                           name='group30'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group30.finds}/>
                  </td>

                  <td>
                    <input className='reports_text' type='text'
                           name='group30'
                           onChange={onGroupChangeComment}
                           value={currentReport.group30.comments}
                    />
                  </td>
                </tr>

              </table>
            </div>



            <h2 className='mt-3 text-align-right'>
              ה. פרטי מרכב הרכב
            </h2>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <td className='mt-3 text-align-center' style={{background: "lightgrey"}}>מס"ד</td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}>דרישה</td>
                  <td className='mt-3 text-align-center' style={{width: '40%',background: "lightgrey"}}>ממצאים</td>
                  <td className='mt-3 text-align-center' style={{background: "lightgrey"}}>הערות</td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    2.1
                  </td>
                  <td className='mt-3 text-align-center'>
                    יצרן
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group36.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group36'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group36.finds}/>
                  </td>

                  <td>
                    <input className='reports_text' type='text'
                           name='group36'
                           onChange={onGroupChangeComment}
                           value={currentReport.group36.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    2.2
                  </td>
                  <td className='mt-3 text-align-center'>
                    תוקף ומס' יצרן (מקומי)
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group37.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group37'
                           list='group37_list'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group37.finds}/>
                  </td>
                  <datalist id="group37_list">
                    <option value="31/12/21"> 31/12/21</option>
                    <option value="31/12/22"> 31/12/22</option>
                    <option value="31/12/23"> 31/12/23</option>
                    <option value="31/12/24"> 31/12/24</option>
                    <option value="31/12/25"> 31/12/25</option>
                  </datalist>
                  <td>
                    <input className='reports_text' type='text'
                           name='group37'
                           onChange={onGroupChangeComment}
                           value={currentReport.group37.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    2.3
                  </td>
                  <td className='mt-3 text-align-center'>
                    מס' WVTA של הרכב המושלם
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group38.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group38'
                           list='group38_list'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group38.finds}/>

                    <datalist id="group38_list">
                      <option value="e*2007/46*">e*2007/46*</option>
                      <option value="e*2018/858*">e*2018/858*</option>
                    </datalist>

                  </td>

                  <td>
                    <input className='reports_text' type='text'
                           name='group38'
                           onChange={onGroupChangeComment}
                           value={currentReport.group38.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    2.4
                  </td>
                  <td className='mt-3 text-align-center'>
                    מס' תעודת אב טיפוס
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group39.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group39'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group39.finds}/>
                  </td>

                  <td>
                    <input className='reports_text' type='text'
                           name='group39'
                           onChange={onGroupChangeComment}
                           value={currentReport.group39.comments}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    2.5
                  </td>
                  <td className='mt-3 text-align-center'>
                    תוקף תעודת אב טיפוס
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group40.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group40'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group40.finds}/>
                  </td>


                  <td>
                    <input className='reports_text' type='text'
                           name='group40'
                           onChange={onGroupChangeComment}
                           value={currentReport.group40.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    2.6
                  </td>
                  <td className='mt-3 text-align-center'>
                    זהות מעבדה
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group41.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group41'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group41.finds}/>
                  </td>

                  <td>
                    <input className='reports_text' type='text'
                           name='group41'
                           onChange={onGroupChangeComment}
                           value={currentReport.group41.comments}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    2.7
                  </td>
                  <td className='mt-3 text-align-center'>
                    הוראת רישום לאוטובוס
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group42.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group42'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group42.finds}/>

                  </td>

                  <td>
                    <input className='reports_text' type='text'
                           name='group42'
                           onChange={onGroupChangeComment}
                           value={currentReport.group42.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    2.8
                  </td>
                  <td className='mt-3 text-align-center'>
                    מס' נוסעים מאחור
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group43.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group43'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group43.finds}/>
                  </td>

                  <td>
                    <input className='reports_text' type='text'
                           name='group43'
                           onChange={onGroupChangeComment}
                           value={currentReport.group43.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    2.9
                  </td>
                  <td className='mt-3 text-align-center'>
                    מס' נוסעים ליד הנהג
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group44.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group44'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group44.finds}/>
                  </td>

                  <td>
                    <input className='reports_text' type='text'
                           name='group44'
                           onChange={onGroupChangeComment}
                           value={currentReport.group44.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    2.10
                  </td>
                  <td className='mt-3 text-align-center'>
                    מס' אישור התקנה
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group45.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group45'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group45.finds}/>
                  </td>

                  <td>
                    <input className='reports_text' type='text'
                           name='group45'
                           onChange={onGroupChangeComment}
                           value={currentReport.group45.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    2.11
                  </td>
                  <td className='mt-3 text-align-center'>
                    תאריך ייצור
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group46.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group46'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group46.finds}/>
                  </td>

                  <td>
                    <input className='reports_text' type='text'
                           name='group46'
                           onChange={onGroupChangeComment}
                           value={currentReport.group46.comments}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    2.12
                  </td>
                  <td className='mt-3 text-align-center'>
                    תווית יצרן
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group47.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group47'
                           list='group47_list'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group47.finds}/>

                    <datalist id="group47_list">
                      <option value="מתאים"> מתאים</option>
                      <option value="לא מתאים">לא מתאים</option>
                    </datalist>
                  </td>


                  <td>
                    <input className='reports_text' type='text'
                           name='group47'
                           onChange={onGroupChangeComment}
                           value={currentReport.group47.comments}
                    />
                  </td>
                </tr>



              </table>
            </div>

          </div>

          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>

            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 4 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>





            <h2 className='text-underline mt-5'>
              ו. משקלים(ק"ג)
            </h2>
            <h2 className='mt-3 mb-3 text-align-right'>
              1 יש למדוד בעזרת פלס דיגיטלי את השיפוע של מישור עליו מתבצעת הבדיקה בשני כיוונים אורכי ורוחבי.
            </h2>
            <h2 className='mt-3 mb-3 text-align-right'>
              2 יש למקם את מדי משקל ואת משטחי הדמה כך, שיפצו על השיפוע של הכביש.
            </h2>
            <h2 className='mt-3 mb-3 text-align-right'>
              3 יש לעלות את הרכב עם הסרן הקדמי על גבי המשקלים והסרן האחורי יעמוד על משטחי הדמי.
            </h2>
            <h2 className='mt-3 text-align-right'>

              4 הבודק יכוון את הנהג , שמירת קשר עין רצופה הינה חובה.
            </h2>
            <h2 className='mt-3 text-align-right'>
              5 טרם קריאת המשקל יש לוודא שהנהג ירד מהרכב והוא ריק ממטען ואנשים.
            </h2>

            <h2 className='mt-3 text-align-right'>
              6 יש לוודא, בעזרת פלס דיגיטלי , שהרכב מפולס במישור אופקי והרוחבי, סטייה מקס' -°5.
            </h2>
            <h2 className='mt-3 text-align-right'>
              7 את הקריאות הנמדדות יש לתעד בסעיף המתאים בטבלאות 1  ו2.
            </h2>
            <h2 className='mt-3 text-align-right'>
              8 יש לרדת מהמשקלים וממשטחי הדמה.
            </h2>
            <h2 className='mt-3 text-align-right'>
              9 יש לעלות את הרכב עם הסרן הקדמי על גבי משטחי הדמי והסרן האחורי יעמוד על המשקלים.
            </h2>
            <h2 className='mt-3 text-align-right'>
              10 יש לפעול ע"פ המפורט בסעיפים 3 עד 8 לעיל.
            </h2>
            <h2 className='mt-3 text-align-right'>
              10.1 יש לחשב את הערכים הבאים:
            </h2>
            <h2 className='mt-3 text-align-right'>
              10.1.1. משקל עצמי: KWtot[kg]= N1 + N2+ N3+ N4
            </h2>
            <h2 className='mt-3 text-align-right'>
              איפה ש:
            </h2>
            <h2 className='mt-3 text-align-right'>
              KW = משקל עצמי מחושב (kerb weight)
            </h2>
            <h2 className='mt-3 text-align-right'>
              Nn = כוח נורמלי הנמדד בגלל מסוים.
            </h2>
            <h2 className='mt-3 text-align-right'>
              10.1.2. התפלגות המשקל העצמי של הרכב בין סרן קדמי/אחורי ובין צד ימין/שמאל כלהלן:
            </h2>
            <h2 className='mt-3 text-align-center'>
              KWf=N1+N2 KWr=N3+N4
            </h2>
            <h2 className='mt-3 text-align-center'>
              איפה ש: KWf = משקל עצ' סרן קדמי; KWr = משקל עצ' סרן אח'; KWtot = משקל עצמי; WB = ר' סרנים.
            </h2>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '30rem'}}
              >
                <tr>
                  <th>KWf[kg]</th>
                  <th>N2[kg]</th>
                  <th>N1[kg]</th>
                </tr>
                <tr>

                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.KWf}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_KWf}
                           value={currentReport.weights.KWf = add(currentReport.weights.N1,currentReport.weights.N2)}
                    />
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.N2}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_N2}
                           value={currentReport.weights.N2}
                    />
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.N1}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_N1}
                           value={currentReport.weights.N1}
                    />
                  </td>
                </tr>
              </table>

            </div>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '30rem'}}
              >
                <tr>
                  <th>KWr[kg]</th>
                  <th>N3[kg]</th>
                  <th>N4[kg]</th>
                </tr>
                <tr>

                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.KWr}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_KWr}
                           value={currentReport.weights.KWr = add(currentReport.weights.N3,currentReport.weights.N4)}
                    />

                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.N3}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_N3}
                           value={currentReport.weights.N3}
                    />
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.N4}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_N4}
                           value={currentReport.weights.N4}
                    />
                  </td>
                </tr>

                <tr>
                  <td> KWtot[kg]</td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.KWtot}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_KWtot}
                           value={currentReport.weights.KWtot = add(currentReport.weights.KWr,currentReport.weights.KWf)}
                    />
                  </td>

                </tr>
              </table>
            </div>
            <h2 className='mt-3 text-align-right'>
              ג. במידה וכמות הדלק שנמדדה במיכל, קטנה מ-90% - יש לחשב:
            </h2>


            <td>
              <div className='flex font-bold'>
                <h2 className='mt-3 text-align-right'>
                  <label htmlFor=''>1. את נפח המיכל כלהלן: </label>
                </h2>

                <h2 className='flex align-items-center gap-04'>
                  <label className='reports_text2'>
                    {oldReport.weights.tank_x}
                  </label>
                  <input className='reports_text'
                         style={{width: '45%'}}
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_tank_x}
                         value={currentReport.weights.tank_x}
                  />

                  <label htmlFor=''>X אורך (ס"מ)</label>
                </h2>
                <h2 className='flex align-items-center gap-04'>
                  <label className='reports_text2'>
                    {oldReport.weights.tank_y}
                  </label>
                  <input className='reports_text'
                         style={{width: '65%'}}
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_tank_y}
                         value={currentReport.weights.tank_y}
                  />
                  <label htmlFor=''>X רוחב (ס"מ)</label>
                </h2>
                <h2 className='flex align-items-center gap-04'>
                  <label className='reports_text2'>
                    {oldReport.weights.tank_z}
                  </label>
                  <input className='reports_text'
                         style={{width: '65%'}}
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_tank_z}
                         value={currentReport.weights.tank_z}
                  />
                  <label htmlFor=''>X גובה (ס"מ)</label>
                </h2>
                <h2 className='flex align-items-center gap-04'>
                  <label className='reports_text2'>
                    {oldReport.weights.tank_total}
                  </label>
                  <input className='reports_text'
                         style={{width: '65%'}}
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_tank_total}
                         value={currentReport.weights.tank_total = floor(((currentReport.weights.tank_x * currentReport.weights.tank_y * currentReport.weights.tank_z) / 1000))}
                  />
                  <label htmlFor=''> =נפח [ליטר]</label>
                </h2>
              </div>
            </td>


            <h2 className='mt-3 text-align-center'>

              2. את תוספת למשקל העצמי של הרכב, כתוצאה מחוסר בדלק כלהלן:
            </h2>
            <h2 className='mt-3 text-align-center'>

              = FWtot=ρf×FV×(0.9-FP⁄100)

            </h2>
            <td>
              <div className='flex font-bold'>
                <h2 className='flex align-items-center gap-04'>
                  <label htmlFor=''> kg</label>

                </h2>

                <h2 className='flex align-items-center gap-04'>

                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_FWtot}
                         value={currentReport.weights.FWtot = floor(multiply(currentReport.weights.ρf, currentReport.weights.FV, (subtract(0.9, (divide(currentReport.weights.FP, 100))))))}

                  />

                  <label htmlFor=''></label>
                </h2>
                <h2 className='flex align-items-center gap-04'>

                  <label htmlFor=''>=(100/ </label>
                </h2>

                <h2 className='flex align-items-center gap-04'>
                  <label className='reports_text2'>
                    {oldReport.weights.FP}
                  </label>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_FP}
                         value={currentReport.weights.FP}
                  />
                  <label htmlFor=''>-0.9</label>
                </h2>

                <h2 className='flex align-items-center gap-04'>
                  <label htmlFor=''>)x </label>
                </h2>

                <h2 className='flex align-items-center gap-04'>
                  <label className='reports_text2'>
                    {oldReport.weights.FV}
                  </label>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_FV}
                         value={currentReport.weights.FV}
                  />
                  <label htmlFor=''> 0.8x </label>
                </h2>
                <h2 className='flex align-items-center gap-04'>
                  <label htmlFor=''> = </label>
                </h2>

              </div>
            </td>


            <h2 className='mt-3 text-align-right'>

              איפה ש:
            </h2>

          </div>

          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>

            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 5 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>






            <h2 className='mt-3 text-align-right'>

              FWtot = משקל הדלק החסר בק"ג.
            </h2>
            <h2 className='text-align-right'>

              ρf = צפיפות נפחית של הדלק 0.8[kg⁄l]
            </h2>
            <h2 className='text-align-right'>

              FV = נפח מיכל הדלק בליטרים.
            </h2>
            <h2 className='text-align-right'>
              FP = אחוז הדלק במיכל.
            </h2>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '30rem'}}
              >
                <tr>
                  <th>FV [l]</th>
                  <th>FP [%]</th>
                </tr>
                <tr>

                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.FV}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'

                           onChange={onGroupChangeWeights_FV}
                           value={currentReport.weights.FV}
                    />
                  </td>
                  <td>
                    <label className='reports_text2'>
                      {oldReport.weights.FP}
                    </label>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_FP}
                           value={currentReport.weights.FP}
                    />
                  </td>

                </tr>

              </table>

            </div>
            <h2 className='mt-3 text-align-right'>
              ולכן תוספת משקל הדלק החסר על סרני הרכב, קדמי ואחורי יחושב כלהלן:

            </h2>


            <td>
              <div className='flex font-bold'>
                <h2 className='flex align-items-right gap-04'>
                  <label for=''> kg</label>
                </h2>

                <h2 className='flex align-items-right gap-04'>

                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_FWr}
                         value={currentReport.weights.FWr=floor(multiply(currentReport.weights.FWtot,divide(currentReport.weights.Lf,currentReport.group28.finds)))}

                  />
                  <label for=''>=</label>
                </h2>

                <h2 className='flex align-items-right gap-04'>

                  <label className='reports_text2'>
                    {oldReport.group28.finds}
                  </label>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_WB}
                         value={currentReport.group28.finds}
                  />
                  <label for=''>/</label>
                </h2>
                <h2 className='flex align-items-right gap-04'>
                  <div className='reports_text2'>
                    {oldReport.weights.Lf}
                  </div>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_Lf}
                         value={currentReport.weights.Lf}
                  />
                  <label for=''>X</label>
                </h2>

                <h2 className='flex align-items-right gap-04'>
                  <div className='reports_text2'>
                    {oldReport.weights.FWtot}
                  </div>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_FWtot}
                         value={currentReport.weights.FWtot}
                  />
                  <label for=''>=</label>
                </h2>
                <h2 className='flex align-items-right gap-04'>
                  <label for=''>FWr = FWtot × Lf⁄WB </label>
                </h2>

              </div>
            </td>
            <h2 className='text-align-center'>

            </h2>

            <td>
              <div className='flex font-bold'>
                <h2 className='flex align-items-right gap-04'>
                  <label for=''> kg</label>
                </h2>

                <h2 className='flex align-items-right gap-04'>

                  <input className='reports_text'
                         type='number'
                         name='weights'
                         maxlength="3"
                         onChange={onGroupChangeWeights_FWf}
                         value={currentReport.weights.FWf=floor(subtract(currentReport.weights.FWtot,currentReport.weights.FWr))}
                  />
                  <label for=''>=</label>
                </h2>

                <h2 className='flex align-items-right gap-04'>
                  <div className='reports_text2'>
                    {oldReport.weights.FWr}
                  </div>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_FWr}
                         value={currentReport.weights.FWr}
                  />
                  <label for=''>-</label>
                </h2>


                <h2 className='flex align-items-right gap-04'>
                  <div className='reports_text2'>
                    {oldReport.weights.FWtot}
                  </div>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_FWtot}
                         value={currentReport.weights.FWtot}
                  />
                  <label for=''>=</label>
                </h2>
                <h2 className='flex align-items-center gap-04'>
                  <label for=''>FWf = FWtot - FWr </label>
                </h2>

              </div>
            </td>
            <h2 className='mt-3 text-align-right'>
              איפה ש:
            </h2>
            <h2 className='mt-3 text-align-right'>
              FWr = תוספת משקל הדלק החסר על סרן אחורי
            </h2>
            <h2 className='text-align-right'>
              FWf = תוספת משקל הדלק החסר על סרן קדמי
            </h2>
            <td>
              <div className='flex font-bold'>
                <h2 className='flex align-items-right gap-04'>
                  <label for=''>Lf = </label>
                </h2>

                <h2 className='flex align-items-right gap-04'>
                  <div className='reports_text2'>
                    {oldReport.weights.Lf}
                  </div>
                  <input className='reports_text'
                         style={{width: '25%'}}
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_Lf}
                         value={currentReport.weights.Lf}
                  />

                  <label for=''>מ"מ - מרחק בין מרכז תא דלק לציר הקדמי.</label>
                </h2>
              </div>
            </td>
            <h2 className='text-align-right'>
              WB = מרחק בין הסרנים
            </h2>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '30rem'}}
              >
                <tr>
                  <th>FWf[kg]</th>
                </tr>
                <tr>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.FWf}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_FWf}
                           value={currentReport.weights.FWf}
                    />
                  </td>
                </tr>
              </table>
            </div>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '30rem'}}
              >
                <tr>
                  <th>FWr[kg]</th>
                </tr>
                <tr>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.FWr}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_FWr}
                           value={currentReport.weights.FWr}
                    />
                  </td>
                </tr>
              </table>
            </div>


            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '30rem'}}
              >
                <tr>
                  <th>FWtot[kg]</th>
                </tr>
                <tr>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.FWtot}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_FWtot}
                           value={currentReport.weights.FWtot}
                    />
                  </td>
                </tr>
              </table>
            </div>


            <h2 className='mt-3 text-align-right'>
              עדכן את טבלת חלוקת העומסים בתוספת משקל הדלק החסר כלהלן:

            </h2>
            <h2 className='mt-3 text-align-right'>

            </h2>

            <div className='flex justify-content-right'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '80rem'}}
              >
                <tr>
                  <th style={{Width: '15%'}}> משקל מותר סרן קדמי – בהתאם ל-VIN או בהתאם לתקנות התע'</th>
                  <th style={{Width: '25%'}}> =KWf[kg]+FWf[kg]</th>
                  <th style={{Width: '25%'}}> N2[kg]</th>
                  <th style={{Width: '25%'}}> N1[kg]</th>
                </tr>
                <td>
                  <div className='reports_text2'>
                    {oldReport.weights.VIN_front}
                  </div>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_VIN_front}
                         value={currentReport.weights.VIN_front=currentReport.SectionD_3.reg_Axle_1}
                  />
                </td>
                <td>
                  <div className='reports_text2'>
                    {oldReport.weights.GVWf}
                  </div>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_GVWf}
                         value={currentReport.weights.GVWf=add(currentReport.weights.KWf,currentReport.weights.FWf)}
                  />
                </td>
                <td>
                  <div className='reports_text2'>
                    {oldReport.weights.N2}
                  </div>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_N2}
                         value={currentReport.weights.N2}
                  />
                </td>
                <td>
                  <div className='reports_text2'>
                    {oldReport.weights.N1}
                  </div>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_N1}
                         value={currentReport.weights.N1}
                  />
                </td>
              </table>
            </div>

            <div className='flex justify-content-right'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '80rem'}}
              >
                <tr>
                  <th style={{Width: '15%'}}> משקל מותר סרן אחורי – בהתאם ל-VIN או בהתאם לתקנות התע'</th>
                  <th style={{Width: '25%'}}> =KWr[kg]+FWr[kg]</th>
                  <th style={{Width: '25%'}}> N3[kg]</th>
                  <th style={{Width: '25%'}}> N4[kg]</th>
                </tr>
                <td>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_VIN_Back}
                         value={currentReport.weights.VIN_Back=currentReport.SectionD_3.reg_Axle_2}
                  />
                </td>
                <td>
                  <div className='reports_text2'>
                    {oldReport.weights.GVWr}
                  </div>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_GVWr}
                         value={currentReport.weights.GVWr=add(currentReport.weights.KWr,currentReport.weights.FWr)}
                  />
                </td>
                <td>
                  <div className='reports_text2'>
                    {oldReport.weights.N3}
                  </div>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_N3}
                         value={currentReport.weights.N3}
                  />
                </td>
                <td>
                  <div className='reports_text2'>
                    {oldReport.weights.N4}
                  </div>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_N4}
                         value={currentReport.weights.N4}
                  />
                </td>
              </table>
            </div>

            <div className='flex justify-content-right'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '80rem'}}
              >
                <tr>
                  <th style={{Width: '15%'}}> KWtot[kg]+FWtot[kg]='</th>
                  <th style={{Width: '25%'}}>
                    <td>
                      <input className='reports_text'
                             type='number'
                             name='weights'
                             onChange={onGroupChangeWeights_overload_WB}
                             value={currentReport.overload_WB=add(currentReport.weights.KWtot,currentReport.weights.FWtot)}
                      />
                    </td>
                  </th>
                  <th
                      style={{Width: '15%'}}>
                    <div className='flex justify-content-right'>
                      <label for=''>ימין</label>
                    </div>
                    <div className='flex justify-content-right'>



                      {currentReport.weight_right == 1 &&
                      <input className='reports_text'

                             type='number'
                             name='weights'
                             onChange={onGroupChangeWeights_GVWr_tot}
                             value={currentReport.weights.GVWr_tot = add(currentReport.weights.N2, currentReport.weights.N3, currentReport.weights.FWtot)}

                      />
                      }
                      {currentReport.weight_right == 0 &&
                      <input className='reports_text'
                             type='number'
                             name='weights'
                             onChange={onGroupChangeWeights_GVWr_tot}
                             value={currentReport.weights.GVWr_tot = add(currentReport.weights.N2, currentReport.weights.N3)}
                      />
                      }
                      <label for=''>[ק"ג]</label>
                    </div>
                  </th>
                  <th style={{Width: '15%'}}>
                    <div className='flex justify-content-right'>
                      <label for=''>שמאל</label>
                    </div>
                    <div className='flex justify-content-right'>
                      {currentReport.weight_left == 1 &&
                      <input className='reports_text'
                             type='number'
                             name='weights'
                             onChange={onGroupChangeWeights_GVWl_tot}
                             value={currentReport.weights.GVWl_tot = add(currentReport.weights.N1, currentReport.weights.N4, currentReport.weights.FWtot)}
                      />
                      }
                      {currentReport.weight_left == 0 &&
                      <input className='reports_text'
                             type='number'
                             name='weights'
                             onChange={onGroupChangeWeights_GVWl_tot}
                             value={currentReport.weights.GVWl_tot = add(currentReport.weights.N1, currentReport.weights.N4)}
                      />
                      }
                      <label for=''>[ק"ג]</label>
                    </div>
                  </th>
                </tr>

              </table>
            </div>






            <div className='flex justify-content-right'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '80rem'}}
              >
                <tr>
                  <th style={{Width: '15%'}}>האם המיכל בצד ימין או שמאל</th>
                  <th style={{Width: '25%'}}>
                    <td>
                      <input className='reports_text'
                             type='checkbox'
                             style={{ marginLeft: '1rem' }}
                             checked={currentReport.weight_right}
                             onChange={(e) => {
                               setCurrentReport({
                                 ...currentReport,
                                 weight_right: e.target.checked,
                               });
                             }}
                      />
                      <label>
                        ימין
                      </label>
                    </td>

                    <td>
                      <input className='reports_text'
                             type='checkbox'
                             style={{ marginLeft: '1rem' }}
                             checked={currentReport.weight_left}
                             onChange={(e) => {
                               setCurrentReport({
                                 ...currentReport,
                                 weight_left: e.target.checked,
                               });
                             }}
                      />
                      <label>
                        שמאל
                      </label>
                    </td>
                  </th>
                </tr>
              </table>
            </div>
            <div className='flex justify-content-right'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '80rem'}}
              >
                <tr>
                  <th style={{Width: '15%'}}> משקל כולל מותר</th>
                  <th style={{Width: '25%'}}>
                    <td>

                      <div className='reports_text2'>
                        {oldReport.SectionD_3.value_GVW}
                      </div>

                      <input className='reports_text'
                             type='number'
                             name='weights'
                             onChange={onGroupChangeWeights_total_allow}
                             value={currentReport.weight_total_allow = currentReport.SectionD_3.reg_GVW  }
                      />
                    </td>
                  </th>
                  <th
                      style={{Width: '45%'}}>
                    <div className='flex justify-content-right'>
                      <label for=''>הצב ערכים שהתקבלו בסעיף 3.10 במשך טופס זה</label>
                    </div>
                  </th>
                </tr>
              </table>


            </div>


            <h2 className='mt-3 text-align-right'>
              3.9 חישוב משקל מורשה : PM = GVW - KW - DPW
            </h2>
            <h2 className='mt-3 text-align-right'>
              איפה ש:
            </h2>
            <h2 className='text-align-right'>
              PM = משקל מורשה
            </h2>
            <h2 className='text-align-right'>
              GVW = משקל כולל ע"פ טבלת ה-VIN של הרכב.
            </h2>
            <h2 className='text-align-right'>
              KW = משקל עצמי מחושב כולל 90% מהנוזלים
            </h2>


            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '15rem'}}
              >
                <tr>
                  <th>KW[kg]</th>
                </tr>
                <tr>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.KW}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_KW}
                           value={currentReport.weights.KW =add(currentReport.weights.KWtot,currentReport.weights.FWtot)}
                    />
                  </td>
                </tr>
              </table>
            </div>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '15rem'}}
              >
                <tr>
                  <th>PM[kg]</th>
                </tr>
                <tr>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.weights.PM}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='weights'
                           onChange={onGroupChangeWeights_PM}
                           value={currentReport.weights.PM = floor((currentReport.SectionD_3.reg_GVW - currentReport.weights.KW))}
                    />
                  </td>
                </tr>
              </table>
            </div>



            <h2 className='mt-3 text-align-right'>
              3.10 חישוב חלוקת עומסים:
            </h2>
            <h2 className='mt-3 text-align-right'>
              א. יש לוודא שלא קיימת חריגה בסימטריה המשקל העצמי, בין צד ימין לצד שמאל של הרכב כמפורט להלן:
            </h2>
            <h2 className='flex justify-content-center'>
              <div className='table-no-border'>
                <img src={pic2} alt={''} style={{width: '100%'}}/>
              </div>
            </h2>

            <h2 className='mt-3 text-align-right'>
              במידה וקיימת חריגה בחלוקת עומסי המשקל העצמי של הרכב בין צד ימין לצד שמאל העולה על 5% יש לסווג סעיף זה כ-"לא
              מתאים".
            </h2>

            <h2 className='mt-3 text-align-right'>
              <th style={{Width: '15%'}}>
                <td>
                  משקל ימין :
                  <div className='reports_text2'>
                    {oldReport.weights.GVWr_tot}
                  </div>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_GVWr_tot}
                         value={currentReport.weights.GVWr_tot}
                  />
                </td>
              </th>
            </h2>

            <h2 className='mt-3 text-align-right'>
              <th style={{Width: '15%'}}>
                <td>
                  משקל שמאל :
                  <div className='reports_text2'>
                    {oldReport.weights.GVWl_tot}
                  </div>
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_GVWl_tot}
                         value={currentReport.weights.GVWl_tot}
                  />
                </td>
              </th>
            </h2>

            <h2 className='mt-3 text-align-right'>
              <th style={{Width: '15%'}}>
                <td>
                  תוצאה חלוקת עומסים באחוזים :

                  {currentReport.weights.GVWr_tot < currentReport.weights.GVWl_tot &&
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_GVW_accept}
                         value={currentReport.weights.GVW_accept= floor(multiply(subtract(1,(divide(currentReport.weights.GVWr_tot,currentReport.weights.GVWl_tot))),100))}
                  />

                  }
                  {currentReport.weights.GVWr_tot > currentReport.weights.GVWl_tot &&
                  <input className='reports_text'
                         type='number'
                         name='weights'
                         onChange={onGroupChangeWeights_GVW_accept}
                         value={currentReport.weights.GVW_accept= floor(multiply(subtract(1,(divide(currentReport.weights.GVWl_tot,currentReport.weights.GVWr_tot))),100))}
                  />
                  }
                </td>
              </th>
            </h2>
          </div>

          <div style={{padding: 100}}></div>

          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 6 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <h2 className='mt-3 mb-3 text-align-right'>
              2 .יש לבצע חישוב חלוקת עומסים למיקום מרכז הארגז/מטען מורשה בתצורה הבאה [לדוגמא]:
            </h2>
            <div className='flex justify-content-right'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '200rem'}}
              >
                <tr>
                  <td className='mt-3 text-align-center' style={{width: '20%',background: "lightgrey"}}>תיאור</td>
                  <td className='mt-3 text-align-center'  style={{width: '5%',background: "lightgrey"}}>סימון</td>
                  <td className='mt-3 text-align-center'  style={{width: '15%',background: "lightgrey"}}>מרחק מסרן קדמי(מ"מ)</td>
                  <td className='mt-3 text-align-center'  style={{width: '15%',background: "lightgrey"}}>סרן קדמי(ק"ג)</td>
                  <td className='mt-3 text-align-center'  style={{width: '15%',background: "lightgrey"}}>סרן אחורי(ק"ג)</td>
                  <td className='mt-3 text-align-center'  style={{width: '15%',background: "lightgrey"}}>סה"כ(ק"ג)</td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    עצמי (לא כולל נהג)
                  </td>
                  <td className='mt-3 text-align-center'>
                    A
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row1'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row1.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row1'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row1.front_weight=currentReport.table_row1.total_row_weight - currentReport.table_row1.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row1'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row1.rear_weight=divide((multiply(currentReport.table_row1.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row1.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row1'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row1.total_row_weight}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    נהג
                  </td>
                  <td className='mt-3 text-align-center'>
                    A
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row2'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row2.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row2'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row2.front_weight=(currentReport.table_row2.total_row_weight) - (currentReport.table_row2.rear_weight)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row2'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row2.rear_weight=divide((multiply(currentReport.table_row2.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row2.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row2'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row2.total_row_weight}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    מדריך
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "red"}}>
                    F
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row3'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row3.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row3'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row3.front_weight=currentReport.table_row3.total_row_weight - currentReport.table_row3.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row3'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row3.rear_weight=divide((multiply(currentReport.table_row3.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row3.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row3'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row3.total_row_weight}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בשורה מס' 1 - צד שמאל
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "red"}}>
                    F
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row4'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row4.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row4'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row4.front_weight=currentReport.table_row4.total_row_weight - currentReport.table_row4.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row4'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row4.rear_weight=divide((multiply(currentReport.table_row4.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row4.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row4'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row4.total_row_weight}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בשורה מס' 2 - צד שמאל
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "yellow"}}>
                    B
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row5'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row5.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row5'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row5.front_weight=currentReport.table_row5.total_row_weight - currentReport.table_row5.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row5'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row5.rear_weight=divide((multiply(currentReport.table_row5.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row5.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row5'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row5.total_row_weight}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בשורה מס' 3 - צד שמאל
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "yellow"}}>
                    B
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row6'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row6.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row6'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row6.front_weight=currentReport.table_row6.total_row_weight - currentReport.table_row6.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row6'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row6.rear_weight=divide((multiply(currentReport.table_row6.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row6.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row6'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row6.total_row_weight}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בשורה מס' 4 - צד שמאל
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "yellow"}}>
                    B
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row7'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row7.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row7'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row7.front_weight=currentReport.table_row7.total_row_weight - currentReport.table_row7.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row7'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row7.rear_weight=divide((multiply(currentReport.table_row7.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row7.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row7'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row7.total_row_weight}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בשורה מס' 5 - צד שמאל
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "yellow"}}>
                    B
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row8'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row8.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row8'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row8.front_weight=currentReport.table_row8.total_row_weight - currentReport.table_row8.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row8'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row8.rear_weight=divide((multiply(currentReport.table_row8.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row8.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row8'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row8.total_row_weight}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בשורה מס' 6 - צד שמאל
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "yellow"}}>
                    B
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row9'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row9.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row9'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row9.front_weight=currentReport.table_row9.total_row_weight - currentReport.table_row9.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row9'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row9.rear_weight=divide((multiply(currentReport.table_row9.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row9.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row9'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row9.total_row_weight}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בשורה מס' 7 - צד שמאל
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "yellow"}}>
                    B
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row10'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row10.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row10'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row10.front_weight=currentReport.table_row10.total_row_weight - currentReport.table_row10.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row10'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row10.rear_weight=divide((multiply(currentReport.table_row10.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row10.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row10'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row10.total_row_weight}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בשורה מס' 8 - צד שמאל
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "yellow"}}>
                    B
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row11'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row11.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row11'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row11.front_weight=currentReport.table_row11.total_row_weight - currentReport.table_row11.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row11'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row11.rear_weight=divide((multiply(currentReport.table_row11.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row11.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row11'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row11.total_row_weight}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בשורה מס' 9 - צד שמאל
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "yellow"}}>
                    B
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row12'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row12.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row12'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row12.front_weight=currentReport.table_row12.total_row_weight - currentReport.table_row12.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row12'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row12.rear_weight=divide((multiply(currentReport.table_row12.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row12.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row12'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row12.total_row_weight}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בשורה מס' 10 - צד שמאל
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "yellow"}}>
                    B
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row13'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row13.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row13'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row13.front_weight=currentReport.table_row12.total_row_weight - currentReport.table_row12.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row13'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row13.rear_weight=divide((multiply(currentReport.table_row13.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row13.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row13'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row13.total_row_weight}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בשורה מס' 11 - צד שמאל
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "#33B9FF"}}>
                    D
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row14'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row14.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row14'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row14.front_weight=currentReport.table_row14.total_row_weight - currentReport.table_row14.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row14'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row14.rear_weight=divide((multiply(currentReport.table_row14.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row14.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row14'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row14.total_row_weight}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בשורה מס' 12 - צד שמאל
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "#33B9FF"}}>
                    D
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row15'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row15.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row15'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row15.front_weight=currentReport.table_row15.total_row_weight - currentReport.table_row15.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row15'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row15.rear_weight=divide((multiply(currentReport.table_row15.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row15.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row15'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row15.total_row_weight}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בשורה מס' 13 - צד שמאל
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "#33B9FF"}}>
                    D
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row16'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row16.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row16'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row16.front_weight=currentReport.table_row16.total_row_weight - currentReport.table_row16.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row16'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row16.rear_weight=divide((multiply(currentReport.table_row16.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row16.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row16'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row16.total_row_weight}
                    />
                  </td>
                </tr>



                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בשורה מס' 1 - צד ימין
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "red"}}>
                    F
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row17'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row17.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row17'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row17.front_weight=currentReport.table_row17.total_row_weight - currentReport.table_row17.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row17'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row17.rear_weight=divide((multiply(currentReport.table_row17.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row17.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row17'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row17.total_row_weight}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בשורה מס' 2 - צד ימין
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "#4EC45B"}}>
                    C
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row18'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row18.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row18'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row18.front_weight=currentReport.table_row18.total_row_weight - currentReport.table_row18.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row18'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row18.rear_weight=divide((multiply(currentReport.table_row18.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row18.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row18'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row18.total_row_weight}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בשורה מס' 3 - צד ימין
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "#4EC45B"}}>
                    C
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row19'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row19.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row19'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row19.front_weight=currentReport.table_row19.total_row_weight - currentReport.table_row19.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row19'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row19.rear_weight=divide((multiply(currentReport.table_row19.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row19.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row19'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row19.total_row_weight}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בשורה מס' 4 - צד ימין
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "#4EC45B"}}>
                    C
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row20'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row20.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row20'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row20.front_weight=currentReport.table_row20.total_row_weight - currentReport.table_row20.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row20'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row20.rear_weight=divide((multiply(currentReport.table_row20.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row20.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row20'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row20.total_row_weight}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בשורה מס' 5 - צד ימין
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "#4EC45B"}}>
                    C
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row21'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row21.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row21'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row21.front_weight=currentReport.table_row21.total_row_weight - currentReport.table_row21.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row21'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row21.rear_weight=divide((multiply(currentReport.table_row21.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row21.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row21'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row21.total_row_weight}
                    />
                  </td>
                </tr>



                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בשורה מס' 6 - צד ימין
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "#4EC45B"}}>
                    C
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row22'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row22.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row22'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row22.front_weight=currentReport.table_row22.total_row_weight - currentReport.table_row22.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row22'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row22.rear_weight=divide((multiply(currentReport.table_row22.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row22.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row22'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row22.total_row_weight}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בשורה מס' 7 - צד ימין
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "#4EC45B"}}>
                    C
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row23'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row23.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row23'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row23.front_weight=currentReport.table_row23.total_row_weight - currentReport.table_row23.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row23'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row23.rear_weight=divide((multiply(currentReport.table_row23.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row23.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row23'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row23.total_row_weight}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בשורה מס' 8 - צד ימין
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "#4EC45B"}}>
                    C
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row24'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row24.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row24'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row24.front_weight=currentReport.table_row24.total_row_weight - currentReport.table_row24.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row24'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row24.rear_weight=divide((multiply(currentReport.table_row24.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row24.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row24'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row24.total_row_weight}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בשורה מס' 8 - צד ימין
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "#4EC45B"}}>
                    C
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row25'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row25.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row25'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row25.front_weight=currentReport.table_row25.total_row_weight - currentReport.table_row25.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row25'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row25.rear_weight=divide((multiply(currentReport.table_row25.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row25.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row25'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row25.total_row_weight}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בשורה מס' 9 - צד ימין
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "#4EC45B"}}>
                    C
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row26'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row26.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row26'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row26.front_weight=currentReport.table_row26.total_row_weight - currentReport.table_row26.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row26'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row26.rear_weight=divide((multiply(currentReport.table_row26.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row26.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row26'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row26.total_row_weight}
                    />
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בשורה מס' 10 - צד ימין
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "#4EC45B"}}>
                    C
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row27'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row27.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row27'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row27.front_weight=currentReport.table_row27.total_row_weight - currentReport.table_row27.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row27'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row27.rear_weight=divide((multiply(currentReport.table_row27.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row27.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row27'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row27.total_row_weight}
                    />
                  </td>
                </tr>




                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בשורה מס' 11 - צד ימין
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "#33B9FF"}}>
                    D
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row28'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row28.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row28'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row28.front_weight=currentReport.table_row28.total_row_weight - currentReport.table_row28.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row28'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row28.rear_weight=divide((multiply(currentReport.table_row28.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row28.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row28'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row28.total_row_weight}
                    />
                  </td>
                </tr>



                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בשורה מס' 12 - צד ימין
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "#33B9FF"}}>
                    D
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row29'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row29.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row29'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row29.front_weight=currentReport.table_row29.total_row_weight - currentReport.table_row29.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row29'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row29.rear_weight=divide((multiply(currentReport.table_row29.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row29.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row29'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row29.total_row_weight}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    נוסעים בספסל האחורי
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "#B68DB3"}}>
                    E
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row30'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row30.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row30'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row30.front_weight=currentReport.table_row30.total_row_weight - currentReport.table_row30.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row30'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row30.rear_weight=divide((multiply(currentReport.table_row30.total_row_weight,(subtract(currentReport.group28.finds,currentReport.table_row30.distance)))),currentReport.group28.finds)}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row30'
                           onChange={onResultTableRow_total_row_weight}
                           value={currentReport.table_row30.total_row_weight}
                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    טובין בתא מטען
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "lightgrey"}}>
                    G
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row31'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row31.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row31'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row31.front_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row31'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row31.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>

                  </td>
                </tr>



                <tr>
                  <td className='mt-3 text-align-center'>
                    השלמת דלק שחסר ל - 90%
                  </td>
                  <td className='mt-3 text-align-center' style={{background: "#F6BC54"}}>
                    H
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row32'
                           onChange={onResultTableRow_distance}
                           value={currentReport.table_row32.distance}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row32'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row32.front_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row32'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row32.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'
                           type='number'
                           name='table_row32'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row32.total_row_weight}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center' style={{background: "#70F546"}}>
                  <td className='mt-3 text-align-center'>
                    <b>משקל כולל (ק"ג)</b>
                  </td>
                  <td >

                  </td>
                  <td >

                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'  style={{border: 0,background: "#70F546"}}
                           type='number'
                           name='table_row51'
                           onChange={onResultTableRow_front_weight}
                           value={currentReport.table_row51.front_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text' style={{border: 0,background: "#70F546"}}
                           type='number'
                           name='table_row51'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row51.rear_weight}
                    />
                  </td>
                  <td className='mt-3 text-align-center'>
                    <input className='reports_text'  style={{border: 0, background: "#70F546"}}
                           type='number'
                           name='table_row51'
                           onChange={onResultTableRow_rear_weight}
                           value={currentReport.table_row51.total_row_weight}
                    />
                  </td>
                </tr>

                <tr className='mt-3 text-align-center' style={{background: "#5E944D"}}>
                  <td className='mt-3 text-align-center'>
                    <b>משקל מורשה (ק"ג)</b>
                  </td>
                  <td >

                  </td>
                  <td >

                  </td>
                  <td >
                    7,500
                  </td>
                  <td >
                    12,000
                  </td>
                  <td >
                    19,500
                  </td>
                </tr>




              </table>
            </div>

            <h2 className='flex justify-content-center'>
              <div className='table-no-border'>
                <img src={pic12} alt={''} style={{ width: '100%' }} />
              </div>
            </h2>



          </div>



          <div style={{padding: 100}}></div>

          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 7 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>


            <h2 className='flex justify-content-center'>
              <div className='table-no-border'>
                <img src={pic13} alt={''} style={{ width: '100%' }} />
              </div>
            </h2>


            <h2 className='flex justify-content-center'>
              <div className='table-no-border'>
                <img src={pic14} alt={''} style={{ width: '100%' }} />
              </div>
            </h2>



          </div>







          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 8 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>







            <h2 className='mt-3 text-align-center'>
              <u>  טבלה מס' 3.</u>
            </h2>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <td className='mt-3 text-align-center' style={{background: "lightgrey"}}>מס"ד</td>
                  <td className='mt-3 text-align-center' style={{width: '15%',background: "lightgrey"}}>פרמטר</td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}>פרמטר</td>
                  <td className='mt-3 text-align-center' style={{background: "lightgrey"}}>הערות</td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    3.1
                  </td>
                  <td>
                    עומס מרבי - סרן 1
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group51.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group51'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group51.finds}/>
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group51'
                           onChange={onGroupChangeComment}
                           value={currentReport.group51.comments}/>
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    3.2
                  </td>
                  <td>
                    עומס מרבי - סרן 2
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group52.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group52'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group52.finds }/>
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group52'
                           onChange={onGroupChangeComment}
                           value={currentReport.group52.comments}/>
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    3.3
                  </td>
                  <td>
                    עומס מרבי - סרן 3
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group53.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group53'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group53.finds}/>
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group53'
                           onChange={onGroupChangeComment}
                           value={currentReport.group53.comments}/>
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    3.4
                  </td>
                  <td>
                    עומס מרבי - סרן 4
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group54.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group54'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group54.finds}/>
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group54'
                           onChange={onGroupChangeComment}
                           value={currentReport.group54.comments}/>
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    3.5
                  </td>
                  <td>
                    עומס מרבי - סרן 5
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group55.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group55'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group55.finds}/>
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group55'
                           onChange={onGroupChangeComment}
                           value={currentReport.group55.comments}/>
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    3.6
                  </td>
                  <td>
                    משקל עצמי קדמי
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group56.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group56'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group56.finds = currentReport.weights.GVWf }/>
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group56'
                           onChange={onGroupChangeComment}
                           value={currentReport.group56.comments}/>
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    3.7
                  </td>
                  <td>
                    משקל עצמי אחורי
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group57.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group57'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group57.finds = currentReport.weights.GVWr}/>
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group57'
                           onChange={onGroupChangeComment}
                           value={currentReport.group57.comments}/>
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    3.8
                  </td>
                  <td>
                    (*)משקל עצמי
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group58.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group58'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group58.finds = currentReport.weights.KW}/>
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group58'
                           onChange={onGroupChangeComment}
                           value={currentReport.group58.comments}/>
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    3.9
                  </td>
                  <td>
                    (**) משקל מורשה
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group59.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group59'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group59.finds = currentReport.weights.PM}/>
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group59'
                           onChange={onGroupChangeComment}
                           value={currentReport.group59.comments}/>
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    3.10
                  </td>
                  <td>
                    חלוקת העומסים
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group60.finds}
                    </div>
                    <input className='reports_text'
                           type='number'
                           name='group60'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group60.finds}/>
                  </td>

                  <td>
                    <div className='reports_text2'>
                      {oldReport.group60.comments}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group60'
                           list = 'group60_list'
                           onChange={onGroupChangeComment}
                           value={currentReport.group60.comments}/>

                    <datalist id="group60_list">
                      <option value="לא מתאים">לא מתאים</option>
                      <option value="מתאים">מתאים</option>
                    </datalist>
                  </td>


                </tr>
              </table>
            </div>


            <h2 className='mt-3 text-align-right'>
              (*) המשקל העצמי לא כולל את משקל הנוסעים ברכב לרבות משקל הנהג.
            </h2>
            <h2 className='flex align-items-center gap-04'>
              (**) המשקל המורשה שווה למשקל המטען המורשה + משקל הנוסעים ברכב.
            </h2>

            <h2 className='mt-3 text-align-right'>
              <u> ז. מידות(מ"מ)</u>
            </h2>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <td className='mt-3 text-align-center' style={{background: "lightgrey"}}>מס"ד</td>
                  <td className='mt-3 text-align-center' style={{width: '15%',background: "lightgrey"}}>פרמטר</td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}>פרמטר</td>
                  <td className='mt-3 text-align-center' style={{background: "lightgrey"}}>הערות</td>
                </tr>



                <tr>
                  <td className='mt-3 text-align-center'>
                    4.1
                  </td>
                  <td>
                    אורך כללי
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group61.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group61'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group61.finds = add(currentReport.group64.finds,currentReport.group65.finds,currentReport.group28.finds)}/>
                  </td>

                  <td>
                    <div className='reports_text2'>
                      {oldReport.group61.comments}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group61'
                           onChange={onGroupChangeComment}
                           value={currentReport.group61.comments}/>
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    4.2
                  </td>
                  <td>
                    רוחב כללי
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group62.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group62'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group62.finds}/>
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group62'
                           onChange={onGroupChangeComment}
                           value={currentReport.group62.comments}/>
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    4.3
                  </td>
                  <td>
                    גובה כללי
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group63.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group63'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group63.finds}/>
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group63'
                           onChange={onGroupChangeComment}
                           value={currentReport.group63.comments}/>
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    4.4
                  </td>
                  <td>
                    שלוחה אחורית
                  </td>
                  <td>

                    <input className='reports_text'
                           type='number'
                           name='group64'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group64.finds}/>
                    במ"מ

                    <input className='reports_text'
                           type='number'
                           name='group69'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group69.finds=floor(multiply(divide(currentReport.group64.finds,currentReport.group28.finds),100),1)}/>
                    באחוזים
                  </td>


                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group76'
                           onChange={onGroupChangeComment}
                           value={currentReport.group64.comments}/>
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    4.5
                  </td>
                  <td>
                    שלוחה קדמית
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group65.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group65'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group65.finds}/>
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group65'
                           onChange={onGroupChangeComment}
                           value={currentReport.group65.comments}/>
                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    4.6
                  </td>
                  <td>
                    נפח תא מטען
                  </td>
                  <td>
                    <div className='reports_text2'>
                      {oldReport.group66.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group66'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group66.finds}/>
                  </td>

                  <td>
                    <div className='reports_text2'>
                      {oldReport.group66.finds}
                    </div>
                    <input className='reports_text'
                           type='text'
                           name='group66'
                           onChange={onGroupChangeComment}
                           value={currentReport.group66.comments}/>
                  </td>
                </tr>

              </table>
            </div>


            <h2 className='flex justify-content-center'>
              יש למלא את המידות בשרטוט הבא:
            </h2>

            <h2 className='flex justify-content-center'>
              <div className='table-no-border'>
                <img src={pic15} alt={''} style={{ width: '100%' }} />
              </div>
            </h2>


          </div>



          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 9 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>


            <h2 className='mt-3 text-align-right'>
              <u>   ח.	התקנים נוספים   </u>
            </h2>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '160rem'}}
              >

                <tr>
                  <td className='mt-3 text-align-center' style={{width: '5%',background: "lightgrey"}}>מס"ד</td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}>התקן/ אביזרי נכים</td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}>שם המתקין/ מאבזר</td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}>שם יצרן</td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}>מס"ד</td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}>מס' דוח מעבדה</td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}>זהות מעבדה</td>

                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    5.1
                  </td>
                  <td>
                <textarea className='reports_text'
                          type='text'
                          name='Device1'
                          onChange={onGroupChangeDevices_Device}
                          value={currentReport.Device1.Device}/>
                  </td>

                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device1'
                            onChange={onGroupChangeDevices_Name}
                            value={currentReport.Device1.Name}/>
                  </td>

                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device1'
                            onChange={onGroupChangeDevices_Manufactor}
                            value={currentReport.Device1.Manufactor}/>
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='Device1'
                           dir='LTR'
                           lang='EN'
                           onChange={onGroupChangeDevices_masad}
                           value={currentReport.Device1.masad}/>
                  </td>

                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device1'
                            dir="ltr"
                            onChange={onGroupChangeDevices_report_lab}
                            value={currentReport.Device1.report_lab}/>
                  </td>

                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device1'
                            onChange={onGroupChangeDevices_identity_lab}
                            value={currentReport.Device1.identity_lab}/>
                  </td>


                </tr>



                <tr>
                  <td className='mt-3 text-align-center'>
                    5.2
                  </td>
                  <td>
                <textarea className='reports_text'
                          type='text'
                          name='Device2'
                          onChange={onGroupChangeDevices_Device}
                          value={currentReport.Device2.Device}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device2'
                            onChange={onGroupChangeDevices_Name}
                            value={currentReport.Device2.Name}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device2'
                            onChange={onGroupChangeDevices_Manufactor}
                            value={currentReport.Device2.Manufactor}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='Device2'
                           dir='LTR'
                           lang='EN'
                           onChange={onGroupChangeDevices_masad}
                           value={currentReport.Device2.masad}/>
                  </td>

                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device2'
                            dir="ltr"
                            onChange={onGroupChangeDevices_report_lab}
                            value={currentReport.Device2.report_lab}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device2'
                            onChange={onGroupChangeDevices_identity_lab}
                            value={currentReport.Device2.identity_lab}/>
                  </td>


                </tr>



                <tr>
                  <td className='mt-3 text-align-center'>
                    5.3
                  </td>
                  <td>
                <textarea className='reports_text'
                          type='text'
                          name='Device3'
                          onChange={onGroupChangeDevices_Device}
                          value={currentReport.Device3.Device}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device3'
                            onChange={onGroupChangeDevices_Name}
                            value={currentReport.Device3.Name}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device3'
                            onChange={onGroupChangeDevices_Manufactor}
                            value={currentReport.Device3.Manufactor}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='Device3'
                           dir='LTR'
                           lang='EN'
                           onChange={onGroupChangeDevices_masad}
                           value={currentReport.Device3.masad}/>
                  </td>

                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device3'
                            dir="ltr"
                            onChange={onGroupChangeDevices_report_lab}
                            value={currentReport.Device3.report_lab}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device3'
                            onChange={onGroupChangeDevices_identity_lab}
                            value={currentReport.Device3.identity_lab}/>
                  </td>



                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    5.4
                  </td>
                  <td>
                <textarea className='reports_text'
                          type='text'
                          name='Device4'
                          onChange={onGroupChangeDevices_Device}
                          value={currentReport.Device4.Device}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device4'
                            onChange={onGroupChangeDevices_Name}
                            value={currentReport.Device4.Name}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device4'
                            onChange={onGroupChangeDevices_Manufactor}
                            value={currentReport.Device4.Manufactor}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='Device4'
                           dir='LTR'
                           lang='EN'
                           onChange={onGroupChangeDevices_masad}
                           value={currentReport.Device4.masad}/>
                  </td>

                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device4'
                            dir="ltr"
                            onChange={onGroupChangeDevices_report_lab}
                            value={currentReport.Device4.report_lab}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device4'
                            onChange={onGroupChangeDevices_identity_lab}
                            value={currentReport.Device4.identity_lab}/>
                  </td>


                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    5.5
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device5'
                            onChange={onGroupChangeDevices_Device}
                            value={currentReport.Device5.Device}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device5'
                            onChange={onGroupChangeDevices_Name}
                            value={currentReport.Device5.Name}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device5'
                            onChange={onGroupChangeDevices_Manufactor}
                            value={currentReport.Device5.Manufactor}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='Device5'
                           dir="LTR"
                           lang="EN"
                           onChange={onGroupChangeDevices_masad}
                           value={currentReport.Device5.masad}/>
                  </td>

                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device5'
                            dir="ltr"
                            onChange={onGroupChangeDevices_report_lab}
                            value={currentReport.Device5.report_lab}/>
                  </td>
                  <td>
                  <textarea className='reports_text'
                            type='text'
                            name='Device5'
                            onChange={onGroupChangeDevices_identity_lab}
                            value={currentReport.Device5.identity_lab}/>
                  </td>

                </tr>

              </table>
            </div>




            <h2 className='mt-3 text-align-right'>
              <u>  ט.	תנאים והגבלות להוספה  </u>
            </h2>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >



                <tr>
                  <td style={{width: '5%'}}>מס"ד</td>
                  <td className='mt-3 text-align-center' style={{width: '90%'}}>תיאור</td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    6.1
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group71'
                           onChange={onGroupChangeComment}
                           value={currentReport.group71.comments}/>
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    6.2
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group72'
                           onChange={onGroupChangeComment}
                           value={currentReport.group72.comments}/>
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    6.3
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group73'
                           onChange={onGroupChangeComment}
                           value={currentReport.group73.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    6.4
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group74'
                           onChange={onGroupChangeComment}
                           value={currentReport.group74.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    6.5
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group75'
                           onChange={onGroupChangeComment}
                           value={currentReport.group75.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    6.6
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group76'
                           onChange={onGroupChangeComment}
                           value={currentReport.group76.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    6.7
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group77'
                           onChange={onGroupChangeComment}
                           value={currentReport.group77.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    6.8
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group78'
                           onChange={onGroupChangeComment}
                           value={currentReport.group78.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    6.9
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group79'
                           onChange={onGroupChangeComment}
                           value={currentReport.group79.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    6.10
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group80'
                           onChange={onGroupChangeComment}
                           value={currentReport.group80.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    6.11
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group81'
                           onChange={onGroupChangeComment}
                           value={currentReport.group81.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    6.12
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group82'
                           onChange={onGroupChangeComment}
                           value={currentReport.group82.comments}/>
                  </td>
                </tr>
              </table>
            </div>

            <h2 className='mt-3 text-align-right'>
              <u> י.	תנאים והגבלות להסרה</u>
            </h2>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <td style={{width: '5%'}}>מס"ד</td>
                  <td style={{width: '90%'}}>תיאור</td>

                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    7.1
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group83'
                           onChange={onGroupChangeComment}
                           value={currentReport.group83.comments}/>

                    <datalist id="group83_list">

                    </datalist>
                  </td>

                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    7.2
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group84'
                           onChange={onGroupChangeComment}
                           value={currentReport.group84.comments}/>
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    7.3
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group85'
                           onChange={onGroupChangeComment}
                           value={currentReport.group85.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    7.4
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group86'
                           onChange={onGroupChangeComment}
                           value={currentReport.group86.comments}/>
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    7.5
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group87'
                           onChange={onGroupChangeComment}
                           value={currentReport.group87.comments}/>
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    7.6
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group88'
                           onChange={onGroupChangeComment}
                           value={currentReport.group88.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    7.7
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group89'
                           onChange={onGroupChangeComment}
                           value={currentReport.group89.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    7.8
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group90'
                           onChange={onGroupChangeComment}
                           value={currentReport.group90.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    7.9
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group91'
                           onChange={onGroupChangeComment}
                           value={currentReport.group91.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    7.10
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group92'
                           onChange={onGroupChangeComment}
                           value={currentReport.group92.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    7.11
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group93'
                           onChange={onGroupChangeComment}
                           value={currentReport.group93.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    7.12
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group94'
                           onChange={onGroupChangeComment}
                           value={currentReport.group94.comments}/>
                  </td>
                </tr>

              </table>
            </div>

            <h2 className='mt-3 text-align-right'>
              <u>שינויים שבוצעו ברכב</u>
            </h2>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <td style={{width: '5%'}}>מס"ד</td>
                  <td style={{width: '90%'}}>תיאור</td>

                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    8.1
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group48'
                           onChange={onGroupChangeComment}
                           value={currentReport.group48.comments}/>


                  </td>

                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    8.2
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group49'
                           onChange={onGroupChangeComment}
                           value={currentReport.group49.comments}/>


                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    8.3
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group50'
                           onChange={onGroupChangeComment}
                           value={currentReport.group50.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    8.4
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group99'
                           onChange={onGroupChangeComment}
                           value={currentReport.group99.comments}/>
                  </td>
                </tr>
              </table>
            </div>




            <h2 className='mt-3 text-align-right'>
              <u>יא.	הערות</u>
            </h2>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <td style={{width: '5%'}}>מס"ד</td>
                  <td style={{width: '90%'}}>תיאור</td>

                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    9.1
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group95'
                           list='group95_list'
                           onChange={onGroupChangeComment}
                           value={currentReport.group95.comments}/>

                    <datalist id="group95_list">
                      <option value='בדיקה בהסמכה, שקילות באמצעות ציוד בדיקה המעבדה'>בדיקה בהסכמה, שקילות באמצעות ציוד בדיקה המעבדה</option>
                      <option value='השקילה בוצעה בנוכחות הבוחן'>השקילה בוצעה בנוכחות הבוחן</option>
                    </datalist>

                  </td>

                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    9.2
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group96'
                           onChange={onGroupChangeComment}
                           value={currentReport.group96.comments}/>


                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    9.3
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group97'
                           onChange={onGroupChangeComment}
                           value={currentReport.group97.comments}/>
                  </td>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    9.4
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group98'
                           onChange={onGroupChangeComment}
                           value={currentReport.group98.comments}/>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>

            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 10 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>




            <h2 className='mt-3 text-align-right'>
              <u>   יב.	בדיקות ודרישות נוספות בהתאם לתקנות התעבורה ודרישות חוק תקנה:</u>
            </h2>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>ממצאים</b></td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>סיווג התאמה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>הערות</b></td>
                </tr>


                <tr style={{background: "lightgrey"}}>
                  <b>בדיקות מחוץ לרכב</b>
                </tr>
                <tr>
                  <td className='mt-3 text-align-center'>
                    8.1
                  </td>
                  <td>
                    ברכב להסעת תלמידים קבלת תעודת בדיקה ואישור התקנה לפנסי איתות.
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group101'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group101.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group101'
                           list='group101_list'
                           value={currentReport.group101.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group101_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group101'
                           onChange={onGroupChangeComment}
                           value={currentReport.group101.comments}

                    />

                  </td>
                </tr>



                <tr>
                  <td className='mt-3 text-align-center'>
                    8.2
                  </td>
                  <td>
                    שילוט נייד מלפני ומאחור "זהירות ילדים"
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group102'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group102.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group102'
                           list='group102_list'
                           value={currentReport.group102.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group102_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group102'
                           onChange={onGroupChangeComment}
                           value={currentReport.group102.comments}

                    />

                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>

                  </td>
                  <td>
                    <b>                    בהתאם לתקנה 83.(ב)
                    </b>
                    מידות השלט - 12.5x30 ס"מ.
                    החומר - פח מצופה בצבע מחזיר אור לבן ואם מותקנת בשלט תאורת LED, פח בצבע שחור;
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group103'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group103.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group103'
                           list='group103_list'
                           value={currentReport.group103.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group103_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group103'
                           onChange={onGroupChangeComment}
                           value={currentReport.group103.comments}

                    />

                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>

                  </td>
                  <td>
                    צבע אותיות המלה "ילדים" יהיה שחור ואם מותקנת תאורת LED בכל צבע, למעט צבע אדום ומידותיהן: גובה - 70 מ"מ; רוחב - עד 54 מ"מ; עובי - 15 מ"מ.
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group104'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group104.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group104'
                           list='group104_list'
                           value={currentReport.group104.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group104_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group104'
                           onChange={onGroupChangeComment}
                           value={currentReport.group104.comments}

                    />

                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>

                  </td>
                  <td>
                    סימון: סימון לפי ת"י 341 סעיף 105.1 וסימון מ.ת. באותיות שגובהן לא יעלה על 3 מ"מ; הסימון יהיה בפינה השמאלית התחתונה של השלט.
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group105'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group105.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group105'
                           list='group105_list'
                           value={currentReport.group105.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group105_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group105'
                           onChange={onGroupChangeComment}
                           value={currentReport.group105.comments}

                    />

                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>

                  </td>
                  <td>
                    תותקן ברכב מערכת איתות חזותית אשר מיועדת להתריע, לפנים ולאחור, בכל עת שדלתות הרכב פתוחות:
                    1.
                    <u>בחזית</u>
                    : שני פנסים בצבע צהוב.
                    2.
                    <u>בחלק האחורי</u>
                    : שני פנסים בצבע אדום.
                    הפנסים יהבהבו אוטומטית עם פתיחת הדלתות – לא תידרש פעולה יזומה ונפרדת של הנהג.
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group106'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group106.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group106'
                           list='group106_list'
                           value={currentReport.group106.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group106_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group106'
                           onChange={onGroupChangeComment}
                           value={currentReport.group106.comments}

                    />

                  </td>
                </tr>
              </table>
            </div>


          </div>

          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>

            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 11 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <div style={{padding: 20}}></div>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>ממצאים</b></td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>סיווג התאמה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>הערות</b></td>
                </tr>



                <tr>
                  <td className='mt-3 text-align-center'>
                    8.4
                  </td>
                  <td>
                    שילוט על דפנות הרכב עפ"י הרשום ברישיון ההסעה "שם וכתובת חב' ההסעה" למעט אוטובוס פרטי, אוטובוס ציבורי עירוני/בין עירוני.
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group107'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group107.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group107'
                           list='group107_list'
                           value={currentReport.group107.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group107_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group107'
                           onChange={onGroupChangeComment}
                           value={currentReport.group107.comments}

                    />

                  </td>
                </tr>
              </table>
            </div>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >
                <tr style={{background: "lightgrey"}}>

                  <b>בדיקות פיזיות שיש לבצע בתוך האוטובוס</b>

                </tr>
              </table>
            </div>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >
                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>ממצאים</b></td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>סיווג התאמה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>הערות</b></td>
                </tr>



                <tr>
                  <td className='mt-3 text-align-center'>
                    8.5
                  </td>
                  <td>
                    טלוויזיה לטובת הנוסעים תמוקם במקום שאינו נראה לעיני הנהג ובנוסף יש לוודא כי קיים וילון בחלון האחורי.
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group108'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group108.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group108'
                           list='group108_list'
                           value={currentReport.group108.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group108_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group108'
                           onChange={onGroupChangeComment}
                           value={currentReport.group108.comments}

                    />

                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    8.6
                  </td>
                  <td>
                    יש לבדוק כי מס' המושבים הקיימים באוטובוס בפועל לא יעלה על מספר המושבים המותרים כמצוין בהוראת הרישום.
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group107'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group107.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group109'
                           list='group109_list'
                           value={currentReport.group109.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group109_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group109'
                           onChange={onGroupChangeComment}
                           value={currentReport.group109.comments}

                    />

                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    8.7
                  </td>
                  <td>
                    המרווח בין המושבים לא יפחת מ-68 ס"מ (מרווח ברכיים), אלא אם כן צוין אחרת בהוראת הרישום.
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group110'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group110.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group110'
                           list='group110_list'
                           value={currentReport.group110.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group110_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group110'
                           onChange={onGroupChangeComment}
                           value={currentReport.group110.comments}

                    />

                  </td>
                </tr>
              </table>
            </div>


            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10.5%'}}>

                  </td>
                  <td className='mt-3 text-align-center'>
                    <div className='table-no-border' >
                      <img src={pic19} alt={''} style={{ width: '80%' }} />
                    </div>
                  </td>
                </tr>

              </table>
            </div>


            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>ממצאים</b></td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>סיווג התאמה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>הערות</b></td>
                </tr>



                <tr>
                  <td className='mt-3 text-align-center'>
                    8.8
                  </td>
                  <td>
                    במידה וקיימת מדרגה בתוך האוטובוס, יש לוודא סימון במחזיר אור צהוב או כל אמצעי אחר שיסייע לנראות.
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group111'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group111.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group111'
                           list='group111_list'
                           value={currentReport.group111.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group111_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group111'
                           onChange={onGroupChangeComment}
                           value={currentReport.group111.comments}

                    />

                  </td>
                </tr>
              </table>
            </div>


          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>


            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 12 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <div style={{padding: 20}}></div>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>ממצאים</b></td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>סיווג התאמה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>הערות</b></td>
                </tr>



                <tr>
                  <td className='mt-3 text-align-center'>
                    8.9
                  </td>
                  <td>
                    יש לוודא התקנת חגורות בטיחות בכל המושבים כולל מושב נהג, למעט אוטובוס ציבורי המיועד להפעלה בקו תחבורה ציבורית.
                    <b>בהתאם לתקנה 364א. (א)</b>
                    חגורות בטיחות בעלות 3 נקודות עיגון במושב נהג, מדריך, במושבים שמאחורי פתחי הכניסה והיציאה או מעקה, כיסא מרכזי בספסל אחורי.2 נקודות עיגון לפחות בכל שאר המושבים.

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group112'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group112.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group112'
                           list='group112_list'
                           value={currentReport.group112.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group112_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group112'
                           onChange={onGroupChangeComment}
                           value={currentReport.group112.comments}

                    />

                  </td>
                </tr>
              </table>
            </div>


            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >
                <tr style={{background: "lightgrey"}}><b>לרכב המיועד להסעת נוסעים בעמידה</b></tr>

              </table>
            </div>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>ממצאים</b></td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>סיווג התאמה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>הערות</b></td>
                </tr>



                <tr>
                  <td className='mt-3 text-align-center'>
                    8.10
                  </td>
                  <td>
                    התאמה להוראת הרישום
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group113'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group113.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group113'
                           list='group113_list'
                           value={currentReport.group113.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group113_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group113'
                           onChange={onGroupChangeComment}
                           value={currentReport.group113.comments}

                    />

                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    8.11
                  </td>
                  <td>
                    רוחב המעבר 45 ס"מ לפחות.
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group114'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group114.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group114'
                           list='group114_list'
                           value={currentReport.group114.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group114_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group114'
                           onChange={onGroupChangeComment}
                           value={currentReport.group114.comments}

                    />

                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    8.13
                  </td>
                  <td>
                    הגובה מרצפת האוטובוס ועד למוטות האחיזה אינו פחות מ-1.85 מ'.
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group115'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group115.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group115'
                           list='group115_list'
                           value={currentReport.group115.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group115_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group115'
                           onChange={onGroupChangeComment}
                           value={currentReport.group115.comments}

                    />

                  </td>
                </tr>
              </table>
            </div>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >
                <tr style={{background: "lightgrey"}}><b>מידות פנים לאוטובוס זעיר</b></tr>

              </table>
            </div>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>ממצאים</b></td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>סיווג התאמה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>הערות</b></td>
                </tr>



                <tr>
                  <td className='mt-3 text-align-center'>
                    8.14
                  </td>
                  <td>
                    גובהו של פתח הכניסה לא יפחת מ-140 ס"מ. גובה פנימי ימדד באופן הבא: מפני הרצפה עד לתקרה, לא יפחת מ-150 ס"מ.   </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group116'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group116.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group116'
                           list='group116_list'
                           value={currentReport.group116.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group116_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group116'
                           onChange={onGroupChangeComment}
                           value={currentReport.group116.comments}

                    />

                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    8.15
                  </td>
                  <td>
                    הדלת האחורית משמשת כדלת חירום ולכן היא חייבת להיפתח מהפנים החוצה. כדי שתהיה אפשרות של פתיחת הדלת מהפנים החוצה, על המושבים בשורה האחרונה להיות מתקפלים.
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group117'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group117.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group117'
                           list='group117_list'
                           value={currentReport.group117.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group117_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group117'
                           onChange={onGroupChangeComment}
                           value={currentReport.group117.comments}

                    />

                  </td>
                </tr>

              </table>
            </div>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >
                <tr style={{background: "lightgrey"}}><b>דרישות נוספות של הרשות לתחבורה ציבורית (חל על כל כלי הרכב המיועדים לתחבורה ציבורית)</b></tr>

              </table>
            </div>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '120rem'}}
              >

                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>ממצאים</b></td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>סיווג התאמה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>הערות</b></td>
                </tr>



                <tr>
                  <td className='mt-3 text-align-center'>
                    8.16
                  </td>
                  <td>

                    הצגת רישיון הפעלה מהרשות לתחבורה ציבורית במשרד התחבורה לכלי הרכב הנבדק.  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group118'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group118.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group118'
                           list='group118_list'
                           value={currentReport.group118.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group118_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group118'
                           onChange={onGroupChangeComment}
                           value={currentReport.group118.comments}

                    />

                  </td>
                </tr>
              </table>
            </div>

            <h2 className='flex justify-content-center'>
              (*) מותר להתקין מושב מדריך למעט באוטובוס פרטי ואוטובוס בקו שירות.
            </h2>
            <h2 className='flex justify-content-center'>
              (*)יש לציין בהיתרים ובהגבלות "מותקן מושב מדריך".
            </h2>
            <h2 className='flex justify-content-center'>
              (*) מושב מדריך אינו נכלל במס' המושבים הרשום ברישיון הרכב.
            </h2>




          </div>


          <div style={{padding: 100}}></div>


          <div className='reports__container' style={{width: '100%'}}>


            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 13 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <div style={{padding: 20}}></div>

            <h2 className='mt-3 text-align-right'>
              <u>יג.	9. התאמה לדרישות הרגולציה בהתאם לנספח IV של דירקטיבה 2007/46/EC או Annex II של הדירקטיבה EU 858/2018</u>
            </h2>
            <div className='flex justify-content-center'>

              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '150rem'}}
              >
                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '15%',background: "lightgrey"}}><b>רגולציה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '20%',background: "lightgrey"}}><b>ממצאים</b></td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>התאמה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>הערות</b></td>
                </tr>



                <tr>
                  <td className='mt-3 text-align-center'>
                    1a
                  </td>
                  <td>
                    רמת רעש
                  </td>
                  <td>
                    R51
                    (EU)
                    No.540/2014
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group119'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group119.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group119'
                           list='group119_list'
                           value={currentReport.group119.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group119_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group119'
                           onChange={onGroupChangeComment}
                           value={currentReport.group119.comments}

                    />
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    2a
                  </td>
                  <td>
                    זיהום אוויר (יורו 5, יורו 6) וגישה למערכות מידע
                  </td>
                  <td>
                    (EC) No
                    715/2007
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group120'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group120.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group120'
                           list='group120_list'
                           value={currentReport.group120.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group120_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group120'
                           onChange={onGroupChangeComment}
                           value={currentReport.group120.comments}
                    />

                  </td>
                </tr>


                <tr>
                  <td className='mt-3 text-align-center'>
                    3a
                  </td>
                  <td>
                    מניעת סיכוני התלקחות (מכלי דלק נוזלי) - R34
                  </td>
                </tr>

                <tr>
                  <td className='mt-3 text-align-center'>
                    3a
                  </td>

                  <td>
                    נדרש לבדוק האם בוצע הוספה או הוזזה של המיכל (אב טיפוס).
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group121'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group121.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group121'
                           list='group121_list'
                           value={currentReport.group121.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group121_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group121'
                           onChange={onGroupChangeComment}
                           value={currentReport.group121.comments}
                    />

                  </td>
                </tr>

                <tr className='mt-3 text-align-center'>
                  <td>
                    3a
                  </td>

                  <td>
                    יש לוודא קיום הגנה מתאימה.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group122'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group122.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group122'
                           list='group122_list'
                           value={currentReport.group122.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group122_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group122'
                           onChange={onGroupChangeComment}
                           value={currentReport.group122.comments}
                    />
                  </td>
                </tr>

                <tr className='mt-3 text-align-center'>
                  <td>
                    3b
                  </td>

                  <td>
                    התקן הגנה תת רכבית (RUPD)
                  </td>
                  <td>
                    R58
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group123'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group123.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group123'
                           list='group123_list'
                           value={currentReport.group123.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group123_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group123'
                           onChange={onGroupChangeComment}
                           value={currentReport.group123.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    4a
                  </td>

                  <td>
                    מיקום לוחית רישוי אחורי
                  </td>
                  <td>
                    (EC) 661/2009 (EU) No.1003/2010
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group124'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group124.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group124'
                           list='group91_list'
                           value={currentReport.group124.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group124_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group124'
                           onChange={onGroupChangeComment}
                           value={currentReport.group124.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    5a
                  </td>

                  <td>
                    מערכת היגוי
                  </td>
                  <td>
                    R79
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group125'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group125.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group125'
                           list='group125_list'
                           value={currentReport.group125.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group125_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group125'
                           onChange={onGroupChangeComment}
                           value={currentReport.group125.comments}
                    />
                  </td>
                </tr>




                <tr className='mt-3 text-align-center'>
                  <td>
                    7a
                  </td>

                  <td>
                    התקני אזהרה ואותות קוליים
                  </td>
                  <td>
                    R28
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group126'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group126.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group126'
                           list='group126_list'
                           value={currentReport.group126.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group126_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group126'
                           onChange={onGroupChangeComment}
                           value={currentReport.group126.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    8a
                  </td>

                  <td>
                    התקנים לראיה בלתי ישירה והתקנתם
                  </td>
                  <td>
                    R46
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group127'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group127.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group127'
                           list='group127_list'
                           value={currentReport.group127.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group127_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group127'
                           onChange={onGroupChangeComment}
                           value={currentReport.group127.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    9a
                  </td>

                  <td>
                    מערכת בלימה
                  </td>
                  <td>
                    R13
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group128'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group128.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group128'
                           list='group128_list'
                           value={currentReport.group128.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group128_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group128'
                           onChange={onGroupChangeComment}
                           value={currentReport.group128.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    10a
                  </td>

                  <td>
                    תאימות אלקטרומגנטית EMC - R10
                  </td>

                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    10a
                  </td>

                  <td>
                    יש לנטר סימון רגולציה או הומולוגציה על גבי ההתקן, לתעדו ולציין מס' האישור בדו"ח הבדיקה.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group129'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group129.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group129'
                           list='group129_list'
                           value={currentReport.group129.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group129_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group129'
                           onChange={onGroupChangeComment}
                           value={currentReport.group129.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    10a
                  </td>

                  <td>
                    <b>או</b>
                    לנטר הרכבה של כל מכלול חשמלי חדש ברכב, ע"י בונה מרכב. ובהתאמה לכך לבקש תעודת בדיקה או הצהרת יצרן בהתאמה לרגולציה האירופית מס' 10.

                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group130'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group130.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group130'
                           list='group130_list'
                           value={currentReport.group130.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group130_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group130'
                           onChange={onGroupChangeComment}
                           value={currentReport.group130.comments}
                    />
                  </td>
                </tr>


              </table>
            </div>

          </div>

          <div style={{padding: 100}}></div>

          <div className='reports__container' style={{width: '100%'}}>


            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 14 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <div style={{padding: 20}}></div>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '150rem'}}
              >
                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '15%',background: "lightgrey"}}><b>רגולציה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '20%',background: "lightgrey"}}><b>ממצאים</b></td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>התאמה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>הערות</b></td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    13a
                  </td>

                  <td>
                    הגנה על כלי רכב מפני שימוש בלתי מורשה
                  </td>
                  <td>
                    R18
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group131'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group131.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group131'
                           list='group131_list'
                           value={currentReport.group131.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group131_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group131'
                           onChange={onGroupChangeComment}
                           value={currentReport.group131.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    15a
                  </td>

                  <td>
                    חוזק מושבים, עיגונם ומשענות ראש
                  </td>
                  <td>
                    R17
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group132'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group132.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group132'
                           list='group132_list'
                           value={currentReport.group132.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group132_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group132'
                           onChange={onGroupChangeComment}
                           value={currentReport.group132.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    15b
                  </td>

                  <td>
                    מושבים ברכב היסעים
                  </td>
                  <td>
                    R80
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group133'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group133.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group133'
                           list='group133_list'
                           value={currentReport.group133.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group133_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>

                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group133'
                           onChange={onGroupChangeComment}
                           value={currentReport.group133.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    17a
                  </td>

                  <td>
                    נגישות לרכב, תמרון  (הילוך אחורי)
                  </td>
                  <td>
                    (EU) No.130/2012
                    (EC) No.661/2009
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group134'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group134.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group134'
                           list='group134_list'
                           value={currentReport.group134.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group134_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group134'
                           onChange={onGroupChangeComment}
                           value={currentReport.group134.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    17b
                  </td>

                  <td>
                    ציוד מד מהירות כולל ההתקנה
                  </td>
                  <td>
                    R39
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group135'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group135.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group135'
                           list='group135_list'
                           value={currentReport.group135.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group135_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group135'
                           onChange={onGroupChangeComment}
                           value={currentReport.group135.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    18a
                  </td>

                  <td>
                    76/114/EEC
                    (EU) No. 19/2011
                  </td>
                  <td>
                    R39
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group136'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group136.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group136'
                           list='group136_list'
                           value={currentReport.group136.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group136_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group136'
                           onChange={onGroupChangeComment}
                           value={currentReport.group136.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    19a
                  </td>

                  <td>
                    עיגון חגורות בטיחות
                  </td>
                  <td>
                    R14
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group137'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group137.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group137'
                           list='group137_list'
                           value={currentReport.group137.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group137_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group137'
                           onChange={onGroupChangeComment}
                           value={currentReport.group137.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    20a
                  </td>
                  <td>
                    התקנה של אמצעי תאורה ואיתות אור-R48
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    תאורת אזהרה :צבע פנסים כתום.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group138'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group138.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group138'
                           list='group138_list'
                           value={currentReport.group138.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group138_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group138'
                           onChange={onGroupChangeComment}
                           value={currentReport.group138.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    תאורת עבודה פנסים בצבע לבן.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group139'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group139.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group139'
                           list='group139_list'
                           value={currentReport.group139.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group139_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group139'
                           onChange={onGroupChangeComment}
                           value={currentReport.group139.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    איתות:
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    נורת איתות צד המותקנת ע"ג הקבינה או המרכב ‏תראה מזווית 5-60 מעלות.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group140'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group140.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group140'
                           list='group140_list'
                           value={currentReport.group140.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group140_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group140'
                           onChange={onGroupChangeComment}
                           value={currentReport.group140.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    יש לוודא ‏שהמרכב אינו מסתיר את אלומת האור.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group141'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group141.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group141'
                           list='group141_list'
                           value={currentReport.group141.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group141_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group141'
                           onChange={onGroupChangeComment}
                           value={currentReport.group141.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    הנורה תותקן בגובה 1500-500 מ"מ מהקרקע.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group142'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group142.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group142'
                           list='group142_list'
                           value={currentReport.group142.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group142_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group142'
                           onChange={onGroupChangeComment}
                           value={currentReport.group142.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    פנסי איתות קדמים לא יהיו רחוקים מ-1800 מ"מ מהחזית של הקבינה.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group143'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group143.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group143'
                           list='group143_list'
                           value={currentReport.group143.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group143_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group143'
                           onChange={onGroupChangeComment}
                           value={currentReport.group143.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    הפנסים אור כתום.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group144'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group144.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group144'
                           list='group144_list'
                           value={currentReport.group144.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group144_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group144'
                           onChange={onGroupChangeComment}
                           value={currentReport.group144.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    תאורה היקפית, נדרשת ברכבים: כל הרכבים שאורכם מעל 6 מטר:
                  </td>
                </tr>

              </table>
            </div>


          </div>





          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>

            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 15 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <div style={{padding: 20}}></div>


            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '150rem'}}
              >
                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '15%',background: "lightgrey"}}><b>רגולציה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '20%',background: "lightgrey"}}><b>ממצאים</b></td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>התאמה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>הערות</b></td>
                </tr>





                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    מרחק מהחזית של הקבינה לפנס הקדמי ביותר בדופן 3 מטר.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group145'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group145.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group145'
                           list='group145_list'
                           value={currentReport.group145.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group145_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group145'
                           onChange={onGroupChangeComment}
                           value={currentReport.group145.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    מרחקים בין הפנסים 3 מטר לאורך המרכב.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group146'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group146.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group146'
                           list='group146_list'
                           value={currentReport.group146.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group146_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group146'
                           onChange={onGroupChangeComment}
                           value={currentReport.group146.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    מרחק של פנס האחורי מקצה האחורי של המרכב עד 1 מ'.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group147'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group147.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group147'
                           list='group147_list'
                           value={currentReport.group147.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group147_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group147'
                           onChange={onGroupChangeComment}
                           value={currentReport.group147.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    הפנסים בצבע אדום.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group148'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group148.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group148'
                           list='group148_list'
                           value={currentReport.group148.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group148_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group148'
                           onChange={onGroupChangeComment}
                           value={currentReport.group148.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    זווית תאורה אנכית ±10 מעלות.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group149'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group149.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group149'
                           list='group149_list'
                           value={currentReport.group149.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group149_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group149'
                           onChange={onGroupChangeComment}
                           value={currentReport.group149.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    זווית תאורה אופקית‎‏ ±45 מעלות.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group150'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group150.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group150'
                           list='group150_list'
                           value={currentReport.group150.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group150_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group150'
                           onChange={onGroupChangeComment}
                           value={currentReport.group150.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    כל התאורה ההיקפית תופעל דרך מתג אחד.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group151'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group151.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group151'
                           list='group151_list'
                           value={currentReport.group151.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group151_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group151'
                           onChange={onGroupChangeComment}
                           value={currentReport.group151.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    תאורת מרכב:
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    קדמי: צבע הפנס לבן/ענבר.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group152'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group152.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group152'
                           list='group152_list'
                           value={currentReport.group152.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group152_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group152'
                           onChange={onGroupChangeComment}
                           value={currentReport.group152.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    אחורי: צבע הפנס אדום.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group153'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group153.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group153'
                           list='group153_list'
                           value={currentReport.group153.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group153_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group153'
                           onChange={onGroupChangeComment}
                           value={currentReport.group153.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    צדדי: צבע הפנס כתום.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group154'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group154.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group154'
                           list='group154_list'
                           value={currentReport.group154.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group154_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group154'
                           onChange={onGroupChangeComment}
                           value={currentReport.group154.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    תותקן תאורת מרכב אחורי כאשר רוחב המרכב עולה על 2.1 מטר.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group155'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group155.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group155'
                           list='group155_list'
                           value={currentReport.group155.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group155_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group155'
                           onChange={onGroupChangeComment}
                           value={currentReport.group155.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    הפנסים של תאורה האחורית יותקנו בגובה מקסימלי.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group156'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group156.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group156'
                           list='group156_list'
                           value={currentReport.group156.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group156_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group156'
                           onChange={onGroupChangeComment}
                           value={currentReport.group156.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    21a
                  </td>

                  <td>
                    <b>מחזירי אור - R3/R104.</b>
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    צבע הפסים לבן/צהוב.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group157'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group157.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group157'
                           list='group157_list'
                           value={currentReport.group157.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group157_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group157'
                           onChange={onGroupChangeComment}
                           value={currentReport.group157.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    חובה לוחות מחזירי אור מחוץ לתא המנוע ‏לרוחב בצורת פסים זוהרים אדום וצהוב.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group158'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group158.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group158'
                           list='group158_list'
                           value={currentReport.group158.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group158_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group158'
                           onChange={onGroupChangeComment}
                           value={currentReport.group158.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    קצה כל מחזיר אור אחורי יהיה במרחק מעל 200 מ"מ פנסי בלימה.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group159'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group159.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group159'
                           list='group159_list'
                           value={currentReport.group159.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group159_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group159'
                           onChange={onGroupChangeComment}
                           value={currentReport.group159.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    פס תקני עם סימון ת"י או ‏E.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group160'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group160.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group160'
                           list='group160_list'
                           value={currentReport.group160.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group160_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group160'
                           onChange={onGroupChangeComment}
                           value={currentReport.group160.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    22a
                  </td>

                  <td>
                    <b>תאורת קדמיות ואחוריות, תאורת בילום ותאורת קצה - R7</b>
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    לוודא סימון ‏E
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group161'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group161.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group161'
                           list='group161_list'
                           value={currentReport.group161.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group161_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group161'
                           onChange={onGroupChangeComment}
                           value={currentReport.group161.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    תאורה אחורית וקדמית:
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    תראה מזוויות אופקית בין 45 מעלות בין הפנסים ל80 ‏מעלות החוצה.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group162'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group162.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group162'
                           list='group162_list'
                           value={currentReport.group162.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group162_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group162'
                           onChange={onGroupChangeComment}
                           value={currentReport.group162.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    תראה מזוויות אנכית ±15.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group163'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group163.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group163'
                           list='group163_list'
                           value={currentReport.group163.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group163_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group163'
                           onChange={onGroupChangeComment}
                           value={currentReport.group163.comments}
                    />
                  </td>
                </tr>

              </table>
            </div>

          </div>


          <div style={{padding: 100}}></div>


          <div className='reports__container' style={{width: '100%'}}>

            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 16 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <div style={{padding: 20}}></div>


            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '150rem'}}
              >
                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '15%',background: "lightgrey"}}><b>רגולציה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '20%',background: "lightgrey"}}><b>ממצאים</b></td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>התאמה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>הערות</b></td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    אין להתקין רשת על פנסים אחוריים ‏שמסתירה מעל 10% מהפנס.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group164'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group164.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group164'
                           list='group164_list'
                           value={currentReport.group164.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group164_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group164'
                           onChange={onGroupChangeComment}
                           value={currentReport.group164.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    גובה של הפנס מהקרקע בין 350 ל-1500 מ"מ.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group165'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group165.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group165'
                           list='group165_list'
                           value={currentReport.group165.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group165_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group165'
                           onChange={onGroupChangeComment}
                           value={currentReport.group165.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    מרחק מקסימלי של הפנס מקצה הצדדי של המרכב 400 מ"מ.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group166'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group166.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group166'
                           list='group166_list'
                           value={currentReport.group166.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group166_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group166'
                           onChange={onGroupChangeComment}
                           value={currentReport.group166.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    מרחק מינימלי בין פנסים יהיה 3000 מ"מ.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group167'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group167.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group167'
                           list='group167_list'
                           value={currentReport.group167.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group167_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group167'
                           onChange={onGroupChangeComment}
                           value={currentReport.group167.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    22b
                  </td>

                  <td>
                    תאורת אור יום DRL
                  </td>
                  <td>
                    R87
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group168'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group168.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group168'
                           list='group168_list'
                           value={currentReport.group168.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group168_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group168'
                           onChange={onGroupChangeComment}
                           value={currentReport.group168.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    22c
                  </td>

                  <td>
                    תאורה היקפית
                  </td>
                  <td>
                    R91
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group169'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group169.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group169'
                           list='group169_list'
                           value={currentReport.group169.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group169_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group169'
                           onChange={onGroupChangeComment}
                           value={currentReport.group169.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    23a
                  </td>

                  <td>
                    תאורת איתות כיוון
                  </td>
                  <td>
                    R6
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group170'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group170.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group170'
                           list='group170_list'
                           value={currentReport.group170.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group170_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group170'
                           onChange={onGroupChangeComment}
                           value={currentReport.group170.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    24a
                  </td>

                  <td>
                    תאורת לוחית רישוי
                  </td>
                  <td>
                    R4
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group171'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group171.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group171'
                           list='group171_list'
                           value={currentReport.group171.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group171_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group171'
                           onChange={onGroupChangeComment}
                           value={currentReport.group171.comments}
                    />
                  </td>
                </tr>




                <tr className='mt-3 text-align-center'>
                  <td>
                    25a
                  </td>

                  <td>
                    תאורה ראשית SB, פולטת קרן אור אסימטרית
                  </td>
                  <td>
                    R31
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group172'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group172.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group172'
                           list='group172_list'
                           value={currentReport.group172.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group172_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group172'
                           onChange={onGroupChangeComment}
                           value={currentReport.group172.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    25b
                  </td>

                  <td>
                    נורות להט לשימוש בפנסים ראשיים מאושרים
                  </td>
                  <td>
                    R37
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group173'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group173.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group173'
                           list='group173_list'
                           value={currentReport.group173.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group173_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group173'
                           onChange={onGroupChangeComment}
                           value={currentReport.group173.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    25c
                  </td>

                  <td>
                    פנסים ראשים מצוידים בנורות HID
                  </td>
                  <td>
                    R98
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group174'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group174.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group174'
                           list='group174_list'
                           value={currentReport.group174.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group174_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group174'
                           onChange={onGroupChangeComment}
                           value={currentReport.group174.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    25d
                  </td>

                  <td>
                    נורות HID לשימוש בפנסים ראשיים HID מאושרים
                  </td>
                  <td>
                    R99
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group175'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group175.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group175'
                           list='group175_list'
                           value={currentReport.group175.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group175_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group175'
                           onChange={onGroupChangeComment}
                           value={currentReport.group175.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    25e
                  </td>

                  <td>
                    תאורה ראשית פולטת קרן אור אסימטרית  מצוידת עם נורות להט ו/או יחידות
                    LED

                  </td>
                  <td>
                    R112
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group176'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group176.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group176'
                           list='group176_list'
                           value={currentReport.group176.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group176_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group176'
                           onChange={onGroupChangeComment}
                           value={currentReport.group176.comments}
                    />
                  </td>
                </tr>






              </table>
            </div>


          </div>

          <div style={{padding: 100}}></div>

          <div className='reports__container' style={{width: '100%'}}>

            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 17 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <div style={{padding: 20}}></div>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '150rem'}}
              >
                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '15%',background: "lightgrey"}}><b>רגולציה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '20%',background: "lightgrey"}}><b>ממצאים</b></td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>התאמה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>הערות</b></td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    25f
                  </td>

                  <td>
                    מערכות תאורה ראשיות  אדפטיביות
                  </td>
                  <td>
                    R123
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group177'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group177.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group177'
                           list='group177_list'
                           value={currentReport.group177.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group177_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group177'
                           onChange={onGroupChangeComment}
                           value={currentReport.group177.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    26a
                  </td>

                  <td>
                    תאורת ערפל קדמית
                  </td>
                  <td>
                    R19
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group178'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group178.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group178'
                           list='group178_list'
                           value={currentReport.group178.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group178_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group178'
                           onChange={onGroupChangeComment}
                           value={currentReport.group178.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    27a
                  </td>

                  <td>
                    אמצעי גרירה
                  </td>
                  <td>
                    (EC) 661/2009 (EU) No.1005/2010
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group179'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group179.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group179'
                           list='group179_list'
                           value={currentReport.group179.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group179_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group179'
                           onChange={onGroupChangeComment}
                           value={currentReport.group179.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    28a
                  </td>
                  <td>
                    תאורת ערפל אחורית -R38
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    תופעל דרך מתג ייעודי.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group180'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group180.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group180'
                           list='group180_list'
                           value={currentReport.group180.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group180_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group180'
                           onChange={onGroupChangeComment}
                           value={currentReport.group180.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    חובה אינדיקציה בלוח מחוונים.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group181'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group181.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group181'
                           list='group181_list'
                           value={currentReport.group181.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group181_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group181'
                           onChange={onGroupChangeComment}
                           value={currentReport.group181.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    אור הפנסים בצבע צהוב.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group182'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group182.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group182'
                           list='group182_list'
                           value={currentReport.group182.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group182_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group182'
                           onChange={onGroupChangeComment}
                           value={currentReport.group182.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    זווית תאורה אנכית ±50 מעלות.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group183'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group183.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group183'
                           list='group183_list'
                           value={currentReport.group183.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group183_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group183'
                           onChange={onGroupChangeComment}
                           value={currentReport.group183.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    יש לוודא סימון ‏E.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group184'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group184.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group184'
                           list='group184_list'
                           value={currentReport.group184.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group184_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group184'
                           onChange={onGroupChangeComment}
                           value={currentReport.group184.comments}
                    />
                  </td>
                </tr>




                <tr className='mt-3 text-align-center'>
                  <td>
                    29a
                  </td>
                  <td>
                    תאורת הילוך אחורי - R23
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    אור הפנסים בצבע לבן.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group185'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group185.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group185'
                           list='group185_list'
                           value={currentReport.group185.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group185_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group185'
                           onChange={onGroupChangeComment}
                           value={currentReport.group185.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    התאורה תופעל ע"י הכנסת הילוך אחורי.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group186'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group186.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group186'
                           list='group186_list'
                           value={currentReport.group91.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group186_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group186'
                           onChange={onGroupChangeComment}
                           value={currentReport.group186.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    חובה אינדיקציה בלוח מחוונים.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group187'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group187.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group187'
                           list='group187_list'
                           value={currentReport.group187.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group187_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group187'
                           onChange={onGroupChangeComment}
                           value={currentReport.group187.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    לרכב הקצר מ-6 מ' מס' מנורות בין 1 ל-2, לרכב הארוך מ-6 מ' מס' מנורות יהיה בין 2 ל-4.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group188'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group188.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group188'
                           list='group188_list'
                           value={currentReport.group188.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group188_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group188'
                           onChange={onGroupChangeComment}
                           value={currentReport.group188.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    גבוה הפנסים מפני הקרקע בין 250 ל-1200 מ"מ.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group189'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group189.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group189'
                           list='group189_list'
                           value={currentReport.group189.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group189_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group189'
                           onChange={onGroupChangeComment}
                           value={currentReport.group189.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    זווית תאורה ±15 מעלות.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group190'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group190.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group190'
                           list='group190_list'
                           value={currentReport.group190.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group190_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group190'
                           onChange={onGroupChangeComment}
                           value={currentReport.group190.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    יש לוודא סימון ‏E
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group191'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group191.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group191'
                           list='group191_list'
                           value={currentReport.group191.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group191_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group191'
                           onChange={onGroupChangeComment}
                           value={currentReport.group191.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    30a
                  </td>

                  <td>
                    תאורת חנייה
                  </td>
                  <td>
                    R77
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group192'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group192.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group192'
                           list='group192_list'
                           value={currentReport.group192.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group192_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group192'
                           onChange={onGroupChangeComment}
                           value={currentReport.group192.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    31a
                  </td>

                  <td>
                    חגורות בטיחות ומערכות ריסון
                  </td>
                  <td>
                    R16
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group193'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group193.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group193'
                           list='group193_list'
                           value={currentReport.group193.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group193_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group193'
                           onChange={onGroupChangeComment}
                           value={currentReport.group193.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    33a
                  </td>

                  <td>
                    מיקום וזיהוי של אמצעי בקרה וחיווי
                  </td>
                  <td>
                    R121
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group194'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group194.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group194'
                           list='group194_list'
                           value={currentReport.group194.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group194_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group194'
                           onChange={onGroupChangeComment}
                           value={currentReport.group194.comments}
                    />
                  </td>
                </tr>




              </table>
            </div>

          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 18 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>


            <div style={{padding: 20}}></div>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '150rem'}}
              >
                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '15%',background: "lightgrey"}}><b>רגולציה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '20%',background: "lightgrey"}}><b>ממצאים</b></td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>התאמה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>הערות</b></td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    34a
                  </td>

                  <td>
                    מערכות הפשרה וסליקת אדים לשמשה קדמית
                  </td>
                  <td>
                    (EU) No.672/2010
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group195'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group195.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group195'
                           list='group195_list'
                           value={currentReport.group195.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group195_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group195'
                           onChange={onGroupChangeComment}
                           value={currentReport.group195.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    35a
                  </td>

                  <td>
                    מערכת ניקוי וניגוב לשמשה קדמית
                  </td>
                  <td>
                    (EU) No.1008/2010
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group196'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group196.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group196'
                           list='group196_list'
                           value={currentReport.group196.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group196_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group196'
                           onChange={onGroupChangeComment}
                           value={currentReport.group196.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    36a
                  </td>

                  <td>
                    מערכת חימום
                  </td>
                  <td>
                    R122
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group197'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group197.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group197'
                           list='group197_list'
                           value={currentReport.group197.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group197_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group197'
                           onChange={onGroupChangeComment}
                           value={currentReport.group197.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    41a
                  </td>

                  <td>
                    פליטת מזהמים לרכב כבד
                  </td>
                  <td>
                    (EC) No.595/2009
                    (EU) 582/2011
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group198'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group198.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group198'
                           list='group198_list'
                           value={currentReport.group198.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group198_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group198'
                           onChange={onGroupChangeComment}
                           value={currentReport.group198.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    45a
                  </td>

                  <td>
                    בטיחות חלונות (זכוכית)
                  </td>
                  <td>
                    R43
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group199'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group199.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group199'
                           list='group199_list'
                           value={currentReport.group199.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group199_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group199'
                           onChange={onGroupChangeComment}
                           value={currentReport.group199.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    46a
                  </td>

                  <td>
                    התקנת צמיגים ברכב
                  </td>
                  <td>
                    92/23/EEC
                    (EU) No.458/2011
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group200'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group200.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group200'
                           list='group200_list'
                           value={currentReport.group200.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group200_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group200'
                           onChange={onGroupChangeComment}
                           value={currentReport.group200.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    46c
                  </td>

                  <td>
                    צמיגי אוויר לרכב מסחרי
                  </td>
                  <td>
                    R54
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group201'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group201.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group201'
                           list='group201_list'
                           value={currentReport.group201.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group201_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group201'
                           onChange={onGroupChangeComment}
                           value={currentReport.group201.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    46d
                  </td>

                  <td>
                    פליטת רעש גלגול צמיגים, אחיזה על משטחים רטובים והתנגדות לגלילה
                  </td>
                  <td>
                    R117
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group202'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group202.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group202'
                           list='group202_list'
                           value={currentReport.group202.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group202_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group202'
                           onChange={onGroupChangeComment}
                           value={currentReport.group202.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    47a
                  </td>
                  <td>
                    מגביל מהירות
                  </td>
                  <td>
                    R89
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group203'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group203.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group203'
                           list='group203_list'
                           value={currentReport.group203.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group203_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group203'
                           onChange={onGroupChangeComment}
                           value={currentReport.group203.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    <b>בכלי רכב מעל 8 טון יש לוודא שקיים טכוגרף.</b>
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    48a
                  </td>
                  <td>
                    משקלים ומידות
                  </td>
                  <td>
                    (EU) No.1230/2012
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group204'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group204.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group204'
                           list='group204_list'
                           value={currentReport.group204.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group204_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group204'
                           onChange={onGroupChangeComment}
                           value={currentReport.group204.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    50a
                  </td>
                  <td>
                    <b>התקני צימוד מכניים - R55</b>
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>
                  <td>
                    תפוח גרירה / אונקל / נשלף / מקורי ומאושר
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group205'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group205.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group205'
                           list='group205_list'
                           value={currentReport.group205.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group205_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group205'
                           onChange={onGroupChangeComment}
                           value={currentReport.group205.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>
                  <td>
                    גובה ציר האונקל מהקרקע 600-400 מ"מ.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group206'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group206.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group206'
                           list='group206_list'
                           value={currentReport.group206.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group206_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group206'
                           onChange={onGroupChangeComment}
                           value={currentReport.group206.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>
                  <td>
                    גובה ציר אונקל משולב כדור מהקרקע (לציר הכדור) 470-400 מ"מ.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group207'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group207.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group207'
                           list='group207_list'
                           value={currentReport.group207.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group207_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group207'
                           onChange={onGroupChangeComment}
                           value={currentReport.group207.comments}
                    />
                  </td>
                </tr>



              </table>
            </div>


          </div>
          <div style={{padding: 100}}></div>

          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 19 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '150rem'}}
              >
                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '15%',background: "lightgrey"}}><b>רגולציה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '20%',background: "lightgrey"}}><b>ממצאים</b></td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>התאמה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>הערות</b></td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    מזמין בדיקה ימסור את המשקל המרבי המותר לגרירה.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group208'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group208.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group208'
                           list='group208_list'
                           value={currentReport.group208.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group208_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group208'
                           onChange={onGroupChangeComment}
                           value={currentReport.group208.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    נדרשת הוראת התקנה והפעלה של ייצרן הנגרר.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group209'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group209.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group209'
                           list='group209_list'
                           value={currentReport.group209.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group209_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group209'
                           onChange={onGroupChangeComment}
                           value={currentReport.group209.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    גיאומטריה של התקן צימוד יאפשר לגורר תמרון חופשי ובטוח ביחס לרכב, וגם יאפשר אזור בטוח שבו המפעל יכול לעבוד ולברוח ממנו בעת הצורך.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group211'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group211.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group211'
                           list='group211_list'
                           value={currentReport.group211.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group211_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group211'
                           onChange={onGroupChangeComment}
                           value={currentReport.group211.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    51a
                  </td>

                  <td>
                    התנהגות בעירה של החומרים המשמשים לבניית פנים מרכב הרכב.
                  </td>
                  <td>
                    R118
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group212'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group212.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group212'
                           list='group212_list'
                           value={currentReport.group212.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group212_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group212'
                           onChange={onGroupChangeComment}
                           value={currentReport.group212.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    52a
                  </td>

                  <td>
                    תקנה זו חלה על כל רכב חד סיפוני, סיפון כפול, קשיח או מפרקי בקטגוריה
                    M2 או M3
                  </td>
                  <td>
                    R107
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group213'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group213.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group213'
                           list='group213_list'
                           value={currentReport.group213.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group213_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group213'
                           onChange={onGroupChangeComment}
                           value={currentReport.group213.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>
                  <td>
                    ביצוע בדיקות בפנים מרכב הרכב, בנושאים נבחרים בהתאם לשיקול דעת המעבדה, לצורך אימות התאמה של הרכב הנבדק לדרישות העדכניות של האיחוד האירופי WVTA ו/או תקנות התעבורה, כלהלן:
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    52d
                  </td>
                  <td>
                    <b>שילוט וסימון [בהתאם לסעיף 3.3 של Annex 11]:</b>
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>
                  <td>
                    <b>הרכב יסומן באופן ברור מבפנים במצב הנראה לעיני הנהג בתנוחת הישיבה שלו:</b>
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    באותיות שגובהן לא יפחת מ-10 מ"מ ובמספרים שגובהן לא יפחת מ-12 מ"מ כלהלן:
                  </td>
                  <td>
                    R107
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group214'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group214.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group214'
                           list='group214_list'
                           value={currentReport.group214.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group214_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group214'
                           onChange={onGroupChangeComment}
                           value={currentReport.group214.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    המספר המרבי של מקומות ישיבה שהרכב נועד לשאת;
                  </td>
                  <td>
                    R107
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group215'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group215.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group215'
                           list='group215_list'
                           value={currentReport.group215.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group215_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group215'
                           onChange={onGroupChangeComment}
                           value={currentReport.group215.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    המספר המרבי של מקומות עמידה, אם בכלל, שהרכב מיועד לשאת;
                  </td>
                  <td>
                    R107
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group216'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group216.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group216'
                           list='group216_list'
                           value={currentReport.group216.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group216_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group216'
                           onChange={onGroupChangeComment}
                           value={currentReport.group216.comments}
                    />
                  </td>
                </tr>




              </table>
            </div>

          </div>

          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 20 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <div style={{padding: 20}}></div>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '150rem'}}
              >
                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '15%',background: "lightgrey"}}><b>רגולציה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '20%',background: "lightgrey"}}><b>ממצאים</b></td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>התאמה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>הערות</b></td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    המספר המרבי של כיסאות הגלגלים, אם בכלל, שהרכב מיועד לשאת;
                  </td>
                  <td>
                    R107
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group217'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group217.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group217'
                           list='group217_list'
                           value={currentReport.group217.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group217_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group217'
                           onChange={onGroupChangeComment}
                           value={currentReport.group217.comments}
                    />
                  </td>
                </tr>

                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    מסת המטען המקסימלי שניתן לשאת בתא המטען.
                  </td>
                  <td>
                    R107
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group218'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group218.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group218'
                           list='group218_list'
                           value={currentReport.group218.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group218_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group218'
                           onChange={onGroupChangeComment}
                           value={currentReport.group218.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    52e
                  </td>
                  <td>
                    <b>הגנה מפני סיכוני אש</b>
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    קיומה של מערכת אוטומטית לכיבוי אש בתא המנוע.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group219'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group219.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group219'
                           list='group219_list'
                           value={currentReport.group219.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group219_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group219'
                           onChange={onGroupChangeComment}
                           value={currentReport.group219.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    52g
                  </td>
                  <td>
                    <b>מטף אש</b>
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    קיומה של מערכת אוטומטית לכיבוי אש בתא המנוע.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group220'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group220.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group220'
                           list='group220_list'
                           value={currentReport.group220.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group220_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group220'
                           onChange={onGroupChangeComment}
                           value={currentReport.group220.comments}
                    />
                  </td>
                </tr>



              </table>
            </div>



            <tr>
              <td className='mt-3 text-align-center' style={{width: '30%'}}>

              </td>
              <td>
                <img src={pic16} alt={''} style={{ width: '100%' }} />
              </td>
            </tr>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '150rem'}}
              >
                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '15%',background: "lightgrey"}}><b>רגולציה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '20%',background: "lightgrey"}}><b>ממצאים</b></td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>התאמה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>הערות</b></td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    52f
                  </td>

                  <td>
                    ציוד חשמלי
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group221'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group221.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group221'
                           list='group221_list'
                           value={currentReport.group221.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group221_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group221'
                           onChange={onGroupChangeComment}
                           value={currentReport.group221.comments}
                    />
                  </td>
                </tr>


              </table>
            </div>

          </div>

          <div style={{padding: 100}}></div>

          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 21 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <div style={{padding: 20}}></div>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '150rem'}}
              >
                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '45%',background: "lightgrey"}}><b>רגולציה</b></td>

                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    52h
                  </td>
                  <td>
                    <b>מספר ומיקום פתחי יציאה:</b>
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    52h
                  </td>

                  <td>
                    מספר הדלתות המינימלי ברכב יהיה שתיים, שתי דלתות שירות או דלת שירות אחת ודלת חירום אחת. בכל רכב דו-סיפוני יהיו שתי דלתות בסיפון התחתון. המספר המינימלי של דלתות השירות הנדרש הוא כדלקמן:
                  </td>
                  <td>
                    <img src={pic17} alt={''} style={{ width: '70%' }} />
                  </td>

                </tr>
              </table>
            </div>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '150rem'}}
              >
                <tr>
                  <td className='mt-3 text-align-center' style={{width: '20%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '15%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '15%',background: "lightgrey"}}><b>רגולציה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '20%',background: "lightgrey"}}><b>ממצאים</b></td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>התאמה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>הערות</b></td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    52i
                  </td>

                  <td>
                    מס' דלתות שירות
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group222'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group222.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group222'
                           list='group222_list'
                           value={currentReport.group222.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group222_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group222'
                           onChange={onGroupChangeComment}
                           value={currentReport.group222.comments}
                    />
                  </td>
                </tr>

              </table>
            </div>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '150rem'}}
              >
                <tr className='mt-3 text-align-right'>
                  <td>
                    52j
                  </td>
                  <td>
                    <b>***מספר יציאות חירום (דלתות, חלונות, מעברי מדרגות ופתחי חירום) - המספר המינימלי של יציאות חירום יהיה כזה שמספר היציאות הכולל בתא נפרד הוא כדלקמן:</b>
                  </td>
                </tr>

                <tr className='mt-3 text-align-right'>
                  <td>

                  </td>
                  <td>
                    <div className='table-no-border'>
                      <img src={pic18} alt={''} style={{ width: '50%' }} />
                    </div>
                  </td>

                </tr>
              </table>
            </div>

          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 22 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <div style={{padding: 20}}></div>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '150rem'}}
              >
                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '15%',background: "lightgrey"}}><b>רגולציה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '20%',background: "lightgrey"}}><b>ממצאים</b></td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>התאמה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>הערות</b></td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    מס' דלתות חירום
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group223'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group223.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group223'
                           list='group223_list'
                           value={currentReport.group223.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group223_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group223'
                           onChange={onGroupChangeComment}
                           value={currentReport.group223.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    52k
                  </td>

                  <td>
                    מס' חלונות חירום
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group224'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group224.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group224'
                           list='group224_list'
                           value={currentReport.group224.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group224_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group224'
                           onChange={onGroupChangeComment}
                           value={currentReport.group224.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    52l
                  </td>

                  <td>
                    מס' פתחי חירום
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group225'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group225.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group225'
                           list='group225_list'
                           value={currentReport.group225.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group225_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group225'
                           onChange={onGroupChangeComment}
                           value={currentReport.group225.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    52m
                  </td>

                  <td>
                    מעברים וגרמי מדרגות
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group226'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group226.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group226'
                           list='group226_list'
                           value={currentReport.group226.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group226_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group226'
                           onChange={onGroupChangeComment}
                           value={currentReport.group226.comments}
                    />
                  </td>
                </tr>
              </table>
            </div>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '150rem'}}
              >

                <tr className='mt-3 text-align-right' style={{background: "lightgrey"}}>
                  <b>מדרגות – מימדי המדרגות יתאימו לדרישות הבאות:</b>
                </tr>

                <tr className='mt-3 text-align-right'>
                  <td>

                  </td>
                  <td>
                    <img src={pic20} alt={''} style={{ width: '70%' }} />
                  </td>
                </tr>
              </table>
            </div>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '150rem'}}
              >
                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '15%',background: "lightgrey"}}><b>רגולציה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '20%',background: "lightgrey"}}><b>ממצאים</b></td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>התאמה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>הערות</b></td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    52m
                  </td>

                  <td>
                    מדרגות, מימדים
                  </td>
                  <td>
                    גובה:
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group227'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group227.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group227'
                           list='group227_list'
                           value={currentReport.group227.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group227_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group227'
                           onChange={onGroupChangeComment}
                           value={currentReport.group227.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    מדרגות, מימדים
                  </td>
                  <td>
                    עומק:
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group228'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group228.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group228'
                           list='group228_list'
                           value={currentReport.group228.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group228_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group228'
                           onChange={onGroupChangeComment}
                           value={currentReport.group228.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    מדרגות, מימדים
                  </td>
                  <td>
                    חפיפה:
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group229'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group229.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group229'
                           list='group229_list'
                           value={currentReport.group229.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group229_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group229'
                           onChange={onGroupChangeComment}
                           value={currentReport.group229.comments}
                    />
                  </td>
                </tr>




              </table>
            </div>

          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 23 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <div style={{padding: 20}}></div>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '150rem'}}
              >
                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '15%',background: "lightgrey"}}><b>רגולציה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '20%',background: "lightgrey"}}><b>ממצאים</b></td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>התאמה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>הערות</b></td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    52o
                  </td>
                  <td>
                    <b>מעקות אחיזה</b>
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    52o
                  </td>
                  <td>
                    למעקות ולמאחזי יד צריך להיות ממשק המאפשר אחיזה מהירה ונוחה של הנוסעים. כל מעקה צריך לספק מקום ליד באורך של לפחות 100 מ"מ.שום מידה בחתך לא תהיה קטנה מ20 מ"מ וגדולה מ45 מ"מ, חוץ ממקרים של מעקות המורכבות על דלתות ומושבים, וברכבים בקטגוריה II,III,B במעברי גישה. במקרים כאלו קוטרה המעקה המינימלי המותר הינו 15 מ"מ ובתנאי שאחד ממדיות ההתקן יהא לכל הפחות בקוטר של 25 מ"מ.
                    למעקות לא יהיו קצוות חדים.

                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>
                  <td>
                    <b>לרכב המיועד להסעת נוסעים בעמידה</b>
                  </td>
                </tr>





                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    הגובה מרצפת האוטובוס ועד למוטות האחיזה לא יפחת מ-1.85 מטר.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group230'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group230.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group230'
                           list='group230_list'
                           value={currentReport.group230.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group230_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group230'
                           onChange={onGroupChangeComment}
                           value={currentReport.group230.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>
                  <td>
                    <b>מעקות ומאחזי יד לנוסעים בישיבה (ברכב הרשאי להוביל נוסעים בישיבה ועמידה)</b>
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    רק מעקות ומאחזי יד יחשבו כמתאימים לדרישות כאשר גובהם מהרצפה לא יפחת מ-800 מ"מ ולא יעלה על 900 מ"מ.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group231'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group231.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group231'
                           list='group231_list'
                           value={currentReport.group231.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group231_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group231'
                           onChange={onGroupChangeComment}
                           value={currentReport.group231.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    כל מקרה של נוסעים בעמידה, צריך להיות אחד משני הדרישות של מעקות או מאחזי יד, והם צריכים להיות בגובה שלא יעלה על 1500 מ"מ מהרצפה. דרישה זו לא חלה על אזור הסמוך לדלת - במקרה שהדלת נמצאת במצב פתוח והיא מונעת שימוש במאחז היד.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group232'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group232.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group232'
                           list='group232_list'
                           value={currentReport.group232.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group232_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group232'
                           onChange={onGroupChangeComment}
                           value={currentReport.group232.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    אזור שיכול להיות מאוכלס  בנוסעים בעמידה, ולא מופרד ע"י כיסאות מקירות צד או הקיר האחורי של הרכב, צריך להיות מצויד במעקה אופקי מקביל לקיר ומותקן בגובה שבין 800-1500 מ"מ.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group233'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group233.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group233'
                           list='group233_list'
                           value={currentReport.group233.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group233_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group233'
                           onChange={onGroupChangeComment}
                           value={currentReport.group233.comments}
                    />
                  </td>
                </tr>





              </table>
            </div>

          </div>

          <div style={{padding: 100}}></div>


          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 24 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <div style={{padding: 20}}></div>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '150rem'}}
              >
                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '15%',background: "lightgrey"}}><b>רגולציה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '20%',background: "lightgrey"}}><b>ממצאים</b></td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>התאמה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>הערות</b></td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>
                  <td>
                    <b>מעקות במושבים מועדפים</b>
                  </td>
                </tr>




                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    מעקות בגובה שבין 800-900 מ"מ, מעל הרצפה, צריכים להיות מותקנים בין המושבים המועדפים לבין דלתות השירות, לצורכי עליה וירידה. אי רציפות במעקות מותרת אם יש צורך בגישה לכיסא נכים, כיסא הממוקם בקשת הגלגלים, גרם מדרגות, גישה למעבר או מעבר. ההפרדה לא תעלה על 1050 מ"מ, ומעקה אופקי אחד לפחות יסופק בצד אחד של ההפרדה.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group234'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group234.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group234'
                           list='group234_list'
                           value={currentReport.group234.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group234_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group234'
                           onChange={onGroupChangeComment}
                           value={currentReport.group234.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>
                  <td>
                    <b>מושבים מרווחים לנוסעים</b>
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    52p
                  </td>

                  <td>
                    המרווח בין המושבים ברכב מקטגוריה M3 לא יפחת מ-68 ס"מ (מרווח ברכיים), אלא אם כן צוין אחרת בהוראת הרישום.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group235'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group235.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group235'
                           list='group235_list'
                           value={currentReport.group235.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group235_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group235'
                           onChange={onGroupChangeComment}
                           value={currentReport.group235.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    52q
                  </td>
                  <td>
                    <b>תקשורת עם הנהג</b>
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    ברכבים בקטגוריה I,II,A צריך להיות אמצעי המאפשר לנוסעים לסמן לנהג שהם רוצים לעצור את הרכב.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group236'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group236.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group236'
                           list='group236_list'
                           value={currentReport.group236.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group236_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group236'
                           onChange={onGroupChangeComment}
                           value={currentReport.group236.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    למערכת תקשורת זו צריכים להיות לחצני הפעלה בולטים, ברכבים בקטגוריה I,A, לא יותר מ1200 מ"מ מהרצפה.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group237'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group237.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group237'
                           list='group237_list'
                           value={currentReport.group237.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group237_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group237'
                           onChange={onGroupChangeComment}
                           value={currentReport.group237.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    לחצני הפעלה צריכים להיות בעלי צבע או צבעים מנוגדים.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group238'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group238.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group238'
                           list='group238_list'
                           value={currentReport.group238.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group238_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group238'
                           onChange={onGroupChangeComment}
                           value={currentReport.group238.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    צריך להיות מספיק לחצנים הפרוסים באופן שווה לאורך הרכב.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group239'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group239.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group239'
                           list='group239_list'
                           value={currentReport.group239.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group239_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group239'
                           onChange={onGroupChangeComment}
                           value={currentReport.group239.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    הפעלה של הלחצנים צריכה לתת אינדיקציה לנוסעים באמצעות שלט אחד או שניים מוארים. האינדיקציה צריכה לכלול, לדוגמא: "האוטובוס עוצר".
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group240'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group240.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group240'
                           list='group240_list'
                           value={currentReport.group240.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group240_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group240'
                           onChange={onGroupChangeComment}
                           value={currentReport.group240.comments}
                    />
                  </td>
                </tr>


              </table>
            </div>

          </div>

          <div style={{padding: 100}}></div>



          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 25 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <div style={{padding: 20}}></div>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '150rem'}}
              >
                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '15%',background: "lightgrey"}}><b>רגולציה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '20%',background: "lightgrey"}}><b>ממצאים</b></td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>התאמה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>הערות</b></td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    52r
                  </td>
                  <td>
                    <b>מכונות למשקאות חמים ובישול</b>
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    יש לוודא אישור מערכת ב- WVTA
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group241'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group241.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group241'
                           list='group241_list'
                           value={currentReport.group241.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group241_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group241'
                           onChange={onGroupChangeComment}
                           value={currentReport.group241.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    במידה והמערכת מופעלת בגז: יש לקבל אישור טכנאי גז למערכת.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group242'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group242.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group242'
                           list='group242_list'
                           value={currentReport.group242.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group242_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group242'
                           onChange={onGroupChangeComment}
                           value={currentReport.group242.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    52s
                  </td>
                  <td>
                    <b>דלתות פנים המרכב</b>
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    מס' דלתות השרות לא יפחת מ-2 כפוף להגבלות המפורטות לעיל.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group243'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group243.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group243'
                           list='group243_list'
                           value={currentReport.group243.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group243_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group243'
                           onChange={onGroupChangeComment}
                           value={currentReport.group243.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    52t
                  </td>
                  <td>
                    <b>דלתות פנים המרכב</b>
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    <b>תאורה של תאי מטען וחיווי דלת מטען פתוחה</b>
                    התאורה צריכה להיות מותקנת בתוך תא המטען בשביל להאיר את פנים התא, בנוסף נורית חיווי על לוח המחוונים אשר מתריעה לנהג כאשר דלת תא המטען פתוחה.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group244'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group244.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group244'
                           list='group244_list'
                           value={currentReport.group244.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group244_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group244'
                           onChange={onGroupChangeComment}
                           value={currentReport.group244.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    52u
                  </td>
                  <td>
                    <b>הגנה על גרם מדרגות</b>
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    יש לוודא סימון במחזיר אור צהוב או כל אמצעי אחר שיסייע לנראות.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group245'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group245.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group245'
                           list='group245_list'
                           value={currentReport.group245.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group245_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group245'
                           onChange={onGroupChangeComment}
                           value={currentReport.group245.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    52u
                  </td>
                  <td>
                    <b>כבודה והגנה על נוסעים</b>
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    לרכבים מקטגוריה III,II,B תאי המטען צריכים להיות מותקנים בתוך גוף מרכב הרכב.תאי המטען צריכים להיות מתוכננים לקחת מטען בצפיפות נפחית של  Kg/m³ 100 במינימום קיבולת לפי: 7 קילוגרם/לנוסע (בקטגוריות III,II).
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group246'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group246.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group246'
                           list='group246_list'
                           value={currentReport.group246.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group246_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group246'
                           onChange={onGroupChangeComment}
                           value={currentReport.group246.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    תאי המטען צריכים להיות: נגישים להעמסה ופריקה וצריכים להיות ניתנים לנעילה, אטומים לאבק ולחות.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group247'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group247.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group247'
                           list='group247_list'
                           value={currentReport.group247.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group247_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group247'
                           onChange={onGroupChangeComment}
                           value={currentReport.group247.comments}
                    />
                  </td>
                </tr>



              </table>
            </div>


          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 26 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>



            <div style={{padding: 20}}></div>

            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '150rem'}}
              >
                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '15%',background: "lightgrey"}}><b>רגולציה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '20%',background: "lightgrey"}}><b>ממצאים</b></td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>התאמה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>הערות</b></td>
                </tr>




                <tr className='mt-3 text-align-center'>
                  <td>
                    52v
                  </td>

                  <td>
                    יש לוודא התקנת חגורות בטיחות בכל המושבים כולל מושב נהג, למעט אוטובוס ציבורי המיועד להפעלה בקו תחבורה ציבורית.
                    <b> בהתאם לתקנה 364א. (א)</b>
                    חגורות בטיחות בעלות 3 נקודות עיגון במושב נהג, מדריך, במושבים שמאחורי פתחי הכניסה והיציאה או מעקה, כיסא מרכזי בספסל אחורי.2 נקודות עיגון לפחות בכל שאר המושבים.

                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group248'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group248.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group248'
                           list='group248_list'
                           value={currentReport.group248.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group248_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group248'
                           onChange={onGroupChangeComment}
                           value={currentReport.group248.comments}
                    />
                  </td>
                </tr>

                <tr className='mt-3 text-align-center'>
                  <td>
                    52w
                  </td>
                  <td>
                    <b>בטיחות דלתות</b>
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    <b>הפעלת דלתות של תאי מטען</b>
                    באוטובוסים שבהם דלת תא המטען נשלטת מתא הנהג, חייבת להיות מותקנת מערכת ניטור שגורמת לדלת להיפתח אם הדלת מזהה חפץ ברוחב של לפחות 50 מ"מ.
                    סגירה חוזרת של הדלת צריכה להיות נשלטת ע"י הנהג.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group249'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group249.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group249'
                           list='group249_list'
                           value={currentReport.group249.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group249_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group249'
                           onChange={onGroupChangeComment}
                           value={currentReport.group249.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    52x
                  </td>
                  <td>
                    <b>מערכת בידור חזותי</b>
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    טלוויזיה לטובת הנוסעים תמוקם במקום שאינו נראה לעיני הנהג ובנוסף יש לוודא כי קיים וילון בחלון האחורי.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group250'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group250.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group250'
                           list='group250_list'
                           value={currentReport.group250.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group250_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group250'
                           onChange={onGroupChangeComment}
                           value={currentReport.group250.comments}
                    />
                  </td>
                </tr>


              </table>
            </div>


          </div>
          <div style={{padding: 100}}></div>
          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 27 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>



            <div style={{padding: 20}}></div>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '150rem'}}
              >
                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '15%',background: "lightgrey"}}><b>רגולציה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '20%',background: "lightgrey"}}><b>ממצאים</b></td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>התאמה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>הערות</b></td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    52y
                  </td>
                  <td>
                    <b>כלי רכב מפרקיים</b>
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>
                  <td>
                    <b>התקנת מראות פנימיות</b>
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    במידה ומותקנת ברכב דלת שרות מאחורי דלת הנהג, יש להתקין מעל הדלת מראה קמורה עגולה, בקוטר של 250 מ"מ לפחות, אשר תאפשר לנהג לראות את אזור הדלת ואזור המדרגות. התקן המראה ואביזריה צריך להיות מותקן באופן קבוע וממוקם:
                    1.קו הראייה לא יהיה מופרע מנוסעים עומדים, ממטען או מתאי המטען שבתוך הרכב.
                    2.לא תהווה סיכון בטיחותי לנוסעים.
                    בדלת האחורית ביותר של "אוטובוס מפרקי", כחלופה השונות ניתן להתקין מע' ניטור מבוססת על מצלמה, מעל הדלת השרות האחורית, אשר תאפשר לנהג לראות את פעילות הדלת ואזור המדרגות. צג המערכת ימוקם בתא הנהג וגודלו יהיה 4 אינץ לפחות.

                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group251'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group251.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group251'
                           list='group251_list'
                           value={currentReport.group251.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group251_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group251'
                           onChange={onGroupChangeComment}
                           value={currentReport.group251.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>
                  <td>
                    <b>גישה לנוסעים עם ניידות מוגבלת - אוטובוס עירוני</b>
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    לא יירשם לראשונה, לפי תקנות התעבורה, אלא אם כן הוא אוטובוס נגיש.<b>אוטובוס עירוני</b>

                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group252'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group252.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group252'
                           list='group252_list'
                           value={currentReport.group252.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group252_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group252'
                           onChange={onGroupChangeComment}
                           value={currentReport.group252.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>
                  <td>
                    <b>אוטובוס נגיש - באוטובוס נגיש יתקיימו כל אלה:</b>
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    (1) מספר הקו ויעדו צוינו בספרות ואותיות בצבע צהוב על רקע שחור לא מבריק;

                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group253'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group253.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group253'
                           list='group253_list'
                           value={currentReport.group253.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group253_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group253'
                           onChange={onGroupChangeComment}
                           value={currentReport.group253.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    (2)  רוחב הדלת המיועדת לכניסה של אדם הנע בכיסא גלגלים, שלא יפחת מ-100 ס"מ, מאפשר

                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group254'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group254.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group254'
                           list='group254_list'
                           value={currentReport.group254.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group254_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group254'
                           onChange={onGroupChangeComment}
                           value={currentReport.group254.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    (3) הותקנו בו סמלי הנגישות;

                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group255'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group255.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group255'
                           list='group255_list'
                           value={currentReport.group255.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group255_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group255'
                           onChange={onGroupChangeComment}
                           value={currentReport.group255.comments}
                    />
                  </td>
                </tr>

                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    (4) הותקן בו מיתקן הרמה לכניסה ויציאה של נוסעים הנעים בכיסא גלגלים;

                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group256'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group256.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group256'
                           list='group256_list'
                           value={currentReport.group256.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group256_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group256'
                           onChange={onGroupChangeComment}
                           value={currentReport.group256.comments}
                    />
                  </td>
                </tr>

                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    (5) הותקן בו מנגנון למניעת תנועה של האוטובוס בעת הפעלת מיתקן ההרמה;

                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group257'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group257.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group257'
                           list='group257_list'
                           value={currentReport.group257.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group257_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group257'
                           onChange={onGroupChangeComment}
                           value={currentReport.group257.comments}
                    />
                  </td>
                </tr>

                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    (6) מסעדי יד, אם הותקנו בו, ניתנים להרמה במושבים המיועדים לאנשים עם מוגבלות, והם

                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group258'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group258.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group258'
                           list='group258_list'
                           value={currentReport.group258.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group258_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group258'
                           onChange={onGroupChangeComment}
                           value={currentReport.group258.comments}
                    />
                  </td>
                </tr>

              </table>
            </div>

          </div>


          <div style={{padding: 100}}></div>


          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 28 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>



            <div style={{padding: 20}}></div>
            <div className='flex justify-content-center'>
              <table
                  className='reports__table reports__table--dark'
                  style={{maxWidth: '150rem'}}
              >
                <tr>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>מס"ד</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>נושא</b></td>
                  <td className='mt-3 text-align-center' style={{width: '15%',background: "lightgrey"}}><b>רגולציה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '20%',background: "lightgrey"}}><b>ממצאים</b></td>
                  <td className='mt-3 text-align-center' style={{width: '10%',background: "lightgrey"}}><b>התאמה</b></td>
                  <td className='mt-3 text-align-center' style={{width: '25%',background: "lightgrey"}}><b>הערות</b></td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    (7) מאחזי יד, בליטות ולחצני עצירה סומנו בצבע צהוב על רקע הנוגד את סביבתו;
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group259'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group259.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group259'
                           list='group259_list'
                           value={currentReport.group259.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group259_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group259'
                           onChange={onGroupChangeComment}
                           value={currentReport.group259.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    (8) בפתחי הכניסה והיציאה ובקצות המדרגות הותקן, לכל רוחב הסף, פס אזהרה;
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group260'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group260.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group260'
                           list='group260_list'
                           value={currentReport.group260.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group260_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group260'
                           onChange={onGroupChangeComment}
                           value={currentReport.group260.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    (9)  במחצית הקדמית של האוטובוס, משני צדי המעבר, הוקצו שלושה מקומות ישיבה לאנשים
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group261'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group261.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group261'
                           list='group261_list'
                           value={currentReport.group261.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group261_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group261'
                           onChange={onGroupChangeComment}
                           value={currentReport.group261.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    (10) מול הדלת המיועדת לכניסתו, הוקצה מקום לכיסא גלגלים עם האדם המתנייע בו,
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group261'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group261.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group261'
                           list='group261_list'
                           value={currentReport.group261.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group261_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group261'
                           onChange={onGroupChangeComment}
                           value={currentReport.group261.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    (11) בטווח יד מהמקום שהוקצה לכיסא הגלגלים, יותקן לחצן עצירה אשר יפעיל נורית
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group262'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group262.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group262'
                           list='group262_list'
                           value={currentReport.group262.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group262_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group262'
                           onChange={onGroupChangeComment}
                           value={currentReport.group262.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>

                  </td>

                  <td>
                    (12)הותקנו בו מערכות לכריזה פנימית וחיצונית.
                  </td>
                  <td>

                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group263'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group263.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group263'
                           list='group263_list'
                           value={currentReport.group263.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group263_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group263'
                           onChange={onGroupChangeComment}
                           value={currentReport.group263.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    52b
                  </td>

                  <td>
                    חוזק מבנה המרכב
                  </td>
                  <td>
                    R66
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group264'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group264.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group264'
                           list='group264_list'
                           value={currentReport.group264.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group264_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group264'
                           onChange={onGroupChangeComment}
                           value={currentReport.group264.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    62
                  </td>

                  <td>
                    מערכות מימן
                  </td>
                  <td>
                    (EC) No.79/2009
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group265'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group265.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group265'
                           list='group265_list'
                           value={currentReport.group265.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group265_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group265'
                           onChange={onGroupChangeComment}
                           value={currentReport.group265.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    63
                  </td>

                  <td>
                    בטיחות כללית
                  </td>
                  <td>
                    (EC) No.661/20
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group266'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group266.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group266'
                           list='group266group263_list'
                           value={currentReport.group266.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group266_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group266'
                           onChange={onGroupChangeComment}
                           value={currentReport.group266.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    65
                  </td>

                  <td>
                    מערכות בלימת חירום מתקדמות
                    AEBS
                  </td>
                  <td>
                    R131
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group267'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group267.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group267'
                           list='group267_list'
                           value={currentReport.group267.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group267_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group267'
                           onChange={onGroupChangeComment}
                           value={currentReport.group267.comments}
                    />
                  </td>
                </tr>


                <tr className='mt-3 text-align-center'>
                  <td>
                    66
                  </td>

                  <td>
                    מערכת התרעה לסטייה מנתיב  LDWS
                  </td>
                  <td>
                    R130
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group268'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group268.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group268'
                           list='group268_list'
                           value={currentReport.group268.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group268_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group268'
                           onChange={onGroupChangeComment}
                           value={currentReport.group268.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    67
                  </td>

                  <td>
                    מערכות מנוע המונע בגז (LPG)  והתקנתו
                  </td>
                  <td>
                    R67
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group269'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group269.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group269'
                           list='group269_list'
                           value={currentReport.group269.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group269_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group269'
                           onChange={onGroupChangeComment}
                           value={currentReport.group269.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    69
                  </td>

                  <td>
                    בטיחות מערכת חשמל
                  </td>
                  <td>
                    R100
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group270'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group270.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group270'
                           list='group270_list'
                           value={currentReport.group270.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group270_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group270'
                           onChange={onGroupChangeComment}
                           value={currentReport.group270.comments}
                    />
                  </td>
                </tr>



                <tr className='mt-3 text-align-center'>
                  <td>
                    70
                  </td>

                  <td>
                    רכיבים למערכות CNG והתקנתם
                  </td>
                  <td>
                    R110
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group271'
                           onChange={onGroupChangeFinds}
                           value={currentReport.group271.finds}/>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group271'
                           list='group271_list'
                           value={currentReport.group271.classification}
                           onChange={onGroupChangeClassification}
                    />
                    <datalist id="group271_list">
                      <option value="A">כן</option>
                      <option value="V">לא</option>
                      <option value="N/A">לא ישים</option>
                    </datalist>
                  </td>
                  <td>
                    <input className='reports_text'
                           type='text'
                           name='group271'
                           onChange={onGroupChangeComment}
                           value={currentReport.group271.comments}
                    />
                  </td>
                </tr>





              </table>
            </div>

          </div>


          <div style={{padding: 100}}></div>

          <div className='reports__container' style={{width: '100%'}}>
            <table className='reports__table'>
              <tr className='reports__table-header'>
                <td style={setWidth(25)}>&nbsp;
                  <h2 className='flex justify-content-center'>
                    <div className='table-no-border'>
                      <img src={logo3} alt={''} style={{ width: '100%' }} />
                    </div>
                  </h2>
                </td>
                <td
                    style={setWidth(50)}
                    className=' text-align-center font-weight-700'
                >
                  טופס בדיקה
                </td>
                <td>
                  <table className='width-100 text-align-center'>
                    <tr>
                      <td colSpan='2'> {moment().format('DD-MM-YY')}</td>
                    </tr>
                    <tr>
                      <td>מהדורה</td>
                      <td>00</td>
                    </tr>
                    <tr className='width-100 text-align-center'>

                      <b>עמוד 29 מתוך 29</b>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>




            <h2 className='mt-3 text-align-right'>
              הערות כלליות ו/או פרטים רלוונטיים אחרים
              <b>(יש לתעד את הרכב, חוץ, פנים, תוויות סטטוטוריות, מכלולים עיקריים ושקילות).</b>
            </h2>

            <textarea className='reports_text' rows="10" cols="33"

                      type='text'
                      name='group272'
                      onChange={onGroupChangeComment}
                      value={currentReport.group272.comments}/>


            <td className='mt-3 text-align-center'>
              <h1 className='mt-3 text-align-center'>
                <b>חתימת הבודק :</b>
              </h1>

              <div>
                {pSigImage ? (
                    <img
                        src={pSigImage}
                        alt='אין חתימה'
                        style={{width: '100px', height: '100px'}}
                    />
                ) : (
                    'אין חתימה'
                )}
              </div>

            </td>

            <h1 className='mt-3 text-align-center'>
              <b>****סוף מסמך****</b>
            </h1>




          </div>



          <div style={{padding: 100}}></div>

          <div className='reports__container' style={{width: '100%'}}>

            <div className='mt-3 text-underline'>
              <h1 className='mt-3 text-align-right'>תמונות</h1>
              <div className='flex justify-content-center'>
                <table
                    className='reports__table reports__table--dark'
                    style={{maxWidth: '120rem'}}
                >
                  <tr>
                    <td style={{width: '5%'}}>מס"ד</td>
                    <td style={{width: '10%'}}>שם התמונה</td>
                    <td style={{width: '10%'}}>העלת תמונה</td>
                    <td style={{width: '10%'}}>תצוגת תמונה</td>
                    <td style={{width: '10%'}}>סטטוס </td>
                    <td style={{width: '10%'}}>האם לשים בתעודה?</td>
                  </tr>


                  <tr>
                    <td>
                      1
                    </td>
                    <td>
                      חזית
                    </td>
                    <td>
                      <FileUpload
                          setFile={setImage1}
                          name='image1'
                          text='image1'
                      />
                    </td>
                    <td>
                      <div>
                        {pImage1 ? (
                            <img
                                src={pImage1}
                                alt='לא הועלה תמונה'
                                style={{width: '100px', height: '100px',transform: `rotate(${-90}deg)`}}
                            />
                        ) : (
                            'אין תמונה'
                        )}
                      </div>

                    </td>
                    <td>
                      <div

                          className={`${
                              !Image1 ? '' : 'bg-success'
                          } color-white p-1`}
                      >
                        {!Image1 ? '' : 'נבחר'}
                      </div>

                    </td>

                    <td>

                    </td>

                  </tr>

                  <tr>
                    <td>
                      2
                    </td>
                    <td>
                      אחורי
                    </td>
                    <td>

                      <FileUpload
                          setFile={setImage2}
                          name='image2'
                          text='image2'
                      />
                    </td>
                    <td>
                      <div>
                        {pImage2 ? (
                            <img
                                src={pImage2}
                                alt='לא הועלה תמונה'
                                style={{width: '100px', height: '100px',transform: `rotate(${-90}deg)`}}
                            />
                        ) : (
                            'אין תמונה'
                        )}
                      </div>

                    </td>
                    <td>
                      <div
                          className={`${
                              !Image2 ? '' : 'bg-success'
                          } color-white p-1`}
                      >
                        {!Image2 ? '' : 'נבחר'}
                      </div>

                    </td>

                    <td>

                    </td>
                  </tr>

                  <tr>
                    <td>
                      3
                    </td>
                    <td>
                      תווית Vin
                    </td>
                    <td>

                      <FileUpload
                          setFile={setImage3}
                          name='image3'
                          text='image3'
                      />
                    </td>
                    <td>
                      <div>
                        {pImage3 ? (
                            <img
                                src={pImage3}
                                alt='לא הועלה תמונה'
                                style={{width: '100px', height: '100px',transform: `rotate(${-90}deg)`}}
                            />
                        ) : (
                            'אין תמונה'
                        )}
                      </div>

                    </td>
                    <td>
                      <div
                          className={`${
                              !Image3 ? '' : 'bg-success'
                          } color-white p-1`}
                      >
                        {!Image3 ? '' : 'נבחר'}
                      </div>

                    </td>

                    <td>

                    </td>
                  </tr>

                  <tr>
                    <td>
                      4
                    </td>
                    <td>
                      <input
                          type='text'
                          name='image4'
                          onChange={onGroupChangeComment}
                          value={currentReport.image4.comments}
                          list='image4_list'
                      />
                      <datalist id='image4_list'>
                        <option value='תיעוד הטבעה על המרכב'> תיעוד הטבעה על המרכב</option>
                        <option value='תיעוד תווית המרכב'> תיעוד תווית המרכב</option>
                        <option value='משקל קדמי ימין'>משקל קדמי ימין </option>
                        <option value='משקל קדמי שמאל'> משקל קדמי שמאל </option>
                        <option value='משקל אחורי ימין'>משקל אחורי ימין</option>
                        <option value='משקל אחורי שמאל'> משקל אחורי שמאל </option>
                        <option value='משקל כללי'> משקל כללי </option>
                        <option value='משקל אחורי'> משקל אחורי </option>
                        <option value='משקל קדמי'> משקל קדמי </option>
                        <option value='תיעוד תווית דופן הידראולית'> תיעוד תווית דופן הידראולית </option>
                        <option value='תיעוד תווית מנוף'> תיעוד תווית מנוף</option>
                        <option value='תיעוד הטבעת על המנוף'> תיעוד הטבעת על המנוף</option>
                        <option value='תיעוד תוויות התקו צימוד'> תיעוד תוויות התקו צימוד</option>
                        <option value='תיעוד התקן צימוד'> תיעוד התקן צימוד</option>
                      </datalist>
                    </td>
                    <td>

                      <FileUpload
                          setFile={setImage4}
                          name='image4'
                          text='image4'
                      />
                    </td>
                    <td>
                      <div>
                        {pImage4 ? (
                            <img
                                src={pImage4}
                                alt='לא הועלה תמונה'
                                style={{width: '100px', height: '100px',transform: `rotate(${-90}deg)`}}
                            />
                        ) : (
                            'אין תמונה'
                        )}
                      </div>

                    </td>
                    <td>
                      <div
                          className={`${
                              !Image4 ? '' : 'bg-success'
                          } color-white p-1`}
                      >
                        {!Image4 ? '' : 'נבחר'}
                      </div>

                    </td>

                    <td>

                    </td>
                  </tr>

                  <tr>
                    <td>
                      5
                    </td>
                    <td>
                      <input
                          type='text'
                          name='image5'
                          onChange={onGroupChangeComment}
                          value={currentReport.image5.comments}
                          list='image5_list'
                      />
                      <datalist id='image5_list'>
                        <option value='תיעוד הטבעה על המרכב'> תיעוד הטבעה על המרכב</option>
                        <option value='תיעוד תווית המרכב'> תיעוד תווית המרכב</option>
                        <option value='משקל קדמי ימין'>משקל קדמי ימין </option>
                        <option value='משקל קדמי שמאל'> משקל קדמי שמאל </option>
                        <option value='משקל אחורי ימין'>משקל אחורי ימין</option>
                        <option value='משקל אחורי שמאל'> משקל אחורי שמאל </option>
                        <option value='משקל כללי'> משקל כללי </option>
                        <option value='משקל אחורי'> משקל אחורי </option>
                        <option value='משקל קדמי'> משקל קדמי </option>
                        <option value='תיעוד תווית דופן הידראולית'> תיעוד תווית דופן הידראולית </option>
                        <option value='תיעוד תווית מנוף'> תיעוד תווית מנוף</option>
                        <option value='תיעוד הטבעת על המנוף'> תיעוד הטבעת על המנוף</option>
                        <option value='תיעוד תוויות התקו צימוד'> תיעוד תוויות התקו צימוד</option>
                        <option value='תיעוד התקן צימוד'> תיעוד התקן צימוד</option>
                      </datalist>
                    </td>
                    <td>

                      <FileUpload
                          setFile={setImage5}
                          name='image5'
                          text='image5'
                      />
                    </td>
                    <td>
                      <div>
                        {pImage5 ? (
                            <img
                                src={pImage5}
                                alt='לא הועלה תמונה'
                                style={{width: '100px', height: '100px',transform: `rotate(${-90}deg)`}}
                            />
                        ) : (
                            'אין תמונה'
                        )}
                      </div>

                    </td>
                    <td>
                      <div
                          className={`${
                              !Image5 ? '' : 'bg-success'
                          } color-white p-1`}
                      >
                        {!Image5 ? '' : 'נבחר'}
                      </div>

                    </td>

                    <td>

                    </td>
                  </tr>

                  <tr>
                    <td>
                      6
                    </td>
                    <td>
                      <input
                          type='text'
                          name='image6'
                          onChange={onGroupChangeComment}
                          value={currentReport.image6.comments}
                          list='image6_list'
                      />
                      <datalist id='image6_list'>
                        <option value='תיעוד הטבעה על המרכב'> תיעוד הטבעה על המרכב</option>
                        <option value='תיעוד תווית המרכב'> תיעוד תווית המרכב</option>
                        <option value='משקל קדמי ימין'>משקל קדמי ימין </option>
                        <option value='משקל קדמי שמאל'> משקל קדמי שמאל </option>
                        <option value='משקל אחורי ימין'>משקל אחורי ימין</option>
                        <option value='משקל אחורי שמאל'> משקל אחורי שמאל </option>
                        <option value='משקל כללי'> משקל כללי </option>
                        <option value='משקל אחורי'> משקל אחורי </option>
                        <option value='משקל קדמי'> משקל קדמי </option>
                        <option value='תיעוד תווית דופן הידראולית'> תיעוד תווית דופן הידראולית </option>
                        <option value='תיעוד תווית מנוף'> תיעוד תווית מנוף</option>
                        <option value='תיעוד הטבעת על המנוף'> תיעוד הטבעת על המנוף</option>
                        <option value='תיעוד תוויות התקו צימוד'> תיעוד תוויות התקו צימוד</option>
                        <option value='תיעוד התקן צימוד'> תיעוד התקן צימוד</option>
                      </datalist>
                    </td>
                    <td>

                      <FileUpload
                          setFile={setImage6}
                          name='image6'
                          text='image6'
                      />
                    </td>
                    <td>
                      <div>
                        {pImage6 ? (
                            <img
                                src={pImage6}
                                alt='לא הועלה תמונה'
                                style={{width: '100px', height: '100px',transform: `rotate(${-90}deg)`}}
                            />
                        ) : (
                            'אין תמונה'
                        )}
                      </div>

                    </td>
                    <td>
                      <div
                          className={`${
                              !Image6 ? '' : 'bg-success'
                          } color-white p-1`}
                      >
                        {!Image6 ? '' : 'נבחר'}
                      </div>

                    </td>

                    <td>

                    </td>
                  </tr>

                  <tr>
                    <td>
                      7
                    </td>
                    <td>
                      <input
                          type='text'
                          name='image7'
                          onChange={onGroupChangeComment}
                          value={currentReport.image7.comments}
                          list='image7_list'
                      />
                      <datalist id='image7_list'>
                        <option value='תיעוד הטבעה על המרכב'> תיעוד הטבעה על המרכב</option>
                        <option value='תיעוד תווית המרכב'> תיעוד תווית המרכב</option>
                        <option value='משקל קדמי ימין'>משקל קדמי ימין </option>
                        <option value='משקל קדמי שמאל'> משקל קדמי שמאל </option>
                        <option value='משקל אחורי ימין'>משקל אחורי ימין</option>
                        <option value='משקל אחורי שמאל'> משקל אחורי שמאל </option>
                        <option value='משקל כללי'> משקל כללי </option>
                        <option value='משקל אחורי'> משקל אחורי </option>
                        <option value='משקל קדמי'> משקל קדמי </option>
                        <option value='תיעוד תווית דופן הידראולית'> תיעוד תווית דופן הידראולית </option>
                        <option value='תיעוד תווית מנוף'> תיעוד תווית מנוף</option>
                        <option value='תיעוד הטבעת על המנוף'> תיעוד הטבעת על המנוף</option>
                        <option value='תיעוד תוויות התקו צימוד'> תיעוד תוויות התקו צימוד</option>
                        <option value='תיעוד התקן צימוד'> תיעוד התקן צימוד</option>
                      </datalist>
                    </td>
                    <td>

                      <FileUpload
                          setFile={setImage7}
                          name='image7'
                          text='image7'
                      />
                    </td>
                    <td>
                      <div>
                        {pImage7 ? (
                            <img
                                src={pImage7}
                                alt='לא הועלה תמונה'
                                style={{width: '100px', height: '100px',transform: `rotate(${-90}deg)`}}
                            />
                        ) : (
                            'אין תמונה'
                        )}
                      </div>

                    </td>
                    <td>
                      <div
                          className={`${
                              !Image7 ? '' : 'bg-success'
                          } color-white p-1`}
                      >
                        {!Image7 ? '' : 'נבחר'}
                      </div>

                    </td>

                    <td>
                      <input className='reports_text'
                             type='checkbox'
                             style={{ marginLeft: '1rem' }}
                             checked={currentReport.image7_set}
                             onChange={(e) => {
                               setCurrentReport({
                                 ...currentReport,
                                 image7_set: e.target.checked,
                               });
                             }}
                      />
                      <label>
                        סמן לאישור
                      </label>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      8
                    </td>
                    <td>
                      <input
                          type='text'
                          name='image8'
                          onChange={onGroupChangeComment}
                          value={currentReport.image8.comments}
                          list='image8_list'
                      />
                      <datalist id='image8_list'>
                        <option value='תיעוד הטבעה על המרכב'> תיעוד הטבעה על המרכב</option>
                        <option value='תיעוד תווית המרכב'> תיעוד תווית המרכב</option>
                        <option value='משקל קדמי ימין'>משקל קדמי ימין </option>
                        <option value='משקל קדמי שמאל'> משקל קדמי שמאל </option>
                        <option value='משקל אחורי ימין'>משקל אחורי ימין</option>
                        <option value='משקל אחורי שמאל'> משקל אחורי שמאל </option>
                        <option value='משקל כללי'> משקל כללי </option>
                        <option value='משקל אחורי'> משקל אחורי </option>
                        <option value='משקל קדמי'> משקל קדמי </option>
                        <option value='תיעוד תווית דופן הידראולית'> תיעוד תווית דופן הידראולית </option>
                        <option value='תיעוד תווית מנוף'> תיעוד תווית מנוף</option>
                        <option value='תיעוד הטבעת על המנוף'> תיעוד הטבעת על המנוף</option>
                        <option value='תיעוד תוויות התקו צימוד'> תיעוד תוויות התקו צימוד</option>
                        <option value='תיעוד התקן צימוד'> תיעוד התקן צימוד</option>
                      </datalist>
                    </td>
                    <td>

                      <FileUpload
                          setFile={setImage8}
                          name='image8'
                          text='image8'
                      />
                    </td>
                    <td>
                      <div>
                        {pImage8 ? (
                            <img
                                src={pImage8}
                                alt='לא הועלה תמונה'
                                style={{width: '100px', height: '100px',transform: `rotate(${-90}deg)`}}
                            />
                        ) : (
                            'אין תמונה'
                        )}
                      </div>

                    </td>
                    <td>
                      <div
                          className={`${
                              !Image8 ? '' : 'bg-success'
                          } color-white p-1`}
                      >
                        {!Image8 ? '' : 'נבחר'}
                      </div>

                    </td>

                    <td>
                      <input className='reports_text'
                             type='checkbox'
                             style={{ marginLeft: '1rem' }}
                             checked={currentReport.image8_set}
                             onChange={(e) => {
                               setCurrentReport({
                                 ...currentReport,
                                 image8_set: e.target.checked,
                               });
                             }}
                      />
                      <label>
                        סמן לאישור
                      </label>
                    </td>
                  </tr>


                  <tr>
                    <td>
                      9
                    </td>
                    <td>
                      <input
                          type='text'
                          name='image9'
                          onChange={onGroupChangeComment}
                          value={currentReport.image9.comments}
                          list='image9_list'
                      />
                      <datalist id='image9_list'>
                        <option value='תיעוד הטבעה על המרכב'> תיעוד הטבעה על המרכב</option>
                        <option value='תיעוד תווית המרכב'> תיעוד תווית המרכב</option>
                        <option value='משקל קדמי ימין'>משקל קדמי ימין </option>
                        <option value='משקל קדמי שמאל'> משקל קדמי שמאל </option>
                        <option value='משקל אחורי ימין'>משקל אחורי ימין</option>
                        <option value='משקל אחורי שמאל'> משקל אחורי שמאל </option>
                        <option value='משקל כללי'> משקל כללי </option>
                        <option value='משקל אחורי'> משקל אחורי </option>
                        <option value='משקל קדמי'> משקל קדמי </option>
                        <option value='תיעוד תווית דופן הידראולית'> תיעוד תווית דופן הידראולית </option>
                        <option value='תיעוד תווית מנוף'> תיעוד תווית מנוף</option>
                        <option value='תיעוד הטבעת על המנוף'> תיעוד הטבעת על המנוף</option>
                        <option value='תיעוד תוויות התקו צימוד'> תיעוד תוויות התקו צימוד</option>
                        <option value='תיעוד התקן צימוד'> תיעוד התקן צימוד</option>
                      </datalist>
                    </td>
                    <td>

                      <FileUpload
                          setFile={setImage9}
                          name='image9'
                          text='image9'
                      />
                    </td>
                    <td>
                      <div>
                        {pImage9 ? (
                            <img
                                src={pImage9}
                                alt='לא הועלה תמונה'
                                style={{width: '100px', height: '100px',transform: `rotate(${-90}deg)`}}
                            />
                        ) : (
                            'אין תמונה'
                        )}
                      </div>

                    </td>
                    <td>
                      <div
                          className={`${
                              !Image9 ? '' : 'bg-success'
                          } color-white p-1`}
                      >
                        {!Image9 ? '' : 'נבחר'}
                      </div>

                    </td>

                    <td>
                      <input className='reports_text'
                             type='checkbox'
                             style={{ marginLeft: '1rem' }}
                             checked={currentReport.image9_set}
                             onChange={(e) => {
                               setCurrentReport({
                                 ...currentReport,
                                 image9_set: e.target.checked,
                               });
                             }}
                      />
                      <label>
                        סמן לאישור
                      </label>

                    </td>
                  </tr>


                  <tr>
                    <td>
                      10
                    </td>
                    <td>
                      <input
                          type='text'
                          name='image10'
                          onChange={onGroupChangeComment}
                          value={currentReport.image10.comments}
                          list='image10_list'
                      />
                      <datalist id='image10_list'>
                        <option value='תיעוד הטבעה על המרכב'> תיעוד הטבעה על המרכב</option>
                        <option value='תיעוד תווית המרכב'> תיעוד תווית המרכב</option>
                        <option value='משקל קדמי ימין'>משקל קדמי ימין </option>
                        <option value='משקל קדמי שמאל'> משקל קדמי שמאל </option>
                        <option value='משקל אחורי ימין'>משקל אחורי ימין</option>
                        <option value='משקל אחורי שמאל'> משקל אחורי שמאל </option>
                        <option value='משקל כללי'> משקל כללי </option>
                        <option value='משקל אחורי'> משקל אחורי </option>
                        <option value='משקל קדמי'> משקל קדמי </option>
                        <option value='תיעוד תווית דופן הידראולית'> תיעוד תווית דופן הידראולית </option>
                        <option value='תיעוד תווית מנוף'> תיעוד תווית מנוף</option>
                        <option value='תיעוד הטבעת על המנוף'> תיעוד הטבעת על המנוף</option>
                        <option value='תיעוד תוויות התקו צימוד'> תיעוד תוויות התקו צימוד</option>
                        <option value='תיעוד התקן צימוד'> תיעוד התקן צימוד</option>
                      </datalist>
                    </td>
                    <td>

                      <FileUpload
                          setFile={setImage10}
                          name='image10'
                          text='image10'
                      />
                    </td>
                    <td>
                      <div>
                        {pImage10 ? (
                            <img
                                src={pImage10}
                                alt='לא הועלה תמונה'
                                style={{width: '100px', height: '100px',transform: `rotate(${-90}deg)`}}
                            />
                        ) : (
                            'אין תמונה'
                        )}
                      </div>

                    </td>
                    <td>
                      <div
                          className={`${
                              !Image10 ? '' : 'bg-success'
                          } color-white p-1`}
                      >
                        {!Image10 ? '' : 'נבחר'}
                      </div>

                    </td>

                    <td>
                      <input className='reports_text'
                             type='checkbox'
                             style={{ marginLeft: '1rem' }}
                             checked={currentReport.image10_set}
                             onChange={(e) => {
                               setCurrentReport({
                                 ...currentReport,
                                 image10_set: e.target.checked,
                               });
                             }}
                      />
                      <label>
                        סמן לאישור
                      </label>

                    </td>
                  </tr>


                  <tr>
                    <td>
                      11
                    </td>
                    <td>
                      <input
                          type='text'
                          name='image11'
                          onChange={onGroupChangeComment}
                          value={currentReport.image11.comments}
                          list='image11_list'
                      />
                      <datalist id='image11_list'>
                        <option value='תיעוד הטבעה על המרכב'> תיעוד הטבעה על המרכב</option>
                        <option value='תיעוד תווית המרכב'> תיעוד תווית המרכב</option>
                        <option value='משקל קדמי ימין'>משקל קדמי ימין </option>
                        <option value='משקל קדמי שמאל'> משקל קדמי שמאל </option>
                        <option value='משקל אחורי ימין'>משקל אחורי ימין</option>
                        <option value='משקל אחורי שמאל'> משקל אחורי שמאל </option>
                        <option value='משקל כללי'> משקל כללי </option>
                        <option value='משקל אחורי'> משקל אחורי </option>
                        <option value='משקל קדמי'> משקל קדמי </option>
                        <option value='תיעוד תווית דופן הידראולית'> תיעוד תווית דופן הידראולית </option>
                        <option value='תיעוד תווית מנוף'> תיעוד תווית מנוף</option>
                        <option value='תיעוד הטבעת על המנוף'> תיעוד הטבעת על המנוף</option>
                        <option value='תיעוד תוויות התקו צימוד'> תיעוד תוויות התקו צימוד</option>
                        <option value='תיעוד התקן צימוד'> תיעוד התקן צימוד</option>
                      </datalist>
                    </td>
                    <td>

                      <FileUpload
                          setFile={setImage11}
                          name='image11'
                          text='image11'
                      />
                    </td>
                    <td>
                      <div>
                        {pImage11 ? (
                            <img
                                src={pImage11}
                                alt='לא הועלה תמונה'
                                style={{width: '100px', height: '100px',transform: `rotate(${-90}deg)`}}
                            />
                        ) : (
                            'אין תמונה'
                        )}
                      </div>

                    </td>
                    <td>
                      <div
                          className={`${
                              !Image11 ? '' : 'bg-success'
                          } color-white p-1`}
                      >
                        {!Image11 ? '' : 'נבחר'}
                      </div>

                    </td>

                    <td>
                      <input className='reports_text'
                             type='checkbox'
                             style={{ marginLeft: '1rem' }}
                             checked={currentReport.image11_set}
                             onChange={(e) => {
                               setCurrentReport({
                                 ...currentReport,
                                 image11_set: e.target.checked,
                               });
                             }}
                      />
                      <label>
                        סמן לאישור
                      </label>
                    </td>
                  </tr>



                  <tr>
                    <td>
                      12
                    </td>
                    <td>
                      <input
                          type='text'
                          name='image12'
                          onChange={onGroupChangeComment}
                          value={currentReport.image12.comments}
                          list='image12_list'
                      />
                      <datalist id='image12_list'>
                        <option value='תיעוד הטבעה על המרכב'> תיעוד הטבעה על המרכב</option>
                        <option value='תיעוד תווית המרכב'> תיעוד תווית המרכב</option>
                        <option value='משקל קדמי ימין'>משקל קדמי ימין </option>
                        <option value='משקל קדמי שמאל'> משקל קדמי שמאל </option>
                        <option value='משקל אחורי ימין'>משקל אחורי ימין</option>
                        <option value='משקל אחורי שמאל'> משקל אחורי שמאל </option>
                        <option value='משקל כללי'> משקל כללי </option>
                        <option value='משקל אחורי'> משקל אחורי </option>
                        <option value='משקל קדמי'> משקל קדמי </option>
                        <option value='תיעוד תווית דופן הידראולית'> תיעוד תווית דופן הידראולית </option>
                        <option value='תיעוד תווית מנוף'> תיעוד תווית מנוף</option>
                        <option value='תיעוד הטבעת על המנוף'> תיעוד הטבעת על המנוף</option>
                        <option value='תיעוד תוויות התקו צימוד'> תיעוד תוויות התקו צימוד</option>
                        <option value='תיעוד התקן צימוד'> תיעוד התקן צימוד</option>
                      </datalist>
                    </td>
                    <td>

                      <FileUpload
                          setFile={setImage12}
                          name='image12'
                          text='image12'
                      />
                    </td>
                    <td>
                      <div>
                        {pImage12 ? (
                            <img
                                src={pImage12}
                                alt='לא הועלה תמונה'
                                style={{width: '100px', height: '100px',transform: `rotate(${-90}deg)`}}
                            />
                        ) : (
                            'אין תמונה'
                        )}
                      </div>

                    </td>
                    <td>
                      <div
                          className={`${
                              !Image12 ? '' : 'bg-success'
                          } color-white p-1`}
                      >
                        {!Image12 ? '' : 'נבחר'}
                      </div>

                    </td>

                    <td>
                      <input className='reports_text'
                             type='checkbox'
                             style={{ marginLeft: '1rem' }}
                             checked={currentReport.image12_set}
                             onChange={(e) => {
                               setCurrentReport({
                                 ...currentReport,
                                 image12_set: e.target.checked,
                               });
                             }}
                      />
                      <label>
                        סמן לאישור
                      </label>
                    </td>
                  </tr>


                </table>
              </div>

              <div className='flex justify-content-center mt-4 mb-3'>
                <button
                    type='submit'
                    className='button bg-secondary w-400 reports__submit'
                    style={{ padding: '2rem', fontSize: '1.4em' }}
                >
                  {currentReport.id ? 'צור טופס' : 'עדכן טופס'}
                </button>

              </div>


            </div>
          </div>
          <ToastContainer position='bottom-left' autoClose={2500} />

        </form>
        <Loader
            isVisible={isLoading}
            text='טוען נתונים...'
        />

      </React.Fragment>
  );
};

const stateToProps = (state) => {
  return {
    user: state.userReducer,
  };
};

export default connect(stateToProps)(Report_Type_2);
