import firebase from 'firebase';
import 'firebase/storage';
/*
const firebaseConfig = {
  apiKey: "AIzaSyAFlS5Yna1r5nHZxeLsRsuMoF-VPKR0Jns",
  authDomain: "hit-lab-storage.firebaseapp.com",
  projectId: "hit-lab-storage",
  storageBucket: "hit-lab-storage.appspot.com",
  messagingSenderId: "69836550437",
  appId: "1:69836550437:web:ce3923b4414af1ee3aaacc",
  measurementId: "G-29GFYQ4DRC"
};
*/

/*
const firebaseConfig = {
  apiKey: "AIzaSyAb3oY78KRwUk8v4_2RSJuEL2U8_23z5rU",
  authDomain: "hitlab-16dd5.firebaseapp.com",
  projectId: "hitlab-16dd5",
  storageBucket: "hitlab-16dd5.appspot.com",
  messagingSenderId: "787261953920",
  appId: "1:787261953920:web:c1b1156bcabda6afa25365",
  measurementId: "G-CTN634EV29"
};
*/

const firebaseConfig = {
  apiKey: "AIzaSyDEbxD2wE1-MBNTi_uLLI_bI4mCqfXUycM",
  authDomain: "storage-73f1d.firebaseapp.com",
  projectId: "storage-73f1d",
  storageBucket: "storage-73f1d.appspot.com",
  messagingSenderId: "305592496635",
  appId: "1:305592496635:web:74e422f15a86b3ee771b5e"
};



firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default };
